import { gql } from '@apollo/client';

export const GET_FABRIC_INDIVIDUAL_PRICING = gql`
  query GetFabricIndividualPricing {
    productRetailPrices(pricingType: INDIVIDUAL) {
      coreFabricId
      amount
      productCategory
    }
    settings {
      productCategories {
        key
        truncatedName
      }
    }
    coreFabrics(withIndividualPricing: true) {
      id
      code
      name
    }
  }
`;
