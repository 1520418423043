import router from 'next/router';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { gql } from '@apollo/client';
import { GarmentTemplatesListQuery, ProductCategory, useOrderItemGarmentPageAddOrderItemMutation, useProductCategoriesSelectQuery } from '@graphql';
import { Modal } from 'components';
import { uiVar } from 'graphql/common';
import { AppLayout } from 'layouts';
import { Button, parseRouteId } from 'modules/common';
import { GarmentTemplateListModalLayout, isProduct } from 'modules/garments';
import mixpanelService from 'services/mixpanel.service';

export type AddItemTemplate = GarmentTemplatesListQuery['designTemplates'][0];

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (isVisible: boolean) => void;
}

export const AddItemModal = ({ isModalOpen, setIsModalOpen }: Props) => {
  const { orderId } = router.query;
  const [isSaving, setIsSaving] = useState(false);
  const [productCategory, setProductCategory] = useState<ProductCategory>();
  const [selectedTemplate, setSelectedTemplate] = useState<AddItemTemplate>();
  const [searchText, setSearchText] = useState('');

  const { data: productCategoriesData, loading: productCategoriesLoading } = useProductCategoriesSelectQuery({ fetchPolicy: 'cache-first' });
  const productCategories = productCategoriesData?.settings?.productCategories;

  useEffect(() => {
    if (!productCategory && productCategories?.length) {
      let defaultProduct = 'MENS_JACKET_PANTS' as ProductCategory;
      if (!productCategories.find(({ key }) => key === 'MENS_JACKET_PANTS')) defaultProduct = productCategories[0].key;
      if (productCategories.length) setProductCategory(defaultProduct);
    }
  }, [productCategories]);

  const onModalClose = () => {
    setSelectedTemplate(undefined);
    setIsModalOpen(false);
  };

  const [addItem] = useOrderItemGarmentPageAddOrderItemMutation();

  const handleSave = async () => {
    try {
      uiVar('saving');
      setIsSaving(true);
      const parsedOrderId = parseRouteId(orderId);
      const { data } = await addItem({
        variables: {
          orderId: parsedOrderId,
          orderItemInput: {
            productCategory: productCategory,
            templateId: selectedTemplate.id,
          },
        },
      });

      mixpanelService.track('ORDER_ADD_ITEM', {
        orderId: parsedOrderId,
        orderItemId: data?.addOrderItem?.id,
        productCategory,
        searchText,
        isProduct: isProduct(selectedTemplate),
      });

      uiVar('ready');
      if (!data.addOrderItem.id) {
        console.error('Tried re-routing after adding order item but there was no item id.');
        setIsSaving(false);
        return;
      }
      router.push(`/orders/${orderId}/order-item/${data.addOrderItem.id}/fabric`);
    } catch (e) {
      setIsSaving(false);
      console.error(e);
      toast.error("Couldn't save item. Please try again.");
    }
  };

  return (
    <Modal
      isOpen={isModalOpen && !productCategoriesLoading}
      setIsOpen={setIsModalOpen}
      onClose={onModalClose}
      alignTop
      className="sm:max-w-xl md:max-w-4xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl"
    >
      <div className="flex">
        <div className="w-full">
          <GarmentTemplateListModalLayout
            productCategory={productCategory}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            searchText={searchText}
            setSearchText={setSearchText}
            setProductCategory={setProductCategory}
          />
        </div>
      </div>
      <div className="mt-10 flex justify-between">
        <Button variant="neutral" onClick={() => setIsModalOpen(false)}>
          Cancel
        </Button>
        {!selectedTemplate && (
          <Button variant="neutral" isDisabled>
            Select a product to continue
          </Button>
        )}
        {selectedTemplate && (
          <Button onClick={handleSave} isDisabled={isSaving}>
            Continue
          </Button>
        )}
      </div>
    </Modal>
  );
};

AddItemModal.mutation = gql`
  mutation OrderItemGarmentPageAddOrderItem($orderId: ID!, $orderItemInput: CreateOrderItemInput!) {
    addOrderItem(orderId: $orderId, orderItemInput: $orderItemInput) {
      id
    }
  }
`;

AddItemModal.getLayout = function getLayout(page) {
  return <AppLayout>{page}</AppLayout>;
};
