import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import cn from 'classnames';
import Link from 'next/link';

import { GetOrdersQueryResult, useGetOrdersQuery, OrderFulfilmentStatus, OrderStatus, SortOrder } from '@graphql';
import { serializeArrayQueryParams } from 'helpers/string-helpers';
import { Table, Column, Pagination } from 'components/Table';
import { ArrayElement } from 'types/common';

type Order = ArrayElement<GetOrdersQueryResult['data']['orders']['items']>;

interface Props {
  orders: GetOrdersQueryResult['data']['orders']['items'];
  total?: number;
  isLoading: boolean;
  paginate?: boolean;
  hideCustomer?: boolean;
}

const CustomIdTd = (info) => {
  const customId = info.getValue() as Order['customId'];
  const orderId = info.row.original.id;

  return orderId ? (
    <div className="text-center text-blue-500 font-semibold hover:text-blue-600 truncate w-40 mx-auto">
      <Link href={`/orders/${orderId}`}>{customId || orderId}</Link>
    </div>
  ) : null;
};

const OrderStatusTd = (info) => {
  const orderStatus = info.getValue() as Order['orderStatus'];

  return orderStatus ? (
    <div
      className={cn('font-semibold', {
        'text-emerald-500': orderStatus?.key === OrderStatus.Draft || orderStatus?.key === OrderStatus.Complete,
        'text-amber-500': orderStatus?.key === OrderStatus.Processing || orderStatus?.key === OrderStatus.ReadyToProcess,
        'text-red-500': orderStatus?.key === OrderStatus.Error || orderStatus?.key === OrderStatus.Cancelled,
      })}
    >
      {orderStatus?.name}
    </div>
  ) : null;
};

const FulfilmentStatusTd = (info) => {
  const orderFulfilmentStatus = info.getValue() as Order['orderFulfilmentStatus'];

  return orderFulfilmentStatus ? (
    <div
      className={cn('font-semibold', {
        'text-emerald-500': orderFulfilmentStatus?.key === OrderFulfilmentStatus.Fulfilled,
        'text-amber-500': orderFulfilmentStatus?.key === OrderFulfilmentStatus.PartiallyFulfilled,
        'text-red-500': orderFulfilmentStatus?.key === OrderFulfilmentStatus.Unfulfilled,
      })}
    >
      {orderFulfilmentStatus?.name}
    </div>
  ) : null;
};

const DateTd = (info) => {
  const date = info.getValue();

  return date ? new Date(date).toLocaleDateString() : null;
};

const CustomerTd = (info) => {
  const customer = info.getValue() as Order['customer'];

  return customer ? (
    <Link href={`/customers/${customer?.id}`} className="text-blue-500 font-semibold hover:text-blue-600">
      {customer?.firstName} {customer?.lastName}
    </Link>
  ) : null;
};

const SalesRepTd = (info) => {
  const salesRep = info.getValue() as Order['salesRep'];

  return salesRep ? (
    <div>
      {salesRep?.firstName} {salesRep?.lastName}
    </div>
  ) : null;
};

export const OrdersTable = ({ orders = [], total, isLoading, paginate = true, hideCustomer = false }: Props) => {
  return (
    <Table<Order> data={orders} loading={isLoading} className="text-center">
      <Column<Order> accessor="customId" header="Order #" cell={CustomIdTd} />
      <Column<Order> accessor="orderStatus" header="Order Status" cell={OrderStatusTd} />
      <Column<Order> accessor="orderFulfilmentStatus" header="Fulfilment Status" cell={FulfilmentStatusTd} />
      <Column<Order> accessor="createdAt.origin" header="Created At" cell={DateTd} />
      <Column<Order> accessor="processedAt" header="Processed At" cell={DateTd} />
      {!hideCustomer && <Column<Order> accessor="customer" header="Customer" cell={CustomerTd} />}
      <Column<Order> accessor="salesRep" header="Sales Rep" cell={SalesRepTd} />
      {paginate && <Pagination count={orders?.length} total={total} />}
    </Table>
  );
};

export const OrdersListTable = () => {
  const { query } = useRouter();

  const { data, loading: isLoading } = useGetOrdersQuery({
    variables: {
      search: query?.search as string,
      customerId: query?.customer as string,
      salesRepId: query?.user as string,
      showroomId: query?.showroom as string,
      orderStatus: serializeArrayQueryParams(query?.orderStatus) as OrderStatus[],
      orderFulfilmentStatus: serializeArrayQueryParams(query?.orderFulfilmentStatus) as OrderFulfilmentStatus[],
      createdAt: query?.from && query?.to ? { from: query?.from, to: query?.to } : undefined,
      sortField: 'createdAt',
      sortOrder: SortOrder.Desc,
      pagination: { pageSize: Number(query?.pageSize) || 10, page: Number(query?.page) || 1 },
    },
    fetchPolicy: 'cache-first',
  });

  return <OrdersTable orders={data?.orders?.items} total={data?.orders?.total} isLoading={isLoading} />;
};

OrdersListTable.fragments = {
  orderRow: gql`
    fragment OrderRow on Order {
      id
      customId
      customer {
        id
        customId
        firstName
        lastName
      }
      salesRep {
        firstName
        lastName
      }
      createdAt {
        origin
        fromNow
      }
      orderStatus {
        key
        name
        message
      }
      orderFulfilmentStatus {
        key
        name
      }
      processedAt
    }
  `,
};

OrdersListTable.queries = {
  getOrders: gql`
    ${OrdersListTable.fragments.orderRow}

    query GetOrders(
      $orderNumber: Int
      $search: String
      $customerId: ID
      $salesRepId: ID
      $showroomId: ID
      $orderStatus: [OrderStatus!]
      $orderFulfilmentStatus: [OrderFulfilmentStatus!]
      $createdAt: DateRangeInput
      $take: Int
      $sortField: String
      $sortOrder: SortOrder
      $pagination: PaginationInput
    ) {
      orders(
        orderNumber: $orderNumber
        search: $search
        customerId: $customerId
        salesRepId: $salesRepId
        showroomId: $showroomId
        orderStatus: $orderStatus
        orderFulfilmentStatus: $orderFulfilmentStatus
        createdAt: $createdAt
        take: $take
        sortField: $sortField
        sortOrder: $sortOrder
        pagination: $pagination
      ) {
        items {
          ...OrderRow
        }
        total
      }
    }
  `,
};
