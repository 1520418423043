import { FetchPolicy, gql } from '@apollo/client';
import { apolloClientUnauthenticated } from 'libs/apollo';

import { FeatureFlagsQuery } from '@graphql';

export const GET_FEATURE_FLAGS = gql`
  query FeatureFlags {
    featureFlags {
      AUTH_AUTH0
      USER_MANAGEMENT
      DISCOUNTS
    }
  }
`;

export const getFeatureFlags = async (fetchPolicy: FetchPolicy = 'cache-first') => {
  const { data } = await apolloClientUnauthenticated.query<FeatureFlagsQuery>({
    query: GET_FEATURE_FLAGS,
    fetchPolicy,
  });

  return data.featureFlags;
};
