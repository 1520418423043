import startOfDay from 'date-fns/startOfDay';
import startOfWeek from 'date-fns/startOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfDay from 'date-fns/endOfDay';
import endOfWeek from 'date-fns/endOfWeek';
import endOfMonth from 'date-fns/endOfMonth';
import subDays from 'date-fns/subDays';
import subWeeks from 'date-fns/subWeeks';
import subMonths from 'date-fns/subMonths';
import differenceInDays from 'date-fns/differenceInDays';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import { enGB } from 'date-fns/locale';
import { timeDay, timeHour, timeWeek, timeMonth, CountableTimeInterval } from 'd3-time';

import { DateRangeInput, GarmentCategory } from '@graphql';
import { Alteration } from 'modules/reporting/components/AlterationsPeriodData';

export enum TimeBucket {
  hour = 'hour',
  day = 'day',
  week = 'week',
  month = 'month',
}

export enum TimePeriod {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Custom = 'Custom',
}

export type DateRange = {
  from: Date;
  to: Date;
};

export type DateRangeSerialized = {
  from: string;
  to: string;
};

export const START_OF_2022 = startOfDay(new Date('2022-01-01'));

export const validateDateString = (dateString: string) => {
  const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date(), { locale: enGB });

  if (+parsedDate < +START_OF_2022) return false;

  return isValid(parsedDate);
};

export const parseDateRange = (dateRangeInput: DateRangeInput) => ({
  from: dateRangeInput?.from ? startOfDay(new Date(dateRangeInput.from)) : null,
  to: dateRangeInput?.to ? endOfDay(new Date(dateRangeInput.to)) : null,
});

export const getDailyRange = (daysAgo = 1, date: Date = new Date()): DateRange => {
  return {
    from: startOfDay(subDays(date, daysAgo)),
    to: endOfDay(subDays(date, daysAgo)),
  };
};

export const getWeeklyRange = (weeksAgo = 1, date: Date = new Date()): DateRange => {
  return {
    from: startOfWeek(subWeeks(date, weeksAgo), { weekStartsOn: 1 }),
    to: endOfWeek(subWeeks(date, weeksAgo), { weekStartsOn: 1 }),
  };
};

export const getMonthlyRange = (monthsAgo = 1, date: Date = new Date()): DateRange => {
  return {
    from: startOfMonth(subMonths(date, monthsAgo)),
    to: endOfMonth(subMonths(date, monthsAgo)),
  };
};

export const getCustomRange = (from: Date, to: Date, isPrev = false) => {
  // calculate diff, mon - sun = 6 so add 1 to equal 7 (11.59.59 etc)
  let diff = differenceInDays(to, from) + 1;

  if (!isPrev) {
    diff = 0;
  }

  return {
    from: startOfDay(subDays(from, diff)),
    to: endOfDay(subDays(to, diff)),
  };
};

export const timePeriodRange = {
  [TimePeriod.Daily]: getDailyRange,
  [TimePeriod.Weekly]: getWeeklyRange,
  [TimePeriod.Monthly]: getMonthlyRange,
  [TimePeriod.Custom]: getCustomRange,
};

export const getTimePeriodFromRange = (range: DateRange) => {
  const diff = differenceInDays(range.to, range.from) + 1;

  if (diff === 1) return TimePeriod.Daily;
  if (diff === 7 && +startOfWeek(range.from, { weekStartsOn: 1 }) === +range.from && +endOfWeek(range.to, { weekStartsOn: 1 }) === +range.to)
    return TimePeriod.Weekly;
  if (diff >= 28 && diff <= 31 && +startOfMonth(range.from) === +range.from && +endOfMonth(range.to) === +range.to) return TimePeriod.Monthly;

  return TimePeriod.Custom;
};

export const serializeRange = ({ from, to }: DateRange) => ({
  from: from ? from.toISOString() : null,
  to: to ? to.toISOString() : null,
});

export const deserializeRange = ({ from, to }: DateRangeSerialized) => ({
  from: from ? new Date(from) : null,
  to: to ? new Date(to) : null,
});

export const formatAsPercent = (num: number) => {
  if (isNaN(num)) return '0%';

  return new Intl.NumberFormat('default', {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(num);
};

export const formatAsCurrency = (num: number, locale: string, currency: string, stripIfInteger = true, isRound = false) => {
  const roundedNum = isRound ? Math.round(num) : num;
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })
    .format(roundedNum)
    .replace('.00', stripIfInteger ? '' : '.00');
};

export const formatAsDecimal = (num: number) => {
  return new Intl.NumberFormat('default', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
};

export const getTotalAlterationsCost = (alterations?: Alteration[], garmentCategory?: GarmentCategory) => {
  if (!alterations) return 0;

  return alterations.reduce((a, alteration) => {
    return (
      a +
      alteration?.items?.reduce((b, item) => {
        // filter out items in the alteration that are not within the category
        const cost =
          garmentCategory && item.orderItemDesign.garmentCategory.key !== garmentCategory
            ? 0
            : item?.options?.reduce((c, o) => c + (o?.cost?.amount || 0), 0);

        return b + cost;
      }, 0)
    );
  }, 0);
};

export const getTotalAlterations = (alterations?: Alteration[], garmentCategory?: GarmentCategory) => {
  if (!alterations) return 0;

  return alterations.reduce((a, alteration) => {
    return (
      a +
      alteration?.items?.reduce((b, item) => {
        // filter out items in the alteration that are not within the category
        return b + (garmentCategory && item.orderItemDesign.garmentCategory.key !== garmentCategory ? 0 : item?.options?.length);
      }, 0)
    );
  }, 0);
};

export const getStatChange = (
  stat: number,
  previousStat: number,
  locale?: string,
  currency?: string,
  formatter?: (num: number, locale: string, currency: string) => string
) => {
  const diff = stat - previousStat;
  const changeType = diff > 0 ? 'increase' : diff < 0 ? 'decrease' : '';
  const change = previousStat === 0 ? '' : stat === 0 ? -1 : diff / previousStat;

  return {
    stat: `${formatter ? formatter(stat, locale, currency) : stat}`,
    previousStat: `${formatter ? formatter(previousStat, locale, currency) : previousStat}`,
    change: change ? formatAsPercent(change) : '',
    changeType,
    diff: `${diff > 0 ? '+' : ''}${formatter ? formatter(diff, locale, currency) : diff}`,
  };
};

export const divideIfNotZero = (numerator, denominator) => {
  if (denominator === 0 || isNaN(denominator)) {
    return 0;
  } else {
    return numerator / denominator;
  }
};

export const getSortedOptions = <Type>(data: Type[], getId: (d: Type) => string, getValue: (d: Type) => string) => {
  return data.map((d) => ({ id: getId(d), value: getValue(d) })).sort((a, b) => a.value.localeCompare(b.value));
};

export const parseAlterationOption = (v: string) => {
  const num = parseInt(v);

  if (!num) return '';
  return num > 0 ? `Let out` : `Take in`;
};

export const getTimeSeriesEmptyData = (range: DateRange): [string, number][] => {
  const days = differenceInDays(range.to, range.from);
  let timeFn: CountableTimeInterval = timeDay;

  if (days <= 2) {
    timeFn = timeHour;
  } else if (days > 1 && days <= 45) {
    timeFn = timeDay;
  } else if (days > 45 && days <= 180) {
    timeFn = timeWeek;
  } else {
    timeFn = timeMonth;
  }

  const intervals = timeFn.range(range.from, range.to);

  return intervals.map((d) => [d.toString(), 0]);
};
