import { formatAsCurrency } from 'helpers';
import { FabricProductCategoryPrice } from '../IndividualPricingState';
import cn from 'classnames';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { useEffect, useRef } from 'react';
import { validateNumber } from 'helpers/measurement-helpers';

type Props = {
  priceInfo: FabricProductCategoryPrice;
  isDisabled: boolean;
  onSwitchEditMode: () => void;
  onPriceUpdated: (rawAmount: string) => void;
};

export const InteractivePriceTableData = ({ priceInfo, isDisabled, onSwitchEditMode, onPriceUpdated }: Props) => {
  const { tailor } = useActiveSession();
  const inputRef = useRef<HTMLInputElement>();
  const { isEditMode, isUpdated, rawAmount } = priceInfo;

  useEffect(() => {
    if (isEditMode) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  const hasValue = rawAmount.length > 0;
  return (
    <td
      className={cn('p-4 border border-gray-300 text-center', {
        'pointer-events-none': isDisabled,
        'cursor-pointer hover:bg-blue-100': !isDisabled && !isEditMode,
        'bg-orange-300': !isEditMode && isUpdated,
      })}
      onClick={() => !isEditMode && onSwitchEditMode()}
    >
      {(() => {
        switch (isEditMode && !isDisabled) {
          case false:
            if (hasValue) {
              return <span>{formatAsCurrency(Number(rawAmount), tailor.locale, tailor.currency)}</span>;
            } else {
              return <span data-testid="no-value">&nbsp;</span>;
            }
          case true:
            return (
              <input
                className="w-20"
                type="text"
                ref={inputRef}
                value={rawAmount}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length === 0 || validateNumber(value, 2)) {
                    onPriceUpdated(value);
                  }
                }}
                onBlur={() => {
                  if (rawAmount.length > 0) onPriceUpdated(Number(rawAmount).toString());
                  onSwitchEditMode();
                }}
              />
            );
        }
      })()}
    </td>
  );
};
