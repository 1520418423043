import { gql } from '@apollo/client';
import { useCreateAlterationMutation, useGetActiveAlterationProvidersQuery } from '@graphql';
import { Box, Input, Select } from 'components';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CreateAlterationInput } from 'types/generated';
import { AlterationsFormItems } from 'modules/alterations/components';
import mixpanelService from 'services/mixpanel.service';

export const AlterationsForm = () => {
  const router = useRouter();

  const formMethods = useForm<CreateAlterationInput>({
    defaultValues: {
      alterationProviderId: null,
      dueAt: dayjs().format('YYYY-MM-DD'),
      alterationItems: [
        {
          orderItemDesignId: null,
          alterationItemOptions: [],
          note: '',
        },
      ],
      orderId: '',
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
  } = formMethods;

  const { data: alterationProvidersData } = useGetActiveAlterationProvidersQuery();

  const watchedProviderId = watch('alterationProviderId');

  const [createAlteration, { loading: mutationLoading }] = useCreateAlterationMutation({
    onCompleted: (data) => {
      toast.success('Alteration created.');

      mixpanelService.track('ALTERATION_CREATE', {
        orderId: String(router.query.orderId),
        alterationId: data.createAlteration.id,
        fromOrderPage: !!router.query.fromOrderPage,
      });

      router.push(`/alterations/${data.createAlteration.id}`);
    },

    onError: () => {
      toast.error('Please try again.');
    },
  });

  const onSubmit = handleSubmit(async (formData) => {
    await createAlteration({
      variables: { createAlterationInput: { ...formData } },
    });
  });

  return (
    <>
      <FormProvider {...formMethods}>
        <Box>
          <form onSubmit={onSubmit} id="newAlterationForm" data-testid="new-alteration-form">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Select
                  label="Select a provider"
                  register={register('alterationProviderId', {
                    required: true,
                    onChange: () => resetField('alterationItems'),
                  })}
                  errorMessage={errors.alterationProviderId?.message}
                  testId="select-alteration-provider"
                >
                  <option value="">Please select a provider:</option>
                  {alterationProvidersData?.alterationProviders?.items.map((provider) => (
                    <option value={provider.id} key={provider.id} data-testid={`alteration-provider-${provider.name}`}>
                      {provider.name}
                    </option>
                  ))}
                </Select>
              </div>

              <div>
                <Input label="Due date" htmlProps={{ type: 'date' }} register={register('dueAt', { required: true })} />
              </div>
            </div>

            {Boolean(watchedProviderId) && <AlterationsFormItems isLoading={mutationLoading} />}
          </form>
        </Box>
      </FormProvider>
    </>
  );
};

AlterationsForm.queries = {
  activeAlterationProviders: gql`
    query GetActiveAlterationProviders($isActive: Boolean, $pagination: PaginationInput, $take: Int) {
      alterationProviders(isActive: $isActive, pagination: $pagination, take: $take) {
        items {
          id
          name
          isActive
          address
          contactPerson
          contactNumber
          email
          updatedAt {
            origin
            fromNow
          }
        }
      }
    }
  `,
};

AlterationsForm.mutations = {
  createAlteration: gql`
    mutation CreateAlteration($createAlterationInput: CreateAlterationInput!) {
      createAlteration(createAlterationInput: $createAlterationInput) {
        id
      }
    }
  `,
};
