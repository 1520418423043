import { Tabs } from 'components';
import { useDesignOptionsForm } from '../designOptions.context';
import { DesignOptionsSummaryList } from './DesignOptionsSummaryList';

export const DesignOptionsSummaryListContainer = ({ designId, isTemplate }: { designId: string; isTemplate: boolean }) => {
  const { config } = useDesignOptionsForm();

  return (
    config && (
      <div className="mt-6 p-4">
        {Boolean(config.extras.length) && (
          <Tabs
            tabs={[
              {
                name: 'Foundations',
                content: <DesignOptionsSummaryList groups={config.foundations} designId={designId} isTemplate={isTemplate} />,
              },
              {
                name: 'Extras',
                content: <DesignOptionsSummaryList groups={config.extras} designId={designId} isTemplate={isTemplate} />,
              },
            ]}
          />
        )}
        {!config.extras.length && <DesignOptionsSummaryList groups={config.foundations} designId={designId} isTemplate={isTemplate} />}
      </div>
    )
  );
};
