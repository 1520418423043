import classNames from 'classnames';
import Image from 'next/legacy/image';
import { useMemo } from 'react';

import MissingImage from 'assets/svg/missing.svg';
import { useDesignOptionsForm } from '../designOptions.context';
import { DesignOptionField } from '../designOptions.types';
import { DesignOptionsSelect } from './DesignOptionsSelect';

type Props = {
  className?: string;
  field: DesignOptionField;
  groupNames?: string[];
  subGroupLabel?: string;
  errorMessage?: string;
  isDisabled?: boolean;
};

const conflictsTextLength = 250;

const formatLongConflicts = (conflicts: string) => {
  const splitedConflicts = conflicts.split(' or ');
  if (splitedConflicts) {
    const formattedConflicts = splitedConflicts.map((option, index) => (
      <p key={index}>
        {index > 0 && <span className="mx-2 font-bold">or</span>}
        {option.trim()}
      </p>
    ));
    return formattedConflicts;
  } else {
    return conflicts;
  }
};

export const DesignOptionsSelectNestedGraphic = ({
  className,
  field,
  groupNames = [],
  subGroupLabel = '',
  errorMessage,
  isDisabled = false,
}: Props) => {
  const { formData, setAndSaveField } = useDesignOptionsForm();
  const selectedCode = formData[field.name];
  const groups = useMemo(() => {
    const options = field.texts.map((name, i) => ({
      name,
      code: field.values[i],
      illustration: field.illustrations[i],
      image: field.images[i],
      price: field.prices[i],
      sortOrder: field.sortOrders[i],
    }));
    return Object.entries<{ name: string; code: string; illustration: string; image: string; price: string; sortOrder: number; sortField: string }[]>(
      options.reduce(
        (result, option) => {
          const { name, code, illustration, image, price, sortOrder } = option;
          const groupMatch = groupNames.find((groupName) => String(name).toLowerCase().includes(groupName.toLowerCase()));
          if (groupMatch) {
            result[groupMatch].push({
              name: String(name).replace(new RegExp(groupMatch, 'i'), '').trim(),
              code,
              illustration,
              image,
              price,
              sortOrder,
            });
          } else {
            result[name] = [option];
          }
          return result;
        },
        groupNames.reduce((r, g) => ({ [g]: [], ...r }), {})
      )
    )
      .map(([groupName, options]) => ({
        groupName,
        groupIllustration: options?.find((x) => !!x.illustration)?.illustration,
        groupImage: options[0]?.image,
        groupPrice: options[0]?.price,
        selected: !!options.map((o) => o.code).find((c) => c === selectedCode),
        options: options.some((x) => !!x.sortOrder) ? options.sort((a, b) => a.sortOrder - b.sortOrder) : options,
      }))
      .sort((a, b) => a.groupName.localeCompare(b.groupName));
  }, [field.texts, selectedCode]);
  const selectedGroup = groups.find((g) => g.selected);

  return (
    <div className={classNames('w-full', className)}>
      <div className="flex flex-col content-between w-full mb-1">
        <div className="text-gray-500 text-xs font-medium">{field.label}</div>
        {errorMessage && (
          <div className=" bg-fire-500 text-fire-50 rounded p-2 mt-2 text-xs leading-normal">
            {errorMessage.length < conflictsTextLength ? <div className="text-center">{errorMessage}</div> : formatLongConflicts(errorMessage)}
          </div>
        )}
      </div>
      <div className="flex flex-row flex-wrap -mx-2 mt-4">
        {groups
          .filter(({ options }) => options.length)
          .filter(({ groupName }) => String(groupName).toLowerCase() !== 'please select')
          .map(({ groupName, groupIllustration, groupImage, groupPrice, selected, options }) => (
            <div key={groupName} className="max-w-xs w-full h-28 xl:h-40 md:w-1/2 lg:w-1/3 xl:w-1/4 px-2 xl:px-2 mb-2 lg:mb-4 xl:mb-4 group">
              <div
                className={classNames(
                  selected && !errorMessage && 'bg-yellow-crayola-50 border-yellow-crayola-500',
                  selected && errorMessage && 'bg-fire-50 border-fire-500',
                  !selected && 'hover:bg-yellow-crayola-50 hover:border-yellow-crayola-500',
                  isDisabled ? 'cursor-wait' : 'cursor-pointer',
                  'flex-0 flex flex-col p-1 border h-full relative'
                )}
                onClick={() =>
                  !isDisabled &&
                  !selected &&
                  setAndSaveField({ id: field.name, value: String(options[0].code), label: field.label, displayValue: groupName })
                }
                data-option-type={field.name}
                data-option-code={options[0]?.code}
              >
                {groupPrice && (
                  <div
                    className={`text-xs rounded text-aquamarine-800 uppercase flex items-center px-2 font-normal leading-4 bg-aquamarine-100 absolute top-2 right-2 z-10`}
                  >
                    {groupPrice}
                  </div>
                )}
                {groupIllustration ? (
                  <div className="grow m-2 relative">
                    <Image layout="fill" src={groupIllustration} />
                    {groupImage && (
                      <span className="hidden group-hover:block">
                        <Image objectFit="cover" layout="fill" src={groupImage} />
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="pl-1 grow flex m-2 justify-center items-center">
                    <MissingImage className="max-h-14 xl:max-h-24 fill-neutral-500" />
                  </div>
                )}
                <div className="flex font-semibold text-xs h-10 items-center justify-center text-center">{groupName}</div>
              </div>
            </div>
          ))}
      </div>
      {selectedGroup?.options?.length > 1 && (
        <DesignOptionsSelect
          className="w-full"
          isDisabled={isDisabled}
          field={
            selectedGroup.options.reduce(
              (r, o) => ({ label: r.label, name: r.name, type: r.type, texts: [...r.texts, o.name], values: [...r.values, o.code] }),
              {
                label: subGroupLabel,
                name: field.name,
                type: 'SELECT',
                texts: [],
                values: [],
              }
            ) as DesignOptionField
          }
          errorMessage={errorMessage && ' '}
        />
      )}
    </div>
  );
};
