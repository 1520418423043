import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Button } from 'modules/common';

interface SlideoverPanelProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  heading?: string;
  subheading?: string;
  maxWidthCss?: string;
  children: React.ReactNode;
  submitButton?: React.ReactNode;
  showCancel?: boolean;
  showExit?: boolean;
}

const SlideoverPanel = ({
  isOpen,
  setIsOpen,
  heading,
  subheading,
  submitButton,
  children,
  maxWidthCss = 'max-w-xl',
  showCancel = true,
  showExit = true,
}: SlideoverPanelProps): React.ReactElement => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 mt-16" onClose={setIsOpen}>
        <div className="absolute inset-0">
          <Transition.Child
            enter="transition-opacity ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 opacity-50" />
          </Transition.Child>

          <div className="absolute inset-y-0 right-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition-transform ease-out duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition-transform ease-in duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={`w-screen ${maxWidthCss}`}>
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          {heading}

                          {subheading && <div className="text-sm font-normal text-gray-400">{subheading}</div>}
                        </Dialog.Title>
                        {showExit && (
                          <div className="ml-3 h-7 flex items-center">
                            <Button onClick={() => setIsOpen(false)} aria-label="Close" isText>
                              <XIcon className="h-6 w-6" />
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      {/* Replace with your content */}
                      {children}
                      {/* /End replace */}
                    </div>
                  </div>

                  {submitButton && (
                    <div className="shrink-0 px-4 py-4 flex justify-end">
                      {showCancel && (
                        <Button variant="neutral" onClick={() => setIsOpen(false)}>
                          Cancel
                        </Button>
                      )}
                      <div className="ml-4">{submitButton}</div>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export { SlideoverPanel };
