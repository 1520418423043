import { gql } from '@apollo/client';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { useMemo } from 'react';
import Select, { OptionProps } from 'react-select';

import {
  GarmentCategory,
  GetApplicableMeasurementsQueryResult,
  MeasurementType,
  OrderPageQuery,
  useGeApplicableMeasurementsOnOrdersQuery,
} from '@graphql';
import { Icon } from 'components';
import { ArrayElement, SelectOption } from 'types/common';
import cn from 'classnames';
import { MeasurementOption } from './MeasurementOption';

export type MeasurementTypeObject = { key: MeasurementType; name: string };
type applicableMeasurements = GetApplicableMeasurementsQueryResult['data']['applicableMeasurements'];
export type MeasurementsSelectOption = {
  type?: MeasurementTypeObject;
} & SelectOption;

type Props = {
  remake: ArrayElement<OrderPageQuery['order']['items']>['remake'];
  isDisabled?: boolean;
  className?: string;
  placeholder?: string;
  options: MeasurementsSelectOption[];
  value: string;
  onChange: (value: MeasurementsSelectOption) => void;
  garmentCategory: GarmentCategory;
  hasBodyMeasurement?: boolean;
};

export const MeasurementsSelect = ({
  isDisabled,
  className,
  placeholder,
  options,
  value,
  onChange,
  garmentCategory,
  hasBodyMeasurement,
  remake,
}: Props) => {
  const { fulfilment } = useActiveSession();
  const { data: dataM, loading: loadingM } = useGeApplicableMeasurementsOnOrdersQuery({
    variables: { garmentCategory },
    fetchPolicy: 'cache-first',
  });
  const applicableOptions = options?.filter((x) => dataM?.applicableMeasurements?.some((z) => z.type.key === x.type?.key));
  const addMeasurementOptions: MeasurementsSelectOption[] = useMemo(() => {
    const AddGarmentOption = { value: 'ADD_GARMENT', label: 'Add Garment Measurement' };
    const AddSimplifiedOption = { value: 'ADD_SIMPLIFIED', label: 'Add Simplified Try On Measurement' };
    const AddTryOnOption = { value: 'ADD_TRY_ON', label: 'Add Try On Measurement' };
    const AddAppliedOption = { value: 'ADD_APPLIED', label: 'Add Applied Measurement', isDisabled: !hasBodyMeasurement };

    const measurementTypesMap: Record<MeasurementType, any> = {
      [MeasurementType.Applied]: AddAppliedOption,
      [MeasurementType.Garment]: AddGarmentOption,
      [MeasurementType.Simplified]: AddSimplifiedOption,
      [MeasurementType.TryOn]: AddTryOnOption,
      [MeasurementType.Body]: null, //TODO:need to confirm about this.
    };

    // switch (fulfilment) {
    //   case 'PDF':
    //     return canUseFinishedMeasurement(garmentCategory) ? [AddGarmentOption, AddSimplifiedOption, AddAppliedOption] : [];
    //   case 'JEROME_SIMPLIFIED':
    //     return canUseFinishedMeasurement(garmentCategory) ? [AddSimplifiedOption] : [];
    //   case 'JEROME_FINISHED':
    //     return canUseFinishedMeasurement(garmentCategory) ? [AddGarmentOption, AddSimplifiedOption, AddAppliedOption] : [];
    //   case 'JEROME':
    //     return canUseFinishedMeasurement(garmentCategory)
    //       ? [AddGarmentOption, AddTryOnOption, AddSimplifiedOption, AddAppliedOption]
    //       : [AddTryOnOption];
    //   case 'JEROME_TRY_ON':
    //     return [AddTryOnOption];
    //   default:

    const measurements = dataM?.applicableMeasurements
      ?.filter((x) => !!x)
      .map((m) => {
        return measurementTypesMap[m?.type?.key];
      });
    return measurements?.filter((y) => !!y);
  }, [dataM]);
  if (loadingM) return <></>;
  const CustomOption = ({
    label,
    isDisabled,
    data,
    innerProps,
    innerRef,
  }: OptionProps<SelectOption & { type: MeasurementTypeObject } & { isDisabled: boolean }>) => {
    if (['ADD_TRY_ON', 'ADD_BODY', 'ADD_GARMENT', 'ADD_SIMPLIFIED', 'ADD_APPLIED'].includes(data.value.toString())) {
      return (
        <div
          className={cn(`flex items-center my-3 mx-4 cursor-pointer`, {
            'text-gray-400 pointer-events-none': isDisabled,
          })}
          ref={innerRef}
          {...innerProps}
        >
          <Icon icon="plus" className="h-6" />
          <div className=" px-2 py-1 text-xs font-medium">{label}</div>
        </div>
      );
    }
    return <MeasurementOption innerProps={innerProps} label={label} type={data.type} ref={innerRef} remake={remake} />;
  };

  return (
    <Select
      className={className}
      isDisabled={isDisabled}
      placeholder={placeholder}
      options={[...applicableOptions, ...addMeasurementOptions]}
      isSearchable={false}
      components={{ Option: CustomOption }}
      styles={{
        control: (base, state) => ({
          ...base,
          border: state.isFocused ? '1px solid #6b7280' : '1px solid #d1d5db',
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid #6b7280',
          },
          borderRadius: '6px',
          padding: '2px 0',
        }),
      }}
      value={options.find((option) => option.value === value)}
      onChange={onChange}
    />
  );
};

MeasurementsSelect.query = gql`
  query GeApplicableMeasurementsOnOrders($garmentCategory: GarmentCategory) {
    applicableMeasurements(garmentCategory: $garmentCategory) {
      type {
        name
        key
      }
    }
  }
`;
