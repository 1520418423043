import { useEffect, useState } from 'react';
import { Feature, useFeature } from './useFeature';

export const useFeatureAsync = (feature: Feature) => {
  const [loading, setLoading] = useState(true);
  const [enabled, setEnabled] = useState(false);

  const { isFeatureEnabled } = useFeature();

  useEffect(() => {
    (async () => {
      const isEnabled = await isFeatureEnabled(feature);

      setEnabled(isEnabled);
      setLoading(false);
    })();
  }, []);

  return { loading, enabled };
};
