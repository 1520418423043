import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { gql } from '@apollo/client';

import { useDesignOptionsSummaryListOrderItemQuery } from '@graphql';
import { Icon } from 'components';
import { parseRouteId } from 'modules/common';
import { PricingPill } from 'modules/pricing';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { useFeature } from 'hooks';
import { ConfigGroup } from 'types/common';
import { useDesignOptionsForm } from '../designOptions.context';

type Props = {
  groups: ConfigGroup[];
  designId: string;
  isTemplate: boolean;
};

export const DesignOptionsSummaryList = ({ groups, designId, isTemplate }: Props) => {
  const router = useRouter();
  const { itemId } = router.query;
  const { getIsFieldHidden, getPrice } = useDesignOptionsForm();
  const [expandedDesignOptionGroup, setExpandedDesignOptionGroup] = useState('');
  const { tailorSlug } = useActiveSession();
  const { isValueInAllowList } = useFeature();

  const { data: orderItemData } = useDesignOptionsSummaryListOrderItemQuery({ variables: { orderItemId: parseRouteId(itemId), isTemplate } });

  const design = useMemo(
    () => (designId ? orderItemData?.orderItem.designs?.find((design) => design.id === designId) : orderItemData?.orderItem.designs[0]),
    [orderItemData]
  );

  const handleExpandCloseGroup = (groupLabel: string) => {
    expandedDesignOptionGroup === groupLabel ? setExpandedDesignOptionGroup('') : setExpandedDesignOptionGroup(groupLabel);
  };

  const getIsGroupExpanded = (groupName: string) => expandedDesignOptionGroup === groupName;

  return (
    <>
      {groups.map((group) => {
        const groupPrices = group.subGroups
          .flatMap((subGroup) => [...subGroup.types])
          .map((type) => {
            const optionPrice = getPrice({ typeCode: type.code });
            if (Boolean(optionPrice) && optionPrice > 0) {
              return {
                typeCode: type.code,
                price: optionPrice,
              };
            }
          })
          .filter((o) => Boolean(o));

        return (
          <div className="border p-6 bg-white my-2 rounded" key={group.name}>
            <div className="flex justify-between items-center cursor-pointer" onClick={() => handleExpandCloseGroup(group.name)}>
              <div className="flex items-center">
                <div className="font-semibold mr-4">{group.name}</div>
                {isValueInAllowList({ feature: 'ff_pricing_allow_list', valueToCheck: tailorSlug }) && Boolean(groupPrices.length) && (
                  <PricingPill amount={groupPrices.flatMap((p) => p && p.price).reduce((a, b) => a + b)} />
                )}
              </div>
              {getIsGroupExpanded(group.name) ? <Icon icon="chevron-up" className="ml-2" /> : <Icon icon="chevron-down" className="ml-2" />}
            </div>
            {getIsGroupExpanded(group.name) && (
              <>
                {group.subGroups
                  .flatMap((subGroup) => [...subGroup.types])
                  .map((type) => {
                    const price = groupPrices.find((price) => price.typeCode === type.code);
                    if (getIsFieldHidden(type.code)) {
                      return null;
                    }
                    const option = design.options.find((option) => option.typeCode === type.code);
                    if (!option) {
                      return null;
                    }
                    return (
                      <div className="text-sm my-6 text-neutral-900 last:mb-0 flex" key={type.code}>
                        <div className="mr-4">
                          {type.name} - {option.name || option.value}
                        </div>
                        {isValueInAllowList({ feature: 'ff_pricing_allow_list', valueToCheck: tailorSlug }) && Boolean(price) && (
                          <PricingPill amount={price.price} />
                        )}
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

DesignOptionsSummaryList.fragments = {
  orderItem: gql`
    fragment DesignOptionsSummaryListOrderItemFragment on OrderItem {
      id
      designs {
        id
        options {
          id
          name
          value
          typeCode
        }
      }
    }
  `,
};

DesignOptionsSummaryList.query = gql`
  ${DesignOptionsSummaryList.fragments.orderItem}

  query DesignOptionsSummaryListOrderItem($orderItemId: ID!, $isTemplate: Boolean!) {
    orderItem(orderItemId: $orderItemId, isTemplate: $isTemplate) {
      ...DesignOptionsSummaryListOrderItemFragment
    }
  }
`;
