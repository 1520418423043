import { useRouter } from 'next/router';
import React, { ReactNode, useMemo } from 'react';
import cn from 'classnames';
import { Route } from 'types/routes';
import { Button } from 'modules/common/components';

type Props = {
  href: Route;
  query?: { [key: string]: string | string[] };
  className?: string;
  children: ReactNode;
};

const BASE_CLASSES = ['text-sm', 'px-3', 'py-2', 'ml-2', 'first:ml-0', 'inline-block', 'rounded'];

export const HeaderLink = ({ href, query, className, children }: Props) => {
  const router = useRouter();

  const isActive = useMemo(() => router.pathname.includes(href), [href, router]);
  const classes = cn(className, BASE_CLASSES, {
    'font-medium bg-gray-700 rounded': isActive,
  });

  return (
    <Button isLink variant="unstyled" href={href} query={query} className={classes}>
      {children}
    </Button>
  );
};
