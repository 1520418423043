import Head from 'next/head';
import { ReactNode } from 'react';

import { Container } from 'layouts';
import { baseSiteTitle } from 'pages/_app';
import { OrderItemBreadcrumbs } from './OrderItemBreadcrumbs';
import { OrderItemNav } from './OrderItemNav';
import { OrderItemProgressSideBar } from './OrderItemProgressSideBar';

interface Props {
  showProgressSideBar?: boolean;
  isTemplate?: boolean;
  children: ReactNode;
}

export const OrderItemContainer = ({ showProgressSideBar = false, isTemplate = false, children }: Props) => {
  const pageTitle = `${baseSiteTitle} | ${isTemplate ? 'Design template' : 'Order item'}`;
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <Container className="pt-0">
        <div className="p-4 bg-neutral-50">
          <OrderItemBreadcrumbs />
          <OrderItemNav isTemplate={isTemplate} />
          <div className="flex h-design-option-view bg-white">
            <div className="flex-1 overflow-x-hidden overflow-y-auto">{children}</div>
            {showProgressSideBar && <OrderItemProgressSideBar isTemplate={isTemplate} className="my-4" />}
          </div>
        </div>
      </Container>
    </>
  );
};
