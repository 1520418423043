import { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { useAppUpdateQuery } from '@graphql';
import { Icon } from 'components';

const AppUpdate = () => {
  const [showUpdate, setShowUpdate] = useState(false);
  const { data } = useAppUpdateQuery({ fetchPolicy: 'network-only', pollInterval: 5 * 60 * 1000 /* 5 minutes */ });
  const apiVer = data?.health?.api.ver;
  const apiUptime = data?.health?.api.uptime;
  const hqVer = process.env.NEXT_PUBLIC_APP_VERSION;

  useEffect(() => {
    if (apiVer && hqVer && apiVer !== hqVer && apiUptime > 5 * 60 /* 5 minutes */) {
      setShowUpdate(true);
    }
  }, [data]);

  const handleButtonClick = () => {
    // clear cache
    if (caches) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    }
    // reload page
    window.location.reload();
  };

  if (!showUpdate) return null;

  return (
    <div className="group flex items-center justify-center h-16 bg-yellow-crayola-500">
      <a className="group:hover:bg-red-500 flex items-center justify-center cursor-pointer" onClick={handleButtonClick}>
        <Icon className="mr-2" icon="refresh" />A new update is available <span className="ml-4 underline">Reload Taper</span>
      </a>
    </div>
  );
};

AppUpdate.queries = {
  appUpdate: gql`
    query AppUpdate {
      health {
        api {
          ver
          uptime
        }
      }
    }
  `,
};

export default AppUpdate;
