import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import { useGetAlterationProvidersQuery, GetAlterationProvidersQueryResult } from '@graphql';
import Link from 'next/link';

import { ArrayElement } from 'types/common';
import { Table, Column, Pagination } from 'components/Table';

type AlterationProvider = ArrayElement<GetAlterationProvidersQueryResult['data']['alterationProviders']['items']>;

const NameTd = (info) => {
  const name = info.getValue() as AlterationProvider['name'];
  const id = info.row.original.id;

  return (
    <Link className="font-semibold text-blue-500 hover:text-blue-600" href={`/alteration-providers/${id}`}>
      {name}
    </Link>
  );
};

export const AlterationsProviderListTable = () => {
  const { query } = useRouter();

  const { data, loading } = useGetAlterationProvidersQuery({
    variables: {
      pagination: { pageSize: Number(query?.pageSize) || 10, page: Number(query?.page) || 1 },
    },
    fetchPolicy: 'cache-first',
  });

  return (
    <Table<AlterationProvider> data={data?.alterationProviders?.items || []} loading={loading} className="text-center">
      <Column<AlterationProvider> accessor="name" cell={NameTd} />
      <Column<AlterationProvider> accessor="contactPerson" header="Contact Person" />
      <Column<AlterationProvider> accessor="email" />
      <Column<AlterationProvider> accessor="updatedAt.fromNow" header="Last updated" />
      <Pagination count={data?.alterationProviders?.items.length} total={data?.alterationProviders?.total} />
    </Table>
  );
};

AlterationsProviderListTable.queries = {
  alterationProviders: gql`
    query GetAlterationProviders($isActive: Boolean, $pagination: PaginationInput, $take: Int) {
      alterationProviders(isActive: $isActive, pagination: $pagination, take: $take) {
        items {
          id
          name
          isActive
          address
          contactPerson
          contactNumber
          email
          updatedAt {
            origin
            fromNow
          }
        }
        total
      }
    }
  `,
};
