import router from 'next/router';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useUpdateOrderItemFabricMutation } from '@graphql';
import { uiVar } from 'graphql/common';
import { DesignOptionKeyValue, DesignOptionsFormProvider, OrderItemWithConflict } from 'modules/designOptions';
import { getNewCmtOptions, isCmtLiningOptionSelected, updateDesignsWithCmtLiningOptions } from 'modules/fabric';
import { OrderItemContainer } from 'modules/orderItem';
import { omit } from 'lodash';

const FabricTabsLayout = ({ children }) => {
  const [updateFabricMutation] = useUpdateOrderItemFabricMutation();

  const { orderId, itemId } = router.query;

  const isTemplate = router.pathname.includes('/design-templates/[itemId]/fabric');

  useEffect(() => {
    if (!router.query.tab) {
      // route to first tab
      if (isTemplate) {
        router.push(`/design-templates/${itemId}/fabric/fabric`);
      } else {
        router.push(`/orders/${orderId}/order-item/${itemId}/fabric/fabric`);
      }
    }
  }, [router.query]);

  // Only ever called on Lining (CMT tab)
  const handleSaveCMTLining = async (options: DesignOptionKeyValue[], orderItem: OrderItemWithConflict) => {
    try {
      uiVar('saving');

      const { id } = orderItem;
      const newCmtOptions = getNewCmtOptions(orderItem.liningOptions, options);
      const lining = isCmtLiningOptionSelected({ ...orderItem.liningOptions, cmtOptions: newCmtOptions }) && { lining: null };
      const updatedDesigns = updateDesignsWithCmtLiningOptions(newCmtOptions, orderItem.designs);

      await updateFabricMutation({
        variables: {
          orderItemId: id,
          orderItemInput: {
            // mutation doesn't return fabric unless passed so explicitly pass
            ...(orderItem.fabric?.id && { fabric: orderItem.fabric.id }),
            ...(orderItem.lining?.id && { lining: orderItem.lining.code }),
            liningOptions: newCmtOptions.map((opt) => omit(opt, '__typename')),
            ...lining,
          },
          isTemplate,
        },
        optimisticResponse: {
          // @ts-ignore
          updateOrderItem: {
            id,
            ...orderItem,
            // Because lining options are stored in design options, we need to update design option apollo cache
            designs: updatedDesigns,
            liningOptions: {
              ...orderItem.liningOptions,
              cmtOptions: newCmtOptions,
            },
            ...lining,
            __typename: 'OrderItem',
            estimatedDeliveryDate: orderItem?.estimatedDeliveryDate?.formatted ? orderItem?.estimatedDeliveryDate : null,
          },
        },
      });
    } catch (e) {
      toast.error(`${e?.message ?? `Couldn't save update.`}`, { autoClose: false });
      console.error(e);
    } finally {
      uiVar('ready');
    }
  };

  return (
    <DesignOptionsFormProvider
      isTemplate={isTemplate}
      onSave={handleSaveCMTLining}
      ignoreFields={['Lining Fabric'] /* IMPORTANT! Must pass this so CMT fields are shown */}
    >
      <OrderItemContainer showProgressSideBar isTemplate={isTemplate}>
        {children}
      </OrderItemContainer>
    </DesignOptionsFormProvider>
  );
};

export { FabricTabsLayout };
