import React from 'react';

type ObscureProps = {
  value: any;
  visible: boolean;
};

export const Obscure = ({ value, visible }: ObscureProps) => {
  const obscure = String(value);
  return <>{!visible ? Array((obscure.length > 1 ? obscure.length : 2) + 1).join('*') : value}</>;
};

Obscure.defaultProps = {
  visible: true,
};
