import { useRouter } from 'next/router';

import { Box } from 'components';
import { GarmentCategoriesSelect } from 'modules/garments/components/GarmentCategoriesSelect';
import { GarmentCategory, GetFrontBackLengthValidationConfigQuery, MeasurementType, useGetFrontBackLengthValidationConfigQuery } from '@graphql';

export type ChildrenProps = {
  garmentCategory: GarmentCategory;
  frontBackLengthValidationConfig: GetFrontBackLengthValidationConfigQuery['getFrontBackLengthValidationConfig'];
};

interface Props {
  measurementId?: string;
  hideGarmentCategory?: boolean;
  measurementType: MeasurementType;
  garmentCategoryFilter?: 'garmentMeasurement';
  children: (props: ChildrenProps) => React.ReactNode;
}

export const MeasurementFormHeader = ({ measurementId, measurementType, hideGarmentCategory = false, garmentCategoryFilter, children }: Props) => {
  const { push, query } = useRouter();
  const garmentCategory = ((query?.garmentCategory as string) || '') as GarmentCategory;
  const { data, loading } = useGetFrontBackLengthValidationConfigQuery({
    variables: {
      garmentCategory,
      measurementType,
    },
  });

  const frontBackLengthValidationConfig = data?.getFrontBackLengthValidationConfig;

  return (
    <>
      {!hideGarmentCategory && !loading && (
        <div className="mb-4">
          <Box isLoading={loading}>
            <div className="grid grid-cols-4 gap-4">
              <label className="flex items-center text-sm font-medium">Garment category</label>
              <div className="col-span-3">
                <GarmentCategoriesSelect
                  value={garmentCategory}
                  onChange={(value: GarmentCategory) => push({ query: { ...query, garmentCategory: value } }, undefined, { shallow: true })}
                  isDisabled={!!measurementId}
                  measurementType={measurementType}
                  //filter={garmentCategoryFilter}
                />
              </div>
            </div>
          </Box>
        </div>
      )}
      {children({ garmentCategory, frontBackLengthValidationConfig })}
    </>
  );
};
