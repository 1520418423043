import { gql } from '@apollo/client';
import { useGetShowroomsQuery, useUpdateOrderShowroomMutation, OrderPageQuery } from '@graphql';
import { Select } from 'components';
import { toast } from 'react-toastify';
import { OrderTimeline } from 'modules/orders/components/OrderTimeline';
import { FormattedAddress } from 'modules/orders/components/FormattedAddress';

interface Props {
  defaultShowroom?: OrderPageQuery['order']['showroom'];
  orderId?: string;
  label?: string;
  onSuccess: () => void;
}

export const ShowroomSelect = ({ defaultShowroom, orderId, label, onSuccess }: Props) => {
  const { data, loading } = useGetShowroomsQuery({ fetchPolicy: 'cache-first' });

  const [updateOrderShowroom] = useUpdateOrderShowroomMutation({
    onCompleted: (mutationData) => {
      toast.success(`Showroom changed to ${mutationData?.updateOrder.showroom.name}`);
    },
  });

  const onChange = async (e) => {
    if (!e.currentTarget.value) {
      return;
    }

    if (orderId) {
      await updateOrderShowroom({
        variables: {
          orderId: orderId,
          updateOrderInput: {
            showroomId: e.currentTarget.value,
          },
        },
      });
      onSuccess();
    }
  };

  return (
    <>
      {loading || !data ? (
        <>Loading</>
      ) : (
        <Select label={label && label} htmlProps={{ onChange, defaultValue: defaultShowroom?.id || '' }}>
          <option value="">Please select</option>
          {!!data?.showrooms?.length &&
            data.showrooms.map((showroom) => (
              <option key={showroom.id} value={showroom.id}>
                {showroom.name}
              </option>
            ))}
        </Select>
      )}
    </>
  );
};

ShowroomSelect.queries = {
  showrooms: gql`
    query GetShowrooms {
      showrooms {
        id
        name
      }
    }
  `,
};

ShowroomSelect.mutations = {
  updateOrderShowroom: gql`
    ${OrderTimeline.fragments.root}
    ${FormattedAddress.fragments.root}

    mutation UpdateOrderShowroom($orderId: ID!, $updateOrderInput: UpdateOrderInput!) {
      updateOrder(orderId: $orderId, updateOrderInput: $updateOrderInput) {
        id
        showroom {
          id
          name
          address {
            ...FormattedAddress
          }
        }
        ...OrderTimeline
      }
    }
  `,
};
