import React from 'react';
import Head from 'next/head';

import { OrdersListTable } from 'modules/orders';
import { baseSiteTitle } from 'pages/_app';
import TableFilters from 'components/TableFilters';
import { QueryParam } from 'components/QueryComboBox';
import { StatusParam } from 'components/StatusCombobox';
import { CreateNewOrderButton } from 'components/CreateNewOrderButton';
import { getMonthlyRangeFromDate } from 'helpers/date-helpers';
import { serializeRange } from 'helpers/reporting-helpers';
import { OrderStatus } from '@graphql';

// use these when generating header links etc
export const getDefaultQueryParams = (params?: { [key: string]: string | string[] }) => ({
  ...serializeRange(getMonthlyRangeFromDate(undefined, 2)),
  orderStatus: [OrderStatus.Draft, OrderStatus.ReadyToProcess, OrderStatus.Processing] as string[],
  page: '1',
  pageSize: '10',
  ...params,
});

const OrdersListPage = (): React.ReactElement => (
  <>
    <Head>
      <title>{baseSiteTitle} | Orders</title>
    </Head>
    <TableFilters
      heading="Orders"
      searchPlaceholder="Order # or Customer"
      statusFilter={StatusParam.OrderStatus}
      ctaContent={<CreateNewOrderButton eventFrom="Order List Page" />}
      filters={[QueryParam.Showroom, QueryParam.User, QueryParam.Customer, StatusParam.FulfilmentStatus]}
    >
      <OrdersListTable />
    </TableFilters>
  </>
);

export default OrdersListPage;
