import React, { ChangeEvent, HTMLProps, MouseEvent, useState } from 'react';
import { Box, Input, Spinner } from 'components';
import { Button } from 'modules/common';
import {
  DiscountType,
  OrderPageQuery,
  OrderStatus,
  UseActiveSessionFragmentFragment,
  useRemoveDiscountMutation,
  useUpdateDiscountMutation,
} from '@graphql';
import { gql } from '@apollo/client';
import { toast } from 'react-toastify';
import { formatAsCurrency } from 'helpers';

interface EditDiscountProps {
  onSave: (event: MouseEvent<HTMLButtonElement>) => void;
  onCancel: (event: MouseEvent<HTMLButtonElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  placeholder?: string;
  value?: string;
  isLoading?: boolean;
  discount?: OrderPageQuery['order']['discount'];
  totalValue?: OrderPageQuery['order']['totalRetail']['amount'];
  discountedValue?: OrderPageQuery['order']['totalRetail']['discountedPrice'];
  orderId?: OrderPageQuery['order']['id'];
  orderStatus?: OrderPageQuery['order']['orderStatus']['key'];
  tailor: UseActiveSessionFragmentFragment['tailor'];
}

const EditDiscount = (props: EditDiscountProps): React.ReactElement => {
  const { onCancel, onChange, label, placeholder, value, isLoading, discount, totalValue, discountedValue, orderId, orderStatus, tailor } = props;

  const [discountCode, setDiscountCode] = useState(value);
  const htmlProps: HTMLProps<HTMLInputElement> = {
    id: 'discountId',
    type: 'text',
    placeholder,
    onChange: (event: ChangeEvent<HTMLInputElement>) => setDiscountCode(event.target.value ?? null),
    maxLength: 24,
    className: ' block w-full shadow-sm text-lg rounded-md disabled:cursor-not-allowed disabled:text-gray-400 disabled:bg-gray-100 font-normal',
  };

  const [updateOrderDiscount, { loading: mutationLoading }] = useUpdateDiscountMutation({
    onCompleted: () => {
      toast.success(`Discount applied`);
    },
    onError: (err) => {
      console.error('err from apply discount', err);
      toast.error(err?.message);
    },
    refetchQueries: ['OrderPage'],
  });

  const [removeOrderDiscount, { loading: removeMutationLoading }] = useRemoveDiscountMutation({
    onCompleted: () => {
      toast.success(`Removed discount code successfully`);
    },
    onError: (err) => {
      console.error('err from removing discount', err);
      toast.error(err?.message);
    },
    refetchQueries: ['OrderPage'],
  });

  const handleRemove = async () => {
    if (!orderId) {
      toast.error('Had trouble removing discount.');
      console.error('Tried removing with no associated order id.');
      return;
    }
    await removeOrderDiscount({
      variables: {
        orderId: orderId,
      },
    });
  };

  const handleSave = async () => {
    if (!orderId) {
      toast.error('Had trouble applying discount.');
      console.error('Tried applying with no associated order id.');
      return;
    }
    if (discountCode === discount?.code) {
      toast.error(`This discount code ${discountCode} is already applied `);
      return;
    }
    await updateOrderDiscount({
      variables: {
        applyDiscountInput: {
          orderId: orderId,
          code: discountCode,
        },
      },
    });
  };

  if (onChange) {
    htmlProps.defaultValue = discountCode;
  } else {
    htmlProps.value = discountCode;
  }

  return (
    <div className="grid grid-cols-1 gap-2 sm:grid-cols-6 w-full">
      {orderStatus === OrderStatus.Draft && (
        <div className="sm:col-span-6">
          <div className="mt-1">
            <Input label={label} htmlProps={htmlProps} className="text-lg" />
          </div>
        </div>
      )}
      {discount && (
        <div className="sm:col-span-6 mt-2">
          <Box backgroundColor="bg-gray-50 p2" shadowStyle="shadow p-3" hasPadding={false}>
            <ul className="normal-case text-sm space-y-2">
              <h6 className="text-lg">Discount Summary</h6>
              <li className="flex justify-between align-center">
                <span className="text-sm">Code</span>
                <div className="text-ellipsis truncate ml-1">{discount?.code}</div>
              </li>
              <li className="flex justify-between align-center">
                <span className="text-sm">Discount</span>
                <div className="text-ellipsis truncate ml-1">
                  {discount.type === DiscountType.FixPercent
                    ? `${discount.typeValue} %`
                    : formatAsCurrency(parseInt(discount.typeValue), tailor.locale, tailor.currency, false)}
                </div>
              </li>
              <li className="flex justify-between align-center">
                <span className="text-sm">Sub Total</span>
                <div className="text-ellipsis truncate ml-1 line-through">{formatAsCurrency(totalValue, tailor.locale, tailor.currency, false)}</div>
              </li>
              <li className="flex justify-between align-center">
                <span className="text-sm">Total</span>
                <div className="text-ellipsis truncate ml-1">{formatAsCurrency(discountedValue, tailor.locale, tailor.currency, false)}</div>
              </li>
            </ul>
          </Box>
        </div>
      )}
      {orderStatus === OrderStatus.Draft && (
        <div className="sm:col-span-6 mt-2 flex flex-row-reverse justify-between">
          <Button className="text-sm" onClick={handleSave} isDisabled={mutationLoading || !discountCode}>
            {mutationLoading ? <Spinner /> : 'Save'}
          </Button>
          {!discount ? (
            <Button className="text-sm" variant="neutral" onClick={onCancel} isDisabled={isLoading}>
              {'Cancel'}
            </Button>
          ) : (
            <Button className="text-sm" variant="neutral" onClick={handleRemove} isDisabled={isLoading}>
              {removeMutationLoading ? <Spinner /> : 'Remove'}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
EditDiscount.mutations = {
  updateOrderDiscount: gql`
    mutation UpdateDiscount($applyDiscountInput: ApplyDiscountInput!) {
      appplyDiscount(applyDiscountInput: $applyDiscountInput) {
        code
        type
        id
        typeValue
      }
    }
  `,
  removeOrderDiscount: gql`
    mutation RemoveDiscount($orderId: String!) {
      removeDiscount(orderId: $orderId)
    }
  `,
};

export default EditDiscount;
