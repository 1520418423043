import React, { AnchorHTMLAttributes, HTMLProps, memo, MouseEvent as ReactMouseEvent, ReactNode } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import mixpanelService from 'services/mixpanel.service';
import amplitudeService, { EventName } from 'services/amplitude.service';

type AnchorClickEvent = (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void;
type ButtonClickEvent = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;

type Props = Omit<HTMLProps<HTMLButtonElement>, 'disabled' | 'state' | 'size'> & {
  eventName?: EventName;
  eventData?: Record<string, unknown>;
  href?: string;
  query?: { [key: string]: string | string[] };
  type?: 'button' | 'submit' | 'reset';
  variant?: 'positive' | 'neutral' | 'destructive' | 'alternative' | 'neutral-alternative' | 'unstyled';
  size?: 'xs' | 'regular' | 'xl';
  isText?: boolean;
  onClick?: AnchorClickEvent | ButtonClickEvent;
  isDisabled?: boolean;
  isExternal?: boolean;
  isLink?: boolean;
  isOpenedInNewTab?: boolean;
  testId?: string;
};

const BASE_CLASSES = ['box-border', 'inline-flex', 'shrink-0', 'items-center', 'outline-none', 'disabled:pointer-events-none', 'focus:ring-2'];
const BASE_CLASSES_NON_TEXT = ['px-4', ...BASE_CLASSES];
const BASE_DISABLED_CLASSES = [...BASE_CLASSES_NON_TEXT, 'pointer-events-none'];

const getButtonName = (node: ReactNode) => {
  if (typeof node === 'string') {
    return node;
  } else if (['number', 'bigint'].includes(typeof node)) {
    return String(node);
  } else if (typeof node === 'function') {
    return (node as typeof Function).name;
  } else if (Array.isArray(node)) {
    return getButtonName(node[0]);
  } else if (typeof node === 'object') {
    const component: any = node;
    if (component?.props?.icon) return component.props.icon;
    if (component?.type?.render?.name) return component.type?.render?.name;
    if (component?.props?.children) return getButtonName(component?.props?.children);
    return component?.constructor?.name;
  }
};

export const Button = memo((props: Props) => {
  const {
    children,
    className,
    eventName,
    eventData,
    href,
    query,
    onClick,
    type = 'button',
    variant = 'positive',
    size = 'regular',
    isDisabled,
    isExternal,
    isLink,
    isOpenedInNewTab,
    testId,
    isText = false,
    ...rest
  } = props;

  const onClickTracked = (event) => {
    if (eventName) {
      amplitudeService.track(eventName, { buttonName: getButtonName(children), ...eventData });
    } else {
      mixpanelService.track('BUTTON_CLICKED', { buttonName: getButtonName(children) });
    }
    if (typeof onClick === 'function') return onClick(event);
  };

  const sizeClasses = {
    'text-xs h-8': size === 'xs',
    'h-10': size === 'regular',
    'text-xl h-11': size === 'xl',
  };

  let classes = cn(className, BASE_CLASSES_NON_TEXT, {
    ...sizeClasses,

    'bg-yellow-crayola-500 text-neutral-900 \
    hover:bg-yellow-crayola-400 \
    active:ring-0 active:bg-yellow-crayola-600 \
    focus:ring-yellow-crayola-200': variant === 'positive',

    'bg-neutral-50 border b-neutral-300 text-neutral-900 border-neutral-200 \
    hover:bg-neutral-100 hover:border-neutral-300 \
    active:ring-0 active:bg-neutral-300 \
    focus:ring-neutral-300 focus:border-0': variant === 'neutral',

    'bg-fire-500 text-neutral-900 \
    hover:bg-fire-400 \
    active:ring-0 active:bg-fire-600 \
    focus:ring-fire-200': variant === 'destructive',

    'bg-neutral-900 text-yellow-crayola-500 \
    hover:bg-neutral-800 \
    active:ring-0 active:bg-neutral-600 \
    focus:ring-neutral-200': variant === 'alternative',

    'bg-neutral-300 text-neutral-600 \
    hover:bg-neutral-400 \
    active:ring-0 active:bg-neutral-400 \
    focus:ring-neutral-200': variant === 'neutral-alternative',
  });

  if ((variant === 'positive' || variant === 'alternative') && isDisabled) {
    classes = cn(className, BASE_DISABLED_CLASSES, 'bg-neutral-100 text-neutral-500', {
      ...sizeClasses,
    });
  }

  if (variant === 'neutral' && isDisabled) {
    classes = cn(className, BASE_DISABLED_CLASSES, 'bg-neutral-100 text-neutral-500 border b-neutral-300', {
      ...sizeClasses,
    });
  }

  if (variant === 'destructive' && isDisabled) {
    classes = cn(className, BASE_DISABLED_CLASSES, 'bg-fire-100 text-fire-800', {
      ...sizeClasses,
    });
  }

  if (isText) {
    classes = cn(className, BASE_CLASSES, 'text-neutral-900 hover:text-neutral-800 active:ring-0 focus:ring-yellow-crayola-200', {
      'text-x': size === 'xs',
      'text-xl': size === 'xl',
      'pointer-events-none': isDisabled,
    });
  }

  if (variant === 'unstyled') classes = className;

  if (isLink && href) {
    if (isExternal) {
      const additionalAttrs: AnchorHTMLAttributes<HTMLAnchorElement> = {};

      if (isOpenedInNewTab) {
        additionalAttrs.target = '_blank';
        additionalAttrs.rel = 'noreferrer';
      }

      return (
        <a {...additionalAttrs} className={classes} href={href} onClick={onClickTracked as AnchorClickEvent}>
          {children}
        </a>
      );
    }

    return (
      <Link href={{ pathname: href, query }} className={classes} onClick={onClickTracked as AnchorClickEvent}>
        {children}
      </Link>
    );
  }

  return (
    <button {...rest} data-testid={testId} className={classes} type={type} disabled={isDisabled} onClick={onClickTracked as ButtonClickEvent}>
      {children}
    </button>
  );
});
