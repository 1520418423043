import { gql } from '@apollo/client';
import { ManufacturerGarmentOfferingsQuery, OrderItemStatus, OrderPageQuery, OrderStatus } from '@graphql';
import { useRouter } from 'next/router';

import { Icon, Tooltip } from 'components';
import { formatAsCurrency } from 'helpers/reporting-helpers';
import { useFeature } from 'hooks';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { Button, parseRouteId } from 'modules/common';
import { getEstShippingOrDeliveryDateText, isFinished } from 'modules/orderItem/orderItem.helpers';
import { ArrayElement } from 'types/common';
import { OrderItemAlterationBadges } from './OrderItemAlterationBadges';
import { OrderItemDesignCell } from './OrderItemDesignCell';
import { OrderItemMeasurementCell } from './OrderItemMeasurementCell';
import { OrderItemMenuCell } from './OrderItemMenuCell';
import { OrderItemStatusCell } from './OrderItemStatusCell';
import { OrderItemPriceBreakdown } from './OrderItemPriceBreakdown';
import { toast } from 'react-toastify';

interface Props {
  order: OrderPageQuery['order'];
  item: ArrayElement<OrderPageQuery['order']['items']>;
  orderStatus: OrderStatus;
  orderId: string | string[];
  index: number;
  tailorSlug: string;
  mfrGarmentOfferings: ManufacturerGarmentOfferingsQuery['manufacturerGarmentOfferings'];
  renderColumns: {
    trackingNumber: boolean;
    status: boolean;
    alteration: boolean;
    design: boolean;
    measurement: boolean;
  };
  setItemToShowSummary: React.Dispatch<any>;
}

const TRACKING_URL = process.env.NEXT_PUBLIC_TRACKING_URL;

export const OrderItemListRow = ({
  order,
  item,
  orderStatus,
  orderId,
  index,
  tailorSlug,
  mfrGarmentOfferings,
  renderColumns: renderColumn,
  setItemToShowSummary,
}: Props) => {
  const router = useRouter();
  const { tailor } = useActiveSession();

  const { isValueInAllowList } = useFeature();

  const getIsItemLocked = (item: ArrayElement<OrderPageQuery['order']['items']>) => {
    if (orderStatus === OrderStatus.Complete || orderStatus === OrderStatus.ReadyToProcess || orderStatus === OrderStatus.Cancelled) return true;
    if (orderStatus === OrderStatus.Draft) return false;
    if (
      item.itemStatus?.key === OrderItemStatus.LeftFactory ||
      item.itemStatus?.key === OrderItemStatus.Shipped ||
      item.itemStatus?.key === OrderItemStatus.Delivered
    ) {
      return false;
    }
    return true;
  };

  const locked = getIsItemLocked(item);
  const showSummaryDrawer = locked || isFinished(item.itemStatus.key);
  const hasMultipleDesigns = item.designs.length > 1;

  const designs = [...item.designs].sort((a, b) => a.garmentCategory.key.localeCompare(b.garmentCategory.key));

  return (
    <>
      <tr className="mb-12 first:mt-6 last:mb-6 text-sm text-left h-16" key={`order-item-list-${index}`}>
        <td>{item.number}</td>
        <td>
          <div className="flex flex-col pr-2">
            <Button
              onClick={() => (showSummaryDrawer ? setItemToShowSummary(item) : router.push(`/orders/${orderId}/order-item/${item.id}/summary`))}
              isLink
              isText
            >
              {item.name}
            </Button>
          </div>
        </td>
        {renderColumn.status && (
          <td>
            <div className="flex  pr-2">
              <OrderItemStatusCell status={item.itemStatus} dateLeftFactory={item.dateLeftFactory?.formatted} />
            </div>
          </td>
        )}
        {renderColumn.trackingNumber && (
          <td>
            <div className="flex pr-2">
              {item.individualTrackingNumber ? (
                TRACKING_URL ? (
                  <a className="underline" href={`${TRACKING_URL}${item.individualTrackingNumber}`} target="_blank" rel="noreferrer">
                    {item.individualTrackingNumber}
                  </a>
                ) : (
                  item.individualTrackingNumber
                )
              ) : (
                <span className="italic">Tracking unavailable</span>
              )}
              {item.individualTrackingNumber && (
                <div
                  onClick={() => {
                    try {
                      navigator.clipboard.writeText(item.individualTrackingNumber);
                      toast.success('Tracking number copied to clipboard.');
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                  className="ml-1 cursor-pointer"
                >
                  <Icon icon="duplicate" width={16} className="ml-1" />
                </div>
              )}
            </div>
          </td>
        )}
        {renderColumn.alteration && (
          <td>
            <div className="flex pr-2">
              <OrderItemAlterationBadges orderItemId={item.id} />
            </div>
          </td>
        )}
        {renderColumn.design && (
          <td>
            <div className="flex pr-2">
              <OrderItemDesignCell item={item} orderId={parseRouteId(orderId)} />
            </div>
          </td>
        )}
        {renderColumn.measurement && !hasMultipleDesigns && (
          <td>
            <div className="flex pr-2">
              <OrderItemMeasurementCell
                order={order}
                orderItem={item}
                locked={locked}
                designId={item?.designs[0]?.id}
                mfrGarmentOfferings={mfrGarmentOfferings}
              />
            </div>
          </td>
        )}
        {renderColumn.measurement && hasMultipleDesigns && (
          <td>
            <div className="h-9"></div>
          </td>
        )}
        <td>
          <div className="flex-1 pr-2">{`${getEstShippingOrDeliveryDateText({
            itemStatus: item?.itemStatus?.key,
            dateLeftFactory: item?.dateLeftFactory?.formatted,
            estDate: item?.estimatedDeliveryDate?.formatted,
          })}`}</div>
        </td>
        <td>
          <div className="pr-2">{item.quantity}</div>
        </td>
        {isValueInAllowList({ feature: 'ff_pricing_allow_list', valueToCheck: tailorSlug }) && (
          <>
            {!item.retail?.discountedPrice && <td className="pr-2">-</td>}
            {item.remake?.remakeReason ? (
              <td>
                <div className="flex items-center pr-2">
                  <div className="font-semibold">{formatAsCurrency(0, tailor.locale, tailor.currency, false)}</div>
                </div>
              </td>
            ) : (
              item.retail?.discountedPrice && (
                <td className="cursor-pointer">
                  <div className="flex items-center pr-2">
                    <Tooltip variant="dark" content={<OrderItemPriceBreakdown item={item} />}>
                      <div className="font-semibold">{formatAsCurrency(item.retail?.discountedPrice, tailor.locale, tailor.currency, false)}</div>
                    </Tooltip>
                  </div>
                </td>
              )
            )}
          </>
        )}
        <td className="text-right">
          <OrderItemMenuCell orderItem={item} locked={locked} />
        </td>
      </tr>
      {hasMultipleDesigns &&
        designs.map((design) => (
          <tr className="text-sm text-left h-16" key={`name-cell-${design.id}`}>
            <td>&nbsp;</td>
            <td>
              <div className="ml-2 flex items-center h-10 pr-2" key={`name-cell-${design.id}`}>
                <div className="rounded-full bg-neutral-600 h-2 w-2 mr-4"></div>
                <div className="">{design.garmentCategory.name}</div>
              </div>
            </td>
            {renderColumn.design && <td>&nbsp;</td>}
            {renderColumn.measurement && (
              <td className="my-6 pr-2" key={`mes-cell-${design.id}`}>
                <OrderItemMeasurementCell
                  order={order}
                  orderItem={item}
                  locked={locked}
                  designId={design.id}
                  mfrGarmentOfferings={mfrGarmentOfferings}
                />
              </td>
            )}
          </tr>
        ))}
    </>
  );
};

OrderItemListRow.fragments = {
  root: gql`
    ${OrderItemMenuCell.fragments.root}
    ${OrderItemDesignCell?.fragments.root ?? ''}
    ${OrderItemMeasurementCell.fragments.root}
    fragment OrderItemListRow on Order {
      items {
        ...OrderItemMenuCell
        ...OrderItemDesignCell
        retail {
          discount {
            id
            code
          }
        }
      }
      ...OrderItemMeasurementCell
    }
  `,
};
