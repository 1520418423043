import { ApolloError, gql } from '@apollo/client';
import { ManufacturerGarmentOfferingsQuery, OrderPageQuery, useTouchOrderMutation } from '@graphql';
import cn from 'classnames';

import { ErrorPanel } from 'components';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { OrderItemList } from 'modules/orderItem';
import { FormattedAddress } from 'modules/orders/components/FormattedAddress';
import { OrderHeader } from 'modules/orders/components/OrderHeader';
import { OrderTimeline } from 'modules/orders/components/OrderTimeline';
import { ProcessCompleteOrderButton } from 'modules/orders/components/ProcessCompleteOrderButton';
import { ShowroomAddress } from 'modules/orders/components/ShowroomAddress';
import { RemakeDeltaModal } from './Remake/RemakeDeltaModal';
import { useEffect, useState } from 'react';

interface Props {
  order: OrderPageQuery['order'];
  mfrGarmentOfferings: ManufacturerGarmentOfferingsQuery['manufacturerGarmentOfferings'];
  loading: boolean;
  error: ApolloError;
}

export const OrderDetailsScreen = ({ order, mfrGarmentOfferings, loading, error }: Props) => {
  const { fulfilment } = useActiveSession();
  const [deltaModalIsShown, setDeltaModalIsShown] = useState(false);
  const [touchOrder, { called }] = useTouchOrderMutation();

  useEffect(() => {
    if (order && !order.touched && !called) {
      setDeltaModalIsShown(true);
      touchOrder({ variables: { orderId: order.id } });
    }
  }, [order]);

  if (error) {
    return <ErrorPanel />;
  }

  return (
    <div className={cn({ 'print:hidden': fulfilment === 'PDF' })}>
      <OrderHeader order={order} loading={loading} onRemakeClick={() => setDeltaModalIsShown(true)} />
      <div className="grid grid-cols-6 gap-4 mt-4">
        <div className="col-span-6">
          <OrderItemList order={order} loading={loading} mfrGarmentOfferings={mfrGarmentOfferings} />
        </div>
        <div className="col-span-4">
          <OrderTimeline order={order} loading={loading} />
        </div>
        <div className="col-span-2">
          <div>
            <ShowroomAddress order={order} loading={loading} />
          </div>
        </div>
      </div>
      {order?.relatedOrders?.remake?.originalOrderId && (
        <RemakeDeltaModal order={order} isOpen={deltaModalIsShown} onClose={() => setDeltaModalIsShown(false)} />
      )}
    </div>
  );
};

OrderDetailsScreen.mutations = {
  touchOrder: gql`
    mutation TouchOrder($orderId: ID!) {
      touchOrder(orderId: $orderId) {
        id
      }
    }
  `,
};

OrderDetailsScreen.fragments = {
  root: gql`
    ${OrderItemList.fragments.root}
    ${ProcessCompleteOrderButton.fragments.root}
    ${OrderTimeline.fragments.root}
    ${FormattedAddress.fragments.root}
    fragment OrderDetailsScreen on Order {
      id
      ...OrderItemList
      ...ProcessCompleteOrderButton
      ...OrderTimeline
      showroom {
        address {
          ...FormattedAddress
        }
      }
      items {
        designs {
          hasSubTemplate
        }
      }
      relatedOrders {
        remake {
          remadeOrderIds
          originalOrderId
        }
        duplicate {
          duplicatedOrderIds
          originalOrderId
        }
      }
      touched
      items {
        remake {
          remakeReason
          remakeReasonText
          delta {
            fabricsDelta {
              type
              name
            }
            designOptionDeltas {
              designId
              delta {
                newChanges {
                  groupName
                  name
                  newValue
                }
                existingChanges {
                  groupName
                  name
                  previousValue
                  newValue
                }
                removedChanges {
                  groupName
                  name
                  value
                }
              }
            }
          }
        }
      }
    }
  `,
};
