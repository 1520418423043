import { gql } from '@apollo/client';
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '@heroicons/react/solid';
import cn from 'classnames';
import { orderBy } from 'lodash';
import Link from 'next/link';
import { useState } from 'react';

import { useGetBodyMeasurementInfoQuery, MeasurementType } from '@graphql';
import { Box, Icon } from 'components';
import { useMeasurementUnit } from 'modules/common/MeasurementUnit';

interface Props {
  customerId: string;
}

export const BodyMeasurementInfo = ({ customerId }: Props) => {
  const { convertFromServerUnit, measurementUnitInfo } = useMeasurementUnit();
  const [open, setOpen] = useState(true);

  const { data, loading } = useGetBodyMeasurementInfoQuery({ variables: { customerId } });

  const latestBodyMeasurement = orderBy(
    data?.measurements?.filter((m) => m.type.key === 'BODY'),
    ['updatedAt.origin'],
    ['desc']
  )[0];

  return (
    <div className="fixed bottom-16 mb-2.5 left-0 right-0 lg:sticky lg:top-4">
      <Box isLoading={loading} backgroundColor="bg-neutral-900" roundedStyle="lg:rounded" shadowStyle="lg:shadow">
        {latestBodyMeasurement ? (
          <>
            <div className="flex justify-between items-center">
              <h3 className="font-semibold text-yellow-crayola-500">Latest Body Measurement</h3>
              <button
                className="lg:hidden w-8 h-8 p-2 font-semibold bg-yellow-crayola-500 text-neutral-900"
                onClick={() => setOpen(!open)}
                aria-expanded={open}
              >
                {open ? <ChevronDoubleDownIcon /> : <ChevronDoubleUpIcon />}
              </button>
            </div>
            <div className={cn('lg:h-auto overflow-hidden', { 'h-auto': open, 'h-0': !open })}>
              <ul className="flex flex-wrap mt-4">
                {latestBodyMeasurement?.fields.map((field, i) => (
                  <li
                    key={field.key}
                    className={cn('flex w-1/2 lg:w-full lg:border-0 justify-between text-sm text-white py-1', {
                      'border-l border-yellow-crayola-500 pl-4 lg:pl-0': i % 2,
                      'pr-4 lg:pr-0': i % 2 === 0,
                    })}
                  >
                    <span>{field.name}</span> <span>{`${convertFromServerUnit(field.value)} ${measurementUnitInfo.label}`}</span>
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : (
          <div className="items-center text-center flex justify-between lg:flex-col">
            <div className="text-neutral-50 text-base">{data?.customer.firstName} doesn&apos;t have any body measurements yet</div>
            <Link
              href={`/measurements/new?type=${MeasurementType.Body}&customerId=${customerId}`}
              className="text-yellow-crayola-500 lg:mt-4 text-sm underline"
            >
              <Icon icon="make-style" className="mr-1" />
              Add Body Measurements
            </Link>
          </div>
        )}
      </Box>
    </div>
  );
};

BodyMeasurementInfo.query = gql`
  query GetBodyMeasurementInfo($customerId: ID!) {
    measurements(customerId: $customerId) {
      id
      name
      type {
        name
        key
      }
      fields {
        key
        name
        value
      }
      updatedAt {
        fromNow
        origin
      }
      garmentCategory {
        key
        name
      }
    }

    customer(customerId: $customerId) {
      id
      customId
      firstName
      lastName
    }
  }
`;
