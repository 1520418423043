import { gql } from '@apollo/client';
import { useArchiveOrderMutation, OrderStatus } from '@graphql';
import { Icon } from 'components';
import { Button } from 'modules/common';
import React from 'react';
import { toast } from 'react-toastify';
import { OrderTimeline } from 'modules/orders/components/OrderTimeline';
import cn from 'classnames';

interface Props {
  orderId: string;
  isArchived: boolean;
  isDisabled?: boolean;
}

export const ArchiveButton = ({ orderId, isArchived, isDisabled = false }: Props) => {
  const [archiveOrder] = useArchiveOrderMutation({
    onCompleted: () => {
      isArchived ? toast.success('Order has been archived') : toast.success('Order has been unarchived');
    },
  });

  const onClick = async (e) => {
    e.preventDefault();

    if (orderId) {
      await archiveOrder({
        variables: {
          orderId,
          updateOrderInput: {
            orderStatus: isArchived ? OrderStatus.Draft : OrderStatus.Archived,
          },
        },
      });
    }
  };

  return (
    <Button className="mr-6" onClick={onClick} variant="alternative" isText isDisabled={isDisabled}>
      <div
        className={cn('flex items-center text-xs', {
          'text-gray-500': !isDisabled,
          'text-gray-300': isDisabled,
        })}
      >
        <Icon icon="archive" className="mr-2" />
        {isArchived ? 'Unarchive order' : 'Archive order'}
      </div>
    </Button>
  );
};

ArchiveButton.mutations = {
  archiveOrder: gql`
    ${OrderTimeline.fragments.root}

    mutation ArchiveOrder($orderId: ID!, $updateOrderInput: UpdateOrderInput!) {
      updateOrder(orderId: $orderId, updateOrderInput: $updateOrderInput) {
        id
        orderStatus {
          key
          name
          message
        }
        ...OrderTimeline
      }
    }
  `,
};
