import React from 'react';
import cn from 'classnames';

import QueryCombobox, { QueryParam } from 'components/QueryComboBox';
import StatusCombobox, { StatusParam } from 'components/StatusCombobox';

export type FilterParam = QueryParam | StatusParam;

export interface Props {
  filters: FilterParam[];
  isShowBorder?: boolean;
}

export interface ComboboxProps {
  filter: FilterParam;
}

export const Combobox = ({ filter }: ComboboxProps) => {
  if ((Object.values(StatusParam) as string[]).includes(filter)) return <StatusCombobox filterParam={filter as StatusParam} />;
  if ((Object.values(QueryParam) as string[]).includes(filter)) return <QueryCombobox filterParam={filter as QueryParam} />;

  return null;
};

const TableFiltersAdvanced = ({ isShowBorder, filters }: Props) => (
  <div className={cn('mt-4  border-gray-300 pt-4', { 'border-t': isShowBorder })}>
    <div className="flex items-end">
      {filters.map((f, i, arr) => (
        <div key={f} className={cn('w-44 lg:w-56', arr.length - 1 !== i && 'mr-2 lg:mr-4')}>
          <Combobox filter={f} />
        </div>
      ))}
    </div>
  </div>
);

export default TableFiltersAdvanced;
