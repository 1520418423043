import { Maybe, OrderPageQuery, RemakeReason } from '@graphql';
import { MeasurementTypePill } from '../MeasurementTypePill';
import { DetailedHTMLProps, ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import { Tooltip } from 'components';
import type { MeasurementTypeObject } from './MeasurementsSelect';
import { ArrayElement } from 'types/common';
import ReactTooltip from 'react-tooltip';

type Props = {
  label: string;
  type: Maybe<MeasurementTypeObject>;
  innerProps: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  remake: ArrayElement<OrderPageQuery['order']['items']>['remake'];
};

const MeasurementOptionComponent = ({ label, type, innerProps, remake }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const content = (
    <div ref={ref} className="flex my-3 mx-4 cursor-pointer" {...innerProps}>
      <div className="grow px-2 py-1 text-xs font-medium">{label}</div>
      {type && <MeasurementTypePill typeName={type.key} />}
    </div>
  );

  const isMeasurementErrorRemake = remake?.remakeReason === RemakeReason.TailorErrorMeasurements;

  return isMeasurementErrorRemake ? (
    <div data-tip={'Make sure to check & update the measurement profile'}>
      <ReactTooltip place="top" type="dark" effect="solid" />
      {content}
    </div>
  ) : (
    content
  );
};

export const MeasurementOption = forwardRef(MeasurementOptionComponent);
