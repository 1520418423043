import { gql } from '@apollo/client';

export const DesignOptions = {
  fragments: {
    conflicts: gql`
      fragment DesignOptionsFormProviderConflictsFragment on Conflict {
        message
        sourceOption {
          typeCode
          value
        }
        conflictOption {
          typeCode
          value
        }
        maxLength
        pattern
      }
    `,
  },
};
