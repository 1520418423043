import { DeleteMaterialPriceInput, GetFabricIndividualPricingQuery, UpdateManyRetailPriceInput, UpdateMaterialPriceInput } from '@graphql';
import { FabricPricesRow, IndividualPricingState } from './types';
import { groupBy, keyBy, keys } from 'lodash';

export const getInitialState = (data: GetFabricIndividualPricingQuery): IndividualPricingState => {
  const { coreFabrics, settings, productRetailPrices } = data;
  const headers = settings.productCategories.map((p) => ({ key: p.key, label: p.truncatedName })).sort((a, b) => a.label.localeCompare(b.label));

  const coreFabricsPerId = keyBy(coreFabrics, (f) => f.id);
  const pricesPerPerFabric = groupBy(productRetailPrices, (p) => p.coreFabricId);
  const rows = keys(pricesPerPerFabric).map((key): FabricPricesRow => {
    const productRetailPrices = pricesPerPerFabric[key];
    const fabric = coreFabricsPerId[productRetailPrices[0].coreFabricId];
    const pricesPerProductType = keyBy(productRetailPrices, (p) => p.productCategory);

    return {
      fabricId: fabric.id,
      label: fabric.name,
      fabricCode: fabric.code,
      prices: headers.map(({ key, label }) => {
        const priceInfo = pricesPerProductType[key];

        return {
          rawAmount: priceInfo?.amount ?? '',
          isEditMode: false,
          productCategory: key,
          productCategoryLabel: label,
          isUpdated: false,
        };
      }),
    };
  });

  rows.sort((a, b) => a.label.localeCompare(b.label));

  return {
    headers,
    rows,
    initialRows: rows,
  };
};

export const getInputParameters = (rows: FabricPricesRow[]): UpdateManyRetailPriceInput => {
  const pricesToDelete: DeleteMaterialPriceInput[] = [];

  const pricesToUpdate = rows.flatMap(({ prices, fabricId }) =>
    prices.map<UpdateMaterialPriceInput>(({ isUpdated, productCategory, rawAmount }) => {
      if (isUpdated) {
        if (rawAmount.length === 0) {
          pricesToDelete.push({ productCategory, fabricId });
        } else {
          return {
            productCategory,
            amount: rawAmount,
            fabricId,
          };
        }
      }
      return null;
    })
  );

  return {
    prices: pricesToUpdate.filter((p) => !!p),
    pricesToRemove: pricesToDelete,
  };
};
