import { useState } from 'react';
import NoFabricSvg from 'assets/no-fabric-image.svg';
import NoLiningSvg from 'assets/no-lining-image.svg';

interface Props {
  src?: string;
  type: 'fabric' | 'lining';
  className?: string;
}

export const FabricLiningImage = ({ src, type, className }: Props) => {
  const [missing, setMissing] = useState(!src);

  if (missing) {
    switch (type) {
      case 'fabric':
        return (
          <div className="flex items-center">
            <NoFabricSvg className={`py-3 ${className}`} />
          </div>
        );
      case 'lining':
        return (
          <div className="flex items-center">
            <NoLiningSvg className={className} />
          </div>
        );
    }
  }

  return <img className={className} src={src} onError={() => setMissing(true)} />;
};
