import { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { gql } from '@apollo/client';
import { useGetAlterationOptionsQuery } from '@graphql';
import { PencilIcon } from '@heroicons/react/outline';
import { ActiveBadge } from 'components';
import { orderBy } from 'lodash';
import { AlterationsOptionModal } from 'modules/alterations/components';

export const AlterationsOptionsList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const { data } = useGetAlterationOptionsQuery();

  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>

                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>

                    <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Garment category
                    </th>

                    <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Last updated
                    </th>

                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {orderBy(data?.alterationOptions, ['updatedAt.origin'], ['desc']).map((option, index) => (
                    <tr key={option.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-6 py-4 whitespace-nowrap text-center">
                        <ActiveBadge isActive={option.isActive} onText="Active" offText="Inactive" />
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <a
                          href="#"
                          className="text-indigo-600 hover:text-indigo-900"
                          onClick={(e) => {
                            e.preventDefault();

                            setSelectedOption(option);

                            setIsOpen(true);
                          }}
                        >
                          {option.name}
                        </a>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-400">{option.garmentCategory.name}</td>

                      <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-400">{option.updatedAt.fromNow}</td>

                      <td className="px-6 py-4 whitespace-nowrap text-sm text-right font-medium">
                        <div className="flex items-center justify-end">
                          <a
                            href="#"
                            className="text-gray-400 hover:text-gray-600"
                            data-tip="Edit option"
                            onClick={(e) => {
                              e.preventDefault();

                              setSelectedOption(option);

                              setIsOpen(true);
                            }}
                          >
                            <PencilIcon className="h-4 w-4" />

                            <ReactTooltip place="top" type="dark" effect="solid" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <AlterationsOptionModal isOpen={isOpen} setIsOpen={setIsOpen} alterationOption={selectedOption} />
    </>
  );
};

AlterationsOptionsList.queries = {
  alterationOptions: gql`
    query GetAlterationOptions {
      alterationOptions {
        id
        name
        isActive
        garmentCategory {
          key
          name
        }
        values
        canDelete
        updatedAt {
          fromNow
          origin
        }
      }
    }
  `,
};
