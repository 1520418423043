import { gql } from '@apollo/client';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { OrderDetailsPrintContact } from 'modules/orders/components/OrderDetailsPrintContact';
import { OrderDetailsPrintDesignOptions } from 'modules/orders/components/OrderDetailsPrintDesignOptions';
import { OrderDetailsPrintDetails } from 'modules/orders/components/OrderDetailsPrintDetails';
import { OrderDetailsPrintFooter } from 'modules/orders/components/OrderDetailsPrintFooter';
import { OrderDetailsPrintHeader } from 'modules/orders/components/OrderDetailsPrintHeader';
import { OrderDetailsPrintMeasurements } from 'modules/orders/components/OrderDetailsPrintMeasurements';
import { OrderDetailsPrintRemarks } from 'modules/orders/components/OrderDetailsPrintRemarks';
import styled from 'styled-components';
import { useOrderDetailsPrintViewQuery } from '@graphql';
import { useRouter } from 'next/router';
import { parseRouteId } from 'modules/common';
import { DesignItem } from 'modules/orders/orders.types';

const PrintCont = styled.div`
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
`;

export const OrderDetailsPrintView = () => {
  const { fulfilment } = useActiveSession();
  const router = useRouter();
  const { orderId } = router.query;
  const { data } = useOrderDetailsPrintViewQuery({ variables: { orderId: parseRouteId(orderId) }, skip: fulfilment !== 'PDF' });
  const order = data?.order;
  const designItems: DesignItem[] = order?.items.flatMap((item) => item.designs.flatMap((design) => ({ item, design })));

  if (!order) return null;

  return (
    <PrintCont>
      <div className="hidden print:block space-y-6">
        <table className="w-full">
          {/* HEADER */}
          <thead>
            <tr>
              <td style={{ height: '165px' }}>&nbsp;</td>
            </tr>
          </thead>
          {/* CONTENT */}
          <tbody>
            <tr>
              <td>
                <OrderDetailsPrintContact
                  customerAddress={order.customer.shippingAddress}
                  shippingShowroom={order.shippingShowroom}
                  salesRep={order.salesRep}
                />
                <OrderDetailsPrintRemarks notes={order.notes} timezone={order.tailor.timezone} />
                <OrderDetailsPrintDetails designItems={designItems} />
                <OrderDetailsPrintMeasurements designItems={designItems} />
                <OrderDetailsPrintDesignOptions items={order.items} />
              </td>
            </tr>
          </tbody>
          {/* FOOTER */}
          <tfoot>
            <tr>
              <td style={{ height: '30px' }}>&nbsp;</td>
            </tr>
          </tfoot>
        </table>
        <div className="fixed top-0 w-full block overflow-visible" style={{ height: '150px', marginTop: 0 }}>
          <OrderDetailsPrintHeader
            orderId={order.customId}
            showroom={order.shippingShowroom}
            salesRep={order.salesRep}
            orderDate={order.createdAt.origin}
            // TODO: use correct submissionDate
            submissionDate={order.createdAt.origin}
          />
        </div>
        <div className="fixed bottom-1 w-full block overflow-visible" style={{ height: '20px' }}>
          <OrderDetailsPrintFooter orderId={order.customId} />
        </div>
      </div>
    </PrintCont>
  );
};

OrderDetailsPrintView.fragments = {
  tailor: gql`
    fragment PrintOrderTailor on Tailor {
      timezone
    }
  `,
  shippingShowroom: gql`
    fragment PrintOrderShippingShowroom on Showroom {
      id
      name
      address {
        id
        line1
        line2
        city
        company
        country
        phone
        postalCode
        province
      }
    }
  `,
  salesRep: gql`
    fragment PrintOrderSalesRep on User {
      id
      firstName
      lastName
    }
  `,
  customer: gql`
    fragment PrintOrderCustomer on Customer {
      id
      shippingAddress {
        id
        firstName
        lastName
        line1
        line2
        city
        company
        country
        phone
        postalCode
        province
      }
      createdAt {
        fromNow
      }
    }
  `,
  notes: gql`
    fragment PrintOrderNotes on OrderNote {
      id
      message
      createdAt {
        origin
      }
      type {
        key
        name
      }
    }
  `,
  itemDesign: gql`
    fragment PrintOrderDesignItem on OrderItemDesign {
      id
      garmentCategory {
        name
      }
      measurement {
        size
        fit {
          name
        }
        finishedMeasurementFields {
          key
          value
          label
        }
      }
      options {
        id
        name
        code
        designTypeOffering {
          name
          code
        }
      }
    }
  `,
  orderItems: gql`
    fragment PrintOrderItems on OrderItem {
      id
      name
      quantity
      fabric {
        id
        code
        vendor
      }
      customFabric
      lining {
        id
        code
      }
      designs {
        ...PrintOrderDesignItem
      }
    }
  `,
};

OrderDetailsPrintView.queries = gql`
  ${OrderDetailsPrintView.fragments.tailor}
  ${OrderDetailsPrintView.fragments.shippingShowroom}
  ${OrderDetailsPrintView.fragments.salesRep}
  ${OrderDetailsPrintView.fragments.customer}
  ${OrderDetailsPrintView.fragments.notes}
  ${OrderDetailsPrintView.fragments.orderItems}
  ${OrderDetailsPrintView.fragments.itemDesign}

  query OrderDetailsPrintView($orderId: ID!) {
    order(orderId: $orderId) {
      id
      customId
      tailor {
        ...PrintOrderTailor
      }
      shippingShowroom {
        ...PrintOrderShippingShowroom
      }
      salesRep {
        ...PrintOrderSalesRep
      }
      createdAt {
        origin
      }
      customer {
        ...PrintOrderCustomer
      }
      notes {
        ...PrintOrderNotes
      }
      items {
        ...PrintOrderItems
      }
    }
  }
`;
