import React from 'react';
import cn from 'classnames';
import { InputError, InputLabel, InputProps, InputSymbol, defaultErrorStyles } from './Input';
import { useActiveSession } from 'hooks/useActiveSessionContext';

function getCurrencySymbol(locale: string, currency: string) {
  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}

export const MoneyInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, register, label, errorMessage, htmlProps, testId, showError = true, hideSpinBox = true }, ref): React.ReactElement => {
    const {
      tailor: { currency, locale },
    } = useActiveSession();

    const symbol = getCurrencySymbol(locale, currency);

    return (
      <div className={className}>
        <InputLabel htmlProps={htmlProps} label={label} />
        <div className="relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">{symbol}</span>
          </div>
          <input
            data-testid={testId}
            type="number"
            ref={ref}
            className={cn(
              'block w-full pr-12 sm:text-sm rounded-md disabled:cursor-not-allowed',
              symbol.length === 1 ? 'pl-7' : '',
              symbol.length === 2 ? 'pl-8' : '',
              symbol.length >= 3 ? 'pl-9' : '',
              defaultErrorStyles({ errorMessage }),
              {
                'no-spin-box': hideSpinBox,
              }
            )}
            {...register}
            {...htmlProps}
          />
          <InputSymbol symbol={currency} />
        </div>
        <InputError showError={showError} errorMessage={errorMessage} />
      </div>
    );
  }
);
