import { Fit, GarmentFieldSetting, GarmentFieldSizeSettings, GarmentFitSetting, Maybe } from '@graphql';
import { DependingFieldSetting } from './types';

export const getBlockMeasurement = (fits: GarmentFitSetting[], fit: Fit, size: string, field: GarmentFieldSetting) =>
  fits
    ?.find((f) => f.key === fit)
    ?.sizes.find((s) => s.key === size)
    ?.fields.find((f) => f.key === field.key);

export const getBlockRange = (block: Maybe<GarmentFieldSizeSettings>, { isOffset, range, isHalved }: GarmentFieldSetting) => {
  if (!block) {
    return null;
  }
  const minValue = isOffset ? block?.value + range.min : range.min;
  const maxValue = isOffset ? block?.value + range.max : range.max;

  return {
    minRange: minValue * (isHalved ? 2 : 1),
    maxRange: maxValue * (isHalved ? 2 : 1),
  };
};

export const getDependantMeasurementFieldsMapping = (fieldSettings: GarmentFieldSetting[]) => {
  const dependingFields = fieldSettings.map((fieldSetting, index) => ({ ...fieldSetting, index })).filter((f) => !!f.basedOn);

  return dependingFields.reduce<Record<`tweaks.${number}.value`, DependingFieldSetting[]>>((prev, { index, ...pendingFieldSetting }) => {
    const basedOnFieldIndex = fieldSettings.findIndex((f) => f.key === pendingFieldSetting.basedOn);

    const currentCalculatedFields = prev[`tweaks.${basedOnFieldIndex}.value`] ?? [];

    currentCalculatedFields.push({
      fieldName: `tweaks.${index}.value`,
      fieldSetting: pendingFieldSetting,
    });

    return {
      ...prev,
      [`tweaks.${basedOnFieldIndex}.value`]: currentCalculatedFields,
    };
  }, {});
};
