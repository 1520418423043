import { Box, TextSkeleton } from 'components';

interface Props {
  numItems?: number;
}

export const OrderItemListSkeletonLoading = ({ numItems = 3 }: Props) => {
  return (
    <Box allowSkeletonLoading>
      <div className="flex items-start justify-between border-b border-neutral-200 pb-6">
        <TextSkeleton className="w-24" />
        <TextSkeleton className="w-24 h-9" />
      </div>
      <div className="flex py-4 border-b border-neutral-200 w-full">
        <div className="uppercase text-gray-400 font-semibold text-xs flex-1 w-3/12">
          <TextSkeleton className="w-12" />
        </div>
        <div className="uppercase text-gray-400 font-semibold text-xs shrink-0 w-1/12">
          <TextSkeleton className="w-12" />
        </div>
        <div className="uppercase text-gray-400 font-semibold text-xs flex-1 w-4/12">
          <TextSkeleton className="w-12 mx-2" />
        </div>
        <div className="uppercase text-gray-400 font-semibold text-xs shrink-0 w-16">
          <TextSkeleton className="w-12" />
        </div>
        <div className="uppercase text-gray-400 font-semibold text-xs shrink-0 w-16"></div>
      </div>
      <div>
        {Array(numItems)
          .fill({})
          .map((row, count) => {
            return (
              <div className="flex mb-12 first:mt-6 last:mb-6 w-full text-sm" key={`order-item-list-${count}`}>
                <div className="flex-1 w-3/12 flex items-center">
                  <TextSkeleton className="w-40" />
                </div>
                <div className="shrink-0 w-1/12 flex items-center">
                  <TextSkeleton className="w-24" />
                </div>
                <div className="flex-1 w-4/12 flex items-center">
                  <TextSkeleton className="w-60 mx-2" />
                </div>
                <div className="shrink-0 w-16 flex items-center">
                  <TextSkeleton className="w-4" />
                </div>
                <div className="shrink-0 w-16 flex items-center">
                  <TextSkeleton className="w-2" />
                </div>
              </div>
            );
          })}
      </div>
    </Box>
  );
};
