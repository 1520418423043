import { PrintOrderNotesFragment } from '@graphql';
import { OrderDetailsPrintSectionHeader } from 'modules/orders/components/OrderDetailsPrintSectionHeader';

interface Props {
  notes: PrintOrderNotesFragment[];
  timezone: string;
}

export const OrderDetailsPrintRemarks = ({ notes, timezone }: Props) => {
  const notesReversed = [...notes].reverse();

  const formatDate = (date: Date) => {
    const timeFormatted = new Intl.DateTimeFormat('default', {
      timeZone: timezone,
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    }).format(date);

    const dateFormatted = new Intl.DateTimeFormat('default', {
      timeZone: timezone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(date);

    return `${dateFormatted} ${timeFormatted}`;
  };

  return (
    <div className="mt-4">
      <OrderDetailsPrintSectionHeader label="Remarks" />
      <p className="mt-2 px-2 text-10">
        {/* TODO: filter out messages */}
        {notesReversed.map((note, i) => (
          <span key={note.id}>
            {note.message} [{formatDate(new Date(note.createdAt.origin))}]{i + 1 !== notes.length ? ', ' : '.'}
          </span>
        ))}
      </p>
    </div>
  );
};
