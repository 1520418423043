import { gql } from '@apollo/client';
import { orderBy } from 'lodash';
import { useMemo } from 'react';

import {
  FulfilmentType,
  GetApplicableMeasurementsQueryResult,
  GetMeasurementsQueryResult,
  Measurement,
  MeasurementType,
  useGetApplicableMeasurementsQuery,
  useGetMeasurementsQuery,
} from '@graphql';
import { Box, Dropdown } from 'components';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { MeasurementItem } from 'modules/measurements/components/MeasurementItem';

type measurements = GetMeasurementsQueryResult['data']['measurements'];
type applicableMeasurements = GetApplicableMeasurementsQueryResult['data']['applicableMeasurements'];

interface Props {
  customerId: string;
  customerFirstName: string;
}

export const getMeasurementPath = ({ type, customerId }: { type: MeasurementType; customerId: string }) =>
  `/measurements/new?type=${type}&customerId=${customerId}`;

function getFulfilmentMeasurementListItems({
  //fulfilment,
  customerId,
  hasBodyMeasurement,
  measurements,
}: {
  //fulfilment: FulfilmentType;
  customerId: string;
  hasBodyMeasurement?: boolean;
  measurements: applicableMeasurements;
}) {
  // const BodyMeasurementListItem = {
  //   name: 'Add body measurement',
  //   path: getMeasurementPath({ type: MeasurementType.Body, customerId }),
  //   type: MeasurementType.Body,
  // };

  // const GarmentMeasurementListItem = {
  //   name: 'Add garment measurement',
  //   path: getMeasurementPath({ type: MeasurementType.Garment, customerId }),
  //   type: MeasurementType.Garment,
  // };

  // const SimplifiedMeasurementListItem = {
  //   name: 'Add simplified try on measurement',
  //   path: getMeasurementPath({ type: MeasurementType.Simplified, customerId }),
  //   type: MeasurementType.Simplified,
  // };

  // const TryOnMeasurementListItem = {
  //   name: 'Add try on measurement',
  //   path: getMeasurementPath({ type: MeasurementType.TryOn, customerId }),
  //   type: MeasurementType.TryOn,
  // };

  // const AppliedMeasurementListItem = {
  //   name: 'Add applied measurement',
  //   path: getMeasurementPath({ type: MeasurementType.Applied, customerId }),
  //   type: MeasurementType.Applied,
  //   isDisabled: !hasBodyMeasurement,
  // };

  let listItems = [];
  listItems = measurements?.map((mm) => {
    return {
      name: `Add ${mm?.type?.name} measurement`,
      path: getMeasurementPath({ type: mm?.type?.key, customerId }),
      type: mm?.type?.key,
      isDisabled: !hasBodyMeasurement && mm?.type?.key === MeasurementType.Applied,
    };
  });

  // switch (fulfilment) {
  //   case FulfilmentType.JeromeTryOn:
  //     listItems = [BodyMeasurementListItem, TryOnMeasurementListItem];
  //     break;
  //   case FulfilmentType.JeromeFinished:
  //     listItems = [BodyMeasurementListItem, GarmentMeasurementListItem, SimplifiedMeasurementListItem, AppliedMeasurementListItem];
  //     break;
  //   case FulfilmentType.JeromeSimplified:
  //     listItems = [BodyMeasurementListItem, SimplifiedMeasurementListItem];
  //     break;
  //   case FulfilmentType.Jerome:
  //     listItems = [
  //       BodyMeasurementListItem,
  //       TryOnMeasurementListItem,
  //       GarmentMeasurementListItem,
  //       SimplifiedMeasurementListItem,
  //       AppliedMeasurementListItem,
  //     ];
  //     break;
  //   case FulfilmentType.Pdf:
  //     listItems = [BodyMeasurementListItem, GarmentMeasurementListItem, SimplifiedMeasurementListItem, AppliedMeasurementListItem];
  //     break;
  //   default:
  //     listItems = [];
  // }

  return listItems?.filter(Boolean);
}

const MeasurementListItem = ({
  measurements,
  name,
  customerId,
  type,
}: {
  measurements: measurements;
  name: string;
  customerId: string;
  type: MeasurementType;
}) => {
  const typeHeading = type.toLowerCase().replace('_', ' ');

  return (
    <>
      <div className="uppercase text-xs text-gray-500 mt-6 font-bold">{`${typeHeading} measurements`}</div>
      {measurements?.length ? (
        <ul className="divide-y divide-gray-100 mt-2">
          {orderBy(measurements, ['updatedAt.origin'], ['desc']).map((measurement) => (
            <MeasurementItem measurement={measurement} customerId={customerId} key={measurement.id} type={measurement.type.key} />
          ))}
        </ul>
      ) : (
        <div className="text-sm mt-2 text-gray-400">{`${name} doesn't have any ${typeHeading} measurements yet.`}</div>
      )}
    </>
  );
};

export const MeasurementsList = ({ customerId, customerFirstName }: Props) => {
  //const { fulfilment } = useActiveSession();
  const { data, loading } = useGetMeasurementsQuery({
    variables: {
      customerId: customerId,
    },
  });
  const { data: dataM, loading: loadingM } = useGetApplicableMeasurementsQuery();

  const measurements = useMemo(() => {
    return data?.measurements?.reduce(
      (prev, curr) => {
        if (!prev[curr.type.key]) prev[curr.type.key] = [];
        prev[curr.type.key].push(curr);
        return prev;
      },
      Object.keys(MeasurementType).reduce((a, v) => ({ ...a, [MeasurementType[v]]: [] }), {})
    );
  }, [data]);

  const hasBodyMeasurement = measurements?.[MeasurementType.Body]?.length;

  const items = getFulfilmentMeasurementListItems({ customerId, hasBodyMeasurement, measurements: dataM?.applicableMeasurements });

  return (
    <Box title="Measurements" isLoading={loading || loadingM}>
      <div className="absolute top-4 right-4">
        <Dropdown name="Add a measurement" items={items || []} />
      </div>
      {measurements &&
        !!items?.length &&
        items.map(({ type }) => (
          <MeasurementListItem
            key={type}
            measurements={measurements[type]}
            name={customerFirstName}
            customerId={customerId}
            type={type as MeasurementType}
          />
        ))}
    </Box>
  );
};

MeasurementsList.query = gql`
  query GetMeasurements($customerId: ID!) {
    measurements(customerId: $customerId) {
      id
      name
      type {
        name
        key
      }
      updatedAt {
        fromNow
        origin
      }
      garmentCategory {
        key
        name
      }
    }
  }
`;
MeasurementsList.applicableMeasurementsquery = gql`
  query GetApplicableMeasurements {
    applicableMeasurements {
      type {
        name
        key
      }
    }
  }
`;
