import { fetchAndActivate, getRemoteConfig, getValue, RemoteConfig } from '@firebase/remote-config';
import { getFeatureFlags } from 'graphql/feature-flags/getFeatureFlags.gql';
import { FeatureFlag } from '@graphql';
import { useEffect } from 'react';
import { firebaseService } from 'services';

export type Feature = keyof FeatureFlag | 'ff_contextual_grouping' | 'ff_pricing_allow_list';

let _remoteConfig: RemoteConfig | undefined;

type DefaultRemoteConfig = {
  [key in Feature]?: string | number | boolean;
};

const DEFAULT_CONFIG: DefaultRemoteConfig = {
  ff_pricing_allow_list: '',
  ff_contextual_grouping: false,
};

export const useFeature = () => {
  useEffect(() => {
    if (!_remoteConfig) {
      _remoteConfig = getRemoteConfig(firebaseService);
      _remoteConfig.settings.minimumFetchIntervalMillis = 360000;
      _remoteConfig.defaultConfig = DEFAULT_CONFIG;
    }
  }, []);

  const initFeatureService = async () => {
    try {
      // load feature flags into apollo cache
      await getFeatureFlags();

      return await fetchAndActivate(_remoteConfig);
    } catch {
      return false;
    }
  };

  const isFeatureEnabled = async (feature: Feature) => {
    // retrieve feature flags from apollo cache
    const featureFlags = await getFeatureFlags();
    if (featureFlags?.[feature]) {
      return featureFlags[feature];
    }

    if (_remoteConfig) {
      return getValue(_remoteConfig, feature).asBoolean();
    }
    return false;
  };

  const isValueInAllowList = ({ feature, valueToCheck }: { feature: Feature; valueToCheck: string }) => {
    if (_remoteConfig) {
      if (!feature.includes('allow_list')) {
        return false;
      }
      return getValue(_remoteConfig, feature)
        .asString()
        .split(',')
        .map((v) => v.trim())
        .includes(valueToCheck);
    }
  };

  return {
    initFeatureService,
    isFeatureEnabled,
    isValueInAllowList,
  };
};
