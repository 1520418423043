import { GetOrderBasicInfoByIdsQuery } from '@graphql';
import { TextSkeleton } from 'components/TextSkeleton';

type Props = {
  title: React.ReactNode;
  orderIds: string[];
  ordersPerId: Record<string, GetOrderBasicInfoByIdsQuery['orders']['items'][number]>;
};

export const RelatedOrderRow = ({ title, orderIds, ordersPerId }: Props) => {
  return (
    <>
      <h4 className="mt-4 text-gray-500">{title}</h4>
      <div className="flex flex-col divide-y">
        {orderIds.map((id) => {
          const orderDetails = ordersPerId[id];

          if (!orderDetails) {
            return (
              <div key={id}>
                <TextSkeleton className="my-2 w-1/6" />
                <TextSkeleton className="my-2 w-1/6" />
                <TextSkeleton className="my-2 w-1/6" />
              </div>
            );
          }
          return (
            <div key={id} className="flex flex-col gap-y-0 py-4">
              <a href={`/orders/${id}`} className="text-sm underline" target="_blank" rel="noreferrer">
                {orderDetails.customId ?? orderDetails.id}
              </a>
              <span className="text-sm">Customer: {`${orderDetails.customer.firstName} ${orderDetails.customer.lastName}`}</span>
              <span className="text-sm">Created at: {orderDetails.createdAt.formatted}</span>
            </div>
          );
        })}
      </div>
    </>
  );
};
