/**
 * Amplitude docs: https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2
 */
import { ActiveSessionQuery } from '@graphql';
import * as amplitude from '@amplitude/analytics-browser';

const amplitudeToken = process.env.NEXT_PUBLIC_AMPLITUDE_TOKEN;

const amplitudeService = {
  /**
   * Initializes a new instance of the Amplitude tracking object
   */
  init: (): void => {
    if (amplitudeToken) {
      amplitude.init(amplitudeToken, {
        autocapture: {
          formInteractions: false,
          fileDownloads: false,
          elementInteractions: false,
        },
      });
    }
  },

  hasUserId: () => amplitudeToken && !!amplitude.getUserId(),
  /**
   * Useful for clearing data when a user logs out.
   */
  reset: (): void => {
    if (amplitudeToken) {
      amplitude.reset();
    }
  },

  /**
   * Send tracking event to Amplitude
   */
  track: amplitude.track,
  /**
   * Identify a user with a unique ID to track user activity across devices, tie a user to their events, and create a user profile.
   * If you never call this method, unique visitors are tracked using a UUID generated the first time they visit the site.
   */
  identify: async ({ user, tailor }: ActiveSessionQuery['activeSession']) => {
    if (amplitudeToken) {
      const identifyObj = new amplitude.Identify();
      identifyObj.set('role', user.role);
      identifyObj.set('tailorSlug', tailor.slug);
      amplitude.setUserId(user.id);
      const r = amplitude.identify(identifyObj);
      return r.promise;
    }
  },
};

export default amplitudeService;

export type EventName = `${string} Clicked` | `${string} Saved` | `${string} Created` | `${string} Updated` | `${string} Selected`;
