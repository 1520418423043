import React, { ChangeEvent, HTMLProps, MouseEvent } from 'react';

import { Input, Spinner } from 'components';
import { Button } from 'modules/common';

interface EditCustomIdProps {
  onSave: (event: MouseEvent<HTMLButtonElement>) => void;
  onCancel: (event: MouseEvent<HTMLButtonElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  placeholder?: string;
  saveButtonLabel?: string;
  cancelButtonLabel?: string;
  value?: string;
  isLoading?: boolean;
}

const EditCustomId = (props: EditCustomIdProps): React.ReactElement => {
  const { onSave, onCancel, onChange, label, placeholder, value, saveButtonLabel, cancelButtonLabel, isLoading } = props;

  const htmlProps: HTMLProps<HTMLInputElement> = {
    id: 'customId',
    type: 'text',
    placeholder,
    onChange,
    maxLength: 24,
  };

  if (onChange) {
    htmlProps.defaultValue = value;
  } else {
    htmlProps.value = value;
  }

  return (
    <div className="flex items-center space-x-4">
      <div className="w-64">
        <Input label={label} htmlProps={htmlProps} />
      </div>
      <div className="flex space-x-2">
        <Button className="text-sm" onClick={onSave} isDisabled={isLoading}>
          {isLoading ? <Spinner /> : saveButtonLabel ?? 'Save'}
        </Button>
        <Button className="text-sm" variant="neutral" onClick={onCancel} isDisabled={isLoading}>
          {cancelButtonLabel ?? 'Cancel'}
        </Button>
      </div>
    </div>
  );
};

export default EditCustomId;
