import { ChevronRightIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import { ReactNode } from 'react';
import cn from 'classnames';
import { Url } from 'next/dist/shared/lib/router/router';

const Icon = ({ icon, hoverState }: { icon: ReactNode; hoverState?: boolean }) => (
  <div className={cn(['shrink-0', 'h-5', 'w-5', 'text-gray-400'], hoverState ? 'hover:text-gray-500' : '')} aria-hidden="true">
    {icon}
  </div>
);

interface BreadcrumbsProps {
  className?: string;
  links: Array<{
    name?: string;
    icon?: ReactNode;
    href?: Url;
  }>;
}

export const Breadcrumbs = ({ links, className }: BreadcrumbsProps): React.ReactElement => {
  return (
    <nav className={cn('flex', className)} aria-label="Breadcrumb">
      <ol className="flex items-center">
        {links.map((page, index) => (
          <li key={index}>
            <div className="flex items-center">
              {index !== 0 && (
                <span className="mr-2">
                  <Icon icon={<ChevronRightIcon />} />
                </span>
              )}

              {!page.href ? (
                <span className="text-sm mr-2 text-neutral-600">
                  {page.name}
                  {page.icon && <Icon icon={page.icon} hoverState />}
                </span>
              ) : (
                <Link href={page.href} className="text-sm mr-2 text-neutral-600 hover:text-neutral-900 " aria-current="page">
                  {page.name}
                  {page.icon && <Icon icon={page.icon} hoverState />}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
