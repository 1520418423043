import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';

interface ActiveBadgeProps {
  isActive: boolean;
  onText?: string;
  offText?: string;
}

export const ActiveBadge = (props: ActiveBadgeProps): React.ReactElement => {
  const { isActive, onText, offText } = props;

  return isActive ? (
    <span className="px-2 h-6 inline-flex text-11 font-semibold rounded bg-emerald-500 text-emerald-100 items-center uppercase">
      <CheckCircleIcon className="h-4 w-4 text-emerald-100" />
      {onText && <span className="ml-1">{onText}</span>}
    </span>
  ) : (
    <span className="px-2 h-6 inline-flex text-11 font-semibold rounded bg-gray-200 text-gray-400 items-center leading-none uppercase">
      <XCircleIcon className="h-4 w-4 text-gray-400" /> {offText && <span className="ml-1">{offText}</span>}
    </span>
  );
};
