import cn from 'classnames';
import { useMemo } from 'react';

import { RadioButton } from 'components/RadioButton';
import { useDesignOptionsForm } from '../designOptions.context';
import { DesignOptionField } from '../designOptions.types';
import { PricingPill } from 'modules/pricing';
import { useFeature } from 'hooks';
import { useActiveSession } from 'hooks/useActiveSessionContext';

type Props = {
  className?: string;
  field: DesignOptionField;
  errorMessage?: string;
  isDisabled?: boolean;
};

export const DesignOptionsRadioGroup = ({ className, field, errorMessage, isDisabled = false }: Props) => {
  const { formData, setAndSaveField, getPrice } = useDesignOptionsForm();
  const price = useMemo(() => getPrice({ typeCode: field.name }), [formData, field]);
  const { tailorSlug } = useActiveSession();
  const { isValueInAllowList } = useFeature();

  return (
    <div className={`flex items-start flex-col ${className}`}>
      <div className="flex justify-between w-full mb-1">
        <div className="text-gray-500 text-xs font-medium">{field.label}</div>
        {isValueInAllowList({ feature: 'ff_pricing_allow_list', valueToCheck: tailorSlug }) && Boolean(price) && <PricingPill amount={price} />}
      </div>
      <div className="flex">
        <RadioButton
          value={field.values[0]}
          name={field.label}
          onChange={(value) => setAndSaveField({ id: field.name, value: value.toString(), label: field.label, displayValue: field.texts[0] })}
          isSelected={formData[field.name] === field.values[0]}
          className={cn({ 'text-red-500': errorMessage })}
          isRequired={true}
          isDisabled={isDisabled}
        >
          {field.texts[0]}
        </RadioButton>
        <RadioButton
          value={field.values[1]}
          name={field.label}
          onChange={(value) => setAndSaveField({ id: field.name, value: value.toString(), label: field.label, displayValue: field.texts[1] })}
          isSelected={formData[field.name] === field.values[1]}
          isRequired={true}
          isDisabled={isDisabled}
          className={cn({ 'text-red-500': errorMessage })}
        >
          {field.texts[1]}
        </RadioButton>
      </div>
      {errorMessage && <div className="text-red-500 text-xs">{errorMessage}</div>}
    </div>
  );
};
