import { measurementTypeColor } from 'modules/measurements/components/MeasurementItem';
import { startCase } from 'lodash';

export const MeasurementTypePill = ({ typeName }: { typeName: string }) => (
  <div
    className={`text-xs rounded-full text-neutral-800 uppercase flex items-center px-2 font-medium leading-4 h-6 ${measurementTypeColor[typeName]}`}
  >
    {startCase(typeName)}
  </div>
);
