import { makeVar } from '@apollo/client';

type LocalQuery = {
  localUi: 'ready' | 'loading' | 'saving' | 'error' | 'not-found';
};

export const uiVar = makeVar<LocalQuery['localUi']>('loading');

export const clearLocalCommon = () => {
  uiVar('loading');
};
