import { useState } from 'react';

import { Tabs } from 'components';
import { PricingModal } from 'modules/pricing/components/PricingModal';
import { FabricTab } from 'modules/pricing/components/FabricTab';
import { LiningTab } from 'modules/pricing/components/LiningTab';
import { Price } from 'modules/pricing/pricing.types';
import { IndividualPriceTab } from './IndividualPriceTab';

export const PricingMatrix = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [price, setPrice] = useState(null);

  const onPriceClick = (price: Price) => {
    setIsOpen(true);
    setPrice(price);
  };

  return (
    <>
      <Tabs
        tabs={[
          {
            name: 'Fabric',
            content: <FabricTab onPriceClick={onPriceClick} />,
          },
          {
            name: '"Cut Length" Fabric',
            content: <FabricTab onPriceClick={onPriceClick} isCutLength={true} />,
          },
          {
            name: '"Individual Price" Fabric',
            content: <IndividualPriceTab />,
          },
          {
            name: 'Lining',
            content: <LiningTab onPriceClick={onPriceClick} />,
          },
        ]}
      />
      <PricingModal isOpen={isOpen} setIsOpen={setIsOpen} price={price} />
    </>
  );
};
