import { MeasurementUnit } from '@graphql';
import { getUnitShortName, getUnitLabel, getUnitValue } from 'helpers/measurement-helpers';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';

/**
 * When measurement unit is not defined in tailor config, we use CM by default
 */
const DEFAULT_MEASUREMENT_UNIT = MeasurementUnit.Cm;
/**
 * The API back-end handle values in CM
 */
export const SERVER_MEASUREMENT_UNIT_VALUE = MeasurementUnit.Cm;

const MeasurementUnitContext = createContext<{ measurementUnit: MeasurementUnit; setMeasurementUnit: (value: MeasurementUnit) => void } | null>(null);

type Props = { children: ReactNode };

export const MeasurementUnitProvider = ({ children }: Props) => {
  const { defaultMeasurementUnit } = useActiveSession();
  const [measurementUnit, setMeasurementUnit] = useState(defaultMeasurementUnit ?? DEFAULT_MEASUREMENT_UNIT);

  const contextValue = useMemo(
    () => ({
      measurementUnit,
      setMeasurementUnit,
    }),
    [measurementUnit]
  );

  return <MeasurementUnitContext.Provider value={contextValue}>{children}</MeasurementUnitContext.Provider>;
};

export const useMeasurementUnit = () => {
  const contextMeasurementUnit = useContext(MeasurementUnitContext);

  const { defaultMeasurementUnit } = useActiveSession();
  const [localMeasurementUnit, localSetMeasurementUnit] = useState(defaultMeasurementUnit ?? DEFAULT_MEASUREMENT_UNIT);

  const measurementUnit = contextMeasurementUnit?.measurementUnit ?? localMeasurementUnit;
  const setMeasurementUnit = contextMeasurementUnit?.setMeasurementUnit ?? localSetMeasurementUnit;

  const hookResponse = useMemo(
    () => ({
      measurementUnitInfo: {
        current: measurementUnit,
        shortName: getUnitShortName(measurementUnit),
        label: getUnitLabel(measurementUnit),
      },
      updateMeasurementUnit: setMeasurementUnit,
      convertUnit: (value: number, unitTo: MeasurementUnit, decimalPlaces = 2) => getUnitValue(value, measurementUnit, unitTo, decimalPlaces),
      convertFromServerUnit: (value: number, decimalPlaces = 2) => getUnitValue(value, SERVER_MEASUREMENT_UNIT_VALUE, measurementUnit, decimalPlaces),
      convertToServerUnit: (value: number, decimalPlaces = 2) => getUnitValue(value, measurementUnit, SERVER_MEASUREMENT_UNIT_VALUE, decimalPlaces),
    }),
    [measurementUnit]
  );

  return hookResponse;
};
