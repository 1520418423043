import styled from 'styled-components';
import { GetAlterationQuery, useGetAlterationQuery } from '@graphql';
import { useActiveSession } from 'hooks/useActiveSessionContext';

export const Outline = styled.div`
  width: 97%;
  height: 100%;
  margin: 0 auto;
  border: 1px solid;
  border-radius: 2mm;
  display: flex;
  flex-direction: column;
  page-break-inside: avoid;
`;

export const Header = styled.div`
  width: 100%;
  height: 32mm;
  border-bottom: 1px solid;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;

  > h3 {
    font-size: 6mm;
    padding: 1mm;
  }

  > span {
    height: 100%;
    padding: 2mm;
    font-size: 5mm;
    font-weight: 600;
    border-left: 1px solid;
    border-bottom: 1px solid;
  }
`;

export const Dates = styled.div`
  padding: 1mm;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1mm;

  > div {
    margin-left: 2mm;
    display: flex;
    align-items: flex-end;
    height: 4mm;

    > span {
      flex: 1 0 45%;
      font-size: 2mm;
      text-transform: uppercase;
    }

    > b {
      flex: 1 0 55%;
      font-size: 3mm;
      text-align: center;
      border-bottom: 1px solid;
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  height: 150mm;
`;

export const ItemBlock = styled.div`
  height: 50mm;
  display: flex;

  :not(:nth-child(3)) {
    border-bottom: 1px solid;
  }
`;

export const ItemDetail = styled.div`
  padding: 2mm 0;
  flex: 1 0 30mm;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 80%;
    margin-right: 3mm;
  }

  p {
    font-size: 4mm;
  }
`;

export const Alteration = styled.div`
  flex: 1 0 100mm;
  padding: 1mm 2mm;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Options = styled.div`
  margin-bottom: 2mm;

  input {
    margin-right: 2mm;
  }

  span {
    font-size: 4mm;
    line-height: 5mm;
  }
`;

export const Note = styled.div`
  display: flex;
  margin-bottom: 1mm;
  > span {
    font-size: 3mm;
    margin-right: 2mm;
  }
  > p {
    font-size: 3mm;
    line-height: 4mm;
    margin-bottom: 0;
    word-break: break-all;
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 16mm;
  border-top: 1px solid;
  display: flex;
  flex-direction: column;
`;

export const FooterRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-content: flex-end;

  > div {
    flex-basis: 50%;
    margin: 2mm;
    display: flex;

    > span {
      flex: 1 0 20%;
      font-size: 2.5mm;
      text-transform: uppercase;
    }

    > b {
      flex: 1 0 80%;
      font-size: 3mm;
      text-align: center;
      border-bottom: 1px solid;
    }
  }
`;

export const AlterationsDetailsPrint = ({ id: alterationId }: { id: string }) => {
  const { tailor } = useActiveSession();
  const tailorName = tailor?.name;

  const { data } = useGetAlterationQuery({ variables: { alterationId } });

  if (!data) return null;

  const { alteration } = data;
  /**
   * print only 3 items in a page
   */
  const ITEMS_PER_PAGE = 3;
  const pages: GetAlterationQuery['alteration'][] = [];
  for (let i = 0; i < alteration.items.length; i++) {
    if (i % ITEMS_PER_PAGE === 0) {
      pages.push({
        ...alteration,
        items: [alteration.items[i]],
      });
    } else {
      pages[Math.floor(i / ITEMS_PER_PAGE)].items.push(alteration.items[i]);
    }
  }

  return (
    <>
      <div className="hidden print:block">
        {pages.map((page, i) => (
          <Outline key={i}>
            <Header>
              <Title>
                <h3 className="uppercase">{tailorName ? `${tailorName} ` : ''}Alteration Slip</h3>
                <span>
                  #{page.order.customId}
                  {pages.length > 1 && ` (${i + 1} / ${pages.length})`}
                </span>
              </Title>

              <Dates>
                <div>
                  <span>Order #:</span>
                  <b>{page.order.customId}</b>
                </div>

                <div>
                  <span>Customer:</span>
                  <b>{page.order.customer.fullName}</b>
                </div>

                <div>
                  <span>Created Date:</span>
                  <b>{page.createdAt.formatted}</b>
                </div>

                <div>
                  <span>Due Date:</span>
                  <b>{page.dueAt.formatted}</b>
                </div>

                <div>
                  <span>Appointment Date:</span>
                  <b></b>
                </div>
                <div>
                  <span>Sales Rep:</span>
                  <b>
                    {page.createdBy.firstName} {page.createdBy.firstName}
                  </b>
                </div>
              </Dates>
            </Header>

            <Body>
              {page.items.map((item) => (
                <ItemBlock key={item.id}>
                  <ItemDetail>{item.orderItemDesign.garmentCategory.name}</ItemDetail>
                  {/* TODO: add order item number here when available */}

                  <Alteration>
                    <Options>
                      {item.options.map((option) => (
                        <div key={option.id}>
                          <input type="checkbox" />
                          <span>{[option.name, option.value].join(' ')}</span>
                        </div>
                      ))}
                    </Options>

                    {item.note && (
                      <Note>
                        <span>Note:</span>
                        <p>{item.note}</p>
                      </Note>
                    )}
                  </Alteration>
                </ItemBlock>
              ))}
            </Body>

            <Footer>
              <FooterRow>
                <div>
                  <span>Provider:</span>
                  <b>{page.provider.name}</b>
                </div>

                <div>
                  <span>Alt Staff:</span>
                  <b></b>
                </div>
              </FooterRow>

              <FooterRow>
                <div>
                  <span>Comments:</span>
                </div>
                <div />
              </FooterRow>
            </Footer>
          </Outline>
        ))}
      </div>
    </>
  );
};
