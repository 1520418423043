import cn from 'classnames';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { Checkbox } from 'components';
import { StepContainer } from '../StepContainer';
import { RemakeItem } from '../RemakeState';
import { RemakeOrderCheckbox } from './RemakeOrderCheckbox';
import { Fragment } from 'react';

type Props = {
  items: RemakeItem[];
  onOrderItemSwitch: (orderItemId: string) => void;
  onOrderItemDesignSwitch: (orderItemId: string, orderItemDesignId: string) => void;
  onNextClick: () => void;
};

export const RemakeGarmentCheckBoxGroup = ({ items, onOrderItemSwitch, onOrderItemDesignSwitch, onNextClick }: Props) => {
  return (
    <StepContainer
      contentClassName="mt-6"
      isBackButtonDisabled
      onNextClick={onNextClick}
      isNextButtonDisabled={!items.some((i) => i.designs.some((d) => d.isChecked))}
    >
      {items.map((item) => (
        <Fragment key={item.orderItemId}>
          {item.designs.length === 1 ? (
            <div className="even:border-y first:border-t last:border-b border-x">
              <div className="flex items-center" onClick={() => onOrderItemSwitch(item.orderItemId)}>
                <RemakeOrderCheckbox className="p-4" item={item} />
                <button className="grow py-3.5 pr-4 flex justify-between">
                  <span>{item.title}</span>
                </button>
              </div>
            </div>
          ) : (
            <Disclosure as="div" className="even:border-y first:border-t last:border-b border-x">
              {({ open }) => (
                <>
                  <div className="flex items-center">
                    <RemakeOrderCheckbox className="p-4" item={item} onOrderItemSwitch={onOrderItemSwitch} />
                    <Disclosure.Button className="grow py-3.5 pr-4 flex justify-between items-center">
                      <span>{item.title}</span>
                      <ChevronUpIcon
                        className={cn('h-5 w-5 text-gray-500', {
                          'rotate-180 transform': !open,
                        })}
                      />
                    </Disclosure.Button>
                  </div>
                  <Disclosure.Panel className="">
                    {item.designs.map((design) => (
                      <div className="flex items-center first:border-t pl-8" key={design.orderItemDesignId}>
                        <Checkbox
                          containerClassName="w-full px-4"
                          isChecked={design.isChecked}
                          onChange={() => onOrderItemDesignSwitch(item.orderItemId, design.orderItemDesignId)}
                        >
                          <span className="grow px-4 py-3.5">{design.title}</span>
                        </Checkbox>
                      </div>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          )}
        </Fragment>
      ))}
    </StepContainer>
  );
};
