import { gql } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { DesignOptionOfferingInput, GetDesignOptionOfferingsQuery, useUpsertDesignOptionOfferingMutation } from '@graphql';
import { MoneyInput } from 'components';
import { Button } from 'modules/common';

interface Props {
  selectedOption: GetDesignOptionOfferingsQuery['designOptionOfferings'][0];
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  refetch: () => void;
}

export const DesignOptionsSlideoverPanel = (props: Props): React.ReactElement => {
  const { selectedOption, isOpen, setIsOpen, refetch } = props;

  const [imagePreview, setImagePreview] = useState(null); // To hold the image preview before uploading it
  const [isUpdated, setIsUpdated] = useState(false);

  const [upsertDesignOption] = useUpsertDesignOptionOfferingMutation();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: selectedOption?.name || selectedOption.name,
      price: selectedOption?.priceFormatted || 0,
    },
  });

  useEffect(() => {
    reset({
      name: selectedOption?.name || selectedOption.name,
      price: selectedOption?.priceFormatted || 0,
    });

    setImagePreview(null);
  }, [reset, selectedOption]);

  const onSubmit = async (formData: DesignOptionOfferingInput) => {
    try {
      await upsertDesignOption({
        variables: {
          designOptionOfferingInput: {
            designOptionOfferingId: selectedOption.id,
            name: formData.name,
            price: formData.price,
          },
          image: imagePreview,
        },
      });

      setIsUpdated(true);

      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setIsUpdated(false);
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                {/* TODO: This should probably be looked at */}
                {/* @ts-ignore */}
                <form className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl" onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex-1 h-0 overflow-y-auto mt-16">
                    <div className="py-6 px-4 bg-gray-800 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          <div className="text-xs">{selectedOption.type.name}</div>
                          <div>{selectedOption.code}</div>
                          <div className="text-xs">{selectedOption.name}</div>
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <Button
                            // TODO: This should probably be looked at
                            // @ts-ignore
                            variant="light"
                            isText
                            aria-label="Close"
                            onClick={() => {
                              setIsOpen(false);
                              setIsUpdated(false);
                            }}
                          >
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="px-4 divide-y divide-gray-200 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          {isUpdated && (
                            <div className="rounded-md bg-emerald-50 p-4">
                              <div className="flex">
                                <div className="shrink-0">
                                  <CheckCircleIcon className="h-5 w-5 text-emerald-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                  <p className="text-sm font-medium text-emerald-800">Successfully updated.</p>
                                </div>
                              </div>
                            </div>
                          )}
                          <div>
                            <label className="block cursor-pointer">
                              <span className="block text-sm font-medium">Custom Name</span>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md"
                                  placeholder={selectedOption?.name}
                                  {...register('name', { required: true })}
                                />
                              </div>
                            </label>
                          </div>
                          <div>
                            <label className="block cursor-pointer">
                              <span className="block text-sm font-medium">Price</span>
                              <div className="mt-1">
                                <MoneyInput
                                  register={register('price', {
                                    required: true,
                                    valueAsNumber: true,
                                  })}
                                />
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="shrink-0 px-4 py-4 flex justify-end">
                    <Button
                      className="mr-2"
                      variant="neutral"
                      onClick={() => {
                        setIsOpen(false);
                        setIsUpdated(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type="submit">Update</Button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

DesignOptionsSlideoverPanel.mutations = {
  upsertDesignOption: gql`
    mutation UpsertDesignOptionOffering($designOptionOfferingInput: DesignOptionOfferingInput!, $image: Upload) {
      upsertDesignOptionOffering(designOptionOfferingInput: $designOptionOfferingInput, image: $image) {
        id
      }
    }
  `,
};
