import { gql } from '@apollo/client';
import { ManufacturerGarmentOfferingsQuery, OrderPageQuery, OrderStatus } from '@graphql';
import { useState } from 'react';

import { AddItemButton } from 'modules/orders';
import { Box } from 'components';
import { useFeature } from 'hooks';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { OrderItemListNoItems } from './OrderItemListNoItems';
import { OrderItemListSkeletonLoading } from './OrderItemListSkeletonLoading';
import { OrderItemSummaryDrawer } from './OrderItemSummaryDrawer';
import { OrderItemListRow } from './OrderItemListRow';

interface Props {
  order: OrderPageQuery['order'];
  mfrGarmentOfferings: ManufacturerGarmentOfferingsQuery['manufacturerGarmentOfferings'];
  loading?: boolean;
}

export const OrderItemList = ({ order, mfrGarmentOfferings, loading }: Props) => {
  const [itemToShowSummary, setItemToShowSummary] = useState(null);
  const [open, setOpen] = useState(false);
  const { tailorSlug } = useActiveSession();
  const { isValueInAllowList } = useFeature();

  if (loading) {
    return <OrderItemListSkeletonLoading numItems={order?.items?.length} />;
  }

  const renderTrackingNumber = order.items.some((i) => !!i.individualTrackingNumber);
  const renderStatusColumn = Boolean(order.items.find((item) => Boolean(item.itemStatus.key)));
  const renderMeasurementColumn = [OrderStatus.Draft, OrderStatus.ReadyToProcess].includes(order.orderStatus.key);
  const renderDesignColumn = [OrderStatus.Draft, OrderStatus.ReadyToProcess].includes(order.orderStatus.key);
  const renderAlterationColumn = Boolean(order.items.flatMap((item) => [...item.designs]).find((design) => design.alterations.length));
  const allowAddItem = order?.orderStatus.key === OrderStatus.Draft;

  if (!order.items.length) {
    return <OrderItemListNoItems showroomId={order?.showroom?.id} allowAddItem={allowAddItem} />;
  }

  return (
    <Box allowSkeletonLoading>
      <div className="flex items-start justify-between border-b border-neutral-200 pb-6">
        <div className="text-lg">Items</div>
        {allowAddItem && <AddItemButton isDisabled={!!order.relatedOrders.remake.originalOrderId} />}
      </div>
      <table className="w-full">
        <thead className="border-b border-neutral-200">
          <tr className="text-left">
            <th className="py-4 uppercase text-gray-400 font-semibold text-xs w-8">ID</th>
            <th className="py-4 uppercase text-gray-400 font-semibold text-xs">Name</th>
            {renderStatusColumn && <th className={'py-4 uppercase text-gray-400 font-semibold text-xs'}>Status</th>}
            {renderTrackingNumber && <th className="py-4 uppercase text-gray-400 font-semibold text-xs">Tracking Number</th>}
            {renderAlterationColumn && <th className="py-4 uppercase text-gray-400 font-semibold text-xs">Alteration</th>}
            {renderDesignColumn && <th className="py-4 uppercase text-gray-400 font-semibold text-xs">Design</th>}
            {renderMeasurementColumn && <th className="py-4 uppercase text-gray-400 font-semibold text-xs w-3/12">Measurement</th>}
            {<th className="py-4 uppercase text-gray-400 font-semibold text-xs">Date</th>}
            <th className="py-4 uppercase text-gray-400 font-semibold text-xs w-16">QTY</th>
            {isValueInAllowList({ feature: 'ff_pricing_allow_list', valueToCheck: tailorSlug }) && (
              <th className="py-4 uppercase text-gray-400 font-semibold text-xs w-16">Total</th>
            )}
            <th className="py-4 uppercase text-gray-400 font-semibold text-xs w-4"></th>
          </tr>
        </thead>
        <tbody>
          {order.items.map((item, index) => {
            return (
              <OrderItemListRow
                key={index}
                order={order}
                item={item}
                orderStatus={order?.orderStatus.key}
                index={index}
                orderId={order.id}
                tailorSlug={tailorSlug}
                mfrGarmentOfferings={mfrGarmentOfferings}
                renderColumns={{
                  trackingNumber: renderTrackingNumber,
                  status: renderStatusColumn,
                  alteration: renderAlterationColumn,
                  design: renderDesignColumn,
                  measurement: renderMeasurementColumn,
                }}
                setItemToShowSummary={(item) => {
                  setItemToShowSummary(item);
                  setOpen(true);
                }}
              />
            );
          })}
        </tbody>
      </table>
      {itemToShowSummary?.id && <OrderItemSummaryDrawer orderItemId={itemToShowSummary?.id} isOpen={open} onClose={() => setOpen(false)} />}
    </Box>
  );
};

OrderItemList.fragments = {
  root: gql`
    ${OrderItemListRow.fragments.root}

    fragment OrderItemList on Order {
      ...OrderItemListRow
      id
      showroom {
        id
      }
      orderStatus {
        key
      }
      items {
        id
        name
        itemStatus {
          key
          name
          message
        }
        designs {
          createdAt {
            fromNow
          }
          alterations {
            id
          }
        }
        dateLeftFactory {
          formatted(format: "ll")
        }
        estimatedDeliveryDate {
          formatted(format: "ll")
        }
        retail {
          total
          base
          lining
          designOptions
          discountedPrice
          discountedAmount
        }
        individualTrackingNumber
        quantity
        number
        firstButtonTweakValidation {
          isValid
          selectedLapelName
          selectedClosureName
        }
      }
    }
  `,
};
