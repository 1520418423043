import { gql } from '@apollo/client';
import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import cn from 'classnames';

import { Box, Input, SlideoverPanel, UnitInput } from 'components';
import { Button } from 'modules/common';

import { MeasurementFormFooter } from 'modules/measurements/components/MeasurementFormFooter';
import { FormProps } from 'modules/measurements/components/MeasurementFormWrap';
import { getMeasurementPath } from 'modules/measurements/components/MeasurementsList';

import {
  MeasurementType,
  BodyMeasurementInput,
  GetBodyMeasurementQuery,
  useGetBodyMeasurementQuery,
  useUpsertBodyMeasurementMutation,
  MeasurementUnit,
} from '@graphql';
import { ArrayElement } from 'types/common';
import { validateNumber, useOnError, getDefaultNameForMeasurement, getUnitLongName } from 'helpers/measurement-helpers';
import { getAbbreviatedDate } from 'helpers/date-helpers';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { Icon } from 'components';
import mixpanelService from 'services/mixpanel.service';
import { useMeasurementUnit } from 'modules/common/MeasurementUnit';

type BodyField = ArrayElement<GetBodyMeasurementQuery['settings']['measurement']['body']['fields']>;

const Info = ({ bodyField }: { bodyField: BodyField }) => (
  <div>
    <h3 className="text-2xl font-semibold mb-8">{bodyField.name}</h3>
    <p className="mb-2">{bodyField.info.text}</p>
    <iframe className="w-full h-80" src={bodyField.info.videoUrl} allowFullScreen />
  </div>
);

export const BodyMeasurementForm = ({ customerId, measurementId }: FormProps) => {
  const { fulfilment } = useActiveSession();
  const { updateMeasurementUnit, convertFromServerUnit, convertToServerUnit, convertUnit, measurementUnitInfo } = useMeasurementUnit();
  const router = useRouter();
  const [applyMeasurement, setApplyMeasurement] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeBodyField, setActiveBodyField] = useState<BodyField>(null);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm<BodyMeasurementInput>();
  const onError = useOnError(toast);

  const { data, loading } = useGetBodyMeasurementQuery({
    variables: {
      measurementId: measurementId,
    },
    onCompleted: (data) => {
      const existingMeasurementData = data.measurement;
      const measurementSettingsData = data.settings.measurement;

      reset({
        name: existingMeasurementData?.name || getDefaultNameForMeasurement({ dateStr: getAbbreviatedDate() }),
        fields: measurementSettingsData?.body.fields.map((field) => {
          const unitValue = existingMeasurementData?.fields?.find((f) => f.key === field.key)?.value;
          return {
            key: field.key,
            value: unitValue ? convertFromServerUnit(unitValue) : 0,
          };
        }),
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const [upsertBodyMeasurement, { loading: isMutationLoading }] = useUpsertBodyMeasurementMutation({
    onCompleted: (data) => {
      if (measurementId) {
        toast.success('Measurement updated.', {
          toastId: data.upsertBodyMeasurement.id,
        });
      } else {
        toast.success('Measurement created.', {
          toastId: data.upsertBodyMeasurement.id,
        });
      }

      mixpanelService.track(measurementId ? 'MEASUREMENT_UPDATE' : 'MEASUREMENT_CREATE', {
        measurementId: data.upsertBodyMeasurement.id,
        type: MeasurementType.Body,
      });

      if (applyMeasurement) {
        router.push(getMeasurementPath({ type: MeasurementType.Applied, customerId }));
      } else {
        redirectToCustomerScreen();
      }
    },

    onError,
  });

  const onSubmit = handleSubmit(async (formData) => {
    const fields = formData.fields.map(({ value, ...rest }) => {
      return { ...rest, value: value ? convertToServerUnit(value) : value };
    });
    try {
      const bodyMeasurementInput: BodyMeasurementInput = {
        name: formData.name,
        fields: fields,
      };

      if (measurementId) {
        bodyMeasurementInput.id = measurementId;
      }

      await upsertBodyMeasurement({
        variables: {
          bodyMeasurementInput: bodyMeasurementInput,
          customerId: customerId,
        },
      });
    } catch (error) {
      console.error(error);
    }
  });

  const redirectToCustomerScreen = () => router.push(`/customers/${customerId}`);

  const onSave = async (shouldApplyMeasurement: boolean) => {
    setApplyMeasurement(shouldApplyMeasurement);
    await onSubmit();
  };

  const bodyFields = useMemo(() => data?.settings?.measurement.body.fields || [], [data]);

  const nextMeasurementUnit = measurementUnitInfo.current === MeasurementUnit.Cm ? MeasurementUnit.Inch : MeasurementUnit.Cm;

  return (
    <>
      <form>
        <div className="mb-4">
          <Box isLoading={loading}>
            <Input
              label="Name"
              htmlProps={{
                id: 'name',
                type: 'text',
              }}
              register={register('name', {
                required: 'Please enter a name.',
              })}
              errorMessage={errors?.name?.message}
            />
          </Box>
        </div>
        <Box isLoading={loading}>
          <div className="flex">
            <div className="flex w-full justify-between items-center mb-4">
              <h2 className="font-semibold text-xl">Measurements</h2>
              <div className="flex items-center gap-x-5">
                <Button
                  className="ml-auto"
                  size="xs"
                  variant="neutral"
                  onClick={() => {
                    bodyFields.forEach((_, i) => {
                      const currentValue = getValues(`fields.${i}.value`);
                      const convertedValue = convertUnit(currentValue, nextMeasurementUnit);
                      setValue(`fields.${i}.value`, convertedValue);
                    });
                    updateMeasurementUnit(nextMeasurementUnit);
                  }}
                >{`Convert to ${getUnitLongName(nextMeasurementUnit)}`}</Button>
                <button
                  type="button"
                  className="text-blue-500 underline"
                  onClick={() => bodyFields.forEach((f, i) => setValue(`fields.${i}.value`, NaN))}
                >
                  Clear all
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-wrap h-120">
            {bodyFields.map((field, index, arr) => (
              <div
                key={field.key}
                className={cn('relative w-1/2 items-center py-2', { 'pr-8 border-r': index < arr.length / 2, 'pl-8': index >= arr.length / 2 })}
                style={{ flex: '1 1 3rem' }}
              >
                <input hidden value={field.key} {...register(`fields.${index}.key` as const)} />
                <div className="flex">
                  <label className="flex-1">{field.name}</label>
                  <Controller
                    name={`fields.${index}.value` as const}
                    control={control}
                    rules={{
                      required: `Enter a ${measurementUnitInfo.shortName} value (2dp max)`,
                      validate: (v) => validateNumber(v) || `Value must be a number (2dp max)`,
                    }}
                    render={({ field: formField, fieldState }) => (
                      <UnitInput
                        className="w-24"
                        unit={measurementUnitInfo.label}
                        htmlProps={{
                          id: field.name,
                          ...formField,
                          onChange: (e) => {
                            const rawUnitValue = e.target.value;
                            if (rawUnitValue === '' || validateNumber(rawUnitValue, 2)) {
                              formField.onChange(e);
                            }
                          },
                        }}
                        errorMessage={fieldState.error?.message}
                        showError={false}
                      />
                    )}
                  />
                  <div>
                    {field?.info ? (
                      <button
                        type="button"
                        className="self-center ml-5"
                        onClick={() => {
                          setActiveBodyField(field);
                          setOpen(true);
                        }}
                      >
                        <Icon icon="info-stroke" width={12} height={12} />
                      </button>
                    ) : (
                      <div className="w-8" />
                    )}
                  </div>
                </div>
                <div className="absolute bottom-3 inline-flex text-red-500 text-xs">{errors?.fields && errors.fields[index]?.value?.message}</div>
              </div>
            ))}
          </div>
        </Box>
        <MeasurementFormFooter
          isDisabled={isMutationLoading}
          isNew={!measurementId}
          onCancel={redirectToCustomerScreen}
          onSubmit={() => onSave(false)}
        >
          {!['JEROME_TRY_ON', 'JEROME_SIMPLIFIED'].includes(fulfilment) && (
            <Button variant="neutral" className="mr-6" onClick={() => onSave(true)} isDisabled={isMutationLoading}>
              Save and apply to garment
            </Button>
          )}
        </MeasurementFormFooter>
      </form>
      <SlideoverPanel
        isOpen={open}
        setIsOpen={setOpen}
        maxWidthCss="max-w-xl"
        showCancel={false}
        showExit={false}
        submitButton={<Button onClick={() => setOpen(false)}>Close</Button>}
      >
        {activeBodyField && <Info bodyField={activeBodyField} />}
      </SlideoverPanel>
    </>
  );
};

BodyMeasurementForm.query = gql`
  query GetBodyMeasurement($measurementId: ID) {
    settings {
      measurement {
        body {
          fields {
            name
            key
            info {
              text
              videoUrl
            }
          }
        }
      }
    }
    measurement(measurementId: $measurementId) {
      id
      name
      fields {
        key
        name
        value
      }
    }
  }
`;

BodyMeasurementForm.mutations = {
  UpsertBodyMeasurement: gql`
    mutation UpsertBodyMeasurement($bodyMeasurementInput: BodyMeasurementInput!, $customerId: ID!) {
      upsertBodyMeasurement(bodyMeasurementInput: $bodyMeasurementInput, customerId: $customerId) {
        id
        name
        updatedAt {
          fromNow
          origin
        }
        type {
          name
          key
        }
        updatedAt {
          fromNow
          origin
        }
        garmentCategory {
          key
          name
        }
      }
    }
  `,
};
