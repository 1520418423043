import Link from 'next/link';
import { MeasurementType, GetMeasurementsQuery } from '@graphql';
import { ArrayElement } from 'types/common';

interface Props {
  customerId: string;
  measurement: ArrayElement<GetMeasurementsQuery['measurements']>;
  type: ArrayElement<GetMeasurementsQuery['measurements']>['type']['key'];
}

export const measurementTypeColor: Record<MeasurementType, string> = {
  [MeasurementType.Body]: 'bg-gray-100',
  [MeasurementType.Garment]: 'bg-blue-100',
  [MeasurementType.TryOn]: 'bg-amber-100',
  [MeasurementType.Simplified]: 'bg-emerald-100',
  [MeasurementType.Applied]: 'bg-violet-100',
};

export const MeasurementItem = ({ measurement, customerId, type }: Props) => {
  return (
    <li key={measurement.id}>
      <Link
        href={{
          pathname: `/measurements/${measurement.id}`,
          query: {
            customerId: customerId,
            type,
            ...(measurement.garmentCategory ? { garmentCategory: measurement.garmentCategory?.key } : {}),
          },
        }}
        className="flex py-2 justify-between items-center text-sm text-gray-600 hover:text-gray-900"
      >
        <div>
          <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-2 ${measurementTypeColor[measurement.type.key]} `}
          >
            {measurement.garmentCategory?.name || measurement.type.name}
          </span>
          <span>{measurement.name}</span>
        </div>
        <em className="text-gray-400 text-xs">{measurement.updatedAt.fromNow}</em>
      </Link>
    </li>
  );
};
