import { ArrowCircleUpIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import { useDropzone } from 'react-dropzone';

type Props = {
  isDisabled: boolean;
  onImportFile: (file: File) => void;
};

export const ImportCsvButton = ({ isDisabled, onImportFile }: Props) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDropAccepted: (files) => onImportFile(files[0]),
    disabled: isDisabled,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />

      <a onClick={open}>
        <div
          className={cn('flex items-center text-xs', {
            'text-gray-500 cursor-pointer': !isDisabled,
            'text-gray-300': isDisabled,
          })}
        >
          <ArrowCircleUpIcon className="h-6 w-6 mr-2" />
          Import CSV
        </div>
      </a>
    </div>
  );
};
