import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

type LinkOrOnClick = {
  link?: { href: string; download?: string };
  onClick: (...args: any[]) => void;
};

type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];

export type MenuOption = {
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
  text: string;
  classes?: {
    text?: string;
    icon?: string;
  };
  isDisabled?: boolean;
  isDelete?: boolean;
  linkOrOnClick: AtLeastOne<LinkOrOnClick>;
};

interface Props {
  options: MenuOption[];
  size?: 'small' | 'large';
  menuClassName?: string;
}

const ButtonContent = ({ text, icon: Icon, classes }: Pick<MenuOption, 'icon' | 'text' | 'classes'>) => (
  <>
    <Icon className={twMerge('w-6 h-6 mr-3', classes?.icon)} />
    <span className={classes?.text}>{text}</span>
  </>
);

export const DotsMenu = ({ menuClassName = 'w-48', options, size = 'large' }: Props) => (
  <Menu as="div" className="relative inline-block text-left">
    <Menu.Button
      className={cn('flex items-center p-1 bg-white border border-neutral-300 text-neutral-700', {
        'p-3': size === 'large',
        'p-1': size === 'small',
      })}
    >
      <span className="sr-only">Open options</span>
      <DotsVerticalIcon className="h-4 w-4" aria-hidden="true" />
    </Menu.Button>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={cn(
          menuClassName,
          'origin-top-right absolute right-0 mt-2 shadow-lg bg-white ring-1 ring-black/5 focus:outline-none divide-y divide-neutral-300 z-10'
        )}
      >
        <div className="py-1">
          {options.map(({ linkOrOnClick, isDisabled, isDelete, ...rest }, i) => (
            <Menu.Item key={i}>
              {({ active, close }) => {
                const className = cn('group flex items-center px-4 py-2 text-sm hover:font-semibold', {
                  'text-neutral-900': !isDisabled && active,
                  'text-neutral-700': !isDisabled && !active,
                  'text-neutral-400 pointer-events-none': isDisabled,
                });

                if (linkOrOnClick?.link?.href) {
                  return (
                    <a className={className} {...linkOrOnClick.link}>
                      <ButtonContent {...rest} />
                    </a>
                  );
                } else {
                  return (
                    <button
                      className={className}
                      {...linkOrOnClick}
                      onClick={(e) => {
                        e.preventDefault();
                        if (linkOrOnClick?.onClick) {
                          linkOrOnClick.onClick(e, isDelete);
                        }
                        close();
                      }}
                    >
                      <ButtonContent {...rest} />
                    </button>
                  );
                }
              }}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
);
