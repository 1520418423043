import { PrintOrderItemsFragment } from '@graphql';
import { setClassName } from 'helpers/pdf/table-helpers';
import { OrderDetailsPrintSectionHeader } from 'modules/orders/components/OrderDetailsPrintSectionHeader';
import { OrderDetailsPrintTableCell } from 'modules/orders/components/OrderDetailsPrintTableCell';

interface Props {
  items: PrintOrderItemsFragment[];
}

export const OrderDetailsPrintDesignOptions = ({ items }: Props) => {
  return (
    <table className="mt-6 w-full">
      {/* section header */}
      <thead>
        <tr>
          <td>
            <OrderDetailsPrintSectionHeader label="Design Options" />
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <table className="w-full uppercase text-6 -mt-0.5">
              {/* main row header */}
              <thead>
                <tr className="bg-neutral-200">
                  <td>
                    <div className="flex -mt-px">
                      <OrderDetailsPrintTableCell content="Ref ID" className="w-13" />
                      <OrderDetailsPrintTableCell content="Class" className="flex-1" />
                      <OrderDetailsPrintTableCell content="Design group (group name and code)" className="flex-1" />
                      <OrderDetailsPrintTableCell content="Design option code (option name and code)" className="flex-1" />
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {items.map((item) =>
                  item.designs.map((design, i) => (
                    <tr key={`design_${design.id}_${i}`}>
                      <td className="p-0">
                        <table className="w-full">
                          {/* option row header */}
                          <thead>
                            <tr>
                              <td className="pr-0">
                                <div className="flex -mt-0.5">
                                  <OrderDetailsPrintTableCell content={design.id} truncate className="w-13 border-b-0" />
                                  <OrderDetailsPrintTableCell content={setClassName(item, design)} truncate className="flex-1 border-b-0" />
                                </div>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {/* options rows */}
                            <tr>
                              <td className="pr-0">
                                <div className="-mt-0.5" style={{ breakInside: 'auto' }}>
                                  {design.options.map((option) => (
                                    <div key={option.id} className="flex" style={{ breakInside: 'avoid', breakAfter: 'auto' }}>
                                      <OrderDetailsPrintTableCell content="" className="w-13" />
                                      <OrderDetailsPrintTableCell content="" className="flex-1" />
                                      <OrderDetailsPrintTableCell
                                        content={`${option.designTypeOffering.name} - ${option.designTypeOffering.code}`}
                                        truncate
                                        className="flex-1"
                                      />
                                      <OrderDetailsPrintTableCell content={`${option.name} - ${option.code}`} truncate className="flex-1" />
                                    </div>
                                  ))}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
