import { useEffect, useState } from 'react';

import { Input } from 'components';
import { useDesignOptionsForm } from '../designOptions.context';
import { DesignOptionField } from '../designOptions.types';

type Props = {
  field: DesignOptionField;
  errorMessage?: string;
  isDisabled?: boolean;
  maxLength?: string;
  regexPattern?: string;
};

// Text design options will never have a price associated
export const DesignOptionsInput = ({ field, errorMessage, isDisabled = false, maxLength, regexPattern }: Props) => {
  const { formData, setField, setAndSaveField } = useDesignOptionsForm();
  const [designOptionErrorMessage, setDesignOptionErrorMessage] = useState(errorMessage);

  useEffect(() => {
    validateInput(formData[field.name]);
  }, [errorMessage]);

  const handleOnChange = (fieldValue: string) => {
    validateInput(fieldValue);
    setField({ id: field.name, value: fieldValue });
  };

  const onFocusChange = (targetVal: string) => {
    if (maxLength && Number(maxLength) > 0) {
      const val = targetVal?.slice(0, Number(maxLength));
      setField({ id: field.name, value: val });
    }
  };

  const validateInput = (fieldValue: string) => {
    if (field.type === 'NUMBER' && fieldValue.includes('-')) {
      setDesignOptionErrorMessage(`Value can't be negative. ${errorMessage}`);
    } else {
      setDesignOptionErrorMessage(errorMessage);
    }
  };
  return (
    <Input
      className="flex-1 min-w-96"
      label={field.label}
      htmlProps={{
        pattern: regexPattern ? regexPattern : '',
        type: field.type === 'TEXT' ? 'TEXT' : 'NUMBER',
        value: formData[field.name],
        ...(field.placeholder && { placeholder: field.placeholder }),
        ...(field.defaultValue && { defaultValue: field.defaultValue }),
        ...(maxLength ? maxLength && { maxLength: Number(maxLength) } : field.maxlength && { maxLength: Number(field.maxlength) }),
        onChange: ({ currentTarget }) => handleOnChange(currentTarget.value),
        onBlur: ({ currentTarget }) =>
          setAndSaveField({ id: field.name, value: currentTarget.value, label: field.label, displayValue: currentTarget.value, forceSave: true }),
        onFocus: ({ currentTarget }) => onFocusChange(currentTarget.value),
        disabled: isDisabled,
      }}
      errorMessage={designOptionErrorMessage}
    />
  );
};
