import { DesignTemplatesListFragmentFragment } from '@graphql';
import { Icon } from 'components';
import cn from 'classnames';
import { getTemplateImage } from 'modules/garments';
import { TemplateOrProductPill } from 'modules/garments/components';
import { DotsMenu } from 'components';
import { MenuOption } from 'components/DotsMenu';

interface Props {
  template: DesignTemplatesListFragmentFragment;
  isSelected?: boolean;
  handleOnClick: () => void;
  menuOptions?: MenuOption[];
}

const BASE_CLASSES = [
  'bg-white',
  'rounded-md',
  'w-44',
  'group',
  'cursor-pointer',
  'border-2',
  'm-auto',
  'flex',
  'flex-col',
  'h-full',
  'hover:bg-yellow-crayola-50',
  'hover:border-yellow-crayola-500',
];

export const GarmentTemplatesListItem = ({ template, isSelected, handleOnClick, menuOptions }: Props) => {
  const classes = cn(
    BASE_CLASSES,
    isSelected && 'bg-yellow-crayola-50 border-yellow-crayola-500',
    !isSelected && 'hover:bg-yellow-crayola-50 hover:border-yellow-crayola-500'
  );

  const TemplateImage = getTemplateImage(template.productCategory);

  if (menuOptions) {
    // map templateId onto menu options
    menuOptions = menuOptions.map((option) => {
      return {
        ...option,
        linkOrOnClick: {
          ...option.linkOrOnClick,
          onClick: () => option?.linkOrOnClick?.onClick(template),
        },
      };
    });
  }

  return (
    <div className={classes}>
      <div className="relative">
        <div onClick={handleOnClick}>
          <div className="relative">
            <div className="w-full h-40">
              {template?.image ? (
                <img className="rounded-t object-cover w-full h-full" src={template?.image.signedUrl} alt={template.productCategory} />
              ) : TemplateImage ? (
                <div className="flex justify-center px-3 py-5 w-full h-full border-b">
                  <TemplateImage />
                </div>
              ) : (
                <div className="flex justify-center items-center py-5 h-full border-b">
                  <Icon icon="photo" className="text-gray-500" />
                </div>
              )}
            </div>
            <div className="absolute m-2 bottom-0">
              <TemplateOrProductPill template={template} />
            </div>
          </div>
          <div className="flex items-center h-full px-4 my-2 font-semibold">{template.name}</div>
        </div>
        {menuOptions && (
          <div className="absolute top-2 right-2">
            <DotsMenu options={menuOptions} size="small" />
          </div>
        )}
      </div>
    </div>
  );
};
