import { Select } from 'components';
import { useIsMounted } from 'hooks';
import React from 'react';
import { gql } from '@apollo/client';
import { ProductCategory, useProductCategoriesSelectQuery } from '@graphql';
import { groupBy } from 'lodash';

const GenderLabel = {
  MALE: "Men's",
  FEMALE: "Women's",
};

interface Props {
  onChange?: (value: ProductCategory) => void;
  value?: string;
  isDisabled?: boolean;
  hideLabel?: boolean;
}

// Note: a default value is not yet supported if this is used without handleOnChange instead of register
const ProductCategoriesSelect = ({ onChange, value, isDisabled = false, hideLabel = false }: Props) => {
  const isMounted = useIsMounted();
  const { data, loading: isLoading } = useProductCategoriesSelectQuery({ fetchPolicy: 'cache-first' });
  const productCategories = data?.settings?.productCategories;

  const handleChange = (currentTarget: EventTarget & HTMLSelectElement) => {
    if (!isMounted || !onChange) {
      return;
    }
    let value: ProductCategory = null;
    try {
      value = currentTarget.value as ProductCategory;
    } catch (e) {
      console.error(e);
    } finally {
      onChange(value);
    }
  };

  const categories = groupBy(productCategories, 'gender');

  return (
    <Select
      label={hideLabel ? undefined : 'Product category'}
      htmlProps={{
        onChange: ({ currentTarget }) => handleChange(currentTarget),
        disabled: isDisabled || isLoading || !data?.settings?.productCategories,
        ...(value && { value: value || '' }),
      }}
    >
      {!isLoading && !productCategories?.length && <option value="">Couldn&apos;t load categories</option>}
      {Object.keys(categories).map(
        (gender) =>
          categories[gender].length && (
            <optgroup key={gender} label={GenderLabel[gender]}>
              {categories[gender].map(
                (product) =>
                  !product.name.includes('legacy') && (
                    <option key={product.key} value={product.key}>
                      {categories[gender].find((p) => p.key === product.key).truncatedName}
                    </option>
                  )
              )}
            </optgroup>
          )
      )}
    </Select>
  );
};

ProductCategoriesSelect.fragments = {
  categories: gql`
    fragment ProductCategoriesSelectFragment on ProductCategorySetting {
      key
      name
      truncatedName
      gender
    }
  `,
};

ProductCategoriesSelect.query = gql`
  ${ProductCategoriesSelect.fragments.categories}

  query ProductCategoriesSelect {
    settings {
      id
      productCategories {
        ...ProductCategoriesSelectFragment
      }
    }
  }
`;

export { ProductCategoriesSelect };
