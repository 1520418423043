import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { gql } from '@apollo/client';
import {
  GetAlterationProviderQuery,
  useCreateAlterationProviderMutation,
  useUpdateAlterationProviderMutation,
  CreateAlterationProviderInput,
} from '@graphql';
import { Box, Input, Select } from 'components';
import { Button } from 'modules/common';

interface Props {
  alterationProvider?: GetAlterationProviderQuery['alterationProvider'];
}

interface FormInput extends Omit<CreateAlterationProviderInput, 'isActive'> {
  isActive: string;
}

export const AlterationsProviderBasicDetailsForm = (props: Props) => {
  const { alterationProvider } = props;

  const router = useRouter();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInput>();

  useEffect(() => {
    if (alterationProvider) {
      reset({
        name: alterationProvider?.name || '',
        address: alterationProvider?.address || '',
        contactPerson: alterationProvider?.contactPerson || '',
        contactNumber: alterationProvider?.contactNumber || '',
        email: alterationProvider?.email || '',
        isActive: alterationProvider?.isActive ? '1' : '0',
      });
    }
  }, [alterationProvider, reset]);

  const [addAlterationProvider, { loading: addLoading }] = useCreateAlterationProviderMutation({
    onCompleted: (data) => {
      toast.success('Provider added.');

      router.push(`/alteration-providers/${data.createAlterationProvider.id}`);
    },

    onError: () => {
      toast.error('Something went wrong, please try again.');
    },

    refetchQueries: ['GetAlterationProviders'],

    awaitRefetchQueries: true,
  });

  const [updateAlterationProvider, { loading: updateLoading }] = useUpdateAlterationProviderMutation({
    onCompleted: () => {
      toast.success('Provider updated.');
    },

    onError: () => {
      toast.error('Something went wrong, please try again.');
    },

    refetchQueries: ['GetAlterationProviders'],

    awaitRefetchQueries: true,
  });

  const onSubmit = handleSubmit(async (formData) => {
    if (alterationProvider?.id) {
      updateAlterationProvider({
        variables: {
          updateAlterationProviderInput: {
            alterationProviderId: alterationProvider.id,
            name: formData.name,
            isActive: Boolean(Number(formData.isActive)),
            address: formData.address,
            contactPerson: formData.contactPerson,
            contactNumber: formData.contactNumber,
            email: formData.email,
          },
        },
      });
    } else {
      addAlterationProvider({
        variables: {
          createAlterationProviderInput: {
            name: formData.name,
            isActive: Boolean(Number(formData.isActive)),
            address: formData.address,
            contactPerson: formData.contactPerson,
            contactNumber: formData.contactNumber,
            email: formData.email,
          },
        },
      });
    }
  });

  return (
    <Box title="Provider Details">
      <form onSubmit={onSubmit} className="space-y-4">
        <div>
          <Input
            label="Provider name"
            register={register('name', { required: 'Please enter a name.' })}
            htmlProps={{ type: 'text' }}
            errorMessage={errors?.name?.message && errors.name.message}
          />
        </div>

        <div>
          <Select label="Status" register={register('isActive')}>
            <option value="0">Inactive</option>
            <option value="1">Active</option>
          </Select>
        </div>

        <div>
          <Input label="Address" register={register('address')} htmlProps={{ type: 'text' }} />
        </div>

        <div>
          <Input label="Contact person" register={register('contactPerson')} htmlProps={{ type: 'text' }} />
        </div>

        <div>
          <Input label="Contact number" register={register('contactNumber')} htmlProps={{ type: 'text' }} />
        </div>

        <div>
          <Input label="Email" register={register('email')} htmlProps={{ type: 'text' }} />
        </div>

        <div className="pt-4 flex justify-between">
          <Button type="submit" isDisabled={addLoading || updateLoading}>
            {alterationProvider ? 'Update provider' : 'Create new provider'}
          </Button>
        </div>
      </form>
    </Box>
  );
};

AlterationsProviderBasicDetailsForm.mutations = {
  createAlterationProvider: gql`
    mutation CreateAlterationProvider($createAlterationProviderInput: CreateAlterationProviderInput!) {
      createAlterationProvider(createAlterationProviderInput: $createAlterationProviderInput) {
        id
        name
        isActive
        address
        contactPerson
        contactNumber
        email
        updatedAt {
          origin
          fromNow
        }
      }
    }
  `,
  updateAlterationProvider: gql`
    mutation UpdateAlterationProvider($updateAlterationProviderInput: UpdateAlterationProviderInput!) {
      updateAlterationProvider(updateAlterationProviderInput: $updateAlterationProviderInput) {
        id
        name
        isActive
        address
        contactPerson
        contactNumber
        email
        updatedAt {
          origin
          fromNow
        }
      }
    }
  `,
};
