import { useAddOrderNoteMutation, OrderPageQuery, OrderNoteType } from '@graphql';
import { ChatAltIcon, CogIcon, PencilAltIcon } from '@heroicons/react/solid';
import { gql } from '@apollo/client';
import { Box, Textarea, TextSkeleton } from 'components';
import { Button } from 'modules/common';
import { orderBy } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { OrderPayloadDrawer } from 'modules/orderItem/components/OrderPayloadDrawer';
import { useActiveSession } from 'hooks/useActiveSessionContext';

interface Props {
  order: OrderPageQuery['order'];
  loading?: boolean;
}

export const OrderTimeline = ({ order, loading }: Props) => {
  const [hasMessage, setHasMessage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [orderPayload, setOrderPayload] = useState('');
  const { loading: sessionLoading, isSuperAdmin } = useActiveSession();

  const [addOrderNote, { loading: mutationLoading }] = useAddOrderNoteMutation({
    onCompleted: () => {
      toast.success('Comment added.');
    },

    onError: () => {
      toast.error('Please try again.');
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = handleSubmit(async (formData) => {
    await addOrderNote({
      variables: {
        orderId: order.id,
        message: formData.message,
      },
    });
    reset();
  });

  const renderLoadingComments = () => {
    const comments = [];
    const numComments = order?.notes?.length || 2;
    for (let i = 0; i < numComments; i++) {
      comments.push(
        <div className="flex mt-4 first:border-t py-6" key={`OrderTimeline${i}`}>
          <TextSkeleton className="w-1/12 h-6 mr-4" />
          <TextSkeleton className="w-8/12" />
        </div>
      );
    }
    return comments;
  };

  if (loading) {
    return (
      <Box title="Timeline" allowSkeletonLoading>
        <TextSkeleton className="w-full h-16" />
        <TextSkeleton className="w-32 h-8 mt-2 border-b border-gray-300 mb-6" />
        {renderLoadingComments()}
      </Box>
    );
  }

  return (
    <>
      <Box title="Timeline">
        <form className="flex flex-col border-b border-gray-200 pb-6" onSubmit={onSubmit}>
          <Textarea
            htmlProps={{ placeholder: 'Add a comment', onChange: ({ currentTarget }) => setHasMessage(Boolean(currentTarget.value)) }}
            register={register('message', {
              required: "Comment can't be empty.",
            })}
            errorMessage={errors?.message?.message as any}
          />
          <div className="mt-2">
            <Button type="submit" isDisabled={mutationLoading || !hasMessage} className="text-sm">
              Add comment
            </Button>
          </div>
        </form>

        <div className="flow-root mt-8">
          <ul role="list" className="-mb-8">
            {Boolean(order?.notes?.length) &&
              orderBy(order.notes, ['createdAt.origin'], ['desc']).map((note, index) => (
                <li key={note.id}>
                  <div className="relative pb-8">
                    {index !== order.notes.length - 1 ? (
                      <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                    ) : null}

                    <div className="relative flex items-start space-x-3">
                      {note.type.key === OrderNoteType.User ? (
                        <>
                          <div className="relative px-1">
                            <div className="h-8 w-8 bg-indigo-400 rounded-full ring-8 ring-white flex items-center justify-center">
                              <ChatAltIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </div>
                          </div>
                          <div className="min-w-0 flex-1">
                            <div>
                              <div className="text-sm font-medium">
                                {note.createdUser.firstName} {note.createdUser.lastName}
                              </div>
                              <p className="mt-0.5 text-sm text-gray-500">Commented {note.createdAt.fromNow}</p>
                            </div>
                            <div className="mt-2 text-sm text-gray-700">
                              <p className="whitespace-break-spaces">{note.message}</p>
                            </div>
                          </div>
                        </>
                      ) : note.type.key === OrderNoteType.System && note.createdUser ? (
                        <>
                          <div>
                            <div className="relative px-1">
                              <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                <PencilAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 py-1.5">
                            <div>
                              <div className="text-sm text-gray-500">
                                <span className="font-medium text-gray-900">{note.message}</span> by{' '}
                                <span className="font-medium text-gray-900">
                                  {note.createdUser.firstName} {note.createdUser.lastName}
                                </span>
                              </div>
                              <p className="mt-0.5 text-sm text-gray-500">{note.createdAt.fromNow}</p>
                            </div>
                          </div>
                        </>
                      ) : note.type.key === OrderNoteType.System &&
                        !note.createdUser &&
                        (!note.message.includes('Order Payload') || (note.message.includes('Order Payload') && isSuperAdmin)) ? (
                        <>
                          <div>
                            <div className="relative px-1">
                              <div className="h-8 w-8 bg-gray-700 rounded-full ring-8 ring-white flex items-center justify-center">
                                <CogIcon className="h-5 w-5 text-white" aria-hidden="true" />
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 py-1.5">
                            <div>
                              <div className="text-sm text-gray-500">
                                <span className="font-medium text-gray-900">
                                  {note.message.includes('Order Payload') ? (
                                    <Button
                                      isDisabled={sessionLoading}
                                      onClick={() => {
                                        setIsOpen(true);
                                        setOrderPayload(order?.orderPayload);
                                      }}
                                      isLink
                                      isText
                                    >
                                      Order Payload
                                    </Button>
                                  ) : (
                                    <span>{note.message}</span>
                                  )}
                                </span>
                              </div>
                              <p className="mt-0.5 text-sm text-gray-500">{note.createdAt.fromNow}</p>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <OrderPayloadDrawer orderPayload={orderPayload} isOpen={isOpen} onClose={() => setIsOpen(false)} />
      </Box>
    </>
  );
};

OrderTimeline.fragments = {
  root: gql`
    fragment OrderTimeline on Order {
      id
      notes {
        id
        createdAt {
          origin
          fromNow
          formatted
        }
        updatedAt {
          origin
          fromNow
          formatted
        }
        message
        createdUser {
          id
          firstName
          lastName
        }
        type {
          key
          name
        }
      }
    }
  `,
};

OrderTimeline.mutations = {
  addOrderNote: gql`
    ${OrderTimeline.fragments.root}

    mutation AddOrderNote($orderId: ID!, $message: String!) {
      addOrderNote(orderId: $orderId, message: $message) {
        ...OrderTimeline
      }
    }
  `,
};
