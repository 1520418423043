import { useEffect } from 'react';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AppState, Auth0Provider, Auth0ProviderOptions, useAuth0 } from '@auth0/auth0-react';
import { clearLocalApolloCache, createApolloClient, errorLink, uploadLink } from 'libs/apollo';
import mixpanelService from 'services/mixpanel.service';
import { datadogRum } from '@datadog/browser-rum';
import Router from 'next/router';
import { ActiveSessionQuery, ActiveSessionDocument } from '@graphql';
import amplitudeService from 'services/amplitude.service';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const ApolloAuth0Provider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const authLink = setContext(async (_, { headers }) => {
    const accessToken = await getAccessTokenSilently();

    return {
      headers: {
        ...headers,
        authorization: accessToken ?? '',
      },
    };
  });

  useEffect(() => {
    apolloClient = createApolloClient([authLink, errorLink, uploadLink]);
  }, []);

  if (!apolloClient) return null;

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export const ApolloProviderWithAuth0 = ({ children }) => {
  const onRedirectCallback = async (appState?: AppState) => {
    const { data } = await apolloClient.query<ActiveSessionQuery>({ query: ActiveSessionDocument });
    const { user } = data.activeSession;

    await mixpanelService.identify(data.activeSession);
    await amplitudeService.identify(data.activeSession);
    datadogRum.setUser({
      id: user.id,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
    });

    Router.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  };

  const providerConfig: Auth0ProviderOptions = {
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: typeof window !== 'undefined' ? window.location.origin : '',
      audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
    },
    /*
     * Work around for blocking of cross-site third-party cookies
     * https://auth0.com/docs/troubleshoot/authentication-issues/renew-tokens-when-using-safari
     */
    useRefreshTokens: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production',
    cacheLocation: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production' ? 'localstorage' : undefined,
  };

  return (
    <Auth0Provider {...providerConfig}>
      <ApolloAuth0Provider>{children}</ApolloAuth0Provider>
    </Auth0Provider>
  );
};

export const onAuth0Logout = () => {
  datadogRum.clearUser();
  mixpanelService.reset();
  clearLocalApolloCache();
  window.Intercom?.('shutdown');
  amplitudeService.reset();
};
