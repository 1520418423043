import { Checkbox } from 'components/Checkbox';
import { RemakeItem } from '../RemakeState';
import { useEffect, useRef } from 'react';

const allDesignsAreCheckedOrUnChecked = (item: RemakeItem) => {
  const [firstDesign, ...restDesigns] = item.designs;
  return restDesigns.every((d) => d.isChecked === firstDesign.isChecked);
};

type Props = {
  className?: string;
  item: RemakeItem;
  onOrderItemSwitch?: (orderItemId: string) => void;
};

export const RemakeOrderCheckbox = ({ className, item, onOrderItemSwitch }: Props) => {
  const checkboxRef = useRef<HTMLInputElement>();

  useEffect(() => {
    checkboxRef.current.indeterminate = !allDesignsAreCheckedOrUnChecked(item);
  }, [item]);

  return (
    <Checkbox ref={checkboxRef} containerClassName={className} isChecked={item.isChecked} onChange={() => onOrderItemSwitch?.(item.orderItemId)} />
  );
};
