import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface SelectProps {
  register?: UseFormRegisterReturn;
  htmlProps?: React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;
  children: React.ReactNode; // TODO: not sure what's the correct type here
  label?: string;
  errorMessage?: string;
  testId?: string;
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>((props, ref): React.ReactElement => {
  const { register, label, htmlProps, errorMessage, children, testId } = props;

  return (
    <>
      {label && (
        <label htmlFor={htmlProps?.id} className="block text-xs font-medium text-gray-500 cursor-pointer mb-1">
          {label}
        </label>
      )}
      <select
        data-testid={testId}
        ref={ref}
        className={`block w-full text-base focus:outline-none sm:text-sm rounded-md cursor-pointer disabled:cursor-not-allowed ${
          errorMessage ? 'border-red-300 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-gray-500 focus:border-gray-500 '
        }`}
        {...register}
        {...htmlProps}
      >
        {children}
      </select>

      {errorMessage && <span className="mt-1 text-red-500 text-xs">{errorMessage}</span>}
    </>
  );
});
