import { ProductCategory, useGarmentTemplatesListQuery } from '@graphql';
import { TaperLoading } from 'modules/common';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { AddItemTemplate } from 'modules/orders';

const TEMPLATE_PAGE_AMOUNT = 12;

export interface GarmentTemplateListProps {
  productCategory: ProductCategory;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setProductCategory: React.Dispatch<React.SetStateAction<string>>;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<AddItemTemplate>>;
}

export const useGarmentTemplateList = ({
  searchText,
  setSearchText,
  setProductCategory,
  productCategory,
  setSelectedTemplate,
}: GarmentTemplateListProps) => {
  const [availableTemplateCount, setAvailableTemplateCount] = useState<number>(0);
  const [displayTemplateCount, setDisplayTemplateCount] = useState<number>(TEMPLATE_PAGE_AMOUNT);

  const { data, loading: isLoading } = useGarmentTemplatesListQuery({
    variables: { productCategory },
    onCompleted: () => {
      setSearchText('');
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setDisplayTemplateCount(TEMPLATE_PAGE_AMOUNT);
  }, [searchText]);

  // TODO: move this logic to the BE
  const filteredTemplates = useMemo(() => {
    const orderedTemplates = data?.designTemplates ? [...data.designTemplates].sort((a) => (a.name.toLowerCase().includes('default') ? -1 : 1)) : [];
    const searchedTemplates = orderedTemplates.filter((template) => template.name.toLowerCase().includes(searchText.toLowerCase()));

    setAvailableTemplateCount(searchedTemplates.length);

    return searchedTemplates.splice(0, displayTemplateCount);
  }, [data, searchText, displayTemplateCount]);

  const handleProductCategoryChange = (category: ProductCategory) => {
    setDisplayTemplateCount(TEMPLATE_PAGE_AMOUNT);
    setProductCategory(category);
    setSelectedTemplate(undefined);
  };

  const productCategoryName = data?.designTemplates.find((t) => t.productCategory === productCategory)?.productCategorySetting.name;

  const GarmentTemplatesList = useMemo(
    () =>
      ({ children }: { children: ReactNode }) => {
        if (isLoading) {
          return (
            <div className="w-full h-96 flex items-center justify-center">
              <TaperLoading />
            </div>
          );
        }
        return <>{children}</>;
      },
    [isLoading]
  );

  const ViewMoreButton = useMemo(
    () => () => {
      const handleViewMore = () => {
        setDisplayTemplateCount(displayTemplateCount + TEMPLATE_PAGE_AMOUNT);
      };
      if (availableTemplateCount <= displayTemplateCount) {
        return null;
      }

      return (
        <button className="block mx-auto mt-4 text-blue-500 underline" onClick={handleViewMore}>
          View More
        </button>
      );
    },
    [displayTemplateCount, availableTemplateCount]
  );

  return {
    GarmentTemplatesList,
    ViewMoreButton,
    filteredTemplates,
    allTemplates: data?.designTemplates,
    handleProductCategoryChange,
    productCategoryName,
  };
};

useGarmentTemplateList.fragments = {
  template: gql`
    fragment DesignTemplatesListFragment on OrderItem {
      id
      name
      image {
        signedUrl
      }
      fabric {
        id
      }
      lining {
        id
      }
      productCategory
      productCategorySetting {
        key
        name
      }
      designs {
        requiresLining
      }
    }
  `,
};

useGarmentTemplateList.query = gql`
  ${useGarmentTemplateList.fragments.template}

  query GarmentTemplatesList($productCategory: ProductCategory!) {
    designTemplates(productCategory: $productCategory) {
      ...DesignTemplatesListFragment
    }
  }
`;
