import React from 'react';
import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  isLoading?: boolean;
  size?: 'small' | 'regular';
};

const BASE_CLASSES = ['rounded-md', 'bg-gray-300', 'w-6'];

export const TextSkeleton = ({ className, isLoading = true, size = 'regular' }: Props) => {
  const classes = twMerge(
    cn(BASE_CLASSES, className, {
      'animate-pulse': isLoading,
      'h-4': size === 'small',
      'h-6': size === 'regular',
    })
  );

  return <div className={classes}></div>;
};
