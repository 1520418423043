import { FabricListFragment, LiningListFragment } from '@graphql';
import cn from 'classnames';
import { getIsFabricOrLiningInStock } from '..';
import { FabricLiningImage } from 'modules/fabric/components/FabricLiningImage';

interface Props {
  className?: string;
  fabricOrLining:
    | (FabricListFragment & { typename: 'fabric' })
    | (LiningListFragment & { typename: 'lining' })
    | {
        id?: string;
        name: string;
        vendor: string;
        fabricCollection?: string;
        status?: string;
        stock?: string;
        replenishmentDate?: string;
        imageUrl?: string;
        typename: 'fabric';
      };
  isSelected?: boolean;
  handleOnClick?: (id: string) => void;
}

export const BASE_FABRIC_CARD_CLASSES = [
  'grow',
  'max-w-fabric-or-lining-card',
  'min-w-fabric-or-lining-card',
  'bg-white',
  'p-2',
  'border-2',
  'flex',
  'justify-between',
  'overflow-hidden',
];

export const FabricOrLiningCard = ({ className, fabricOrLining, isSelected = false, handleOnClick }: Props) => {
  const classes = cn(className, BASE_FABRIC_CARD_CLASSES, {
    'border-yellow-crayola-500': isSelected,
    'border-neutral-200': !isSelected,
  });

  return (
    <div className={classes} onClick={() => handleOnClick && handleOnClick(fabricOrLining.id)}>
      <FabricLiningImage className="h-32 min-w-32 my-auto" src={fabricOrLining.imageUrl} type={fabricOrLining.typename} />
      <div className="grow mx-2">
        {/* TODO: how is it best to order all these fields? Which are the most important? */}
        {fabricOrLining.typename === 'fabric' && (
          <>
            <div className="flex justify-between pb-1">
              <span className="font-bold">{fabricOrLining.name}</span>
            </div>
            <div className="flex justify-between">
              <span>Vendor: </span>
              <span className="font-medium">{fabricOrLining.vendor}</span>
            </div>
            <div className="flex justify-between">
              <span>Collection:</span>
              <span className="font-medium">{fabricOrLining.fabricCollection || '-'}</span>
            </div>
          </>
        )}
        {fabricOrLining.typename === 'lining' && (
          <>
            <div className="flex justify-between pb-1">
              <span className="font-bold">{fabricOrLining.name}</span>
            </div>
            <div className="flex justify-between">
              <span>Code: </span>
              <span className="font-medium whitespace-nowrap">{fabricOrLining.code}</span>
            </div>
            <div className="flex justify-between">
              <span>Level:</span>
              <span className="font-medium">{fabricOrLining.liningLevel || '-'}</span>
            </div>
          </>
        )}
        <div className="flex justify-between">
          <span>Status: </span>
          <span
            className={cn('font-medium', {
              'text-emerald-600': fabricOrLining.status && getIsFabricOrLiningInStock(fabricOrLining.status),
              'text-amber-500': !fabricOrLining.status,
              'text-red-600': !getIsFabricOrLiningInStock(fabricOrLining.status),
            })}
          >
            {fabricOrLining.status || 'Check supplier'}
          </span>
        </div>
        {getIsFabricOrLiningInStock(fabricOrLining.status) && (
          <div className="flex justify-between">
            <span>Stock level:</span>
            <span className="font-medium">{fabricOrLining.stock && Number(fabricOrLining.stock) > 0 ? `${fabricOrLining.stock}m` : '-'}</span>
          </div>
        )}
        {/* TODO: will in stock fabrics ever have a replenishment date? i.e. do we show this only for out of stock fabrics */}
        <div className="flex justify-between">
          <span>Replenishment date:</span>
          <span className="font-medium">
            {fabricOrLining.replenishmentDate
              ? new Date(fabricOrLining.replenishmentDate).toLocaleDateString('en-us', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })
              : '' || 'Unavailable'}
          </span>
        </div>
      </div>
    </div>
  );
};
