import React from 'react';
import cn from 'classnames';

import { Icon } from 'components';
import { styles } from 'components/SimpleCombobox';

interface Props {
  className?: string;
  value: string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch: () => void;
}

const TableSearch = ({ className, value, placeholder = 'Search', onChange, onSearch }: Props) => {
  return (
    <div className={cn('relative flex', className)}>
      <input className={styles.input} value={value} onChange={onChange} placeholder={placeholder} />
      <button type="submit" className="absolute top-0 bottom-0 my-auto right-3 text-gray-700 hover:text-gray-500" onClick={onSearch}>
        <Icon icon="search" />
      </button>
    </div>
  );
};

export default TableSearch;
