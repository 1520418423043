import { OrderItem } from '../types';

type Props = {
  designs: OrderItem['designs'];
  designOptionDeltas: OrderItem['remake']['delta']['designOptionDeltas'];
};

export const RemakeDesignOptionDeltaSection = ({ designs, designOptionDeltas }: Props) => {
  return (
    <div className="flex flex-col divide-y">
      {designOptionDeltas.map(({ designId, delta }) => {
        const design = designs.find((d) => d.id === designId);
        const noChanges = delta.existingChanges.length === 0 && delta.newChanges.length === 0 && delta.removedChanges.length === 0;
        return (
          <div key={designId} className="flex flex-col py-4 mx-4">
            <span className="font-semibold">{`Garment: ${design.garmentCategory.name}`}</span>
            {noChanges && <span className="text-gray-400 text-sm">No design options changes reported</span>}
            {delta.existingChanges.length > 0 && (
              <>
                <span className="mt-4 mb-1">
                  <span className="text-orange-600 font-semibold">Changed</span> design options
                </span>
                {delta.existingChanges.map((change) => (
                  <div key={change.name}>
                    <span className="text-sm">{`${change.groupName} - ${change.name}: `}</span>
                    <span className="text-sm text-red-600 font-semibold line-through">{change.previousValue}</span>
                    <span className="text-sm font-bold">→</span>
                    <span className="text-sm text-green-600 font-semibold">{change.newValue}</span>
                  </div>
                ))}
              </>
            )}
            {delta.newChanges.length > 0 && (
              <>
                <span className="mt-4 mb-1">
                  <span className="text-green-600 font-semibold">New </span> design options
                </span>
                {delta.newChanges.map((change) => (
                  <div key={change.name}>
                    <span className="text-sm">{`${change.groupName} - ${change.name}: `}</span>
                    <span className="text-sm text-green-600 font-semibold">{change.newValue}</span>
                  </div>
                ))}
              </>
            )}
            {delta.removedChanges.length > 0 && (
              <>
                <span className="mt-4 mb-1">
                  <span className="text-red-600 font-semibold">Removed</span> design options
                </span>
                {delta.removedChanges.map((change) => (
                  <div key={change.name} className="line-through">
                    <span className="text-sm">{`${change.groupName} - ${change.name}: `}</span>
                    <span className="text-sm text-red-600 font-semibold">{change.value}</span>
                  </div>
                ))}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
