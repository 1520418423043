import { gql } from '@apollo/client';
import router from 'next/router';

import { Icon, Tooltip } from 'components';
import { parseRouteId } from 'modules/common';
import { areSelectedCmtLiningValid, getIsFabricOrLiningInStock } from 'modules/fabric/fabric.helpers';
import { TemplateOrProductPill, getTemplateImage } from 'modules/garments';
import { useMemo } from 'react';
import { OrderItemProgress } from './OrderItemProgress';
import { OrderItemProgressSideBarSkeleton } from './OrderItemProgressSideBarSkeleton';
import { useDesignOptionsFormProviderOrderItemQuery } from '@graphql';

interface Props {
  className?: string;
  isTemplate: boolean;
}

const FabricProgress = ({ orderItem }) => {
  const { orderId, itemId } = router.query;
  const fabricUrl = useMemo(
    () => (orderItem?.isTemplate ? `/design-templates/${itemId}/fabric/fabric` : `/orders/${orderId}/order-item/${itemId}/fabric/fabric`),
    [orderId, itemId]
  );

  if (orderItem?.fabric) {
    return (
      <OrderItemProgress status={getIsFabricOrLiningInStock(orderItem.fabric.status) ? 'success' : 'error'} onClick={() => router.push(fabricUrl)}>
        <div className="flex flex-col">
          <span className="text-yellow-crayola-500">Fabric</span>
          <span>{orderItem.fabric.name}</span>
        </div>
      </OrderItemProgress>
    );
  }

  if (orderItem?.customFabric) {
    return (
      <OrderItemProgress status="success" onClick={() => router.push(fabricUrl)}>
        <div className="flex flex-col">
          <span className="text-yellow-crayola-500">Fabric</span>
          <span>{orderItem.customFabric}</span>
        </div>
      </OrderItemProgress>
    );
  }

  return (
    <OrderItemProgress status="neutral" onClick={() => router.push(fabricUrl)}>
      <div className="flex flex-col">
        <span className="text-yellow-crayola-500">Fabric</span>
        <span>No fabric selected</span>
      </div>
    </OrderItemProgress>
  );
};

export const OrderItemProgressSideBar = ({ className, isTemplate }: Props) => {
  const { orderId, itemId } = router.query;
  const { data, loading: isLoading } = useDesignOptionsFormProviderOrderItemQuery({
    variables: { orderItemId: parseRouteId(itemId), isTemplate },
    skip: !itemId,
    fetchPolicy: 'cache-first',
  });

  const liningUrl = isTemplate ? `/design-templates/${itemId}/fabric/lining` : `/orders/${orderId}/order-item/${itemId}/fabric/lining`;
  const designOptionsUrl = isTemplate ? `/design-templates/${itemId}/design-options` : `/orders/${orderId}/order-item/${itemId}/design-options`;
  const requiresLining = data?.orderItem?.productCategorySetting.requiresLining;
  const hasCMTLining = useMemo(() => {
    return data?.orderItem?.liningOptions && areSelectedCmtLiningValid(data?.orderItem?.liningOptions);
  }, [data?.orderItem?.liningOptions]);

  if (isLoading) {
    return <OrderItemProgressSideBarSkeleton className={className} />;
  }

  const imageUrl = data?.orderItem.template?.image?.signedUrl || data?.orderItem?.image?.signedUrl;
  const TemplateImage = getTemplateImage(data?.orderItem?.productCategory);

  return (
    <div className={`bg-neutral-900 rounded p-8 bottom-0 w-72 overflow-y-auto ${className}`}>
      <div className="flex text-yellow-crayola-500 mb-6">
        {data?.orderItem.template || isTemplate ? (
          <>
            {imageUrl ? (
              <img src={imageUrl} className="h-16 w-16 rounded object-cover" />
            ) : TemplateImage ? (
              <div className="h-16 w-16 p-1.5 bg-white rounded">
                <TemplateImage width="100%" height="100%" />
              </div>
            ) : (
              <div className="h-16 w-16 bg-neutral-300 rounded flex items-center justify-center text-neutral-900">
                <Icon icon="photo" />
              </div>
            )}
            <div className="ml-5 flex items-center">
              <div>
                <TemplateOrProductPill template={isTemplate ? data?.orderItem : data?.orderItem.template} />
                <div className="mt-1 ">{data?.orderItem?.productCategorySetting.name}</div>
              </div>
            </div>
          </>
        ) : (
          !isTemplate && <div className="h-16 flex items-center">No template selected.</div>
        )}
      </div>
      {!isTemplate && (
        <div className="mb-8">
          <div className="mb-2 text-sm" data-tip="Estimated delivery date">
            <div className="mb-1 flex items-end">
              <div className="font-semibold text-white">Estimated Delivery Date</div>
              <Tooltip
                className="ml-3"
                content={
                  data?.orderItem?.estimatedDeliveryDate?.formatted ? (
                    <div className="w-32 text-xs">
                      Disclaimer: The date provided is an approximation of when the order will arrive and is not guaranteed.
                    </div>
                  ) : (
                    <div className="text-amber-500 w-32 text-xs">
                      Fabric, design options and showroom must be set in order to get information about estimated delivery date.
                    </div>
                  )
                }
              >
                <Icon icon="info-stroke" className="text-white" />
              </Tooltip>
            </div>
          </div>
          {data?.orderItem?.estimatedDeliveryDate?.formatted && (
            <div className="inline-flex items-center bg-indigo-100 text-indigo-800 px-3 rounded-sm text-sm whitespace-nowrap mr-2 leading-6">
              {data?.orderItem?.estimatedDeliveryDate?.formatted || '-'}
            </div>
          )}
        </div>
      )}
      <div className="flex mb-6">
        <FabricProgress orderItem={data?.orderItem} />
      </div>
      {requiresLining && (
        <div className="flex mb-6">
          {!data?.orderItem?.lining && !hasCMTLining && (
            <OrderItemProgress status="neutral" onClick={() => router.push(liningUrl)}>
              <div className="flex flex-col">
                <span className="text-yellow-crayola-500">Lining</span>
                <span>No lining selected</span>
              </div>
            </OrderItemProgress>
          )}
          {(data?.orderItem?.lining || hasCMTLining) && (
            <OrderItemProgress
              status={hasCMTLining ? 'success' : getIsFabricOrLiningInStock(data?.orderItem.lining?.status) ? 'success' : 'error'}
              onClick={() => router.push(liningUrl)}
            >
              <div className="flex flex-col">
                <span className="text-yellow-crayola-500">Lining</span>
                <span>{hasCMTLining ? 'Custom lining selected' : data?.orderItem.lining.name}</span>
              </div>
            </OrderItemProgress>
          )}
        </div>
      )}
      {data?.orderItem?.designs.map((design) => (
        <div className="flex mb-6 last:mb-0" key={`progress-design-options-${design.id}`}>
          <OrderItemProgress
            status={design.conflicts.length ? 'error' : 'success'}
            onClick={() => router.push(`${designOptionsUrl}/${design.garmentCategory.key.toLowerCase()}`)}
          >
            <div className="flex flex-col">
              <span className="text-yellow-crayola-500">
                {data?.orderItem?.designs.length > 1 ? `${design.garmentCategory.name}` : 'Design Options'}
              </span>
              <span>{design.conflicts.length} conflicts</span>
            </div>
          </OrderItemProgress>
        </div>
      ))}
    </div>
  );
};

OrderItemProgressSideBar.fragments = {
  orderItem: gql`
    fragment OrderItemProgressSideBarFragment on OrderItem {
      id
      name
      isTemplate
      productCategory
      productCategorySetting {
        key
        name
        requiresLining
      }
      image {
        signedUrl
      }
      template {
        id
        image {
          signedUrl
        }
        fabric {
          id
        }
        lining {
          id
        }
        designs {
          requiresLining
        }
      }
      fabric {
        id
        status
        name
      }
      customFabric
      lining {
        id
        status
        code
        name
      }
      liningOptions {
        enableCmtLining
        enableCustomLining
        cmtOptions {
          key
          value
        }
        cmtDefaultOptions {
          key
          value
        }
      }
      designs {
        id
        requiresLining
        conflicts {
          message
        }
        garmentCategory {
          key
          name
        }
      }
      estimatedDeliveryDate {
        formatted(format: "ll")
      }
    }
  `,
};
