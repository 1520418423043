import { Modal } from 'components/Modal';
import { useMemo } from 'react';
import { gql } from '@apollo/client';
import { useGetOrderBasicInfoByIdsQuery } from '@graphql';
import { keyBy } from 'lodash';
import { RelatedOrderRow } from './RelatedOrderRow';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  duplicateOriginalOrderId: string | undefined;
  duplicatedOrderIds: string[];
  remakeOriginalOrderId: string | undefined;
  remadeOrderIds: string[];
};

export const RelatedOrdersModal = ({
  isOpen,
  onClose,
  duplicateOriginalOrderId,
  duplicatedOrderIds,
  remakeOriginalOrderId,
  remadeOrderIds,
}: Props) => {
  const { data } = useGetOrderBasicInfoByIdsQuery({
    variables: { orderIds: [...duplicatedOrderIds, ...remadeOrderIds, duplicateOriginalOrderId, remakeOriginalOrderId].filter((id) => !!id) },
  });

  const orderDetailsById = useMemo(() => (data ? keyBy(data.orders.items, 'id') : {}), [data]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={`Related orders`}>
      {duplicateOriginalOrderId && <RelatedOrderRow title="Duplicated from" orderIds={[duplicateOriginalOrderId]} ordersPerId={orderDetailsById} />}
      {duplicatedOrderIds.length > 0 && <RelatedOrderRow title="Duplicated orders" orderIds={duplicatedOrderIds} ordersPerId={orderDetailsById} />}
      {remakeOriginalOrderId && <RelatedOrderRow title="Remade from" orderIds={[remakeOriginalOrderId]} ordersPerId={orderDetailsById} />}
      {remadeOrderIds.length > 0 && <RelatedOrderRow title="Remade orders" orderIds={remadeOrderIds} ordersPerId={orderDetailsById} />}
    </Modal>
  );
};

RelatedOrdersModal.query = gql`
  query GetOrderBasicInfoByIds($orderIds: [ID!]!) {
    orders(orderIds: $orderIds) {
      items {
        id
        customId
        createdAt {
          formatted
        }
        customer {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
