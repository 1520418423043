import { Breadcrumbs } from 'components';
import { HomeIcon } from '@heroicons/react/solid';
import { gql } from '@apollo/client';
import { useBreadcrumbOrderDetailsQuery, useBreadcrumbTemplateQuery } from '@graphql';
import { useRouter } from 'next/router';
import { parseRouteId } from 'modules/common';

const OrderBreadcrumbs = ({ orderId, itemId }) => {
  const { data } = useBreadcrumbOrderDetailsQuery({ variables: { orderId: parseRouteId(orderId) }, fetchPolicy: 'cache-first' });
  const itemName = data?.order.items.find((i) => i.id === itemId)?.name;

  const links = [
    { icon: <HomeIcon />, href: '/dashboard' },
    { name: data?.order.customer.fullName.toUpperCase(), href: `/customers/${data?.order.customer.id}` },
    { name: `#${data?.order.customId}`, href: `/orders/${data?.order.id}` },
    { name: itemName || 'Add Item' },
  ];

  return <Breadcrumbs links={links} className="mt-2 mb-3" />;
};

const TemplateBreadcrumbs = ({ itemId }) => {
  const { data } = useBreadcrumbTemplateQuery({ variables: { orderItemId: parseRouteId(itemId) }, fetchPolicy: 'cache-first' });

  const links = [
    { icon: <HomeIcon />, href: '/dashboard' },
    { name: 'Design Templates', href: `/design-templates?category=${data?.orderItem.productCategory}` },
    { name: 'Template', href: { pathname: `/design-templates`, query: { category: data?.orderItem.productCategory, id: data?.orderItem.id } } },
    { name: data?.orderItem.name },
  ];

  return <Breadcrumbs links={links} className="mt-2 mb-3" />;
};

export const OrderItemBreadcrumbs = () => {
  const router = useRouter();
  const { orderId, itemId } = router.query;

  return orderId ? <OrderBreadcrumbs orderId={orderId} itemId={itemId} /> : <TemplateBreadcrumbs itemId={itemId} />;
};

OrderItemBreadcrumbs.queries = {
  orderDetails: gql`
    query BreadcrumbOrderDetails($orderId: ID!) {
      order(orderId: $orderId) {
        id
        customId
        customer {
          id
          fullName
        }
        items {
          id
          name
        }
      }
    }
  `,
  templateDetails: gql`
    query BreadcrumbTemplate($orderItemId: ID!) {
      orderItem(orderItemId: $orderItemId, isTemplate: true) {
        id
        isTemplate
        name
        productCategory
      }
    }
  `,
};
