/* https://ui.shadcn.com/docs/components/calendar */

import React from 'react';
import { ChevronLeftIcon as ChevronLeft, ChevronRightIcon as ChevronRight } from '@heroicons/react/solid';
import { DayPicker } from 'react-day-picker';
import cn from 'classnames';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-semibold',
        nav: 'space-x-1 flex items-center',
        nav_button: 'border h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'w-9 font-normal text-sm text-neutral-600',
        row: 'flex w-full mt-2',
        cell: 'text-center text-sm p-0',
        day: 'border border-transparent h-9 w-9 p-0 font-normal hover:border-yellow-crayola-500',
        day_selected: 'bg-yellow-crayola-500',
        day_today: '',
        day_outside: 'opacity-50',
        day_disabled: 'opacity-50',
        day_range_middle: 'opacity-50',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className="h-4 w-4 mx-auto" />,
        IconRight: () => <ChevronRight className="h-4 w-4 mx-auto" />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
