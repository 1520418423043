import { datadogRum } from '@datadog/browser-rum';
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { User } from 'firebase/auth';
import { authService } from 'services';
import mixpanelService from 'services/mixpanel.service';
import apolloClient, { clearLocalApolloCache } from 'libs/apollo';
import { ActiveSessionQuery, ActiveSessionDocument } from '@graphql';
import amplitudeService from 'services/amplitude.service';

export type AuthContextType = {
  firebaseUser: User | null;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  token: string | null;
};

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [token, setToken] = useState<AuthContextType['token']>(null);
  const [firebaseUser, setFirebaseUser] = useState<AuthContextType['firebaseUser']>(null);

  useEffect(() => {
    authService.onAuthStateChanged(async (firebaseUser) => {
      setFirebaseUser(firebaseUser);

      if (firebaseUser) {
        setToken(await firebaseUser.getIdToken());
        datadogRum.setUser({
          id: firebaseUser.uid,
          email: firebaseUser.email,
          name: firebaseUser.displayName,
        });
      } else {
        setToken(null);
        datadogRum.clearUser();
        mixpanelService.reset();
        amplitudeService.reset();
      }
      setIsAuthenticating(false);
    });

    authService.onIdTokenChanged(async () => {
      // If the token refreshes, we will store the new token in state.
      if (firebaseUser) {
        setToken(await firebaseUser.getIdToken());
        const { data } = await apolloClient.query<ActiveSessionQuery>({ query: ActiveSessionDocument });
        await mixpanelService.identify(data.activeSession);
        await amplitudeService.identify(data.activeSession);
      }
    });
  }, []);

  const login = async (email: string, password: string) => {
    const firebaseUser = await authService.signInWithEmailAndPassword(email, password);

    const { data } = await apolloClient.query<ActiveSessionQuery>({ query: ActiveSessionDocument });
    await mixpanelService.identify(data.activeSession, true);
    await amplitudeService.identify(data.activeSession);
    setFirebaseUser(firebaseUser);
    setToken(await firebaseUser.getIdToken());
  };

  const logout = async () => {
    await authService.signOut();
    clearLocalApolloCache();
  };

  const value = useMemo(
    () => ({
      firebaseUser,
      isAuthenticated: Boolean(firebaseUser) && Boolean(token),
      isAuthenticating,
      login,
      logout,
      token,
    }),
    [isAuthenticating, firebaseUser, token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('This component must be used within a <AuthProvider> component.');
  }

  return context;
};
