import { HeaderProductCategory, TierPricingLookupMap } from '../types';

export const generateFabricPriceCsv = (headerProductCategories: HeaderProductCategory[], tiers: string[], lookup: TierPricingLookupMap) => {
  const th = ['tier', ...headerProductCategories.map((p) => p.truncatedName)];

  const td = tiers.map((tierName) => {
    const pricePerProductCategory = lookup[tierName];

    return [tierName, ...headerProductCategories.map((p) => pricePerProductCategory?.[p.key]?.amount ?? '')];
  });

  return `data:text/csv;charset=utf-8,${[th, ...td].map((cell) => cell.map((c) => `"${c}"`).join(',')).join('\n')}`;
};
