import { ReactNode } from 'react';
import cn from 'classnames';
import { Button } from 'modules/common/components/Button';

type Props = {
  children: ReactNode;
  contentClassName?: string;
  isBackButtonDisabled?: boolean;
  backText?: string;
  onBackClick?: () => void;
  isNextButtonDisabled?: boolean;
  nextText?: string;
  onNextClick?: () => void;
};

export const StepContainer = ({
  contentClassName,
  children,
  backText = 'Back',
  nextText = 'Next',
  onBackClick,
  onNextClick,
  isBackButtonDisabled = false,
  isNextButtonDisabled = false,
}: Props) => {
  return (
    <div>
      <div className={cn('h-96 overflow-y-auto', contentClassName)}>{children}</div>
      <div className="flex justify-between mt-4">
        <Button className="w-40 flex justify-center" variant="neutral" onClick={onBackClick} isDisabled={isBackButtonDisabled}>
          {backText}
        </Button>
        <Button className="w-40 flex justify-center" variant="positive" onClick={onNextClick} isDisabled={isNextButtonDisabled}>
          {nextText}
        </Button>
      </div>
    </div>
  );
};
