import { Button } from 'modules/common';
import { Icon } from './Icon';

export const CreateNewDesignTemplateButton = ({ handleNewTemplate }: { handleNewTemplate: () => void }) => {
  return (
    <Button onClick={handleNewTemplate}>
      <Icon icon="add-circle" className="h-4" />
      <span className="xl:inline-block ml-1">New Template</span>
    </Button>
  );
};
