import { PrintOrderCustomerFragment, PrintOrderSalesRepFragment, PrintOrderShippingShowroomFragment } from '@graphql';
import { OrderDetailsPrintSectionHeader } from 'modules/orders/components/OrderDetailsPrintSectionHeader';

interface Props {
  customerAddress: PrintOrderCustomerFragment['shippingAddress'] | null;
  shippingShowroom: PrintOrderShippingShowroomFragment | null;
  salesRep: PrintOrderSalesRepFragment | null;
}

export const OrderDetailsPrintContact = ({ customerAddress, shippingShowroom, salesRep }: Props) => {
  return (
    <div className="grid grid-cols-2 w-full gap-4" style={{ marginTop: '-85px' }}>
      <div className="col-span-1">
        <OrderDetailsPrintSectionHeader label="Customer" />
        <div className="mt-4 text-10">
          <div className="grid grid-flow-col justify-start">
            <p className="w-11 mr-4">Name</p>
            <p>{customerAddress?.firstName || customerAddress?.lastName ? `${customerAddress?.firstName} ${customerAddress?.lastName}` : '-'}</p>
          </div>
          <div className="mt-2 grid grid-flow-col justify-start">
            <div className="w-11 mr-4">Phone</div>
            <div>{customerAddress?.phone ?? '-'}</div>
          </div>
          <div className="mt-2 grid grid-flow-col justify-start">
            <p className="w-11 mr-4">Address</p>
            {!customerAddress && '-'}
            {customerAddress && (
              <p>
                {customerAddress.line1 ? `${customerAddress.line1},` : ''} {customerAddress.line2 ? `${customerAddress.line2},` : ''}{' '}
                {customerAddress.city ? `${customerAddress.city},` : ''} {customerAddress.province ? `${customerAddress.province},` : ''}{' '}
                {customerAddress.country}
              </p>
            )}
          </div>
          <div className="mt-2 grid grid-flow-col justify-start">
            <div className="w-11 mr-4">Postcode</div>
            <div>{customerAddress?.postalCode ?? '-'}</div>
          </div>
        </div>
      </div>
      <div className="col-span-1">
        <OrderDetailsPrintSectionHeader label="Receiver" />
        {shippingShowroom && (
          <div className="mt-4 text-10">
            <div className="grid grid-flow-col justify-start">
              <p className="w-11 mr-4">Name</p>
              <p>
                {shippingShowroom.name}{' '}
                {salesRep && (
                  <span>
                    {' '}
                    - {salesRep.firstName} {salesRep.lastName}
                  </span>
                )}
              </p>
            </div>
            <div className="mt-2 grid grid-flow-col justify-start">
              <p className="w-11 mr-4">Address</p>
              <p>
                {shippingShowroom.address?.line1 ? `${shippingShowroom.address?.line1},` : ''}{' '}
                {shippingShowroom.address?.line2 ? `${shippingShowroom.address?.line2},` : ''}{' '}
                {shippingShowroom.address?.city ? `${shippingShowroom.address?.city},` : ''}{' '}
                {shippingShowroom.address?.province ? `${shippingShowroom.address?.province},` : ''} {shippingShowroom.address?.country}
              </p>
            </div>
            <div className="mt-2 grid grid-flow-col justify-start">
              <div className="w-11 mr-4">Postcode</div>
              <div>{shippingShowroom.address?.postalCode ?? '-'}</div>
            </div>
            <div className="mt-2 grid grid-flow-col justify-start">
              <div className="w-11 mr-4">Province</div>
              <div>{shippingShowroom.address?.province ?? '-'}</div>
            </div>
            <div className="mt-2 grid grid-flow-col justify-start">
              <div className="w-11 mr-4">Country</div>
              <div>{shippingShowroom.address?.country ?? '-'}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
