import { useState } from 'react';
import { Button } from 'modules/common';
import { AlterationsOptionModal } from 'modules/alterations/components';

export const AlterationsAddOptionButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Add a new alteration option</Button>
      <AlterationsOptionModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
