import { IndividualPricingState } from '../IndividualPricingState';
import cn from 'classnames';
import { InteractivePriceTableData } from './InteractivePriceTableData';

type Props = {
  headers: IndividualPricingState['headers'];
  rows: IndividualPricingState['rows'];
  isReadOnly: boolean;
  onChangeAmount: (fabricIndex: number, productCategoryIndex: number, rawAmount: string) => void;
  onSwitchPriceEditMode: (fabricIndex: number, productCategoryIndex: number) => void;
};

export const FabricPriceMatrix = ({ headers, rows, isReadOnly, onChangeAmount, onSwitchPriceEditMode }: Props) => {
  return (
    <table className="w-full text-xs">
      <thead>
        <tr>
          <th className="p-4 bg-yellow-200 border border-gray-300 text-center">Fabrics</th>
          {headers.map(({ key, label }) => (
            <th key={key} className="p-4 bg-gray-50 border border-gray-300 text-center">
              {label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map(({ fabricCode, label, prices }, i) => (
          <tr key={fabricCode} className={cn({ 'bg-gray-100': i % 2 === 0, 'bg-gray-50': i % 2 === 1 })}>
            <th className={cn('p-4 border border-gray-300 text-center', { 'bg-amber-100': i % 2 === 0, 'bg-amber-50': i % 2 === 1 })}>{label}</th>
            {prices.map((priceInfo, j) => (
              <InteractivePriceTableData
                key={`${fabricCode}-${priceInfo.productCategory}`}
                priceInfo={priceInfo}
                isDisabled={isReadOnly}
                onPriceUpdated={(rawAmount) => onChangeAmount(i, j, rawAmount)}
                onSwitchEditMode={() => onSwitchPriceEditMode(i, j)}
              />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
