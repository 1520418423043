import { OrderItem } from '../../types';
import { RemakeItem } from './types';

export const createRemakeItemsState = (items: OrderItem[]): RemakeItem[] =>
  items.map((item) => ({
    orderItemId: item.id,
    title: `#${item.number} - ${item.name}`,
    isChecked: false,
    remakeReason: '',
    remakeDescription: '',
    designs: item.designs.map((design) => ({
      orderItemDesignId: design.id,
      title: design.garmentCategory.name,
      isChecked: false,
    })),
  }));
