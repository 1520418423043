import { Icon } from 'components';
import { Search } from 'modules/common';
import { ProductCategoriesSelect } from 'components';
import { GarmentTemplatesListItem } from 'modules/garments/components/GarmentTemplatesListItem';
import { GarmentTemplateListProps, useGarmentTemplateList } from 'hooks/useGarmetTemplateList';
import { CreateNewDesignTemplateButton } from 'components/CreateNewDesignTemplateButton';
import { useEffect } from 'react';
import { AddItemTemplate } from 'modules/orders';
import { MenuOption } from 'components/DotsMenu';

interface Props extends GarmentTemplateListProps {
  selectedTemplate: AddItemTemplate;
  handleNewTemplate: () => void;
  menuOptions?: MenuOption[];
}

export const GarmentTemplateListPageLayout = ({
  productCategory,
  setProductCategory,
  searchText,
  setSearchText,
  selectedTemplate,
  setSelectedTemplate,
  handleNewTemplate,
  menuOptions,
}: Props) => {
  const { GarmentTemplatesList, ViewMoreButton, productCategoryName, handleProductCategoryChange, filteredTemplates, allTemplates } =
    useGarmentTemplateList({
      searchText,
      setSearchText,
      setProductCategory,
      productCategory,
      setSelectedTemplate,
    });

  useEffect(() => {
    // only to run when a template needs to be set via id (i.e. from a query param)
    if (selectedTemplate) {
      const selectedTemplateKeys = Object.keys(selectedTemplate);
      if (selectedTemplateKeys?.length === 1 && selectedTemplateKeys[0] === 'id') {
        const templateToSet = allTemplates?.find(({ id }) => id === selectedTemplate.id);
        if (templateToSet) setSelectedTemplate(templateToSet);
      }
    }
  }, [selectedTemplate, filteredTemplates]);

  return (
    <GarmentTemplatesList>
      {!productCategory ? (
        <>
          <div className="flex justify-center">
            <div className="flex justify-center w-1/2">
              <div className="w-1/2 max-w-sm">
                <ProductCategoriesSelect hideLabel value={productCategory} onChange={(category) => setProductCategory(category)} />
              </div>
            </div>
          </div>
          <div className="w-full h-96 flex flex-col items-center justify-center">
            <div className="w-10 h-10 bg-yellow-crayola-50 rounded flex items-center justify-center mb-2">
              <Icon icon="danger-stroke" className="text-yellow-crayola-600" />
            </div>
            No product category selected
          </div>
        </>
      ) : (
        <>
          <div className="flex w-full mt-8">
            <div className="max-w-sm" style={{ marginTop: '3px' }}>
              <ProductCategoriesSelect hideLabel value={productCategory} onChange={(category) => handleProductCategoryChange(category)} />
            </div>
            <div className="flex ml-auto">
              <div className="mr-4 max-w-sm">
                <Search placeholder="Search for a template" onChange={setSearchText} />
              </div>
              <div className="max-w-sm">
                <CreateNewDesignTemplateButton handleNewTemplate={handleNewTemplate} />
              </div>
            </div>
          </div>
          <div className="bg-white p-8 mt-4">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-6">
              <div className="text-2xl col-span-full">{productCategoryName}</div>
              {!filteredTemplates.length && <div className="w-full h-96 flex items-center justify-center">No results</div>}
              {filteredTemplates.map((template) => {
                return (
                  <GarmentTemplatesListItem
                    key={template?.id}
                    template={template}
                    isSelected={selectedTemplate?.id === template?.id}
                    handleOnClick={() => setSelectedTemplate(template)}
                    menuOptions={menuOptions}
                  />
                );
              })}
            </div>
            <ViewMoreButton />
          </div>
        </>
      )}
    </GarmentTemplatesList>
  );
};
