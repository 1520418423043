import { useState } from 'react';
import cn from 'classnames';

import { SlideoverPanel } from 'components';
import { Button } from 'modules/common';
import { GarmentObservationTypeSetting } from '@graphql';
import { ObservationStepper } from './SimplifiedMeasurementFormObservationStepper';

interface Props {
  observations: GarmentObservationTypeSetting[];
  isGarmentDisabled?: boolean;
}

const Info = ({ observation }: { observation: GarmentObservationTypeSetting }) => {
  return (
    <div>
      <h3 className="text-2xl font-semibold mb-8">{observation.name}</h3>
      {observation.items.length &&
        [observation.items[0], observation.items[observation.items.length - 1]].map(
          (item, i) =>
            (item.imageUrl || item.description) && (
              <div key={i} className={cn('flex items-center', { 'mt-8': i > 0 })}>
                <img className="w-40" src={item.imageUrl} />
                <div className="ml-12">
                  <h4 className="text-xl font-semibold mb-2">{item.name}</h4>
                  <p>{item.description}</p>
                </div>
              </div>
            )
        )}
    </div>
  );
};

export const SimplifiedObservations = ({ observations, isGarmentDisabled }: Props) => {
  const [activeObservation, setActiveObservation] = useState<GarmentObservationTypeSetting>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="grid grid-cols-4 gap-4">
        {observations.map((observation, index) => (
          <ObservationStepper
            key={index}
            index={index}
            observation={observation}
            onInfoClick={() => {
              setActiveObservation(observation);
              setOpen(true);
            }}
            isGarmentDisabled={isGarmentDisabled}
          />
        ))}
      </div>
      <SlideoverPanel
        isOpen={open}
        setIsOpen={setOpen}
        maxWidthCss="max-w-3xl"
        showCancel={false}
        showExit={false}
        submitButton={<Button onClick={() => setOpen(false)}>Close</Button>}
      >
        {activeObservation && <Info observation={activeObservation} />}
      </SlideoverPanel>
    </>
  );
};
