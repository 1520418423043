import { gql } from '@apollo/client';

import { MutationUpdateDefaultMarginPercentArgs, useUpdateDefaultMarginPercentMutation } from '@graphql';
import { Input, Modal } from 'components';
import { Button } from 'modules/common';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  defaultMarginPercent: string;
}

export const MarginModal = ({ isOpen, setIsOpen, defaultMarginPercent }: Props): React.ReactElement => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<MutationUpdateDefaultMarginPercentArgs>();

  const onClose = () => {
    setIsOpen(false);
    reset();
  };

  const onCompleted = () => {
    toast.success(`Default Margin updated.`);
    onClose();
  };

  const onError = () => toast.error('Something went wrong, please try again.');

  const [updateDefaultMarginPercent, { loading }] = useUpdateDefaultMarginPercentMutation({
    onCompleted,
    onError,
  });

  const onSubmit = handleSubmit(async ({ percent }) => {
    updateDefaultMarginPercent({
      variables: {
        percent,
      },
      refetchQueries: ['UseActiveSession'],
    });
  });

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Update Default Margin" onClose={onClose}>
      <p className="text-sm mb-4">
        If no margin has been set a default of <span className="font-semibold">70%</span> will be applied.
      </p>
      <ul className="text-sm mb-4">
        <li>
          Current Margin: <span className="font-semibold">{defaultMarginPercent}</span>
        </li>
      </ul>
      <form onSubmit={onSubmit} className="sm:grid sm:grid-cols-2 gap-4">
        <div>
          <Input
            label="New Margin (%)"
            register={register('percent', { required: 'Please enter a percentage' })}
            htmlProps={{ type: 'number', min: 0, max: 100 }}
            errorMessage={errors?.percent?.message.toString()}
          />
        </div>
        <div className="pt-4 ml-auto">
          <Button type="submit" isDisabled={loading}>
            Update
          </Button>
        </div>
      </form>
    </Modal>
  );
};

MarginModal.mutations = {
  updateDefaultMarginPercent: gql`
    mutation UpdateDefaultMarginPercent($percent: String!) {
      updateDefaultMarginPercent(percent: $percent) {
        slug
        defaultMarginPercent
      }
    }
  `,
};
