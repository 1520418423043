import { gql } from '@apollo/client';
import { AlterationItemInput, GetOrderItemsQuery, useGetOrderItemsQuery, CreateAlterationInput } from '@graphql';
import { TrashIcon } from '@heroicons/react/outline';
import { Select, Textarea } from 'components';
import { Button } from 'modules/common';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { AlterationsFormOptions } from 'modules/alterations/components';

interface Props {
  isLoading?: boolean;
}

const getDesigns = (orderData: GetOrderItemsQuery) => orderData?.order.items.map((item) => item.designs.map((design) => design)).flat();

export const AlterationsFormItems = (props: Props) => {
  const { isLoading } = props;

  const { query } = useRouter();

  const {
    register,
    control,
    setValue,
    formState: { isValid },
  } = useFormContext<CreateAlterationInput>();

  setValue(`orderId`, query.orderId as string);

  const { data: orderData } = useGetOrderItemsQuery({
    variables: { orderId: query.orderId as string },
    onCompleted: (data) => setValue(`alterationItems.0.orderItemDesignId`, getDesigns(data)[0].id),
  });

  const designs = useMemo(() => getDesigns(orderData), [orderData]);

  const {
    fields: alterationItems,
    append: appendAlterationItem,
    remove: removeAlterationItem,
  } = useFieldArray({
    control,
    name: 'alterationItems',
    rules: {
      validate: (alterationItems: Array<AlterationItemInput>) => {
        if (!alterationItems.length) return false;
        return alterationItems.reduce((r, { alterationItemOptions }) => r && !!alterationItemOptions.length, true);
      },
    },
  });

  return (
    <div className="mt-6">
      <div className="font-semibold text-lg">Items</div>
      <ul>
        {alterationItems.map((alterationItem, i) => (
          <li key={alterationItem.id} className="bg-gray-100 p-4 mt-4 border-l-2 border-gray-400">
            <div className="flex justify-between mb-2">
              <div className="font-semibold text-md">Item {i + 1}</div>
              {i !== 0 && <TrashIcon className="w-6 h-6 text-red-500 hover:text-red-600 cursor-pointer" onClick={() => removeAlterationItem(i)} />}
            </div>
            <div className="grid grid-cols-8 gap-4">
              <div className="col-span-4 space-y-4">
                <div>
                  <Select
                    label="Select an item"
                    register={register(`alterationItems.${i}.orderItemDesignId` as const, {
                      onChange: () => {
                        setValue(`alterationItems.${i}.alterationItemOptions`, []);
                      },
                    })}
                    testId={`select-alteration-item`}
                  >
                    {!!designs?.length &&
                      designs.map((design) => (
                        <option key={design.id} value={design.id}>
                          {design.garmentCategory.name} ({design.orderItem.number} - {design.orderItem.name})
                        </option>
                      ))}
                  </Select>
                  <AlterationsFormOptions itemIndex={i} items={designs || []} />
                </div>
              </div>

              <div className="col-span-4">
                <div>
                  <Textarea
                    testId={`alteration-item-note-${i}`}
                    label="Note"
                    htmlProps={{
                      placeholder: 'Other special requests for this item...',
                      rows: 4,
                    }}
                    register={register(`alterationItems.${i}.note` as const)}
                  />
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <div className="flex border-t pt-4 mt-4 justify-between">
        <Button
          testId="add-alteration-item"
          variant="neutral"
          onClick={() =>
            appendAlterationItem({
              orderItemDesignId: designs[0].id,
              alterationItemOptions: [],
              note: '',
            })
          }
        >
          Add another item
        </Button>
        <Button testId="submit-alteration" type="submit" isDisabled={isLoading || !isValid}>
          Create alteration
        </Button>
      </div>
    </div>
  );
};

AlterationsFormItems.queries = {
  orderItems: gql`
    query GetOrderItems($orderId: ID!) {
      order(orderId: $orderId) {
        id
        items {
          id
          name
          designs {
            id
            orderItem {
              id
              name
              number
            }
            garmentCategory {
              key
              name
            }
          }
          estimatedDeliveryDate {
            origin
            formatted(format: "ll")
          }
        }
      }
    }
  `,
};
