import { gql } from '@apollo/client';
import { GetAlterationProviderQuery, useGetAlterationOptionsQuery } from '@graphql';
import { Box } from 'components';
import { AlterationsOption } from 'modules/alterations/components';

interface Props {
  alterationProvider: GetAlterationProviderQuery['alterationProvider'];
}

export const AlterationsProviderOptionsForm = (props: Props) => {
  const { alterationProvider } = props;

  /**
   * Get the full list of options
   */
  const { data } = useGetAlterationOptionsQuery({ fetchPolicy: 'cache-and-network' });

  return (
    <Box title="Alteration Options" isLoading={false}>
      <div className="grid grid-cols-12 gap-8 text-xs font-semibold text-gray-400">
        <div className="col-span-1 p-2 text-center">Status</div>
        <div className="col-span-3 p-2">Name</div>
        <div className="col-span-3 p-2">Garment Category</div>
        <div className="col-span-3 p-2 text-center">Cost</div>
        <div className="col-span-2 p-2 text-center">Last updated</div>
      </div>

      {!!data?.alterationOptions?.length &&
        data.alterationOptions
          .filter((o) => o.isActive)
          .map((option) => {
            const _matchedOption = alterationProvider?.alterationProviderOptions?.find((o) => o.alterationOption.id === option.id);

            return (
              <AlterationsOption
                alterationOption={option}
                isActive={_matchedOption?.isActive}
                lastUpdated={_matchedOption?.updatedAt?.fromNow}
                cost={_matchedOption?.cost?.amount}
                alterationProviderId={alterationProvider.id}
                key={option.id}
              />
            );
          })}
    </Box>
  );
};

AlterationsProviderOptionsForm.queries = {
  getAlterationOptions: gql`
    query GetAlterationOptions {
      alterationOptions {
        id
        name
        isActive
        garmentCategory {
          key
          name
        }
        values
        canDelete
        updatedAt {
          fromNow
          origin
        }
      }
    }
  `,
};
