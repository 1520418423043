import { IndividualPricingState } from '../IndividualPricingState';

export const generateFabricPriceCsv = (rows: IndividualPricingState['rows']) => {
  const th = ['fabric code', 'product category', 'amount'];

  const td = rows.flatMap(({ fabricCode, prices }) =>
    prices
      .map(({ productCategoryLabel, rawAmount }) =>
        rawAmount.length === 0 ? null : [fabricCode, productCategoryLabel, Number(rawAmount).toString()]
      )
      .filter((a) => !!a)
  );

  return `data:text/csv;charset=utf-8,${[th, ...td].map((cell) => cell.map((c) => `"${c}"`).join(',')).join('\n')}`;
};
