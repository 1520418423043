import { ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Feature, useFeatureAsync } from 'hooks';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { Spinner } from 'components';

type Props = {
  children: ReactNode;
  shouldCheckEnv: boolean;
  featureName: Feature;
  redirectedPage?: string;
};

const EnabledFeature = ({ children, shouldCheckEnv, featureName, redirectedPage = '' }: Props) => {
  const router = useRouter();
  const { loading: sessionLoading, tailor, user } = useActiveSession();
  const { loading: featureLoading, enabled: discountsFF } = useFeatureAsync(featureName);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!sessionLoading && !featureLoading) {
      setLoading(false);
    }
  }, [sessionLoading, featureLoading]);

  if (!tailor || !user) return null;

  if (loading) {
    return (
      <div className="flex items-center justify-center h-96">
        <Spinner />
      </div>
    );
  }

  const features = tailor?.config?.availableFeatures || [];
  const hasDiscountConfig = features.some((item) => item.name === featureName && item.enabled);
  const discountEnabledEmail = features.some((item) => item.name === featureName && item.allowedEmail.includes(user.email));

  let canAccess = false;
  if (discountEnabledEmail) {
    canAccess = true;
  } else {
    if (shouldCheckEnv) canAccess = discountsFF && hasDiscountConfig;
    else {
      canAccess = hasDiscountConfig;
    }
  }
  if (canAccess) {
    return <>{children}</>;
  } else {
    redirectedPage ? router.push(redirectedPage) : null;
  }
};

export { EnabledFeature };
