import React, { useEffect, useMemo, useState } from 'react';
import { NextRouter, useRouter } from 'next/router';

import SimpleCombobox, { Option } from './SimpleCombobox';
import {
  useGetUsersQuery,
  useGetShowroomsQuery,
  useGetAlterationProvidersQuery,
  useGetCustomersLazyQuery,
  useGetOneCustomerLazyQuery,
} from '@graphql';
import { getSelectedOption } from 'helpers/order-helpers';
import { debounce } from 'lodash';
import { gql } from '@apollo/client';

export enum QueryParam {
  User = 'user',
  Showroom = 'showroom',
  Customer = 'customer',
  Provider = 'provider',
}

const QueryResponseKey: Record<QueryParam, string> = {
  [QueryParam.User]: 'users',
  [QueryParam.Showroom]: 'showrooms',
  [QueryParam.Customer]: 'customers',
  [QueryParam.Provider]: 'alterationProviders',
};

const getQueries = {
  [QueryParam.User]: useGetUsersQuery,
  [QueryParam.Showroom]: useGetShowroomsQuery,
  [QueryParam.Provider]: useGetAlterationProvidersQuery,
};

const getLazyQueries = {
  [QueryParam.Customer]: {
    many: useGetCustomersLazyQuery,
    one: useGetOneCustomerLazyQuery,
  },
};

const defaultOptions: Record<QueryParam, { id: string; value: string }> = {
  [QueryParam.User]: { id: '', value: 'All Sales Reps' },
  [QueryParam.Showroom]: { id: '', value: 'All Showrooms' },
  [QueryParam.Customer]: { id: '', value: 'All Customers' },
  [QueryParam.Provider]: { id: '', value: 'All Providers' },
};

const getValue: Record<QueryParam, (o) => string> = {
  [QueryParam.User]: (o) => `${o.firstName} ${o.lastName}`,
  [QueryParam.Showroom]: (o) => o.name,
  [QueryParam.Customer]: (o) => `${o.firstName} ${o.lastName}`,
  [QueryParam.Provider]: (o) => o.name,
};

interface Props {
  filterParam: QueryParam;
}

interface ClientFilterProps {
  filterParam: keyof typeof getQueries;
  push: NextRouter['push'];
  query: NextRouter['query'];
}

const ClientFilterComboBox = ({ filterParam, push, query }: ClientFilterProps) => {
  const { data } = getQueries[filterParam]({ fetchPolicy: 'cache-first' });

  const options = useMemo(() => {
    const opts = data?.[QueryResponseKey[filterParam]] || [];

    return [defaultOptions[filterParam], ...(opts.items ? opts.items : opts).map((o) => ({ id: o.id, value: getValue[filterParam](o) }))];
  }, [data]);

  const selected = getSelectedOption(options, query?.[filterParam] as string);

  const onChange = (o) => {
    push({ query: { ...query, [filterParam]: o.id } }, undefined, { shallow: true });
  };

  return <SimpleCombobox options={options} selectedOption={selected} onChange={onChange} />;
};

interface ServerFilterProps {
  filterParam: keyof typeof getLazyQueries;
  push: NextRouter['push'];
  query: NextRouter['query'];
}

const ServerFilterComboBox = ({ filterParam, push, query }: ServerFilterProps) => {
  const queries = getLazyQueries[filterParam];

  const [execute, { called }] = queries.many({ fetchPolicy: 'cache-first' });
  const [getOne, { loading: loadingOne }] = queries.one({ fetchPolicy: 'cache-first' });

  const [options, setOptions] = useState<Option[]>([defaultOptions[filterParam]]);

  const selected = getSelectedOption(options, query?.[filterParam] as string);

  const onChange = (selectedOption: Option) => {
    const newOptions = [defaultOptions[filterParam]];
    if (selectedOption.id !== '') {
      newOptions.push(selectedOption);
    }
    setOptions(newOptions);
    push({ query: { ...query, [filterParam]: selectedOption?.id } }, undefined, { shallow: true });
  };

  useEffect(() => {
    const querySearchValue = query?.[filterParam] as string;
    if (querySearchValue && !called) {
      setOptions([defaultOptions[filterParam], { id: querySearchValue, value: 'Loading...' }]);
      getOne({ variables: { id: querySearchValue } }).then(({ data }) => {
        const newOptions = [defaultOptions[filterParam], { id: data.customer.id, value: getValue[filterParam](data.customer) }];

        setOptions(newOptions);
      });
    }
  }, [query?.[filterParam]]);

  const executeQuery = useMemo(
    () =>
      debounce(async (value: string) => {
        setOptions([defaultOptions[filterParam], { id: '_', value: 'Loading...', disabled: true }]);
        const { data } = await execute({ variables: { search: value } });

        const opts = data?.[QueryResponseKey[filterParam]] || [];

        const newOptions = [
          defaultOptions[filterParam],
          ...(opts.items ? opts.items : opts).map((o) => ({ id: o.id, value: getValue[filterParam](o) })),
        ];

        setOptions(newOptions);
      }, 500),
    [execute]
  );

  const search = (text: string) => {
    if (text.length < 3) {
      executeQuery.cancel();
      setOptions([defaultOptions[filterParam], { value: 'Write at least 3 characters', id: '_', disabled: true }]);
    } else {
      executeQuery(text);
    }
  };

  return (
    <SimpleCombobox
      options={options}
      selectedOption={selected}
      onChange={onChange}
      onChangeText={search}
      isDisabled={loadingOne}
      autoFilter={false}
      clearOnFocus
    />
  );
};

ServerFilterComboBox.queries = {
  customer: gql`
    query GetOneCustomer($id: ID!) {
      customer(customerId: $id) {
        id
        customId
        firstName
        lastName
      }
    }
  `,
};

const QueryCombobox = ({ filterParam }: Props) => {
  const { push, query } = useRouter();

  const isServerSideFiltered = filterParam === QueryParam.Customer;

  return isServerSideFiltered ? (
    <ServerFilterComboBox filterParam={filterParam} push={push} query={query} />
  ) : (
    <ClientFilterComboBox filterParam={filterParam} push={push} query={query} />
  );
};

export default QueryCombobox;
