import { OrderPageQuery } from '@graphql';
import { DocumentDuplicateIcon } from '@heroicons/react/outline';
import { Button } from 'modules/common/components/Button';
import { useState } from 'react';
import cn from 'classnames';
import { RelatedOrdersModal } from './RelatedOrdersModal';

type Props = {
  className?: string;
  relatedOrders: OrderPageQuery['order']['relatedOrders'];
};

export const RelatedOrderAction = ({ className, relatedOrders }: Props) => {
  const [isRelatedModalOpen, setIsRelatedModalOpen] = useState(false);
  const duplicateOriginalOrderId = relatedOrders?.duplicate?.originalOrderId;
  const duplicatedOrderIds = relatedOrders?.duplicate?.duplicatedOrderIds ?? [];
  const remakeOriginalOrderId = relatedOrders?.remake?.originalOrderId;
  const remadeOrderIds = relatedOrders?.remake?.remadeOrderIds ?? [];
  const isEnabled = duplicatedOrderIds.length > 0 || remadeOrderIds.length > 0 || !!duplicateOriginalOrderId || !!remakeOriginalOrderId;
  return (
    <>
      {isEnabled && (
        <>
          <Button onClick={() => setIsRelatedModalOpen(true)} isText className="mr-6" isDisabled={!isEnabled}>
            <div className={cn(className, 'flex items-center text-xs text-gray-500')}>
              <DocumentDuplicateIcon className="w-5 h-5 mr-2" />
              Related orders
            </div>
          </Button>
          <RelatedOrdersModal
            isOpen={isRelatedModalOpen}
            onClose={() => setIsRelatedModalOpen(false)}
            duplicateOriginalOrderId={duplicateOriginalOrderId}
            duplicatedOrderIds={duplicatedOrderIds}
            remakeOriginalOrderId={remakeOriginalOrderId}
            remadeOrderIds={remadeOrderIds}
          />
        </>
      )}
    </>
  );
};
