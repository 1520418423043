import React from 'react';
import cn from 'classnames';

export interface Props {
  children: React.ReactNode;
  className?: string;
  isHidden?: boolean;
  isSelected?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const TabButton = React.forwardRef<HTMLButtonElement, Props>(({ children, className, isHidden, isSelected, onClick }, ref) => {
  return (
    <button
      ref={ref}
      type="button"
      onClick={onClick}
      className={cn(
        'whitespace-nowrap py-2 px-3 border-b-2 text-sm focus:outline-none',
        className,
        {
          'border-b-yellow-crayola-500 text-gray-700': isSelected,
          'border-b-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': !isSelected,
        },
        { hidden: isHidden }
      )}
    >
      {children}
    </button>
  );
});

export default TabButton;
