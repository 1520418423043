import Link from 'next/link';
import { useRouter } from 'next/router';
import { ArrowCircleRightIcon } from '@heroicons/react/outline';

export const DesignOptionsStats = (props) => {
  const { data } = props;

  const router = useRouter();

  const isCurrentLink = (link) => router.asPath === link;

  return (
    <dl className="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-5">
      {data.map((d) => (
        <>
          {d.link ? (
            <Link
              href={d.link}
              shallow
              className={`px-2 py-5 shadow rounded-lg overflow-hidden sm:p-4 flex items-center justify-between group ${
                isCurrentLink(d.link) ? 'bg-gray-800' : 'bg-white'
              }`}
            >
              <div>
                <dt className={`text-sm font-medium truncate ${isCurrentLink(d.link) ? 'text-gray-300' : 'text-gray-500'}`}>{d.name}</dt>
                <dd className={`mt-1 text-3xl font-semibold  ${isCurrentLink(d.link) ? 'text-white' : 'text-gray-900'}`}>{d.count}</dd>
              </div>
              <ArrowCircleRightIcon className={`h-6 w-6 text-gray-400 group-hover:text-gray-700 ${isCurrentLink(d.link) ? 'hidden' : ''}`} />
            </Link>
          ) : (
            <div className="px-2 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-4">
              <dt className="text-sm font-medium text-gray-500 truncate">{d.name}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{d.count}</dd>
            </div>
          )}
        </>
      ))}
    </dl>
  );
};
