import React, { ChangeEvent, HTMLProps, forwardRef } from 'react';
import cn from 'classnames';

type Props = Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'required' | 'disabled'> & {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  containerClassName?: string;
  className?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  children?: React.ReactNode;
};

const BASE_CLASSES = [
  'form-checkbox',
  'border',
  'border-gray-300',
  'transition',
  'duration-150',
  'ease-in-out',
  'rounded',
  'cursor-pointer',
  'h-4',
  'w-4',
  'checked:bg-primary',
  'checked:border-primary',
  'focus:ring-2',
  'focus:ring-blur-200',
  'focus:border-blue-300',
];

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ id, containerClassName, className, onChange, isChecked, isDisabled, children }: Props, ref) => {
    const classes = cn(className, BASE_CLASSES, {
      'opacity-50 pointer-events-none': isDisabled,
    });

    return (
      <label htmlFor={id} className={cn('flex flex-row items-center cursor-pointer', containerClassName)}>
        <input ref={ref} id={id} className={classes} type="checkbox" onChange={onChange} checked={isChecked} disabled={isDisabled} />
        {children}
      </label>
    );
  }
);
