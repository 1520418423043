import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import React from 'react';
import { useTable } from 'react-table';

import { GetGarmentsWithCountQuery, useGetGarmentsWithCountQuery } from '@graphql';

interface Props {
  isLoading: boolean;
  garments: GetGarmentsWithCountQuery['garments'];
}

const columnsData = [
  {
    Header: 'Garment Categories',
    accessor: 'label',
  },
  {
    Header: () => <div className="text-center">Total Design Types</div>,
    accessor: 'designTypeOfferingsCount',
    Cell: ({ value }) => <div className="text-center">{value}</div>,
  },
  {
    Header: () => <div className="text-center">Total Design Options</div>,
    accessor: 'designOptionOfferingsCount',
    Cell: ({ value }) => <div className="text-center">{value}</div>,
  },
];

export const DesignOptionsGarmentCategoriesListTable = (): React.ReactElement => {
  const { data, error, loading } = useGetGarmentsWithCountQuery();

  if (error) return <>Error</>;

  return <GarmentCategoriesListTable isLoading={loading} garments={loading || !data ? Array(5).fill({}) : data.garments} />;
};

const GarmentCategoriesListTable = (props: Props): React.ReactElement => {
  const { isLoading, garments } = props;

  const router = useRouter();

  const columns = React.useMemo(
    () =>
      isLoading
        ? columnsData.map((column) => ({
            ...column,
            Cell: <div className="animate-pulse h-6 rounded bg-gray-200" />,
          }))
        : columnsData,
    [isLoading]
  );

  const tableInstance = useTable({ columns, data: garments });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mt-6">
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200 table-fixed">
        <thead className="bg-gray-50">
          {
            // Loop over the header rows
            headerGroups.map((headerGroup, index) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, i) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()} className="p-4 text-xs font-semibold uppercase tracking-wider text-left" key={i}>
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row, index) => {
              // Prepare the row for display
              prepareRow(row);

              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  className={`cursor-pointer text-gray-500 hover:bg-gray-100 hover:text-gray-900 group ${
                    index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                  }`}
                  onClick={() => router.push(`/design-options/option-types?garmentCategory=${row.original.category}`)}
                  key={row.id}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, index) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()} className="p-4 whitespace-nowrap text-sm text-left" key={index}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};

DesignOptionsGarmentCategoriesListTable.queries = {
  garmentsWithCount: gql`
    query GetGarmentsWithCount {
      garments {
        category
        label
        designTypeOfferingsCount
        designOptionOfferingsCount
      }
    }
  `,
};
