import { TextSkeleton } from 'components';

export const OrderItemProgressSideBarSkeleton = ({ className }: { className?: string }) => {
  return (
    <div className={`bg-neutral-900 rounded p-8 w-72 h-full ${className}`}>
      <div className="flex">
        <TextSkeleton className="h-16 w-16" />
        <div>
          <TextSkeleton className="h-2 w-12 m-2 mt-0" />
          <TextSkeleton className="h-6 w-36 ml-2" />
        </div>
      </div>
      <div className="my-6">
        <TextSkeleton className="h-6 w-24 mb-2" />
        <TextSkeleton className="h-6 w-36 " />
      </div>
      <div className="my-6">
        <TextSkeleton className="h-6 w-24 mb-2" />
        <TextSkeleton className="h-6 w-36 " />
      </div>
      <div className="my-6">
        <TextSkeleton className="h-6 w-24 mb-2" />
        <TextSkeleton className="h-6 w-36 " />
      </div>
    </div>
  );
};
