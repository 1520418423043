import { GetGarmentMeasurementQuery } from '@graphql';
import { ArrayElement } from 'types/common';

type GarmentField = ArrayElement<GetGarmentMeasurementQuery['settings']['measurement']['garment']['fields']>;

export const GarmentInfo = ({ garment }: { garment: GarmentField }) => (
  <div>
    <h3 className="text-2xl font-semibold mb-8">{garment.name}</h3>
    <p className="mb-2 whitespace-break-spaces">{garment.info.text}</p>
    <img className="max-w-md mx-auto" src={garment.info.image} />
  </div>
);
