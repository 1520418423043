import { Menu, Transition } from '@headlessui/react';
import { Fragment, ReactNode, useMemo } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Route } from 'types/routes';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { Button } from 'modules/common';

interface Item {
  name?: string;
  path?: Route;
  query?: { [key: string]: string | string[] };
  component?: ReactNode;
}

interface Props {
  name: string;
  items: Item[];
  align?: 'left' | 'right';
}

const BASE_BUTTON_CLASSES = ['inline-flex', 'items-center', 'justify-center', 'w-full', 'px-3', 'h-8', 'text-sm', 'text-white'];
const BASE_ITEM_CLASSES = [
  'absolute',
  'w-64',
  'mt-2',
  'origin-top-right',
  'bg-gray-900',
  'rounded-md',
  'shadow-lg',
  'ring-1',
  'ring-black',
  'ring-opacity-5',
  'focus:outline-none',
  'z-10',
];

// TODO: re-do this component once we have designs
export const HeaderLinkSelect = ({ name, items, align = 'left' }: Props): React.ReactElement => {
  const router = useRouter();

  const checkIsActive = (link) => router.pathname.includes(link);
  const isMenuButtonActive = useMemo(() => items.find((item) => checkIsActive(item?.path)), [items, router]);

  const menuButtonClasses = cn(BASE_BUTTON_CLASSES, {
    'font-medium bg-gray-700 rounded': isMenuButtonActive,
  });

  const menuItemClasses = cn(BASE_ITEM_CLASSES, {
    'left-0': align === 'left',
    'right-0': align === 'right',
  });

  return (
    <Menu as="div" className="relative inline-block">
      <Menu.Button className={menuButtonClasses}>
        {name}
        <ChevronDownIcon className="w-5 h-5 ml-1 -mr-1 text-gray-500" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={menuItemClasses}>
          {items.map((item, index) => (
            <div className="px-1 py-1" key={index}>
              <Menu.Item>
                {({ close }) => (
                  <>
                    {item?.name && item?.path && (
                      <Button
                        isLink
                        variant="unstyled"
                        href={item?.path}
                        query={item?.query}
                        onClick={close}
                        className={cn('group flex rounded-md items-center w-full px-2 py-2 text-sm text-left text-white', {
                          'bg-gray-700': checkIsActive(item?.path),
                        })}
                      >
                        {item?.name}
                      </Button>
                    )}
                    {item?.component && item?.component}
                  </>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
