import { useState } from 'react';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { gql } from '@apollo/client';
import { useCancelOrderMutation } from '@graphql';
import { Icon, Modal, Textarea } from 'components';
import { Button } from 'modules/common';

interface Props {
  orderId?: string;
  isDisabled?: boolean;
}

export const CancelOrderButton = ({ orderId, isDisabled }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const [cancelOrderMutation, { loading }] = useCancelOrderMutation({
    onCompleted: () => {
      toast.success('Order cancelled.');
      reset();
      setIsModalOpen(false);
    },
    onError: () => {
      toast.success('Something went wrong, please try again.');
    },
  });

  const onSubmit = handleSubmit(async (formData) => {
    await cancelOrderMutation({
      variables: {
        orderId,
        message: formData.message,
      },
    });
  });

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)} className="mr-6" variant="alternative" isText isDisabled={isDisabled}>
        <div className={cn('text-gray-500 flex items-center text-xs', { 'opacity-50': isDisabled })}>
          <Icon icon="cross" className="mr-2" width={12} height={12} />
          Cancel order
        </div>
      </Button>
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <form onSubmit={onSubmit}>
          <div className="font-semibold text-center text-lg mb-6">Cancel order</div>
          <Textarea
            htmlProps={{ placeholder: 'Reason for cancelling order' }}
            register={register('message', {
              required: 'You must provide a reason for cancelling an order.',
            })}
            errorMessage={errors?.message?.message as any}
          />
          <div className="flex justify-end mt-6">
            <Button type="submit" className="ml-2" isDisabled={loading}>
              Submit
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

CancelOrderButton.mutation = gql`
  mutation CancelOrder($orderId: ID!, $message: String!) {
    cancelOrder(orderId: $orderId, message: $message) {
      id
      orderStatus {
        key
        name
        message
      }
      ...OrderTimeline
    }
  }
`;
