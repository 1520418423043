import { gql, useReactiveVar } from '@apollo/client';
import { useOrderItemFooterQuery } from '@graphql';

import { Spinner } from 'components';
import { parseRouteId, PageFooter } from 'modules/common';
import { uiVar } from 'graphql/common';
import { useFeature } from 'hooks';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import router from 'next/router';
import { formatAsCurrency } from 'helpers/reporting-helpers';

interface Props {
  nextButton: React.ReactNode;
  backButton?: React.ReactNode;
  isTemplate: boolean;
}

export const OrderItemFooter = ({ nextButton, backButton, isTemplate }: Props) => {
  const { itemId } = router.query;
  const { data } = useOrderItemFooterQuery({ skip: !itemId, variables: { orderItemId: parseRouteId(itemId), isTemplate } });
  const { tailorSlug, tailor } = useActiveSession();
  const { isValueInAllowList } = useFeature();
  const ui = useReactiveVar(uiVar);

  return (
    <PageFooter>
      <div className="flex justify-end items-center max-w-7xl mx-auto px-8">
        {backButton && <div className="mr-auto">{backButton}</div>}
        {!isTemplate && isValueInAllowList({ feature: 'ff_pricing_allow_list', valueToCheck: tailorSlug }) && (
          <div className="mr-6">
            <div className="text-neutral-600 text-xs text-right">Item Total</div>
            <div className="font-semibold">
              {ui === 'saving' ? (
                <Spinner size="md" />
              ) : (
                formatAsCurrency(
                  data?.orderItem?.remake?.remakeReason ? 0 : data?.orderItem?.retail?.discountedPrice,
                  tailor.locale,
                  tailor.currency,
                  false
                ) || '-'
              )}
            </div>
            {data?.orderItem?.retail?.discountedAmount && data?.orderItem?.retail?.discountedAmount > 0 && (
              <div className="font-semibold line-through">
                {ui === 'saving' ? <></> : formatAsCurrency(data?.orderItem?.retail?.total, tailor.locale, tailor.currency, false) || '-'}
              </div>
            )}
          </div>
        )}
        {nextButton}
      </div>
    </PageFooter>
  );
};

OrderItemFooter.fragments = {
  orderItem: gql`
    fragment OrderItemFooterFragment on OrderItem {
      id
      isTemplate
      remake {
        remakeReason
      }
      retail {
        total
        discountedPrice
        discountedAmount
      }
    }
  `,
};

OrderItemFooter.query = gql`
  ${OrderItemFooter.fragments.orderItem}

  query OrderItemFooter($orderItemId: ID!, $isTemplate: Boolean!) {
    orderItem(orderItemId: $orderItemId, isTemplate: $isTemplate) {
      ...OrderItemFooterFragment
    }
  }
`;
