import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

type QueryTabsProps = {
  queryParam?: string;
  tabs: Array<{ id: string; name: string }>;
};

export const QueryTabs = ({ queryParam = 'tab', tabs }: QueryTabsProps): React.ReactElement => {
  const router = useRouter();
  const currentTab = router.query[queryParam] || tabs?.[0].id;

  return (
    <div className="mt-4">
      <nav className="flex divide-x divide-neutral-300" aria-label="Tabs">
        {tabs.map((tab) => {
          const selected = tab.id === currentTab;
          return (
            <Link key={tab.id} href={{ pathname: '/dashboard', query: { [queryParam]: tab.id } }} legacyBehavior>
              <div
                className={cn(
                  selected ? 'text-neutral-900' : 'border-neutral-300 border-b text-neutral-500 hover:text-neutral-700',
                  'relative cursor-pointer flex-1 overflow-hidden p-4 text-sm text-center hover:bg-neutral-50 focus:z-10'
                )}
              >
                <span className="select-none">{tab.name}</span>
                <div aria-hidden="true" className={cn(selected ? 'bg-yellow-crayola-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5')} />
              </div>
            </Link>
          );
        })}
      </nav>
    </div>
  );
};

export default QueryTabs;
