import { useFormContext, Controller } from 'react-hook-form';
import cn from 'classnames';

import { Icon } from 'components';
import { GarmentObservationTypeSetting } from '@graphql';

const ToggleButton = ({ className: externalClasses, testId, onClick, label, image, isDisabled, side = 'left' }) => {
  const className = cn(
    `flex items-center justify-center flex-wrap lg:flex-nowrap px-3 text-sm border rounded-md`,
    isDisabled ? 'cursor-not-allowed border-gray-300 opacity-40 bg-gray-100 text-gray-500' : 'text-gray-500 border-gray-300',
    { 'py-2': !image },
    externalClasses
  );

  return (
    <button data-testid={testId} className={className} onClick={onClick} disabled={isDisabled} type="button">
      {side === 'left' ? (
        <>
          {image && image}
          <div className="flex justify-between w-full items-center">
            <span className="flex-1 text-gray-500">{label}</span>
            <Icon width={28} icon="minus" />
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between w-full items-center">
            <Icon width={40} icon="plus" />
            <span className="flex-1">{label}</span>
          </div>
          {image && image}
        </>
      )}
    </button>
  );
};

export const ObservationStepper = ({
  observation,
  index,
  onInfoClick = () => null,
  isGarmentDisabled,
}: {
  observation: GarmentObservationTypeSetting;
  index: number;
  onInfoClick?: () => void;
  isGarmentDisabled?: boolean;
}) => {
  const { control } = useFormContext();
  const sortedItems = [...observation.items].sort((a, b) => a.value - b.value);
  const hasDescription = observation.items.some((item) => item.imageUrl || item.description);
  return (
    <Controller
      name={`observations.${index}.option`}
      control={control}
      render={({ field: { onChange, value } }) => {
        const selectedValue = value ? sortedItems.find((i) => i.key === value) : sortedItems.find((i) => i.value === 0);
        const valueIndex = sortedItems.findIndex((i) => i.key === selectedValue?.key);
        const lowerValue = sortedItems[valueIndex - 1];
        const higherValue = sortedItems[valueIndex + 1];
        return (
          <>
            <div className="col-span-1 flex items-center">
              <div className="text-sm text-gray-500">{observation.name}</div>
            </div>
            <div className="col-span-3 flex items-center">
              <ToggleButton
                side="left"
                className="flex-1"
                testId={observation.name}
                label={observation.items[0].name}
                image={<img className="h-16 pt-0.5" style={{ filter: 'hue-rotate(90deg)' }} src={observation.items[0].imageUrl} />}
                isDisabled={!lowerValue || isGarmentDisabled}
                onClick={() => onChange(lowerValue.key)}
              />
              <span className="px-4 w-24 text-center text-xl">{selectedValue?.value || 0}</span>
              <ToggleButton
                side="right"
                className="flex-1"
                testId={observation.name}
                label={observation.items[observation.items.length - 1].name}
                image={<img className="h-16 pt-0.5" src={observation.items[0].imageUrl} />}
                isDisabled={!higherValue || isGarmentDisabled}
                onClick={() => onChange(higherValue.key)}
              />
              {hasDescription ? (
                <button type="button" className="self-center ml-5" onClick={onInfoClick}>
                  <Icon icon="info-stroke" width={12} height={12} />
                </button>
              ) : (
                <div className="w-4 ml-4" />
              )}
            </div>
          </>
        );
      }}
    />
  );
};
