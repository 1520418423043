import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { OrderItemDesign, useOrderItemDesignOptionsPageQuery, useUpdateOrderItemDesignOptionsMutation } from '@graphql';
import TabsWithRoute from 'components/TabsWithRoute';
import { uiVar } from 'graphql/common';
import { Button, TaperLoading } from 'modules/common';
import { DesignOptionsFormFields } from 'modules/designOptions/components/DesignOptionsFormFields';
import { DesignOptionsGroupsMenu } from 'modules/designOptions/components/DesignOptionsGroupsMenu';
import { LINING_OPTIONS, overrideDesignOptions } from 'modules/designOptions/designOptions.helpers';
import { DesignOptionsFormProvider } from 'modules/designOptions/designOptions.provider';
import { DesignOptionKeyValue, OrderItemWithConflict } from 'modules/designOptions/designOptions.types';
import { OrderItemFooter } from 'modules/orderItem';
import mixpanelService from 'services/mixpanel.service';
import { DeepPartial } from 'types/common';
import { useDesignOptionsForm } from 'modules/designOptions/designOptions.context';
import { DesignOptions } from 'modules/designOptions/designOptionsConflict.gql';
import { omit } from 'lodash';

const getTabRoute = (design: DeepPartial<OrderItemDesign>) => design?.garmentCategory.key.toLowerCase();

export const OrderItemDesignOptionsTabs = ({ isTemplate = false }: { isTemplate: boolean }) => {
  const router = useRouter();
  const { orderId, itemId, tab } = router.query;
  const { data, loading: isLoading } = useOrderItemDesignOptionsPageQuery({
    variables: { orderItemId: itemId as string, isTemplate },
  });
  const [updateDesignOptionsMutation] = useUpdateOrderItemDesignOptionsMutation();
  const [selectedDesignId, setSelectedDesignId] = useState('');
  const designs = data?.orderItem.designs || [];

  useEffect(() => {
    if (!designs) return;
    if (!tab) {
      // route to first tab if tab not set
      const tabRoute = getTabRoute(designs[0]);
      if (!tabRoute || tabRoute === 'undefined') return;

      router.push(
        isTemplate ? `/design-templates/${itemId}/design-options/${tabRoute}` : `/orders/${orderId}/order-item/${itemId}/design-options/${tabRoute}`
      );
    } else {
      setSelectedDesignId(designs.find((d) => getTabRoute(d) === String(tab))?.id);
    }
  }, [designs, tab]);

  const handleSave = async (options: DesignOptionKeyValue[], orderItem: OrderItemWithConflict) => {
    try {
      uiVar('saving');
      const design = orderItem.designs.find((design) => design.id === selectedDesignId);

      const { id, options: oldOptions } = design;

      await updateDesignOptionsMutation({
        variables: {
          orderItemId: String(itemId),
          orderItemDesignId: id,
          orderItemDesignInput: { options: options.map((o) => omit(o, 'id')) },
          isTemplate,
        },
        optimisticResponse: {
          updateOrderItemDesign: {
            id,
            ...design,
            options: overrideDesignOptions(oldOptions, options),
            __typename: 'OrderItemDesign',
            orderItem: {
              id,
              estimatedDeliveryDate: {
                formatted: orderItem.estimatedDeliveryDate?.formatted || null,
              },
              retail: {
                total: orderItem?.retail?.total || 0,
                discountedPrice: orderItem?.retail?.discountedPrice || 0,
                discountedAmount: orderItem?.retail?.discountedAmount || 0,
              },
            },
          },
        },
      });
    } catch (e) {
      toast.error(`${e?.message ?? `Couldn't save update.`}`, { autoClose: false });
      console.error(e);
    } finally {
      uiVar('ready');
    }
  };

  const handleNext = () => {
    const currentTabIndex = designs.map((d) => d.id).indexOf(selectedDesignId);
    const nextPageRoute = currentTabIndex === designs.length - 1 ? 'summary' : `design-options/${getTabRoute(designs[currentTabIndex + 1])}`;
    mixpanelService.track('ORDER_ITEM_NEXT_BUTTON', { from: 'design-options', to: nextPageRoute });

    router.push(isTemplate ? `/design-templates/${itemId}/${nextPageRoute}` : `/orders/${orderId}/order-item/${itemId}/${nextPageRoute}`);
  };

  const handleBack = () => {
    const currentTabIndex = designs.map((d) => d.id).indexOf(selectedDesignId);
    const backPageRoute = currentTabIndex < 1 ? 'fabric' : `design-options/${getTabRoute(designs[currentTabIndex - 1])}`;

    router.push(isTemplate ? `/design-templates/${itemId}/${backPageRoute}` : `/orders/${orderId}/order-item/${itemId}/${backPageRoute}`);
  };

  if (isLoading) return <TaperLoading isCentred />;
  if (!data?.orderItem) return null;
  return (
    <DesignOptionsFormProvider
      isTemplate={isTemplate}
      key={selectedDesignId}
      onSave={handleSave}
      ignoreFields={LINING_OPTIONS /** We ignore it because is not handled in the design options tab */}
      selectedDesignId={selectedDesignId}
    >
      {designs.length === 1 ? (
        <DesignOptionsScreen />
      ) : (
        <div className="relative h-full">
          <TabsWithRoute
            buttonClassName="w-full"
            tabsClassName="border-b border-gray-200 absolute w-full pt-5 bg-white z-10"
            panelsClassName="h-full pt-12"
            panelClassName="h-full"
            tabs={[...designs].map((design) => {
              return {
                name: design.garmentCategory.name,
                route: getTabRoute(design),
                onChangeTab: () => setSelectedDesignId(design.id),
                content: <DesignOptionsScreen />,
              };
            })}
          />
        </div>
      )}
      <OrderItemFooter
        isTemplate={isTemplate}
        backButton={
          <Button variant="neutral" onClick={handleBack}>
            Previous
          </Button>
        }
        nextButton={<Button onClick={handleNext}>Next</Button>}
      />
    </DesignOptionsFormProvider>
  );
};

OrderItemDesignOptionsTabs.fragments = {
  orderItem: gql`
    ${DesignOptions.fragments.conflicts}
    fragment OrderItemDesignOptionsPageFragment on OrderItem {
      id
      productCategory
      isTemplate
      designs {
        id
        garmentCategory {
          key
          name
        }
        conflicts {
          ...DesignOptionsFormProviderConflictsFragment
        }
      }
      estimatedDeliveryDate {
        formatted(format: "ll")
      }
      retail {
        total
        discountedPrice
        discountedAmount
      }
    }
  `,
};

OrderItemDesignOptionsTabs.query = gql`
  ${OrderItemDesignOptionsTabs.fragments.orderItem}

  query OrderItemDesignOptionsPage($orderItemId: ID!, $isTemplate: Boolean!) {
    orderItem(orderItemId: $orderItemId, isTemplate: $isTemplate) {
      ...OrderItemDesignOptionsPageFragment
    }
  }
`;

OrderItemDesignOptionsTabs.mutation = gql`
  ${DesignOptions.fragments.conflicts}

  mutation UpdateOrderItemDesignOptions(
    $orderItemId: ID!
    $orderItemDesignId: ID!
    $orderItemDesignInput: OrderItemDesignInput!
    $isTemplate: Boolean!
  ) {
    updateOrderItemDesign(
      orderItemId: $orderItemId
      orderItemDesignId: $orderItemDesignId
      orderItemDesignInput: $orderItemDesignInput
      isTemplate: $isTemplate
    ) {
      id
      options {
        id
        value
        typeCode
        price
      }
      conflicts {
        ...DesignOptionsFormProviderConflictsFragment
      }
      orderItem {
        id
        estimatedDeliveryDate {
          formatted(format: "ll")
        }
        retail {
          total
          discountedPrice
          discountedAmount
        }
      }
      __typename
    }
  }
`;

const DesignOptionsScreen = () => {
  const { loading } = useDesignOptionsForm();

  if (loading) return <TaperLoading isCentred />;

  return (
    <div className="relative flex h-full overflow-hidden">
      <div className="flex w-full pt-4">
        <DesignOptionsGroupsMenu />
        <DesignOptionsFormFields />
      </div>
    </div>
  );
};
