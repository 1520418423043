import { gql, useReactiveVar } from '@apollo/client';
import {
  OrderPageQuery,
  useProcessOrderSetOrderItemMeasurementMutation,
  useSetOrderReadyToProcessMutation,
  useUpdateOrderStatusMutation,
  OrderItemStatus,
  OrderStatus,
} from '@graphql';
import { Icon, Modal, Tooltip } from 'components';
import { Button, parseRouteId } from 'modules/common';
import { uiVar } from 'graphql/common';
import { orderRequirementDisplayName } from 'helpers/order-helpers';
import { useRouter } from 'next/router';
import { MouseEvent, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { orderService } from 'services/order.service';
import { OrderTimeline } from './OrderTimeline';
import { useActiveSession } from 'hooks/useActiveSessionContext';

interface Props {
  order: OrderPageQuery['order'];
}

export const ProcessCompleteOrderButton = ({ order }: Props) => {
  const { isSuperAdmin } = useActiveSession();
  const router = useRouter();
  const { orderId } = router.query;
  const ui = useReactiveVar(uiVar);
  const [isConfirmProcessOrderModalOpen, setIsConfirmProcessOrderModalOpen] = useState(false);
  const [isProcessOrderErrorModalOpen, setIsProcessOrderErrorModalOpen] = useState(false);
  const [processOrderErrors, setProcessOrderErrors] = useState<string[]>([]);

  const [setOrderReadyToProcess] = useSetOrderReadyToProcessMutation();
  const [setOrderItemDesignMeasurement] = useProcessOrderSetOrderItemMeasurementMutation();
  const [updateOrderStatus] = useUpdateOrderStatusMutation();

  const isOrderReadyToProcess = useMemo(() => {
    return order.outstandingRequirements.status === 'COMPLETE';
  }, [order.outstandingRequirements.status]);

  const isOrderReadyToComplete = useMemo(() => {
    if (
      order.items.every(
        (item) =>
          item.itemStatus.key === OrderItemStatus.Delivered ||
          item.itemStatus.key === OrderItemStatus.Shipped ||
          item.itemStatus.key === OrderItemStatus.LeftFactory ||
          item.itemStatus.key === OrderItemStatus.Error ||
          item.itemStatus.key === OrderItemStatus.Cancelled
      )
    ) {
      return true;
    }
    return false;
  }, [order?.items]);

  const getTooltipContent = () => {
    return (
      <div className="w-64 p-4 text-sm text-gray-900 text-left">
        <div className="whitespace-nowrap">This order has been processed.</div>
        <div className="text-gray-500">
          Changes or cancellations can&apos;t be processed after fabric has been cut. There can be a delay between order submission and fabric
          cutting, meaning on occasion changes are possible after the deadline. Get in touch at
          <a href="mailto:orders@jerome.com" className="text-blue-600">
            {' '}
            orders@jerome.com{' '}
          </a>
          to find out.
        </div>
      </div>
    );
  };

  const getOrderNotCompleteTooltipContent = () => {
    const requirements = orderRequirementDisplayName(order.outstandingRequirements.requirements);
    return (
      <div className="p-2 w-max text-left text-base">
        <p className="font-semibold">To process this order:</p>
        {requirements.map((requirement, i) => (
          <p key={i} className="mt-2">
            <Icon icon="danger-stroke" className="text-fire-500" /> <span className="ml-1">{requirement}</span>
          </p>
        ))}
      </div>
    );
  };

  const handleClickProcessOrder = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      uiVar('saving');

      const parsedOrderId = parseRouteId(orderId);

      // Ensure all measurement are up-to-date
      for (let i = 0; i < order.items.length; i++) {
        // TODO: this won't pass through fit to orderItemMeasurementInput. Need to review this if supporting more than just try on measurements.
        for (let j = 0; j < order.items[i].designs.length; j++) {
          if (order.items[i].designs[j].measurement.createdFrom != null) {
            await setOrderItemDesignMeasurement({
              variables: {
                orderId: parsedOrderId,
                orderItemDesignId: order.items[i].designs[j].id,
                // TODO: make sure to copy order.items[i].measurement.createdFrom on fulfilment service when flattening order items
                orderItemMeasurementInput: { measurementId: order.items[i].designs[j].measurement.createdFrom.id },
              },
            });
          }
        }
      }

      await setOrderReadyToProcess({ variables: { orderId: parsedOrderId } });
      uiVar('ready');
    } catch (error) {
      if (!error?.message) {
        toast.error('Unable to update order.');
      }
      uiVar('ready');
      setProcessOrderErrors(error?.message?.split('\n'));
      setIsProcessOrderErrorModalOpen(true);
    } finally {
      setIsConfirmProcessOrderModalOpen(false);
    }
  };

  const handleUpdateOrderStatus = async (event: MouseEvent<HTMLButtonElement>, newStatus: OrderStatus) => {
    event.preventDefault();
    try {
      uiVar('saving');
      await updateOrderStatus({
        variables: {
          orderId: parseRouteId(orderId),
          updateOrderInput: {
            orderStatus: newStatus,
          },
        },
      });

      uiVar('ready');

      toast.success('Order updated.');
      setIsConfirmProcessOrderModalOpen(false);
    } catch {
      toast.error('Unable to update order.');
      uiVar('ready');
    }
  };

  return (
    <>
      {order.orderStatus.key === 'DRAFT' && (
        <div>
          {isOrderReadyToProcess && (
            <Button className="flex items-center py-3" onClick={() => setIsConfirmProcessOrderModalOpen(true)} isDisabled={ui === 'saving'}>
              Process order <Icon icon="right-arrow" className="ml-2" />
            </Button>
          )}
          {!isOrderReadyToProcess && (
            <Tooltip content={getOrderNotCompleteTooltipContent()}>
              <Button className="flex items-center py-3" isDisabled>
                Process order <Icon icon="right-arrow" className="ml-2" />
              </Button>
            </Tooltip>
          )}
        </div>
      )}
      {order.orderStatus.key === OrderStatus.ReadyToProcess && (
        <div className="flex items-center">
          <Icon icon="clock" className="mr-1 text-indigo-600" />
          {order.readyToProcessAt?.fromNow ? (
            <p>
              {order.readyToProcessAt.fromNow.includes('ago') ? (
                <>Order was submitted for processing {order.readyToProcessAt.fromNow}</>
              ) : (
                <>
                  Order will be <strong>closed</strong> {order.readyToProcessAt.fromNow}
                </>
              )}
            </p>
          ) : (
            'This order will be processed soon.'
          )}
        </div>
      )}
      {order.orderStatus.key === OrderStatus.Processing && (
        <>
          {!isOrderReadyToComplete && (
            <div className="flex items-center">
              <Tooltip content={getTooltipContent()} className="mr-1 mb-1">
                <Icon icon="info-filled" className="text-red-500" />
              </Tooltip>
              This order has been submitted and is processing.
            </div>
          )}
          {isOrderReadyToComplete && (
            <div className="flex items-center">
              <Icon icon="truck-filled" className="text-blue-600 mr-3" />
              {order.items.find((item) => item.itemStatus.key === 'ERROR') ? 'This order contains error(s).' : 'This order has been shipped'}
              <Button
                className="flex items-center py-3 ml-6"
                onClick={(event) => handleUpdateOrderStatus(event, OrderStatus.Complete)}
                isDisabled={ui === 'saving'}
              >
                {order.items.find((item) => item.itemStatus.key === 'ERROR') ? 'Close order' : 'Complete order'}
                <Icon icon="completed" className="ml-3" />
              </Button>
            </div>
          )}
        </>
      )}
      {order.orderStatus.key === OrderStatus.Complete && isSuperAdmin && (
        <div className="flex items-center">
          <Icon icon="completed" className="mr-3 text-emerald-500" />
          This order has been completed
          <Button
            className="flex items-center py-3 ml-6"
            onClick={(event) => handleUpdateOrderStatus(event, OrderStatus.Processing)}
            isDisabled={ui === 'saving'}
          >
            Re-open
          </Button>
        </div>
      )}
      {order.orderStatus.key === OrderStatus.Archived && <div>This order has been archived.</div>}
      {order.orderStatus.key === OrderStatus.Error && (
        <div>{`There was an error with this order. ${orderService.parseDayangOrJeromeError(order.orderStatus?.message) || ''}`}</div>
      )}
      {order.orderStatus.key === OrderStatus.Cancelled && <div>This order has been cancelled.</div>}
      <Modal isOpen={isConfirmProcessOrderModalOpen} setIsOpen={setIsConfirmProcessOrderModalOpen} title="Process Order Confirm">
        <div className="flex">This will begin processing the order and cannot be undone.</div>
        <div className="flex w-full justify-end mt-6">
          <Button onClick={() => setIsConfirmProcessOrderModalOpen(false)} variant="neutral" isDisabled={ui === 'saving'} className="mr-2">
            Cancel
          </Button>
          <Button onClick={handleClickProcessOrder} isDisabled={ui === 'saving'}>
            {ui === 'saving' ? 'Updating...' : 'Yes, process order'}
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={isProcessOrderErrorModalOpen}
        setIsOpen={setIsProcessOrderErrorModalOpen}
        title="Process Order Error(s)"
        titleClassNames="normal-case"
      >
        <div className="flex justify-center items-center mt-2">
          {processOrderErrors.length < 2
            ? 'There was an error when trying to process order:'
            : 'There were some errors when trying to process order:'}
        </div>
        <div className="text-red-500 mt-6">
          <ul className="mx-8 list-disc">
            {processOrderErrors.map((error, index) => (
              <li className="mt-2 first:mt-0" key={`process-error-${index}`}>
                {error}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-end mt-6">
          <Button variant="alternative" onClick={() => setIsProcessOrderErrorModalOpen(false)}>
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

ProcessCompleteOrderButton.fragments = {
  root: gql`
    fragment ProcessCompleteOrderButton on Order {
      id
      orderStatus {
        key
        name
        message
      }
      outstandingRequirements {
        status
        requirements
      }
      readyToProcessAt {
        fromNow
      }
      items {
        id
        itemStatus {
          key
        }
      }
    }
  `,
};

ProcessCompleteOrderButton.mutations = {
  setOrderReadyToProcess: gql`
    ${ProcessCompleteOrderButton.fragments.root}
    ${OrderTimeline.fragments.root}

    mutation SetOrderReadyToProcess($orderId: ID!) {
      setOrderReadyToProcess(orderId: $orderId) {
        ...ProcessCompleteOrderButton
        ...OrderTimeline
      }
    }

    mutation ProcessOrderSetOrderItemMeasurement($orderId: ID!, $orderItemMeasurementInput: OrderItemMeasurementInput!, $orderItemDesignId: ID!) {
      setOrderItemDesignMeasurement(
        orderId: $orderId
        orderItemDesignId: $orderItemDesignId
        orderItemDesignMeasurementInput: $orderItemMeasurementInput
      ) {
        id
      }
    }
  `,
  updateOrderStatus: gql`
    ${OrderTimeline.fragments.root}

    mutation UpdateOrderStatus($orderId: ID!, $updateOrderInput: UpdateOrderInput!) {
      updateOrder(orderId: $orderId, updateOrderInput: $updateOrderInput) {
        orderStatus {
          key
          name
          message
        }
        ...OrderTimeline
      }
    }
  `,
};
