import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import cn from 'classnames';
import { gql } from '@apollo/client';

import { Table, Column, Pagination } from 'components/Table';
import { useGetAlterationsQuery, GetAlterationsQueryResult, AlterationStatus } from '@graphql';
import { serializeArrayQueryParams } from 'helpers/string-helpers';
import { ArrayElement } from 'types/common';

type Alteration = ArrayElement<GetAlterationsQueryResult['data']['alterations']['items']>;

const OrderTd = (info) => {
  const id = info.getValue() as Alteration['id'];
  const customId = info.row.original.order.customId;

  return (
    <Link className="font-semibold text-blue-500 hover:text-blue-600" href={`/alterations/${id}`}>
      {customId}
    </Link>
  );
};

const Status = (info) => {
  const { key, name } = info.getValue() as Alteration['status'];

  return (
    <div
      className={cn('font-semibold', {
        'text-emerald-500': key === AlterationStatus.Completed,
        'text-amber-500': key === AlterationStatus.InProgress,
        'text-red-500': key === AlterationStatus.Cancelled,
      })}
    >
      {name}
    </div>
  );
};

const CreatedByTd = (info) => {
  const { id, firstName, lastName } = info.getValue() as Alteration['createdBy'];

  return (
    <Link className="font-semibold text-blue-500 hover:text-blue-600" href={`/customers/${id}`}>
      {firstName} {lastName}
    </Link>
  );
};

const CreatedAtTd = (info) => {
  const createdAt = info.getValue() as Alteration['createdAt']['origin'];

  return new Date(createdAt).toLocaleDateString();
};

export const AlterationsListTable = (): React.ReactElement => {
  const { query } = useRouter();

  const { data, loading } = useGetAlterationsQuery({
    variables: {
      orderId: query?.search as string,
      alterationProviderId: query?.provider as string,
      createdByUserId: query?.user as string,
      createdAt: query?.from && query?.to ? { from: query?.from, to: query?.to } : undefined,
      alterationStatus: serializeArrayQueryParams(query?.alterationStatus) as AlterationStatus[],
      pagination: { pageSize: Number(query?.pageSize) || 10, page: Number(query?.page) || 1 },
    },
    fetchPolicy: 'cache-first',
  });

  return (
    <Table<Alteration> data={data?.alterations?.items || []} loading={loading} className="text-center">
      <Column<Alteration> accessor="id" header="Order #" cell={OrderTd} />
      <Column<Alteration> accessor="provider.name" header="Provider" />
      <Column<Alteration> accessor="status" header="Status" cell={Status} />
      <Column<Alteration> accessor="items" header="Items" cell={(info) => info.getValue().length} />
      <Column<Alteration> accessor="createdBy" header="Created By" cell={CreatedByTd} />
      <Column<Alteration> accessor="createdAt.origin" header="Created At" cell={CreatedAtTd} />
      <Pagination count={data?.alterations?.items.length} total={data?.alterations?.total} />
    </Table>
  );
};

AlterationsListTable.queries = {
  alterations: gql`
    query GetAlterations(
      $orderId: ID
      $alterationProviderId: ID
      $createdByUserId: ID
      $measuredByUserId: ID
      $createdAt: DateRangeInput
      $alterationStatus: [AlterationStatus!]
      $showrooms: [String!]
      $garmentCategory: String
      $pagination: PaginationInput
    ) {
      alterations(
        orderId: $orderId
        alterationProviderId: $alterationProviderId
        createdByUserId: $createdByUserId
        measuredByUserId: $measuredByUserId
        createdAt: $createdAt
        alterationStatus: $alterationStatus
        showrooms: $showrooms
        garmentCategory: $garmentCategory
        pagination: $pagination
      ) {
        items {
          id
          status {
            key
            name
          }
          provider {
            id
            name
          }
          showroom {
            name
          }
          createdAt {
            origin
          }
          createdBy {
            id
            firstName
            lastName
          }
          items {
            orderItemDesign {
              garmentCategory {
                key
                name
              }
              measurement {
                createdFrom {
                  createdBy {
                    firstName
                    lastName
                  }
                }
              }
            }
            options {
              id
              name
              value
              cost {
                amount
              }
            }
          }
          order {
            customId
            showroom {
              name
            }
          }
        }
        total
      }
    }
  `,
};
