import { gql } from '@apollo/client';

import { useGetLiningPricingQuery, useSetupLiningPricingMutation } from '@graphql';
import { OnPriceClick } from 'modules/pricing/pricing.types';
import { PricingTab } from 'modules/pricing/components/PricingTab';

interface Props {
  onPriceClick: OnPriceClick;
}

export const LiningTab = (props: Props) => {
  const { data, loading: isLoading } = useGetLiningPricingQuery();
  const [setupPricing] = useSetupLiningPricingMutation({ refetchQueries: ['GetLiningPricing'] });

  return <PricingTab type="Lining" data={data} isLoading={isLoading} onSetupClick={setupPricing} {...props} />;
};

LiningTab.fragments = {
  tier: gql`
    fragment LiningTier on LiningTier {
      id
      name
      isCutLength
    }
  `,
};

LiningTab.queries = {
  root: gql`
    query GetLiningPricing {
      prices: liningRetailPrices {
        id
        amount
        productCategory
        tier: liningTier {
          ...LiningTier
        }
      }
      settings {
        productCategories {
          key
          truncatedName
        }
      }
      tiers: liningTiers {
        ...LiningTier
      }
    }
  `,
};

LiningTab.mutation = {
  setupLiningPricing: gql`
    mutation SetupLiningPricing($productCategories: [ProductCategory!]!) {
      setupLiningPricing(productCategories: $productCategories) {
        id
        amount
        productCategory
        tier: liningTier {
          ...LiningTier
        }
      }
    }
  `,
};
