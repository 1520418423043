import { StepContainer } from '../StepContainer';
import { Select } from 'components/Select';
import { Textarea } from 'components';
import { REMAKE_OPTIONS } from '../../constants';
import { RemakeItem } from '../RemakeState';
import { RemakeReason } from '@graphql';

type Props = {
  items: RemakeItem[];
  onReasonChange: (orderItemId: string, reason: RemakeReason) => void;
  onReasonDescriptionChange: (orderItemId: string, description: string) => void;
  onBackClick: () => void;
  onNextClick: () => void;
};

export const RemakeOrderItemReasonForm = ({ items, onReasonChange, onReasonDescriptionChange, onBackClick, onNextClick }: Props) => {
  return (
    <StepContainer
      contentClassName="divide-y mt-2"
      onBackClick={onBackClick}
      onNextClick={onNextClick}
      nextText="Confirm"
      isNextButtonDisabled={items.filter((i) => i.isChecked).some((i) => !i.remakeReason)}
    >
      {items
        .filter((i) => i.isChecked)
        .map((item) => (
          <div key={item.orderItemId} className="pb-4">
            <p>{item.title}</p>
            <p className="text-sm text-gray-500">
              {item.designs
                .filter((d) => d.isChecked)
                .map((d) => d.title)
                .join(', ')}
            </p>

            <div className="my-2">
              <Select
                label="Remake reason"
                htmlProps={{
                  id: 'type',
                  value: item.remakeReason,
                  onChange: (e) => onReasonChange(item.orderItemId, e.target.value as RemakeReason),
                }}
              >
                <option value="" disabled>
                  Please select a reason
                </option>
                {REMAKE_OPTIONS.map((option) => (
                  <option value={option.key} key={option.key}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </div>
            <div className="my-2">
              <Textarea
                label="Additional information"
                htmlProps={{ value: item.remakeDescription, onChange: (e) => onReasonDescriptionChange(item.orderItemId, e.target.value) }}
              />
            </div>
          </div>
        ))}
    </StepContainer>
  );
};
