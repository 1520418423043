import React, { ReactNode } from 'react';
import { Spinner } from './Spinner';
import cn from 'classnames';
import { useReactiveVar } from '@apollo/client';
import { uiVar } from 'graphql/common/use-query-local-common.graphql';
import { TextSkeleton } from '.';

interface BoxProps {
  title?: string;
  isLoading?: boolean;
  children: React.ReactNode;
  hasPadding?: boolean;
  allowSkeletonLoading?: boolean;
  backgroundColor?: string;
  roundedStyle?: string;
  shadowStyle?: string;
}

export const BoxHeader = ({ children }: { children: ReactNode }) => {
  return <div className="bg-gray-50 py-3 px-6 border-b border-gray-200 rounded-t">{children}</div>;
};

export const BoxFooter = ({ children }: { children: ReactNode }) => {
  return <div className="shadow bg-gray-50 py-3 px-6 border-t border-gray-200 rounded-b">{children}</div>;
};

const Box = ({
  title,
  isLoading,
  children,
  hasPadding = true,
  allowSkeletonLoading = false,
  backgroundColor = 'bg-white',
  roundedStyle = 'rounded',
  shadowStyle = 'shadow',
}: BoxProps): React.ReactElement => {
  const ui = useReactiveVar(uiVar);

  const classes = cn('relative', backgroundColor, roundedStyle, shadowStyle, {
    'p-6': hasPadding,
  });

  return (
    <div className={classes}>
      {isLoading ? (
        <div className="w-full flex items-center justify-center h-40">
          <Spinner size="lg" />
        </div>
      ) : (
        <>
          {title && (
            <div className="flex items-start justify-between pb-6">
              <div className="text-lg">
                {ui === 'loading' && allowSkeletonLoading && <TextSkeleton className="w-32 h-8" />}
                {ui !== 'loading' && title}
              </div>
            </div>
          )}

          {children}
        </>
      )}
    </div>
  );
};

export { Box };
