import { OrderItem } from '../types';

type Props = {
  fabricsDeltas: OrderItem['remake']['delta']['fabricsDelta'];
};

export const RemakeFabricDetailSection = ({ fabricsDeltas }: Props) => {
  const liningDelta = fabricsDeltas.find((f) => f.type === 'lining');
  const fabricDelta = fabricsDeltas.find((f) => f.type === 'fabric');

  return (
    <div className="flex flex-col border-b py-4 mx-4">
      {fabricsDeltas.length === 0 && <span className="text-gray-400 text-sm">Fabric and lining remain the same</span>}
      {liningDelta && (
        <div>
          <span className="text-sm">Lining Removed: </span>
          <span className="text-sm text-red-600 font-semibold line-through">{liningDelta.name}</span>
        </div>
      )}
      {fabricDelta && (
        <div>
          <span className="text-sm">Fabric Removed: </span>
          <span className="text-sm text-red-600 font-semibold line-through">{fabricDelta.name}</span>
        </div>
      )}
    </div>
  );
};
