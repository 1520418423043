import { ReactNode } from 'react';
import { useRouter } from 'next/router';

import { useActiveSession } from 'hooks/useActiveSessionContext';

type Props = {
  children: ReactNode;
};

const AdminProtected = ({ children }: Props) => {
  const router = useRouter();
  const { loading, isAdmin, isSuperAdmin } = useActiveSession();

  if (loading) return null;

  if (!isSuperAdmin && !isAdmin) {
    router.push('/dashboard');
    return null;
  }

  return <>{children}</>;
};

export { AdminProtected };
