import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React, { Fragment } from 'react';
import { Button } from 'modules/common';
import cn from 'classnames';

export interface ModalProps {
  className?: string;
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  children: React.ReactNode;
  submitButton?: React.ReactNode;
  alignTop?: boolean;
  onClose?: () => void;
  titleClassNames?: string;
}

const CONTENT_BASE_CLASSES = [
  'inline-block',
  'align-bottom',
  'bg-white',
  'rounded-lg',
  'text-left',
  'overflow-hidden',
  'shadow-xl',
  'transform',
  'transition-all',
  'sm:my-8',
  'sm:align-middle',
  'align-top',
  'sm:max-w-xl',
  'sm:w-full',
];

const TITLE_BASE_CLASSES = ['text-lg', 'leading-6', 'font-medium', 'text-gray-900', 'mb-4', 'capitalize'];

const Modal = (props: ModalProps): React.ReactElement => {
  const { className, isOpen, setIsOpen, title, children, submitButton, alignTop, onClose, titleClassNames } = props;

  const contentClassName = cn(CONTENT_BASE_CLASSES, className);
  const titleClassName = cn(TITLE_BASE_CLASSES, titleClassNames);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={onClose || setIsOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {!alignTop && (
            // This element is to trick the browser into centering the modal contents.
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
          )}

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={contentClassName}>
              <div className="px-4 pt-5 pb-4 sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <Button onClick={onClose || (() => setIsOpen(false))} aria-label="Close" isText>
                    <XIcon className="h-6 w-6" />
                  </Button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    {title && (
                      <Dialog.Title as="h3" className={titleClassName}>
                        {title}
                      </Dialog.Title>
                    )}

                    {children}
                  </div>
                </div>
              </div>

              {submitButton && (
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  {submitButton}

                  <div className="mr-2">
                    <Button variant="neutral" onClick={onClose || (() => setIsOpen(false))}>
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export { Modal };
