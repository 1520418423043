import { generateFabricPriceCsv } from './helpers';
import { IndividualPricingState } from '../IndividualPricingState';
import cn from 'classnames';
import { ArrowCircleDownIcon } from '@heroicons/react/outline';

type Props = {
  rows: IndividualPricingState['rows'];
  isDisabled: boolean;
};

export const ExportCsvButton = ({ rows, isDisabled }: Props) => {
  return (
    <a href={generateFabricPriceCsv(rows)} target="_blank" aria-disabled={isDisabled} download="individual_fabric_prices.csv" rel="noreferrer">
      <div
        className={cn('flex items-center text-xs', {
          'text-gray-500': !isDisabled,
          'text-gray-300': isDisabled,
        })}
      >
        <ArrowCircleDownIcon className="h-6 w-6 mr-2" />
        Export
      </div>
    </a>
  );
};
