import { gql } from '@apollo/client';

import {
  DesignOptionsFormProviderOrderItemQueryResult,
  OrderItem,
  useDesignOptionsFormProviderOrderItemQuery,
  useUpdateOrderItemFabricMutation,
} from '@graphql';
import { Tabs } from 'components';
import TabsWithRoute from 'components/TabsWithRoute';
import {
  CustomLiningForm,
  Fabric,
  FabricOrLining,
  FabricOrLiningObj,
  Lining,
  LiningList,
  isCmtLiningOptionSelected,
  updateDesignsWithCmtLiningOptions,
} from 'modules/fabric';
import { FabricList } from 'modules/fabric/components/FabricList';
import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Button, TaperLoading, parseRouteId } from 'modules/common';
import { OrderItemFooter } from 'modules/orderItem';
import { uiVar } from 'graphql/common';
import { toast } from 'react-toastify';
import { useThrottledCallback } from 'use-debounce';
import mixpanelService from 'services/mixpanel.service';
import { useDesignOptionsForm } from 'modules/designOptions/designOptions.context';
import { omit } from 'lodash';
import { DesignOptions } from 'modules/designOptions/designOptionsConflict.gql';

interface Props {
  onSaveFabricOrLining: (
    code: FabricOrLiningObj,
    fabricOrLining: FabricOrLining,
    orderItem: DesignOptionsFormProviderOrderItemQueryResult['data']['orderItem']
  ) => void;
}

export const OrderItemFabricTabs = ({ isTemplate = false }: { isTemplate?: boolean }) => {
  const router = useRouter();
  const { itemId } = router.query;
  const [productRequiresLining, setProductRequiresLining] = useState(true);

  const { data, loading: isLoading } = useDesignOptionsFormProviderOrderItemQuery({
    variables: { orderItemId: parseRouteId(itemId), isTemplate },
    fetchPolicy: 'cache-first',
    onCompleted(data) {
      setProductRequiresLining(data.orderItem.productCategorySetting.requiresLining);
    },
  });

  const [updateFabricMutation] = useUpdateOrderItemFabricMutation();

  // Only ever called on Fabric and Lining (Standard tab) - never called on Lining (CMT) tab
  const handleSaveFabricOrLining: Props['onSaveFabricOrLining'] = useThrottledCallback(
    async (fabricLining, fabricOrLining, orderItem: OrderItem) => {
      try {
        uiVar('saving');

        const { id, liningOptions } = orderItem;

        const currentlyHasCmtLining = isCmtLiningOptionSelected(liningOptions);

        const newCmtLiningOptions = fabricOrLining === Lining && currentlyHasCmtLining ? liningOptions.cmtDefaultOptions : null;

        let fabricId = fabricLining.id;
        let liningCode = fabricLining.code;

        // deselect fabric or lining if same tile clicked
        if (fabricId === orderItem.fabric?.id) {
          fabricId = null;
        }

        if (liningCode === orderItem.lining?.code) {
          liningCode = null;
        }

        await updateFabricMutation({
          variables: {
            orderItemId: id,
            orderItemInput: {
              ...(orderItem.fabric?.id && { fabric: orderItem.fabric.id }),
              ...(orderItem.lining?.id && { lining: orderItem.lining.code }),
              // [fabricOrLining]: null,
              [fabricOrLining]: fabricOrLining === Fabric ? fabricId : liningCode,
              ...(newCmtLiningOptions && { liningOptions: newCmtLiningOptions.map((opt) => omit(opt, '__typename')) }),
            },
            isTemplate,
          },
          optimisticResponse: {
            // @ts-ignore
            updateOrderItem: {
              id,
              ...orderItem,
              [fabricOrLining]: {
                ...fabricLining,
                __typename: fabricOrLining === Fabric ? 'CoreFabric' : 'CoreLining',
              },
              ...(newCmtLiningOptions && {
                liningOptions: {
                  ...orderItem.liningOptions,
                  cmtOptions: newCmtLiningOptions,
                },
              }),
              // Because lining options are stored in design options, we need to update design option apollo cache
              ...(newCmtLiningOptions && {
                designs: updateDesignsWithCmtLiningOptions(newCmtLiningOptions, orderItem.designs),
              }),
              estimatedDeliveryDate: {
                formatted: orderItem.estimatedDeliveryDate?.formatted || null,
              },
              retail: {
                total: orderItem?.retail?.total || 0,
                discountedAmount: orderItem?.retail?.discountedAmount || 0,
                discountedPrice: orderItem?.retail?.discountedPrice || 0,
              },
              __typename: 'OrderItem',
            },
          },
        });
      } catch (e) {
        toast.error(`${e?.message ?? `Couldn't save update.`}`, { autoClose: false });
        console.error(e);
      } finally {
        uiVar('ready');
      }
    },
    500, // prevent double clicks
    { trailing: false }
  );

  if (isLoading) return <TaperLoading isCentred />;

  return (
    <div className="my-6">
      {productRequiresLining ? (
        <TabsWithRoute
          buttonClassName="w-full"
          tabs={[
            {
              name: 'Fabric',
              route: 'fabric',
              content: <FabricTab nextRoute="fabric/lining" orderItem={data?.orderItem} onSaveFabricOrLining={handleSaveFabricOrLining} />,
            },
            { name: 'Lining', route: 'lining', content: <LiningTab orderItem={data?.orderItem} onSaveFabricOrLining={handleSaveFabricOrLining} /> },
          ]}
        />
      ) : (
        <FabricTab orderItem={data?.orderItem} onSaveFabricOrLining={handleSaveFabricOrLining} />
      )}
    </div>
  );
};

const FabricTab = ({ orderItem, onSaveFabricOrLining, nextRoute = 'design-options' }) => {
  const router = useRouter();
  const { orderId, itemId } = router.query;
  const { isTemplate } = useDesignOptionsForm();

  const handleNext = () => {
    mixpanelService.track('ORDER_ITEM_NEXT_BUTTON', { from: 'fabric', to: nextRoute });

    router.push(isTemplate ? `/design-templates/${itemId}/${nextRoute}` : `/orders/${orderId}/order-item/${itemId}/${nextRoute}`);
  };

  const handleClose = () => {
    router.push(isTemplate ? { pathname: `/design-templates`, query: { category: orderItem.productCategory } } : `/orders/${orderId}`);
  };

  return (
    <>
      <FabricList
        isTemplate={isTemplate}
        onSelectFabric={(fabric) => onSaveFabricOrLining(fabric, Fabric, orderItem)}
        selectedFabric={orderItem?.fabric}
        customFabric={orderItem?.customFabric}
        productCategory={orderItem?.productCategory}
      />
      <OrderItemFooter
        isTemplate={isTemplate}
        backButton={
          <Button variant="neutral" onClick={handleClose}>
            Close
          </Button>
        }
        nextButton={<Button onClick={handleNext}>Next</Button>}
      />
    </>
  );
};

const LiningTab = ({
  orderItem,
  onSaveFabricOrLining,
}: {
  orderItem: DesignOptionsFormProviderOrderItemQueryResult['data']['orderItem'];
  onSaveFabricOrLining: Props['onSaveFabricOrLining'];
}) => {
  const router = useRouter();
  const { orderId, itemId } = router.query;
  const { isTemplate } = useDesignOptionsForm();

  const handleNext = () => {
    mixpanelService.track('ORDER_ITEM_NEXT_BUTTON', { from: 'lining', to: 'design-options' });
    router.push(isTemplate ? `/design-templates/${itemId}/design-options` : `/orders/${orderId}/order-item/${itemId}/design-options`);
  };

  const handleClose = () => {
    router.push(isTemplate ? { pathname: `/design-templates`, query: { category: orderItem.productCategory } } : `/orders/${orderId}`);
  };

  const liningList = (
    <>
      <LiningList
        onSelectLining={(lining) => onSaveFabricOrLining(lining, Lining, orderItem)}
        selectedLining={orderItem?.lining}
        itemId={orderItem?.id}
        productCategory={orderItem?.productCategory}
      />
      <OrderItemFooter
        isTemplate={isTemplate}
        backButton={
          <Button variant="neutral" onClick={handleClose}>
            Close
          </Button>
        }
        nextButton={<Button onClick={handleNext}>Next</Button>}
      />
    </>
  );

  const [selectedTab, setSelectedTab] = useState(0);
  const initialSelectedTabSet = useRef(false);

  const enableCmtLining = orderItem?.liningOptions?.enableCmtLining || orderItem?.liningOptions?.enableCustomLining;

  useEffect(() => {
    if (orderItem && enableCmtLining && !initialSelectedTabSet.current) {
      initialSelectedTabSet.current = true;
      setSelectedTab(isCmtLiningOptionSelected(orderItem.liningOptions) ? 1 : 0);
    }
  }, [orderItem]);

  if (!enableCmtLining) {
    return liningList;
  }

  return (
    <>
      <div className="mt-6">
        <Tabs
          selectedIndex={selectedTab}
          tabs={[
            { name: 'Standard', content: liningList, onChangeTab: () => setSelectedTab(0) },
            {
              name: 'CMT',
              content: (
                <CustomLiningForm
                  orderItem={orderItem}
                  note={
                    orderItem?.productCategory === 'LADIES_JACKET_PANTS_SKIRT' || orderItem?.productCategory === 'MENS_JACKET_PANTS_VEST'
                      ? 'Note: lining quantity will be applied separately to every garment that requires lining. If 3m of lining is required in total for two garments (e.g. jacket and vest), enter 1.5m in CMT Lining Quantity.'
                      : ''
                  }
                />
              ),
              onChangeTab: () => setSelectedTab(1),
            },
          ]}
        />
      </div>
      <OrderItemFooter
        isTemplate={isTemplate}
        backButton={
          <Button variant="neutral" onClick={handleClose}>
            Close
          </Button>
        }
        nextButton={<Button onClick={handleNext}>Next</Button>}
      />
    </>
  );
};

OrderItemFabricTabs.fragments = {
  fabricAndLining: gql`
    ${DesignOptions.fragments.conflicts}
    ${FabricList.fragments.list}

    fragment OrderItemFabricTabsLayout on OrderItem {
      id
      productCategory
      productCategorySetting {
        key
        requiresLining
      }
      fabric {
        ...FabricList
      }
      customFabric
      lining {
        name
        code
        id
      }
      liningOptions {
        enableCmtLining
        enableCustomLining
        cmtDefaultOptions {
          key
          value
        }
        cmtOptions {
          key
          value
        }
      }
      designs {
        id
        garmentCategory {
          key
        }
        conflicts {
          ...DesignOptionsFormProviderConflictsFragment
        }
      }
      estimatedDeliveryDate {
        formatted(format: "ll")
      }
      retail {
        total
        discountedPrice
        discountedAmount
      }
    }
  `,
};

OrderItemFabricTabs.mutation = gql`
  ${DesignOptions.fragments.conflicts}
  mutation UpdateOrderItemFabric($orderItemId: ID!, $orderItemInput: UpdateOrderItemInput!, $isTemplate: Boolean!) {
    updateOrderItem(orderItemId: $orderItemId, orderItemInput: $orderItemInput, isTemplate: $isTemplate) {
      id
      liningOptions {
        cmtOptions {
          key
          value
        }
      }
      lining {
        id
        code
        __typename
      }
      fabric {
        id
        code
        __typename
      }
      customFabric
      designs {
        id
        garmentCategory {
          key
        }
        options {
          id
          value
          typeCode
          price
        }
        conflicts {
          ...DesignOptionsFormProviderConflictsFragment
        }
      }
      estimatedDeliveryDate {
        formatted(format: "ll")
      }
      retail {
        total
        discountedPrice
        discountedAmount
      }
      __typename
    }
  }
`;
