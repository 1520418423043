import { Icon } from 'components';
import { Search } from 'modules/common';
import { ProductCategoriesSelect } from 'components';
import { GarmentTemplatesListItem } from 'modules/garments/components/GarmentTemplatesListItem';
import { GarmentTemplateListProps, useGarmentTemplateList } from 'hooks/useGarmetTemplateList';
import { AddItemTemplate } from 'modules/orders';

interface Props extends GarmentTemplateListProps {
  selectedTemplate: AddItemTemplate;
}

export const GarmentTemplateListModalLayout = ({
  productCategory,
  setProductCategory,
  searchText,
  setSearchText,
  selectedTemplate,
  setSelectedTemplate,
}: Props) => {
  const { GarmentTemplatesList, ViewMoreButton, productCategoryName, handleProductCategoryChange, filteredTemplates } = useGarmentTemplateList({
    searchText,
    setSearchText,
    setProductCategory,
    productCategory,
    setSelectedTemplate,
  });

  return (
    <GarmentTemplatesList>
      {!productCategory ? (
        <>
          <div className="flex justify-center">
            <div className="flex justify-center w-1/2">
              <div className="w-1/2 max-w-sm">
                <ProductCategoriesSelect value={productCategory} onChange={(category) => setProductCategory(category)} />
              </div>
            </div>
          </div>
          <div className="w-full h-96 flex flex-col items-center justify-center">
            <div className="w-10 h-10 bg-yellow-crayola-50 rounded flex items-center justify-center mb-2">
              <Icon icon="danger-stroke" className="text-yellow-crayola-600" />
            </div>
            No product category selected
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between">
            <div className="text-3xl my-6">{productCategoryName}</div>
            <div className="flex justify-end w-1/2">
              <div className="w-1/2 max-w-sm" style={{ marginTop: '3px' }}>
                <ProductCategoriesSelect value={productCategory} onChange={(category) => handleProductCategoryChange(category)} />
              </div>
              <div className="flex items-center w-1/2 max-w-sm" style={{ marginLeft: '5px' }}>
                <Search onChange={setSearchText} />
              </div>
            </div>
          </div>
          <div className="max-h-create-order-item-content overflow-y-auto overflow-x-hidden">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-6">
              {!filteredTemplates.length && <div className="w-full h-96 flex items-center justify-center">No results</div>}
              {filteredTemplates.map((template) => {
                return (
                  <GarmentTemplatesListItem
                    key={template?.id}
                    template={template}
                    isSelected={selectedTemplate?.id === template?.id}
                    handleOnClick={() => setSelectedTemplate(template)}
                  />
                );
              })}
            </div>
            <ViewMoreButton />
          </div>
        </>
      )}
    </GarmentTemplatesList>
  );
};
