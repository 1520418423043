import cn from 'classnames';
import { Icon } from 'components';

interface Props {
  className?: string;
  testId?: string;
  value: number;
  showValue?: boolean;
  color?: string;
  showUnits?: boolean;
  isInches?: boolean;
  measurementUnitLabel?: string;
  showWarning?: boolean;
}

export const MeasurementFormBlockDisplay = ({
  className,
  testId,
  value,
  measurementUnitLabel,
  showValue = false,
  color = 'text-gray-400',
  showWarning = false,
}: Props) => {
  return (
    <div className={cn('col-span-1', className)}>
      <span className="flex h-full items-center justify-center">
        <span data-testid={testId} className={color}>
          {showValue ? value || '' : '-'}
          {showValue && measurementUnitLabel && !!value && <span className="text-sm"> {measurementUnitLabel}</span>}
        </span>
        {showWarning && <Icon icon="warning" className="text-amber-500 ml-1" width={12} height={12} />}
      </span>
    </div>
  );
};

export const MeasurementFormBlockDisplayHeading = ({
  className,
  heading,
  color = 'text-gray-400',
}: {
  className?: string;
  heading: string;
  color?: string;
}) => (
  <div className={cn('col-span-1 text-center', className)}>
    <span className={cn('block py-2 font-semibold text-xs', color)}>{heading}</span>
  </div>
);
