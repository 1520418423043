import cn from 'classnames';

interface Props {
  template: {
    lining: {
      id: string;
    };
    fabric: {
      id: string;
    };
    designs: {
      requiresLining: boolean;
    }[];
  };
}

export const isProduct = (template: Props['template']): boolean => {
  const requiresLining = template.designs.reduce((result, design) => result && design.requiresLining, false);
  return !!(template.fabric && (requiresLining ? template.lining : true));
};

export const TemplateOrProductPill = ({ template }: Props) => {
  const isProductOrNot = isProduct(template);

  const pillClasses = cn('rounded-sm px-2 text-xs', {
    'bg-yellow-crayola-100 text-yellow-crayola-900': isProductOrNot,
    'bg-aquamarine-100 text-aquamarine-800': !isProductOrNot,
  });

  return (
    <div className="flex">
      <div className={pillClasses}>{isProductOrNot ? 'Product' : 'Template'}</div>
    </div>
  );
};
