import { OrderPageQuery, useCreateRemakeOrderMutation } from '@graphql';
import { Modal } from 'components/Modal';
import { useEffect, useState } from 'react';
import { RemakeGarmentCheckBoxGroup } from './steps/RemakeGarmentCheckBoxGroup';
import { RemakeOrderItemReasonForm } from './steps/RemakeOrderItemReasonForm';
import { actions, useRemakeReducer } from './RemakeState';
import { Button } from 'modules/common';
import { getRemakeOrderParams } from './helpers';
import { RemakeSubmittingScreen } from './steps/RemakeSubmittingScreen';

type Props = {
  order: OrderPageQuery['order'];
  isOpen: boolean;
  onClose: () => void;
  onRemakeSuccess: (remadeOrderId: string) => void;
};

const STEPS = {
  SELECT_GARMENTS: 0,
  TYPE_REASON: 1,
  SUBMITING: 2,
} as const;

const titlePerStep = {
  [STEPS.SELECT_GARMENTS]: 'Select garments to remake',
  [STEPS.TYPE_REASON]: 'Reason to remake',
  [STEPS.SUBMITING]: 'Submitting',
};

export const RemakeOrderModal = ({ order, isOpen, onClose, onRemakeSuccess }: Props) => {
  const { state: remakeItems, dispatch } = useRemakeReducer();
  const [currentStep, setStep] = useState(0);

  const [remakeOrder, { loading, error }] = useCreateRemakeOrderMutation();

  const handleClose = () => {
    if (!loading) {
      onClose();
    }
  };

  const handleSubmit = async () => {
    setStep(STEPS.SUBMITING);

    try {
      const { data } = await remakeOrder({ variables: { params: getRemakeOrderParams(order.id, remakeItems) } });
      data && onRemakeSuccess(data.remakeOrder.id);
      dispatch(actions.initialize(order.items));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    dispatch(actions.initialize(order.items));
  }, [order.items]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title={titlePerStep[currentStep]}>
      {(() => {
        switch (currentStep) {
          case STEPS.SELECT_GARMENTS:
            return (
              <RemakeGarmentCheckBoxGroup
                items={remakeItems}
                onOrderItemSwitch={(orderItemId) => dispatch(actions.switchOrderItem(orderItemId))}
                onOrderItemDesignSwitch={(orderItemId, orderItemDesignId) => dispatch(actions.switchOrderItemDesign(orderItemId, orderItemDesignId))}
                onNextClick={() => setStep(STEPS.TYPE_REASON)}
              />
            );
          case STEPS.TYPE_REASON:
            return (
              <RemakeOrderItemReasonForm
                items={remakeItems}
                onReasonChange={(orderItemId, reason) => dispatch(actions.updateRemakeReason(orderItemId, reason))}
                onReasonDescriptionChange={(orderItemId, description) => dispatch(actions.updateRemakeDescription(orderItemId, description))}
                onBackClick={() => setStep(STEPS.SELECT_GARMENTS)}
                onNextClick={handleSubmit}
              />
            );
          case STEPS.SUBMITING:
            return <RemakeSubmittingScreen loading={loading} error={error} onBackClick={() => setStep(STEPS.TYPE_REASON)} />;
          default:
            return (
              <>
                <p>Unsupported step</p>
                <Button onClick={() => setStep(STEPS.SELECT_GARMENTS)}>Go Back</Button>
              </>
            );
        }
      })()}
    </Modal>
  );
};
