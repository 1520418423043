import { gql } from '@apollo/client';
import { useOrderItemAlterationBadgesQuery, AlterationStatus } from '@graphql';
import { PuzzleIcon } from '@heroicons/react/outline';
import cn from 'classnames';

export const OrderItemAlterationBadges = ({ className = '', orderItemId }) => {
  const { data, loading: isLoading } = useOrderItemAlterationBadgesQuery({ variables: { orderItemId } });
  const classes = cn('flex items-center flex-wrap gap-2', className);

  if (!data || isLoading) {
    return null;
  }

  return (
    <div className={classes}>
      {data?.orderItem?.designs?.map((design) =>
        design.alterations.map((alteration, index) => (
          <a
            href={`/alterations/${alteration.id}`}
            key={index}
            className={`inline-flex items-center py-1 px-2 rounded text-xs whitespace-nowrap ${
              alteration.status.key === AlterationStatus.Completed
                ? 'bg-emerald-50 text-emerald-500 hover:text-emerald-600 hover:bg-emerald-100'
                : 'bg-red-50 text-red-500 hover:text-red-600 hover:bg-red-100'
            }`}
          >
            <PuzzleIcon className="h-4 w-4 mr-1" /> ALT#
            {/* TODO: display alteration custom item id here when available */}
            {index + 1}
          </a>
        ))
      )}
    </div>
  );
};

OrderItemAlterationBadges.fragments = {
  orderItems: gql`
    fragment OrderItemAlterationBadgesFragment on OrderItem {
      id
      name
      designs {
        alterations {
          id
          status {
            key
          }
        }
      }
    }
  `,
};

OrderItemAlterationBadges.query = gql`
  ${OrderItemAlterationBadges.fragments.orderItems}

  query OrderItemAlterationBadges($orderItemId: ID!) {
    orderItem(orderItemId: $orderItemId, isTemplate: false) {
      ...OrderItemAlterationBadgesFragment
    }
  }
`;
