import { Combobox } from '@headlessui/react';
import cn from 'classnames';

import { Option, Props, Options, Button, styles } from './SimpleCombobox';

interface MultipleComboboxProps extends Props {
  placeholder: string;
  selectedOptions?: Option[];
  onChange?: (options: Option[]) => void;
}

export const Badge = ({ num }: { num: number }) => (
  <span className="ml-2 min-w-5 h-5 px-1.5 py-0.5 inline-block text-center rounded-full bg-yellow-crayola-500 text-xs">{num}</span>
);

const MultipleCombobox = ({ label, options, placeholder, selectedOptions = [], onChange }: MultipleComboboxProps) => (
  <Combobox as="div" value={selectedOptions} onChange={onChange} multiple>
    {label && <Combobox.Label className={styles.label}>{label}</Combobox.Label>}
    <div className="relative">
      <Combobox.Label className={cn(styles.input, 'flex items-center')}>
        {placeholder} {!!selectedOptions?.length && <Badge num={selectedOptions.length} />}
      </Combobox.Label>
      <Button />
      <Options options={options} />
    </div>
  </Combobox>
);

export default MultipleCombobox;
