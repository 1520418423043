import { GarmentCategory } from '@graphql';

// Keep this in sync with SUPPORTED_ALLOWANCES in /api/src/modules/measurement/Measurement/Measurement.constant.ts
export const SUPPORTED_FINISHED_MEASUREMENT_GARMENTS: GarmentCategory[] = [
  GarmentCategory.Jacket,
  GarmentCategory.Pants,
  GarmentCategory.Vest,
  GarmentCategory.Shirt,
  GarmentCategory.ShortSleeveShirt,
  GarmentCategory.ShirtV2,
  GarmentCategory.ShortSleeveShirtV2,
  GarmentCategory.TalShirt,
  GarmentCategory.TalShortSleeveShirt,
  GarmentCategory.PremiumShirtV2,
];
