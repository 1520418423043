import CreatableSelect from 'react-select/creatable';
import { GroupBase, OptionProps, OptionsOrGroups } from 'react-select';
import { SelectOption } from 'types/common';

type Props = {
  options: SelectOption[];
  defaultValue?: OptionsOrGroups<SelectOption, GroupBase<SelectOption>>;
  label?: string;
  onChange?: (options: SelectOption[]) => void;
  onCreateOption?: (label: string) => void;
  isLoading?: boolean;
};

export const CreatableMultiSelect = ({ options, defaultValue, label, onChange, onCreateOption, isLoading }: Props) => {
  // This controls the style of the options in the dropdown list
  const CustomOption = ({ label, innerProps, innerRef }: OptionProps) => {
    return (
      <>
        {!options.find((option) => option.label === label) && (
          <div className="flex my-3 mx-4 cursor-pointer" ref={innerRef} {...innerProps}>
            <div className=" px-2 py-1 text-xs font-medium">{label}</div>
          </div>
        )}
        {Boolean(options.find((option) => option.label === label)) && (
          <div className="flex my-3 mx-4 cursor-pointer" ref={innerRef} {...innerProps}>
            <div className="bg-gray-100 rounded-full px-2 py-1 text-xs font-medium">{label}</div>
          </div>
        )}
      </>
    );
  };

  return (
    <CreatableSelect
      styles={{
        control: (base, state) => ({
          ...base,
          border: state.isFocused ? '2px solid #6b7280' : '1px solid #d1d5db',
          boxShadow: 'none',
          '&:hover': {
            border: '2px solid #6b7280',
          },
          borderRadius: '6px',
          padding: '0 0',
        }),
        multiValue: (provided) => ({
          // This controls the style of the selected options
          ...provided,
          borderRadius: '100px',
          backgroundColor: '#f3f4f6',
          padding: '0px 1px',
          fontWeight: '500',
          fontSize: '0.85rem',
        }),
      }}
      isLoading={isLoading}
      components={{ Option: CustomOption }}
      isMulti
      onCreateOption={onCreateOption}
      onChange={onChange}
      isClearable={false}
      value={defaultValue}
      name={label}
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
    />
  );
};
