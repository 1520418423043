import { MeasurementType } from '@graphql';

export const events = {
  PAGE_VIEW: 'Page View',
  BUTTON_CLICKED: 'Button Clicked',
  SIGN_IN: 'Sign In',
  TAB_CLICKED: 'Tab Clicked',
  ORDER_CREATE: 'Order Created',
  ORDER_ADD_ITEM: 'Order Add Item',
  ORDER_ITEM_NEXT_BUTTON: 'Order Item Next Button',
  ORDER_ITEM_CLOSE_BUTTON: 'Order Item Close Button',
  ORDER_ITEM_FABRIC_SELECTED: 'Order Item Fabric Selected',
  ORDER_ITEM_CUSTOM_FABRIC_ADDED: 'Order Item Custom Fabric Added',
  ORDER_ITEM_LINING_SELECTED: 'Order Item Lining Selected',
  ORDER_ITEM_DESIGN_OPTION_SAVE: 'Order Item Design Option Saved',
  ORDER_DUPLICATE: 'Order Duplicate',
  ORDER_ITEM_DUPLICATE: 'Order Item Duplicate',
  ORDER_ITEM_ADD_MEASUREMENT: 'Order Item Measurement Added',
  ALTERATION_CREATE: 'Alteration Created',
  ALTERATION_CTA_MODAL_BUTTON: 'Alteration CTA Modal Button',
  MEASUREMENT_CREATE: 'Measurement Created',
  MEASUREMENT_OBSERVATION_ADDED: 'Measurement Observation Added',
  MEASUREMENT_UPDATE: 'Measurement Updated',
  FABRIC_SEARCH: 'Fabric Search Request',
  TYPEFORM_OPENED: 'Typeform opened',
  TYPEFORM_SUBMITTED: 'Typeform submitted',
  ORDER_ITEM_SELECT_FIRST_BUTTON_MEASUREMENT: 'Order Item First Button Measurement Selected',
  ORDER_ITEM_CANCEL_SELECTION_FIRST_BUTTON_MEASUREMENT: 'Order Item First Button Measurement Selection Cancelled',
  ORDER_ITEM_SELECTED_FIRST_BUTTON_MEASUREMENT: 'Order Item First Button Measurement Selected',
};

export type EventName = keyof typeof events;

export type EventProperties = {
  PAGE_VIEW: null;
  SIGN_IN: null;

  // Design flow funnel
  ORDER_CREATE: { orderId: string };
  ORDER_ADD_ITEM: {
    orderId: string;
    orderItemId: string;
    productCategory: string;
    searchText: string;
    isProduct: boolean;
  };
  ORDER_ITEM_NEXT_BUTTON: {
    from: string;
    to: string;
  };
  ORDER_ITEM_CLOSE_BUTTON: null;
  ORDER_ITEM_FABRIC_SELECTED: {
    id: string;
    name: string;
    code: string;
    status: string;
    fabricCollection: string;
    replenishmentDate: any;
    vendor: string;
    stock: number;
    imageUrl: string;
    orderItemId: string;
    searchText: string;
  };
  ORDER_ITEM_CUSTOM_FABRIC_ADDED: {
    orderItemId: string;
    customFabric: string;
  };
  ORDER_ITEM_LINING_SELECTED: {
    name: string;
    code: string;
    id: string;
    orderItemId: string;
    searchText: string;
  };
  ORDER_ITEM_DESIGN_OPTION_SAVE: {
    orderId?: string;
    orderItemId: string;
    typeCode?: string;
    label?: string;
    value?: string;
    displayValue?: string | number;
  };
  ORDER_DUPLICATE: { orderToDuplicateId: string };
  ORDER_ITEM_DUPLICATE: {
    orderId: string | string[];
    orderItemId: string;
    productCategory: string;
    name: string;
  };
  ORDER_ITEM_ADD_MEASUREMENT: {
    orderId: string | string[];
    orderItemId: string;
    measurementId: string;
    existingMeasurement: boolean;
  };

  // Measurements
  MEASUREMENT_CREATE: {
    measurementId: string;
    type: MeasurementType;
  };
  MEASUREMENT_OBSERVATION_ADDED: {
    measurementId: string;
    observation: string;
  };
  MEASUREMENT_UPDATE: {
    measurementId: string;
    type: MeasurementType;
  };

  // Alterations
  ALTERATION_CREATE: {
    orderId: string | string[];
    alterationId: string;
    fromOrderPage: boolean;
  };

  // Fabric Search
  FABRIC_SEARCH: { searchText: string };

  BUTTON_CLICKED: { buttonName: string };
  TAB_CLICKED: { tabName: string };
  TYPEFORM_OPENED: null;
  TYPEFORM_SUBMITTED: null;
  ALTERATION_CTA_MODAL_BUTTON: { orderId: string };
  ORDER_ITEM_SELECT_FIRST_BUTTON_MEASUREMENT: {
    orderId: string | string[];
    orderItemId: string;
    measurementId: string;
    existingMeasurement: boolean;
  };
  ORDER_ITEM_CANCEL_SELECTION_FIRST_BUTTON_MEASUREMENT: {
    orderId: string | string[];
    orderItemId: string;
    measurementId: string;
    existingMeasurement: boolean;
  };
  ORDER_ITEM_SELECTED_FIRST_BUTTON_MEASUREMENT: {
    orderId: string | string[];
    orderItemId: string;
    measurementId: string;
    existingMeasurement: boolean;
  };
};

export const {
  PAGE_VIEW,
  SIGN_IN,
  TAB_CLICKED,
  ORDER_CREATE,
  ORDER_ADD_ITEM,
  ORDER_ITEM_NEXT_BUTTON,
  ORDER_ITEM_CLOSE_BUTTON,
  ORDER_ITEM_FABRIC_SELECTED,
  ORDER_ITEM_CUSTOM_FABRIC_ADDED,
  ORDER_ITEM_LINING_SELECTED,
  ORDER_ITEM_DESIGN_OPTION_SAVE,
  ORDER_DUPLICATE,
  ORDER_ITEM_DUPLICATE,
  ORDER_ITEM_ADD_MEASUREMENT,
  ALTERATION_CREATE,
  ALTERATION_CTA_MODAL_BUTTON,
  MEASUREMENT_CREATE,
  MEASUREMENT_OBSERVATION_ADDED,
  MEASUREMENT_UPDATE,
  FABRIC_SEARCH,
  TYPEFORM_OPENED,
  TYPEFORM_SUBMITTED,
  ORDER_ITEM_SELECT_FIRST_BUTTON_MEASUREMENT,
  ORDER_ITEM_CANCEL_SELECTION_FIRST_BUTTON_MEASUREMENT,
  ORDER_ITEM_SELECTED_FIRST_BUTTON_MEASUREMENT,
} = events;
