import { ManufacturerGarmentOfferingsQuery, OrderItemStatus } from '@graphql';

export const isFinished = (itemStatus: OrderItemStatus) => itemStatus === 'LEFT_FACTORY' || itemStatus === 'SHIPPED' || itemStatus === 'DELIVERED';
export const getEstShippingOrDeliveryDateText = ({
  itemStatus,
  dateLeftFactory,
  estDate,
}: {
  itemStatus: OrderItemStatus;
  dateLeftFactory: string;
  estDate: string;
}) => {
  let resultTxt = '';
  if (!itemStatus || dateLeftFactory?.length === 0) return estDate ? `Estimated shipping date: ${estDate}` : `NA`;
  switch (itemStatus) {
    case OrderItemStatus.Processing:
    case OrderItemStatus.AwaitingFabric:
    case OrderItemStatus.InProduction:
    case OrderItemStatus.ProductionComplete:
    case OrderItemStatus.Confirmed:
    case OrderItemStatus.Processed:
    case OrderItemStatus.OnHold:
    case OrderItemStatus.PatternMaking:
      resultTxt = dateLeftFactory ? `Estimated shipping date: ${dateLeftFactory}` : `${estDate ? `Estimated delivery date: ${estDate}` : 'NA'}`;
      break;
    case OrderItemStatus.Shipped:
    case OrderItemStatus.LeftFactory:
    case OrderItemStatus.Delivered:
      resultTxt = estDate ? `Estimated delivery date: ${estDate}` : `${dateLeftFactory ? `Estimated delivery date: ${dateLeftFactory}` : 'NA'}`;
      break;
    case OrderItemStatus.Error:
    case OrderItemStatus.Cancelled:
    case OrderItemStatus.Unknown:
      resultTxt = 'NA';
      break;
    default:
      break;
  }
  return resultTxt;
};

export const issupportedMeasurementGarment = ({
  mfrGarmentOfferings,
  currentGarmentCategory,
  orderItemGarmentCategory,
}: {
  mfrGarmentOfferings: ManufacturerGarmentOfferingsQuery['manufacturerGarmentOfferings'];
  currentGarmentCategory: string;
  orderItemGarmentCategory: string;
}) => {
  return mfrGarmentOfferings?.some(
    (x) => x?.garmentCategory === orderItemGarmentCategory && x?.supportedMeasurementGarmentList?.includes(currentGarmentCategory)
  );
};
