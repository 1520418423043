import { StepContainer } from '../StepContainer';
import { Spinner } from 'components/Spinner';

type Props = {
  loading: boolean;
  error?: Error;
  onBackClick: () => void;
};

export const RemakeSubmittingScreen = ({ loading, error, onBackClick }: Props) => {
  return (
    <StepContainer onBackClick={onBackClick} nextText="Remake" isNextButtonDisabled>
      <div className="w-full h-full flex flex-col items-center justify-center">
        {loading ? (
          <>
            <span className="mb-4">Remaking Order...</span>
            <Spinner size="xl" />
          </>
        ) : (
          error && <p className="text-red-500 text-base text-center">Something went wrong when remaking the order, Please go back and try again</p>
        )}
      </div>
    </StepContainer>
  );
};
