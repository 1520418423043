import { gql } from '@apollo/client';
import { useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { groupBy } from 'lodash';

import {
  TryOnMeasurementInput,
  TryOnMeasurementTweak,
  useGetTryOnMeasurementQuery,
  useUpsertTryOnMeasurementMutation,
  TryOnMeasurementItem,
  MeasurementType,
} from '@graphql';
import { Box, Input, Modal, Select, Tabs } from 'components';
import { Button } from 'modules/common';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import {
  getAvailableFits,
  getDefaultFitValue,
  checkConflicts,
  Conflict,
  tweakGroupDisplayName,
  useOnComplete,
  useOnError,
  useOnRedirect,
  getDefaultNameForMeasurement,
} from 'helpers/measurement-helpers';
import { ConflictError } from 'modules/measurements/components/ConflictError';
import { MeasurementFormFooter } from 'modules/measurements/components/MeasurementFormFooter';
import { Tweaks } from 'modules/measurements/components/TryOnTweaks';
import { FormProps } from 'modules/measurements/components/MeasurementFormWrap';
import { getAbbreviatedDate } from 'helpers/date-helpers';
import { useMeasurementUnit } from 'modules/common/MeasurementUnit';

export const TryOnMeasurementForm = ({
  customerId,
  measurementId,
  garmentCategory,
  frontBackLengthValidationConfig,
  isGarmentDisabled,
}: FormProps) => {
  const router = useRouter();
  const { tailor } = useActiveSession();
  const [conflicts, setConflicts] = useState<Conflict[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const useFormMethods = useForm<TryOnMeasurementInput>();
  const onComplete = useOnComplete({ router, toast });
  const onError = useOnError(toast);
  const onRedirect = useOnRedirect(router);
  const { measurementUnitInfo, convertFromServerUnit, updateMeasurementUnit } = useMeasurementUnit();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useFormMethods;

  const selectedFit = watch('fit');
  const selectedSize = watch('size');

  const { data, loading } = useGetTryOnMeasurementQuery({
    skip: !garmentCategory || !tailor,
    variables: {
      garmentCategory,
      measurementId,
    },
    onCompleted: (data) => {
      const existingMeasurementData = data.measurement;
      const measurementSettingsData = data.settings.measurement;
      const measurementDefaults = measurementSettingsData?.tryOn?.defaults;

      reset({
        name: existingMeasurementData?.name || getDefaultNameForMeasurement({ garmentCategory, dateStr: getAbbreviatedDate() }),
        fit: existingMeasurementData?.fit?.key || getDefaultFitValue({ tailor, garmentCategory, data }),
        size: existingMeasurementData?.size || null,

        tweaks: measurementSettingsData?.tryOn.tweaks.map((tweak) => {
          const defaultTweak = measurementDefaults?.tweaks.find((t) => (t as TryOnMeasurementTweak).typeKey === tweak.key) as TryOnMeasurementTweak;
          const foundTweak = existingMeasurementData?.tweaks.find((t) => (t as TryOnMeasurementTweak).typeKey === tweak.key) as TryOnMeasurementTweak;
          return {
            typeKey: tweak.key,
            itemKey: (foundTweak?.itemKey as TryOnMeasurementItem) || (defaultTweak?.itemKey as TryOnMeasurementItem) || null,
            option: foundTweak?.option || defaultTweak?.option || null,
          };
        }),
      });
    },

    onError: (error) => {
      console.error(error);
    },
  });

  const availableFits = getAvailableFits({ tailor, garmentCategory, data });

  const displayGroups = useMemo(() => Object.keys(groupBy(data?.settings.measurement.tryOn.tweaks, (t) => t.displayGroup)), [data]);

  const [upsertTryOnMeasurement, { loading: isMutationLoading }] = useUpsertTryOnMeasurementMutation({
    onCompleted: ({ upsertTryOnMeasurement: { id } }) =>
      onComplete({
        measurementId,
        customerId,
        orderId: router.query?.orderId as string,
        designId: router.query?.designId as string,
        newMeasurementId: measurementId ? '' : id,
        measurementType: MeasurementType.TryOn,
      }),
    onError,
  });

  const save = async () => {
    try {
      const formData = getValues();
      await upsertTryOnMeasurement({
        variables: {
          customerId: customerId || (router.query.customerId as string),
          tryOnMeasurementInput: {
            id: measurementId,
            name: formData.name,
            garmentCategory,
            fit: formData.fit,
            size: formData.size,
            tweaks: formData.tweaks.filter((tweak) => tweak.itemKey && tweak.option),
          },
        },
      });
    } catch (error) {
      console.error('Error when submitting try on measurement form', error);
    }
  };

  const onSubmit = handleSubmit(async () => {
    try {
      const values = getValues();
      const conflicts = checkConflicts({ tweaks: values.tweaks, data });

      if (conflicts.length) {
        setConflicts(conflicts);
        setIsModalOpen(true);
        return;
      }

      await save();
    } catch (error) {
      console.error('Error when submitting try on measurement form', error);
    }
  });

  const onCancel = () => onRedirect({ customerId, orderId: router.query?.orderId as string });

  return (
    <>
      {garmentCategory && (
        <FormProvider {...useFormMethods}>
          <form>
            <div className="mb-4">
              <fieldset disabled={isGarmentDisabled}>
                <Box isLoading={loading}>
                  <div className="grid grid-cols-8 gap-6">
                    <div className="col-span-8">
                      <Input
                        label="Name"
                        htmlProps={{
                          id: 'name',
                          type: 'text',
                        }}
                        register={register('name', {
                          required: 'Please add a name',
                        })}
                        errorMessage={errors.name?.message}
                      />
                    </div>
                    <div className="col-span-4">
                      <Select label="Fit" register={register('fit', { required: 'Please select a fit' })} htmlProps={{ id: 'fit' }}>
                        <option value="">Choose a fit</option>
                        {availableFits.map((fit) => (
                          <option key={fit.key} value={fit.key}>
                            {fit.name}
                          </option>
                        ))}
                      </Select>
                    </div>
                    <div className="col-span-4">
                      <Select
                        label="Size"
                        register={register('size', {
                          required: 'Please select a size',
                        })}
                        htmlProps={{ id: 'Size', defaultValue: '' }}
                      >
                        <option value="">Choose a size</option>
                        {selectedFit &&
                          availableFits
                            .find((fit) => fit.key === selectedFit)
                            ?.sizes.map((size) => (
                              <option key={size.key} value={size.key}>
                                {size.name}
                              </option>
                            ))}
                      </Select>
                    </div>
                  </div>
                </Box>
              </fieldset>
            </div>
            <Box hasPadding={false} isLoading={loading}>
              <Tabs
                tabsListClassName="-mb-px flex"
                panelsClassName="p-6"
                buttonClassName="flex-1 py-4 border-r last:border-r-0"
                tabs={displayGroups.map((group) => ({
                  name: tweakGroupDisplayName[group],
                  content: (
                    <Tweaks
                      tweaks={data?.settings.measurement.tryOn.tweaks}
                      defaultTweaks={data?.settings.measurement.tryOn.defaults?.tweaks}
                      fit={selectedFit}
                      size={selectedSize}
                      influences={data?.influences}
                      displayGroup={group}
                      measurementUnitInfo={measurementUnitInfo}
                      convertFromServerUnit={convertFromServerUnit}
                      updateMeasurementUnit={updateMeasurementUnit}
                      garmentCategory={garmentCategory}
                      frontBackLengthValidationConfig={frontBackLengthValidationConfig}
                      isGarmentDisabled={isGarmentDisabled}
                    />
                  ),
                }))}
              />
            </Box>
            <MeasurementFormFooter
              isDisabled={isMutationLoading}
              isNew={!measurementId}
              onCancel={onCancel}
              onSubmit={onSubmit}
              isGarmentDisabled={isGarmentDisabled}
            />
          </form>
        </FormProvider>
      )}
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="Is this correct?">
        <div className="mt-4 flex flex-col">
          {conflicts.map(({ conflictA, conflictB }, i) => (
            <div key={`error-${i}`}>
              <ConflictError {...conflictA} />
              <ConflictError {...conflictB} />
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-6">
          <Button variant="neutral" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button isDisabled={isMutationLoading} className="ml-2" onClick={save}>
            Yes, save
          </Button>
        </div>
      </Modal>
    </>
  );
};

TryOnMeasurementForm.fragments = {
  TryOnMeasurementFragment: gql`
    fragment TryOnMeasurementFragment on Measurement {
      id
      name
      fit {
        key
        name
      }
      size
      tweaks {
        __typename
        ... on GarmentMeasurementTweak {
          key
          value
        }
        ... on TryOnMeasurementTweak {
          typeKey
          itemKey
          option
        }
      }
    }
  `,
};

TryOnMeasurementForm.query = gql`
  query GetTryOnMeasurement($garmentCategory: GarmentCategory!, $measurementId: ID) {
    measurement(measurementId: $measurementId) {
      id
      name
      fit {
        key
        name
      }
      size
      tweaks {
        ... on TryOnMeasurementTweak {
          typeKey
          itemKey
          option
        }
      }
    }
    settings {
      id
      measurement {
        tryOn(garmentCategory: $garmentCategory) {
          fits {
            key
            name
            sizes {
              key
              name
            }
          }
          tweaks {
            key
            name
            displayGroup
            items {
              key
              name
              options {
                key
                name
              }
              info {
                text
                image
              }
            }
            blockMeasurements {
              garmentCategory
              fit
              blockMeasurement
              bodyPart
              value
            }
          }
          defaults {
            tweaks {
              ... on TryOnMeasurementTweak {
                typeKey
                itemKey
                option
              }
            }
          }
        }
      }
    }
    influences(garmentCategory: $garmentCategory) {
      bodyPart
      garmentCategory
      influence
      influencedPart
    }
  }
`;

TryOnMeasurementForm.mutations = {
  UpsertTryOnMeasurement: gql`
    mutation UpsertTryOnMeasurement($customerId: ID!, $tryOnMeasurementInput: TryOnMeasurementInput!) {
      upsertTryOnMeasurement(customerId: $customerId, tryOnMeasurementInput: $tryOnMeasurementInput) {
        ...TryOnMeasurementFragment
        type {
          name
          key
        }
        updatedAt {
          fromNow
          origin
        }
        garmentCategory {
          key
          name
        }
      }
    }
    ${TryOnMeasurementForm.fragments.TryOnMeasurementFragment}
  `,
};
