import fetch from 'cross-fetch';
import { authService } from 'services';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, fromPromise } from '@apollo/client';
import { clearLocalCommon } from 'graphql/common/use-query-local-common.graphql';
import { getFeatureFlags } from 'graphql/feature-flags/getFeatureFlags.gql';
import { createUploadLink } from 'apollo-upload-client';

export const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_API_URI,
  fetch,
});

export const uploadLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_API_URI,
  headers: {
    'apollo-require-preflight': 'true',
  },
});

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await authService.getToken(false);

  return {
    headers: {
      ...headers,
      authorization: accessToken || '',
    },
  };
});

export const apolloCache = new InMemoryCache({
  typePolicies: {
    GarmentCategoryDisplay: {
      keyFields: ['key'],
    },
    ProductCategorySetting: {
      keyFields: ['key'],
    },
    GarmentCategorySetting: {
      keyFields: ['key'],
    },
    OrderStatusDisplay: {
      keyFields: ['key'],
    },
    OrderItemStatusDisplay: {
      keyFields: ['key'],
    },
    OrderItem: {
      fields: {
        liningOptions: {
          merge: true,
        },
      },
    },
  },
  possibleTypes: {
    MeasurementTweak: ['GarmentMeasurementTweak', 'TryOnMeasurementTweak'],
  },
});

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  // TODO: handle error tracking on production environment
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(path)}`);

      if (extensions.code === 'UNAUTHENTICATED') {
        // redirect to login if user unauthenticated and flag has changed
        return fromPromise(
          getFeatureFlags('cache-first').then(async (currFlags) => {
            const updatedFlags = await getFeatureFlags('network-only');
            if (currFlags.AUTH_AUTH0 !== updatedFlags.AUTH_AUTH0) {
              window.location.href = '/login';
            }
          })
        );
      }
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

export const createApolloClient = (links: ApolloLink[]) => {
  return new ApolloClient({
    link: ApolloLink.from(links),
    cache: apolloCache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
};

const apolloClient = createApolloClient([authLink, errorLink, uploadLink]);

export const apolloClientUnauthenticated = createApolloClient([errorLink, httpLink]);

export const clearLocalApolloCache = async () => {
  await apolloClient.clearStore();
  clearLocalCommon();
};

export default apolloClient;
