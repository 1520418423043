import { gql } from '@apollo/client';
import { CreateAlterationInput, GetOrderItemsQuery, useGetAlterationProviderOptionsQuery } from '@graphql';
import { TrashIcon } from '@heroicons/react/outline';
import { Select } from 'components';
import { Button } from 'modules/common';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useAlterationOptionValue } from './useAlterationOptionValue';

interface Props {
  itemIndex: number;
  items: GetOrderItemsQuery['order']['items'][0]['designs'];
}

export const AlterationsFormOptions = ({ itemIndex, items }: Props) => {
  const { register, control, watch } = useFormContext<CreateAlterationInput>();
  const { convertFromAlterationOptionServerUnit } = useAlterationOptionValue();

  const {
    fields: alterationItemOptions,
    append: appendAlterationItemOption,
    remove: removeAlterationItemOption,
  } = useFieldArray({
    control,
    name: `alterationItems.${itemIndex}.alterationItemOptions` as const,
  });

  const watchedAlterationProviderId = useWatch({
    name: 'alterationProviderId',
  });
  const watchedOrderItemId = useWatch({
    name: `alterationItems.${itemIndex}.orderItemDesignId`,
  });
  const selectedGarmentCategory = items.find((item) => item.id === watchedOrderItemId)?.garmentCategory.key;

  const { data } = useGetAlterationProviderOptionsQuery({ variables: { alterationProviderId: watchedAlterationProviderId } });
  const alterationOptions =
    data?.alterationProviderOptions
      .filter(
        (providerOption) =>
          providerOption.isActive &&
          providerOption.alterationOption.isActive &&
          providerOption.alterationOption.garmentCategory.key === selectedGarmentCategory
      )
      .map((providerOption) => providerOption.alterationOption) || [];

  return (
    <div className="mt-6">
      <div className="font-semibold text-sm mb-2">Alteration options</div>

      {!!alterationItemOptions?.length && (
        <ul className="space-y-2 mb-6">
          {alterationItemOptions.map((alterationItemOption, i) => {
            const watchedOptionId = watch(`alterationItems.${itemIndex}.alterationItemOptions.${i}.alterationOptionId` as const);
            const selectedOption = alterationOptions.find((option) => option.id === watchedOptionId);
            return (
              <li key={alterationItemOption.id} className="flex items-center justify-between space-x-2">
                <div className="flex-1">
                  <Select
                    register={register(`alterationItems.${itemIndex}.alterationItemOptions.${i}.alterationOptionId` as const)}
                    htmlProps={{ required: true }}
                  >
                    {alterationOptions.map((alterationOption) => (
                      <option key={alterationOption.id} value={alterationOption.id}>
                        {alterationOption.name}
                      </option>
                    ))}
                  </Select>
                </div>

                {selectedOption?.values?.length > 0 && (
                  <div className="flex-1">
                    <Select
                      register={register(`alterationItems.${itemIndex}.alterationItemOptions.${i}.value` as const)}
                      htmlProps={{ required: true }}
                    >
                      <option value="" />
                      {selectedOption.values.map((rawValue) => (
                        <option key={rawValue} value={rawValue}>
                          {convertFromAlterationOptionServerUnit(rawValue)}
                        </option>
                      ))}
                    </Select>
                  </div>
                )}

                <div onClick={() => removeAlterationItemOption(i)} className="flex items-center cursor-pointer">
                  <TrashIcon className="w-5 h-5 text-gray-400 hover:text-red-500" />
                </div>
              </li>
            );
          })}
        </ul>
      )}

      <div className="mt-2">
        <Button
          testId="add-alteration-option"
          variant="neutral"
          onClick={() =>
            appendAlterationItemOption({
              alterationOptionId: alterationOptions[0]?.id,
              value: null,
            })
          }
        >
          Add an alteration option
        </Button>
      </div>
    </div>
  );
};

AlterationsFormOptions.queries = {
  alterationProviderOptions: gql`
    query GetAlterationProviderOptions($alterationProviderId: ID!) {
      alterationProviderOptions(alterationProviderId: $alterationProviderId) {
        id
        isActive
        alterationOption {
          id
          name
          values
          isActive
          garmentCategory {
            key
          }
        }
        cost {
          formatted
        }
      }
    }
  `,
};
