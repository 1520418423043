import { OrderPageQuery, OrderStatus } from '@graphql';
import { RefreshIcon } from '@heroicons/react/solid';
import { Button } from 'modules/common/components/Button';
import { useState } from 'react';
import cn from 'classnames';
import { RemakeOrderModal } from './RemakeOrderModal';
import { toast } from 'react-toastify';
import router from 'next/router';

type Props = {
  className?: string;
  order: OrderPageQuery['order'];
  isDisabled: boolean;
};

export const RemakeOrderAction = ({ className, order, isDisabled }: Props) => {
  const [isRemakeModalOpen, setIsRemakeModalOpen] = useState(false);
  const isEnabled = !isDisabled && [OrderStatus.Processing, OrderStatus.Complete].includes(order.orderStatus.key);
  return (
    <>
      <Button onClick={() => setIsRemakeModalOpen(true)} isText className="mr-6" isDisabled={!isEnabled}>
        <div
          className={cn(className, 'flex items-center text-xs', {
            'text-gray-500': isEnabled,
            'text-gray-300': !isEnabled,
          })}
        >
          <RefreshIcon className="w-5 h-5 mr-2" />
          Remake order
        </div>
      </Button>
      {isEnabled && (
        <RemakeOrderModal
          isOpen={isRemakeModalOpen}
          onClose={() => setIsRemakeModalOpen(false)}
          order={order}
          onRemakeSuccess={(newOrderId) => {
            setIsRemakeModalOpen(false);
            toast.success('Remake Created');
            router.push(`/orders/${newOrderId}`);
          }}
        />
      )}
    </>
  );
};
