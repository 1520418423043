import { ReactNode } from 'react';
import cn from 'classnames';

export type VerticalMenuOption<T> = {
  content: ReactNode;
  value: T;
};

type Props<T> = {
  className?: string;
  onClick: (value: T) => void;
  options: VerticalMenuOption<T>[];
  selectedItemValue: T;
  errors?: string[];
};

const BASE_CLASSES = ['flex', 'flex-col']; // TODO: could make orientation configurable in the future
const MENU_ITEM_BASE_CLASSES = ['w-full', 'rounded', 'p-4', 'border-2', 'border-transparent', 'cursor-pointer'];

export function VerticalMenuSelection<T>({ options, onClick, className, selectedItemValue, errors }: Props<T>) {
  const classes = cn(BASE_CLASSES, className);

  const getMenuItemClasses = ({ isSelected, hasError }: { isSelected: boolean; hasError?: boolean }) => {
    return cn(MENU_ITEM_BASE_CLASSES, {
      'bg-yellow-crayola-50 border-yellow-crayola-500': isSelected && !hasError,
      'bg-red-50 border-red-500': isSelected && hasError,
      'text-red-500': hasError,
    });
  };

  return (
    <div className={classes}>
      {options.map((option, count) => {
        return (
          <div
            key={`vertical-menu-${count}`}
            className={getMenuItemClasses({
              isSelected: selectedItemValue === option.value,
              hasError: errors?.includes(String(option.content)),
            })}
            onClick={() => onClick(option.value)}
          >
            {option.content}
          </div>
        );
      })}
    </div>
  );
}
