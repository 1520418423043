import { Button } from 'modules/common';
import { Icon } from './Icon';

export const ErrorPanel = () => {
  return (
    <div className="flex items-center justify-center h-2/3 w-full">
      <div className="rounded-md shadow flex items-center justify-center flex-col p-12 md:p-24 w-2/3 w-full sm:max-w-7xl bg-white text-center">
        <div className="h-12 w-12 bg-red-50 rounded-lg flex items-center justify-center mb-4">
          <Icon icon="warning" className="text-red-600" />
        </div>
        <div className="text-sm leading-5 font-medium mb-2">Something went wrong</div>
        <div className="text-sm leading-5 mb-10">
          If this problem persists, please contact{' '}
          <a href="mailto:help@taper.ai" className="text-blue-600 underline">
            help@taper.ai
          </a>
        </div>
        <Button onClick={() => location.reload()}>Refresh page</Button>
      </div>
    </div>
  );
};
