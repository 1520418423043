import { Icon } from 'components';
import React from 'react';
import { JEROME_CMT_LINING_VENDOR_KEY, extractDesignOptions, isCustomLining } from 'modules/fabric/fabric.helpers';
import { DesignOptionsFormProviderOrderItemQueryResult } from '@graphql';
import { useDesignOptionsForm } from 'modules/designOptions/designOptions.context';
import { serializeDesignOptions } from 'modules/designOptions/designOptions.helpers';
import { DesignOptionsFormField } from 'modules/designOptions/components/DesignOptionsFormField';

export const CustomLiningForm = ({
  note,
  orderItem,
}: {
  note?: string;
  orderItem: DesignOptionsFormProviderOrderItemQueryResult['data']['orderItem'];
}) => {
  const { formData, loading } = useDesignOptionsForm();

  const vendorCmtOption = serializeDesignOptions(formData).find((field) => field.typeCode === JEROME_CMT_LINING_VENDOR_KEY);
  const isCustomLiningSelected = vendorCmtOption && isCustomLining(vendorCmtOption.value);

  const customLiningFields = (!loading && extractDesignOptions(orderItem)) || [];

  return (
    <div>
      <div className="mb-4">
        {isCustomLiningSelected && (
          <div className="flex ">
            <a
              className="text-sm font-medium text-blue-700 mr-3"
              target="_blank"
              rel="noreferrer"
              href={process.env.NEXT_PUBLIC_CUSTOM_LINING_URL || ''}
            >
              Create custom lining
            </a>
            <a className="ml-1 flex items-center" href="/custom-lining-guide.pdf" target="_blank">
              <Icon icon="info-filled" className="text-blue-500" />
            </a>
          </div>
        )}
      </div>
      <div className="flex flex-wrap gap-4">
        {customLiningFields.map(({ typeCode }) => (
          <div key={typeCode}>
            <DesignOptionsFormField typeCode={typeCode} />
          </div>
        ))}
      </div>
      {Boolean(note) && <div className="mt-6 text-sm">{note}</div>}
    </div>
  );
};
