import React from 'react';
import cn from 'classnames';

interface ContainerProps {
  isFluid?: boolean;
  title?: string;
  actions?: React.ReactNode[];
  isLoading?: boolean;
  children: React.ReactNode;
  noWrap?: boolean;
  className?: string;
}

const Container = (props: ContainerProps) => {
  const { isFluid, title, actions, isLoading, children, noWrap = false, className = '' } = props;

  return (
    <div className={cn({ 'sm:px-6 md:px-8 py-6': !noWrap }, { 'max-w-7xl mx-auto': !isFluid }, className)}>
      {!isLoading && (
        <>
          {(title || actions) && (
            <div className="lg:flex lg:items-center lg:justify-between mb-4 print:hidden">
              <div className="flex-1 min-w-0">
                <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{title && title}</h1>
              </div>

              {!!actions?.length && (
                <div className="flex items-center space-x-2 mt-2 lg:mt-0">
                  {actions.map((action, index) => (
                    <div key={index}>{action}</div>
                  ))}
                </div>
              )}
            </div>
          )}

          {children}
        </>
      )}
    </div>
  );
};

export { Container };
