import { formatAsCurrency } from 'helpers/reporting-helpers';
import { useActiveSession } from 'hooks/useActiveSessionContext';

export const PricingPill = ({ amount }: { amount: number }) => {
  const { tailor } = useActiveSession();

  return (
    <div className="bg-aquamarine-100 text-aquamarine-800 rounded-sm px-2 leading-5 text-xs">
      {formatAsCurrency(amount, tailor.locale, tailor.currency, false)}
    </div>
  );
};
