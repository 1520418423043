import React, { useState } from 'react';
import { useFilters, useTable } from 'react-table';

import { gql } from '@apollo/client';
import { GetDesignOptionOfferingsQuery } from '@graphql';
import { DesignOptionsSlideoverPanel } from './DesignOptionsSlideoverPanel';
import { Input } from 'components';

interface Props {
  options: GetDesignOptionOfferingsQuery['designOptionOfferings'];
  isLoading: boolean;
}

const columnsData = [
  {
    Header: 'Name',
    accessor: (row) => row.designOption?.name || row.name,
    id: 'name',
  },

  {
    Header: 'Code',
    accessor: 'code',
  },

  {
    Header: () => <div className="text-center">Price</div>,
    accessor: 'priceFormatted.formatted',
    disableFilters: true,
    Cell: ({ value }) => <div className="text-center">{value || '-'}</div>,
  },
];

export const DesignOptionsListTable = (props): React.ReactElement<Props> => {
  const { options, isLoading, refetch } = props;

  const [selectedOption, setSelectedOption] = useState(null);
  const [isOptionFormPanelOpen, setIsOptionFormPanelOpen] = useState(false);

  const data = React.useMemo(() => options, [options]);

  const columns = React.useMemo(
    () =>
      isLoading
        ? columnsData.map((column) => ({
            ...column,
            Cell: <div className="animate-pulse h-6 rounded bg-gray-200" />,
          }))
        : columnsData,
    [isLoading]
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: ({ column }) => {
        return (
          <Input
            htmlProps={{
              type: 'text',
              value: column.filterValue || '',
              onChange: (e) => {
                column.setFilter(e.currentTarget.value || undefined);
              },
              placeholder: `Filter by ${column.Header}`,
            }}
          />
        );
      },
    }),
    []
  );

  const tableInstance = useTable({ columns, data, defaultColumn }, useFilters);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const handleOnRowClick = (e, row) => {
    e.preventDefault();

    setSelectedOption(row.original);
    setIsOptionFormPanelOpen(true);
  };

  return (
    <>
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mt-6">
        <table {...getTableProps()} className="min-w-full divide-y divide-gray-200 table-fixed">
          <thead className="bg-gray-50">
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, i) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps()}
                        className={`p-4 text-xs font-semibold uppercase tracking-wider text-left ${i === 1 ? 'w-2/6' : 'w-1/6'}`}
                        key={i}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }

            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()} key={index} className="bg-gray-100 border-t">
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, i) => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps()} className="p-2 text-xs font-semibold uppercase tracking-wider text-left" key={i}>
                        {column.canFilter ? column.render('Filter') : null}
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);

                return (
                  // Apply the row props
                  <tr
                    {...row.getRowProps()}
                    className={`cursor-pointer text-gray-500 hover:bg-gray-100 hover:text-gray-900 group ${
                      index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                    }`}
                    onClick={
                      (e) => handleOnRowClick(e, row)
                      // router.push(`/design-options/${row.original.id}`)
                    }
                    key={row.id}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, index) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()} className="p-4 text-sm text-left" key={index}>
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>

      {!!selectedOption && (
        <DesignOptionsSlideoverPanel
          selectedOption={selectedOption}
          isOpen={isOptionFormPanelOpen}
          setIsOpen={setIsOptionFormPanelOpen}
          refetch={refetch}
        />
      )}
    </>
  );
};

DesignOptionsListTable.queries = {
  coreDesignOptions: gql`
    query GetDesignOptionOfferings($garmentCategory: GarmentCategory!, $typeCode: String!) {
      designOptionOfferings(garmentCategory: $garmentCategory, typeCode: $typeCode) {
        id
        name
        code
        type {
          id
          name
          code
        }
        priceFormatted {
          amount
          formatted
        }
      }
      garments {
        category
        label
      }
    }
  `,
};
