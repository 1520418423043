import { OrderPageQuery } from '@graphql';
import { Box, TextSkeleton } from 'components';
import { FormattedAddress } from 'modules/orders/components/FormattedAddress';

interface Props {
  order: OrderPageQuery['order'];
  loading?: boolean;
}

export const ShowroomAddress = ({ order, loading }: Props) => {
  if (loading) {
    return (
      <Box allowSkeletonLoading>
        <TextSkeleton className="w-44" />
        <TextSkeleton size="small" className="mt-8 w-32" />
        <TextSkeleton size="small" className="w-24 mt-1" />
        <TextSkeleton size="small" className="w-24 mt-1" />
        <TextSkeleton size="small" className="w-20 mt-1" />
        <TextSkeleton className="w-full h-8 mt-8" />
      </Box>
    );
  }

  return (
    <Box>
      <h3 className="font-semibold mb-4 flex items-center">Shipping To:</h3>
      {order?.showroom?.address ? <FormattedAddress address={order.showroom.address} /> : 'No address was found for this showroom'}
    </Box>
  );
};
