import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import mixpanelService from 'services/mixpanel.service';
import TabButton, { Props as TabButtonProps } from './TabButton';

export interface ITab {
  name: string;
  content: React.ReactElement;
  isHidden?: boolean;
  onChangeTab?: () => void;
}

export interface TabsProps {
  tabs: ITab[];
  tabsClassName?: string;
  tabsListClassName?: string;
  panelsClassName?: string;
  panelClassName?: string;
  buttonClassName?: string;
  TabButtonComponent?: React.JSXElementConstructor<TabButtonProps>;
  /**
   * Only required for use with TabsWithRoute component.
   */
  selectedIndex?: number;
}

const Tabs = ({
  tabs,
  tabsClassName = 'border-b border-gray-200',
  tabsListClassName = '-mb-px flex overflow-y-auto',
  panelsClassName = 'mt-4',
  panelClassName,
  buttonClassName,
  TabButtonComponent = TabButton,
  selectedIndex,
}: TabsProps): React.ReactElement => {
  const handleButtonClick = (selectedTab: ITab) => {
    mixpanelService.track('TAB_CLICKED', { tabName: selectedTab.name });
    selectedTab.onChangeTab && selectedTab.onChangeTab();
  };

  return (
    <Tab.Group selectedIndex={selectedIndex}>
      <div className={tabsClassName}>
        <Tab.List className={tabsListClassName}>
          {tabs.map((tab, index) => (
            <Tab as={Fragment} key={index}>
              {({ selected }) => (
                <TabButtonComponent className={buttonClassName} isSelected={selected} isHidden={tab.isHidden} onClick={() => handleButtonClick(tab)}>
                  {tab.name}
                </TabButtonComponent>
              )}
            </Tab>
          ))}
        </Tab.List>
      </div>

      <Tab.Panels className={panelsClassName}>
        {tabs.map((tab, index) => (
          <Tab.Panel key={index} className={panelClassName}>
            {tab.content}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export { Tabs };
