// https://headlessui.com/react/menu#integrating-with-next-js

import { forwardRef } from 'react';
import Link from 'next/link';

interface Props {
  href: string;
  className?: string;
  children: React.ReactNode;
}

export const LinkInPopup = forwardRef<HTMLAnchorElement, Props>(({ href, children, ...rest }, ref) => {
  return (
    <Link href={href} ref={ref} {...rest}>
      {children}
    </Link>
  );
});
