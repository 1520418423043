import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import cn from 'classnames';

interface CustomInputHTMLProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  'data-cy'?: string;
}

export interface InputProps {
  className?: string;
  register?: UseFormRegisterReturn;
  label?: string;
  htmlProps?: CustomInputHTMLProps;
  errorMessage?: string;
  testId?: string;
  showError?: boolean;
  hideSpinBox?: boolean;
}

export const InputLabel = ({ label, htmlProps }: Pick<InputProps, 'label' | 'htmlProps'>) => (
  <>
    {label && (
      <label htmlFor={htmlProps?.id} className="block text-xs font-medium text-gray-500 cursor-pointer mb-1">
        {label}
      </label>
    )}
  </>
);

export const InputSymbol = ({ symbol }: { symbol: string }) => (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <span className="text-gray-500 sm:text-sm" id="price-currency">
      {symbol}
    </span>
  </div>
);

export const InputError = ({ showError, errorMessage }: Pick<InputProps, 'showError' | 'errorMessage'>) => (
  <>{showError && errorMessage && <span className="inline-flex text-red-500 text-xs">{errorMessage}</span>}</>
);

export const defaultErrorStyles = ({ errorMessage }: Pick<InputProps, 'errorMessage'>) => ({
  'border-red-300 focus:ring-red-500 focus:border-red-500': errorMessage,
  'border-gray-300 focus:ring-gray-500 focus:border-gray-500': !errorMessage,
});

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, register, errorMessage, label, htmlProps, testId, showError = true, hideSpinBox = true }, ref): React.ReactElement => {
    return (
      <div className={className}>
        <InputLabel htmlProps={htmlProps} label={label} />
        <input
          data-testid={testId}
          ref={ref}
          className={cn(
            'block w-full shadow-sm sm:text-sm rounded-md disabled:cursor-not-allowed disabled:text-gray-400 disabled:bg-gray-100 font-normal',
            defaultErrorStyles({ errorMessage }),
            {
              'no-spin-box': hideSpinBox,
            }
          )}
          {...register}
          {...htmlProps}
        />
        <InputError showError={showError} errorMessage={errorMessage} />
      </div>
    );
  }
);

export { Input };

/**
 * NOTES
 *
 * ### Do not add classes to htmlProps because it will break the existing styles, if you need to control the width of the input, use a wrapper instead.
 *
 * Don't do: <Input htmlProps={{ className: 'w-12' }} />
 * Do: <div className="w-12"><Input /></div>
 *
 * ### htmlProps.id is required when using <Input /> with label, so that the input will have focus when clicking on the label.
 */
