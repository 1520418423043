import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface TextareaProps {
  register?: UseFormRegisterReturn;
  label?: string;
  errorMessage?: string;
  htmlProps?: React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;
  testId?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref): React.ReactElement => {
  const { register, label, errorMessage, htmlProps, testId } = props;

  return (
    <>
      {label && (
        <label htmlFor={htmlProps?.id} className="block text-xs font-medium text-gray-500 cursor-pointer mb-1">
          {label}
        </label>
      )}
      <textarea
        data-testid={testId}
        ref={ref}
        className={`shadow-sm block w-full sm:text-sm rounded-md ${
          errorMessage ? 'border-red-300 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-gray-500 focus:border-gray-500'
        }`}
        {...register}
        {...htmlProps}
      />
      {errorMessage && <span className="inline-flex text-red-500 text-xs mt-2">{errorMessage}</span>}
    </>
  );
});

export { Textarea };
