import { gql } from '@apollo/client';
import { Tag, useGetTagsQuery, useUpsertTagMutation, useUpdateOrderTagsMutation } from '@graphql';
import { CreatableMultiSelect, Spinner } from 'components';
import { Button, parseRouteId } from 'modules/common';
import router from 'next/router';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { SelectOption } from 'types/common';
import { OrderTimeline } from 'modules/orders/components/OrderTimeline';

interface Props {
  handleCancel: () => void;
  onSuccess: () => void;
  defaultTags: Partial<Tag>[];
}

export const EditTags = ({ handleCancel, onSuccess, defaultTags }: Props) => {
  const { orderId } = router.query;
  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>(defaultTags.map(({ name: label, id: value }) => ({ label, value })));
  const [updateOrderTags, { loading: isSavingOrderTags }] = useUpdateOrderTagsMutation();
  const [upsertTag] = useUpsertTagMutation();

  // Disable save button unless tags have been edited
  // TODO: improve this to work no matter the order of the tags
  const isSaveButtonDisabled = useMemo(
    () => defaultTags.map((tag) => tag.id).join('') === selectedOptions.map((option) => option.value).join(''),
    [defaultTags, selectedOptions]
  );

  const { data, loading } = useGetTagsQuery();
  const options = data?.tags.map(({ name: label, id: value }) => ({ label, value })) || [];

  const handleCreateTag = async (label: string) => {
    const { data } = await upsertTag({ variables: { tagInput: { name: label } } });
    const newTag = data?.upsertTag;

    setSelectedOptions([...selectedOptions, { label, value: newTag.id }]);
  };

  const handleSave = async () => {
    try {
      if (!orderId) {
        toast.error('Had trouble saving tags.');
        console.error('Tried saving a tag with no associated order id.');
        return;
      }
      await updateOrderTags({
        variables: {
          orderId: parseRouteId(orderId),
          updateOrderInput: {
            tagIds: selectedOptions.map((option) => option.value as string),
          },
        },
      });
      toast.success('Tags updated.');
      onSuccess();
    } catch {
      toast.error('Had trouble saving tags.');
    }
  };

  return (
    <div className="flex items-center">
      <div className="w-80">
        <CreatableMultiSelect
          options={options}
          defaultValue={selectedOptions}
          onChange={(values) => setSelectedOptions(values)}
          onCreateOption={handleCreateTag}
          isLoading={loading}
        />
      </div>
      <Button className="text-sm ml-2" onClick={handleSave} isDisabled={isSavingOrderTags || isSaveButtonDisabled}>
        {isSavingOrderTags ? <Spinner /> : 'Save'}
      </Button>
      <Button className="text-sm ml-1" variant="neutral" onClick={handleCancel} isDisabled={isSavingOrderTags}>
        Cancel
      </Button>
    </div>
  );
};

EditTags.mutations = {
  updateOrderTags: gql`
    ${OrderTimeline.fragments.root}

    mutation UpdateOrderTags($orderId: ID!, $updateOrderInput: UpdateOrderInput!) {
      updateOrder(orderId: $orderId, updateOrderInput: $updateOrderInput) {
        id
        tags {
          id
          name
        }
        ...OrderTimeline
      }
    }
  `,
};
