import { gql } from '@apollo/client';

import { RetailPriceType, useGetProductPricingQuery, useSetupProductPricingMutation } from '@graphql';
import { OnPriceClick } from 'modules/pricing/pricing.types';
import { PricingTab } from 'modules/pricing/components/PricingTab';

interface Props {
  isCutLength?: boolean;
  onPriceClick: OnPriceClick;
}

export const FabricTab = (props: Props) => {
  const { data, loading: isLoading } = useGetProductPricingQuery({
    variables: {
      fabricTierIsCutLength: props.isCutLength ?? false,
      pricingType: props.isCutLength ? RetailPriceType.TierCutLength : RetailPriceType.Tier,
    },
  });
  const [setupPricing] = useSetupProductPricingMutation({ refetchQueries: ['GetProductPricing'] });

  return <PricingTab type="Fabric" data={data} isLoading={isLoading} onSetupClick={setupPricing} {...props} />;
};

FabricTab.fragments = {
  tier: gql`
    fragment FabricTier on FabricTier {
      id
      name
      isCutLength
    }
  `,
};

FabricTab.queries = {
  root: gql`
    ${FabricTab.fragments.tier}

    query GetProductPricing($pricingType: RetailPriceType!, $fabricTierIsCutLength: Boolean!) {
      prices: productRetailPrices(pricingType: $pricingType) {
        id
        amount
        productCategory
        tier: fabricTier {
          ...FabricTier
        }
      }
      settings {
        productCategories {
          key
          truncatedName
        }
      }
      tiers: fabricTiers(isCutLength: $fabricTierIsCutLength) {
        ...FabricTier
      }
    }
  `,
};

FabricTab.mutation = {
  setupProductPricing: gql`
    mutation SetupProductPricing($productCategories: [ProductCategory!]!) {
      setupProductPricing(productCategories: $productCategories) {
        id
        amount
        productCategory
        tier: fabricTier {
          ...FabricTier
        }
      }
    }
  `,
};
