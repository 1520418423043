import { useRouter } from 'next/router';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  GarmentMeasurementField,
  GarmentMeasurementInput,
  MeasurementType,
  MeasurementUnit,
  useGetGarmentMeasurementQuery,
  useUpsertGarmentMeasurementMutation,
} from '@graphql';
import { Box, Input, SlideoverPanel } from 'components';
import { getAbbreviatedDate } from 'helpers/date-helpers';
import { useOnComplete, useOnError, useOnRedirect, getDefaultNameForMeasurement, getUnitLongName } from 'helpers/measurement-helpers';
import { Button } from 'modules/common';
import { FormProps } from 'modules/measurements/components/MeasurementFormWrap';
import { MeasurementFormFooter } from 'modules/measurements/components/MeasurementFormFooter';
import { GarmentInfo } from './GarmentInfo';
import { useMeasurementUnit } from 'modules/common/MeasurementUnit';
import { GarmentField, GarmentMeasurementFields } from './GarmentMeasurementFields';

export const GarmentMeasurementForm = ({
  customerId,
  measurementId,
  garmentCategory,
  frontBackLengthValidationConfig,
  isGarmentDisabled,
}: FormProps) => {
  const [open, setOpen] = useState(false);
  const [activeGarment, setActiveGarment] = useState<GarmentField>(null);
  const router = useRouter();
  const onComplete = useOnComplete({ router, toast });
  const onError = useOnError(toast);
  const onRedirect = useOnRedirect(router);
  const { convertFromServerUnit, convertToServerUnit, measurementUnitInfo, updateMeasurementUnit, convertUnit } = useMeasurementUnit();

  const useFormMethods = useForm<GarmentMeasurementInput>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useFormMethods;

  const { data, loading } = useGetGarmentMeasurementQuery({
    skip: !garmentCategory,
    variables: {
      garmentCategory,
      measurementId,
    },
    onCompleted: (data) => {
      const existingMeasurementData = data.measurement;
      const measurementSettingsData = data.settings.measurement;

      reset({
        name: existingMeasurementData?.name || getDefaultNameForMeasurement({ garmentCategory, dateStr: getAbbreviatedDate() }),
        fields: measurementSettingsData?.garment.fields.map((field) => {
          const value = existingMeasurementData?.fields?.find((f) => f.key === field.key)?.value;
          return {
            key: GarmentMeasurementField[field.key],
            value: value ? convertFromServerUnit(value) : 0,
          };
        }),
      });
    },

    onError: (error) => {
      console.error(error);
    },
  });

  const [upsertGarmentMeasurement, { loading: isMutationLoading }] = useUpsertGarmentMeasurementMutation({
    onCompleted: ({ upsertGarmentMeasurement: { id } }) =>
      onComplete({
        measurementId,
        customerId,
        orderId: router.query?.orderId as string,
        designId: router.query?.designId as string,
        newMeasurementId: measurementId ? '' : id,
        measurementType: MeasurementType.Garment,
      }),
    refetchQueries: ['OrderItemMeasurementCellMeasurements'],
    onError,
  });

  const onSubmit = handleSubmit(async (formData) => {
    try {
      await upsertGarmentMeasurement({
        variables: {
          customerId: customerId || (router.query.customerId as string),
          garmentMeasurementInput: {
            id: measurementId,
            name: formData.name,
            fields: formData.fields.map((f) => ({ ...f, value: convertToServerUnit(f.value) })),
            garmentCategory,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  });

  const onCancel = () => onRedirect({ customerId, orderId: router.query?.orderId as string });

  const bodyFields = watch('fields');

  const nextMeasurementUnit = measurementUnitInfo.current === MeasurementUnit.Cm ? MeasurementUnit.Inch : MeasurementUnit.Cm;

  return (
    <>
      {garmentCategory && (
        <FormProvider {...useFormMethods}>
          <form>
            <div className="mb-4">
              <fieldset disabled={isGarmentDisabled}>
                <Box isLoading={loading}>
                  <Input
                    label="Name"
                    htmlProps={{
                      id: 'name',
                      type: 'text',
                    }}
                    register={register('name', {
                      required: 'Please enter a name.',
                    })}
                    errorMessage={errors?.name?.message}
                  />
                </Box>
              </fieldset>
            </div>
            <Box isLoading={loading}>
              <fieldset disabled={isGarmentDisabled}>
                <div className="flex w-full justify-between items-center mb-4">
                  <h2 className="font-semibold text-xl mb-4">Measurements</h2>
                  <Button
                    className="ml-auto"
                    size="xs"
                    variant="neutral"
                    onClick={() => {
                      bodyFields.forEach((_, i) => {
                        const currentValue = getValues(`fields.${i}.value`);
                        const convertedValue = convertUnit(currentValue, nextMeasurementUnit);
                        setValue(`fields.${i}.value`, convertedValue);
                      });
                      updateMeasurementUnit(nextMeasurementUnit);
                    }}
                  >{`Convert to ${getUnitLongName(nextMeasurementUnit)}`}</Button>
                </div>
                <GarmentMeasurementFields
                  convertFromServerUnit={convertFromServerUnit}
                  data={data}
                  measurementUnitInfo={measurementUnitInfo}
                  onOpenGarmentPanel={(garmentField) => {
                    setActiveGarment(garmentField);
                    setOpen(true);
                  }}
                  garmentCategory={garmentCategory}
                  frontBackLengthValidationConfig={frontBackLengthValidationConfig}
                />
              </fieldset>
            </Box>
            <MeasurementFormFooter
              isDisabled={isMutationLoading}
              isNew={!measurementId}
              onCancel={onCancel}
              onSubmit={onSubmit}
              isGarmentDisabled={isGarmentDisabled}
            />
          </form>
        </FormProvider>
      )}
      <SlideoverPanel
        isOpen={open}
        setIsOpen={setOpen}
        maxWidthCss="max-w-xl"
        showCancel={false}
        showExit={false}
        submitButton={<Button onClick={() => setOpen(false)}>Close</Button>}
      >
        {activeGarment && <GarmentInfo garment={activeGarment} />}
      </SlideoverPanel>
    </>
  );
};

import { gql } from '@apollo/client';

GarmentMeasurementForm.query = gql`
  query GetGarmentMeasurement($garmentCategory: GarmentCategory!, $measurementId: ID) {
    measurement(measurementId: $measurementId) {
      id
      name
      fields {
        key
        name
        value
      }
    }
    settings {
      id
      measurement {
        garment(garmentCategory: $garmentCategory) {
          fields {
            name
            key
            range {
              min
              max
            }
            isRequired
            info(type: GARMENT) {
              text
              image
            }
          }
        }
      }
    }
  }
`;

GarmentMeasurementForm.mutations = {
  UpsertGarmentMeasurement: gql`
    mutation UpsertGarmentMeasurement($garmentMeasurementInput: GarmentMeasurementInput!, $customerId: ID!) {
      upsertGarmentMeasurement(garmentMeasurementInput: $garmentMeasurementInput, customerId: $customerId) {
        id
        name
        updatedAt {
          fromNow
          origin
        }
        type {
          name
          key
        }
        updatedAt {
          fromNow
          origin
        }
        garmentCategory {
          key
          name
        }
      }
    }
  `,
};
