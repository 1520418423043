import React, { ReactNode } from 'react';
import cn from 'classnames';

type Props<T> = {
  className?: string;
  children: ReactNode;
  onChange: (value: T) => void;
  name: string;
  value: T;
  isSelected?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
};

const BASE_CLASSES = ['flex', 'relative', 'block', 'text-sm', 'text-gray-550', 'my-2', 'cursor-pointer', 'items-center', 'mx-4', 'first:ml-0'];

export function RadioButton<T>({ isSelected, isDisabled, isRequired, children, name, className, onChange, value }: Props<T>) {
  const classes = cn(BASE_CLASSES, className, {
    'opacity-50 pointer-events-none': isDisabled,
    'border-gray-300': !isSelected,
  });

  return (
    <label className={classes}>
      <input
        className="mr-2"
        onChange={() => onChange(value)}
        type="radio"
        name={name}
        checked={isSelected}
        required={isRequired}
        disabled={isDisabled}
      />
      {children}
    </label>
  );
}
