import { createContext, useContext } from 'react';

import { DesignOptionsFormProviderGroupsFragment } from '@graphql';
import { ConfigGroup } from 'types/common';

type Config = {
  extras: ConfigGroup[];
  foundations: ConfigGroup[];
};

export type DesignOptionsContextType = {
  formData: Record<string, string>;
  designOptionGroups: DesignOptionsFormProviderGroupsFragment[];
  setField: ({ id, value }: { id: string; value: string }) => void;
  setAndSaveField: ({
    id,
    value,
    label,
    displayValue,
    forceSave,
  }: {
    id: string;
    value: string;
    displayValue: string | number;
    label: string;
    forceSave?: boolean;
  }) => void;
  getIsFieldHidden: (typeCode: string) => boolean;
  conflicts: {
    message: string;
    conflictOption: { typeCode: string; value: string };
    sourceOption: { typeCode: string; value: string };
    maxLength: string;
    pattern: string;
  }[];
  getPrice: ({ typeCode }: { typeCode: string }) => number;

  config: Config;
  selectedGroup: ConfigGroup;
  setSelectedGroup: React.Dispatch<React.SetStateAction<ConfigGroup>>;
  loading: boolean;
  isTemplate: boolean;
};

export const DesignOptionsContext = createContext<DesignOptionsContextType | null>(null);

export const useDesignOptionsForm = () => {
  const context = useContext(DesignOptionsContext);

  if (!context) {
    throw new Error('This component must be used within a <DesignOptionsFormProvider> component.');
  }

  return context;
};
