import React from 'react';
import Head from 'next/head';

import TableFilters from 'components/TableFilters';
import { AlterationsListTable } from 'modules/alterations';
import { baseSiteTitle } from 'pages/_app';
import { QueryParam } from 'components/QueryComboBox';
import { StatusParam } from 'components/StatusCombobox';
import { AlterationStatus } from '@graphql';
import { CreateNewAlterationButton } from 'components/CreateNewAlterationButton';

// use these when generating header links etc
export const getDefaultQueryParams = (params?: { [key: string]: string | string[] }) => ({
  alterationStatus: [AlterationStatus.InProgress, AlterationStatus.Cancelled, AlterationStatus.Completed] as string[],
  ...params,
});

const AlterationsListPage = (): React.ReactElement => (
  <>
    <Head>
      <title>{baseSiteTitle} | Alterations</title>
    </Head>
    <TableFilters
      heading="Alterations"
      searchPlaceholder="Order #"
      statusFilter={StatusParam.AlterationStatus}
      ctaContent={<CreateNewAlterationButton />}
      filters={[QueryParam.Provider, QueryParam.User]}
    >
      <AlterationsListTable />
    </TableFilters>
  </>
);

export default AlterationsListPage;
