import { gql } from '@apollo/client';
import { toast } from 'react-toastify';
import { Box, Select } from 'components';
import { PuzzleIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import { useGetAlterationQuery, useUpdateAlterationMutation, AlterationStatus } from '@graphql';
import { useAlterationOptionValue } from './useAlterationOptionValue';

export const AlterationsDetailsScreen = ({ id: alterationId }: { id: string }) => {
  const { data } = useGetAlterationQuery({
    variables: {
      alterationId,
    },
  });
  const { convertFromAlterationOptionServerUnit } = useAlterationOptionValue();

  const [updateAlteration] = useUpdateAlterationMutation({
    onCompleted: () => {
      toast.success('Status updated.');
    },

    onError: () => {
      toast.error('Please try again.');
    },
  });

  const onStatusChange = async (e) => {
    await updateAlteration({
      variables: {
        alterationId,
        updateAlterationInput: {
          status: e.currentTarget.value,
        },
      },
    });
  };

  return (
    <div className="print:hidden">
      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-4">
          <Box>
            <div className="space-y-4">
              {!!data?.alteration?.items?.length &&
                data.alteration.items.map((item) => (
                  <div key={item.id}>
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="text-md font-semibold flex justify-between items-center">
                          {item.orderItemDesign.orderItem.number} - {item.orderItemDesign.garmentCategory.name}
                        </h3>

                        {!!item.note && <div className="text-sm">{item.note}</div>}
                      </div>
                    </div>

                    <ul className="mt-2">
                      {item.options.map((option) => (
                        <li className="py-2 flex items-center justify-between text-sm border-t" key={option.id}>
                          <div className="w-0 flex-1 flex items-center">
                            <PuzzleIcon className="shrink-0 h-4 w-4 text-gray-400" aria-hidden="true" />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {option.name} - {convertFromAlterationOptionServerUnit(option.value)}
                            </span>
                          </div>
                          <div className="ml-4 shrink-0">{option.cost.formatted}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </Box>
        </div>

        <div className="col-span-2">
          <Box>
            <Select
              label="Status"
              htmlProps={{
                value: data?.alteration.status.key,
                onChange: onStatusChange,
              }}
            >
              <option value={AlterationStatus.Completed}>Completed</option>
              <option value={AlterationStatus.InProgress}>In progress</option>
              <option value={AlterationStatus.Cancelled}>Cancelled</option>
            </Select>

            <ul className="text-sm text-gray-400 space-y-2 mt-4">
              <li className="flex justify-between">
                <span>Order:</span>
                <span className="text-right">
                  <Link href={`/orders/${data?.alteration.order.id}`} className="text-blue-500 hover:text-blue-600">
                    <b>#{data?.alteration.order.customId}</b>
                  </Link>
                </span>
              </li>

              <li className="flex justify-between">
                <span>Customer:</span>
                <span className="text-right">
                  <Link href={`/customers/${data?.alteration.order.customer.id}`} className="text-blue-500 hover:text-blue-600">
                    <b>{data?.alteration.order.customer.fullName}</b>
                  </Link>
                </span>
              </li>

              <li className="flex justify-between">
                <span>Status:</span>
                {data?.alteration.status.key === AlterationStatus.Cancelled && (
                  <span className="text-red-500 font-semibold text-right">{data?.alteration.status.name}</span>
                )}

                {data?.alteration.status.key === AlterationStatus.Completed && (
                  <span className="text-emerald-500 font-semibold text-right">{data?.alteration.status.name}</span>
                )}

                {data?.alteration.status.key === AlterationStatus.InProgress && (
                  <span className="text-amber-500 font-semibold text-right">{data?.alteration.status.name}</span>
                )}
              </li>

              <li className="flex justify-between">
                <span>Due on:</span>
                <span className="text-right">{data?.alteration.dueAt.toNow}</span>
              </li>

              <li className="flex justify-between">
                <span>Provider:</span>
                <span className="text-right">{data?.alteration.provider.name}</span>
              </li>

              <li className="flex justify-between">
                <span>Created by:</span>
                <span className="text-right">
                  {data?.alteration.createdBy.firstName} {data?.alteration.createdBy.lastName}
                </span>
              </li>

              <li className="flex justify-between">
                <span>Created on:</span>
                <span className="text-right">{data?.alteration.createdAt.fromNow}</span>
              </li>
            </ul>
          </Box>
        </div>
      </div>
    </div>
  );
};

AlterationsDetailsScreen.queries = {
  alteration: gql`
    query GetAlteration($alterationId: ID!) {
      alteration(alterationId: $alterationId) {
        id
        provider {
          id
          name
        }
        dueAt {
          toNow
          fromNow
          formatted(format: "YYYY-MM-DD")
        }
        createdAt {
          fromNow
          formatted(format: "YYYY-MM-DD")
        }
        createdBy {
          id
          firstName
          lastName
        }
        status {
          key
          name
        }
        items {
          id
          note

          orderItemDesign {
            garmentCategory {
              key
              name
            }
            orderItem {
              number
            }
          }

          options {
            id
            name
            value
            cost {
              formatted
            }
          }
        }
        order {
          id
          customId
          customer {
            id
            customId
            fullName
          }
        }
        updatedAt {
          origin
          fromNow
        }
      }
    }
  `,
};

AlterationsDetailsScreen.mutations = {
  updateAlteration: gql`
    mutation UpdateAlteration($alterationId: ID!, $updateAlterationInput: UpdateAlterationInput!) {
      updateAlteration(alterationId: $alterationId, updateAlterationInput: $updateAlterationInput) {
        id
        status {
          key
          name
        }
      }
    }
  `,
};
