import { gql } from '@apollo/client';
import { GetUsersQuery, useGetUsersQuery, useUpdateOrderStaffMutation } from '@graphql';
import { Select } from 'components';
import { toast } from 'react-toastify';
import { OrderTimeline } from 'modules/orders/components/OrderTimeline';
import { orderBy } from 'lodash';

interface Props {
  defaultSalesRep?: GetUsersQuery['users'][0];
  orderId?: string;
  label?: string;
  onSuccess?: () => void;
}

export const StaffSelect = ({ defaultSalesRep, orderId, label, onSuccess }: Props) => {
  const { data, loading } = useGetUsersQuery({ fetchPolicy: 'cache-first' });

  const [updateOrderStaff] = useUpdateOrderStaffMutation({
    onCompleted: (mutationData) => {
      toast.success(`Sales Rep changed to ${mutationData?.updateOrder.salesRep.firstName} ${mutationData?.updateOrder.salesRep.lastName}`);
    },
  });

  const onChange = async (e) => {
    if (!e.currentTarget.value) {
      return;
    }

    if (orderId) {
      await updateOrderStaff({
        variables: {
          orderId,
          updateOrderInput: {
            salesRepId: e.currentTarget.value,
          },
        },
      });
      onSuccess();
    }
  };

  return (
    <>
      {loading || !data ? (
        <>Loading</>
      ) : (
        <Select label={label && label} htmlProps={{ onChange, defaultValue: defaultSalesRep?.id || '' }}>
          <option value="">Please select</option>
          {data.users.length &&
            orderBy(data?.users, ['lastName'], ['asc']).map((staff) => (
              <option key={staff.id} value={staff.id}>
                {staff.firstName} {staff.lastName}
              </option>
            ))}
        </Select>
      )}
    </>
  );
};

StaffSelect.mutations = {
  updateOrderStaff: gql`
    ${OrderTimeline.fragments.root}

    mutation UpdateOrderStaff($orderId: ID!, $updateOrderInput: UpdateOrderInput!) {
      updateOrder(orderId: $orderId, updateOrderInput: $updateOrderInput) {
        id
        salesRep {
          id
          firstName
          lastName
        }
        ...OrderTimeline
      }
    }
  `,
};
