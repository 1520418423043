import { ConflictItem } from 'helpers/measurement-helpers';

export const ConflictError = ({ type, item, option }: ConflictItem) => (
  <div>
    <span className="font-semibold">{type}</span> is set to{' '}
    <span className="font-semibold">
      {item} {`"${option}"`}
    </span>
  </div>
);
