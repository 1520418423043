import { AlterationsDetailsScreen, AlterationsDetailsPrint } from 'modules/alterations/components';

interface Props {
  id: string;
}

export const AlterationsDetails = (props: Props) => {
  return (
    <>
      <AlterationsDetailsScreen {...props} />
      <AlterationsDetailsPrint {...props} />
    </>
  );
};
