import { gql } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

import { Modal } from 'components';
import { Button } from 'modules/common';
import { Icon } from 'components';
import { useCreateOrderMutation, useGetCreateOrderCustomersLazyQuery } from 'types/generated';
import mixpanelService from 'services/mixpanel.service';
import { debounce } from 'lodash';

interface Option {
  readonly label: string;
  readonly value: string;
  readonly disabled?: boolean;
}

type Props = {
  customerId?: string;
  eventFrom: string;
};

export const CreateNewOrderButton = ({ customerId, eventFrom }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Option>();
  const [createOrder, { loading: creatingOrder }] = useCreateOrderMutation({ refetchQueries: ['GetOrders'] });
  const [searchCustomers, { loading: queryLoading, error }] = useGetCreateOrderCustomersLazyQuery();
  const router = useRouter();
  const loading = queryLoading || creatingOrder;

  useEffect(() => {
    if (!modalOpen) setSelectedCustomer(null);
  }, [modalOpen]);

  const handleCreateOrder = async () => {
    try {
      const { data } = await createOrder({ variables: { customerId: customerId || selectedCustomer.value } });
      mixpanelService.track('ORDER_CREATE', { orderId: data.createOrder.id });

      router.push(`/orders/${data.createOrder.id}`);
    } catch {
      toast.error("Couldn't create new order.");
    }
    setModalOpen(false);
  };

  const searchOptions = useMemo(
    () =>
      debounce((value: string, callback: (options: Option[]) => void) => {
        searchCustomers({ variables: { search: value } }).then(({ data }) => {
          const newOptions = data.customers.items
            .map((customer) => ({
              value: customer.id,
              label: `${customer.firstName} ${customer.lastName} ${customer.customId ? `- ${customer.customId}` : ''}`,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
          callback(newOptions);
        });
      }, 500),
    [searchCustomers]
  );

  return (
    <>
      <Button
        eventName="Create Order Button Clicked"
        eventData={{ from: eventFrom }}
        onClick={() => (customerId ? handleCreateOrder() : setModalOpen(true))}
      >
        <Icon icon="add-circle" className="h-4" />
        <span className="hidden xl:inline-block ml-1">New order</span>
      </Button>
      <Modal isOpen={modalOpen} setIsOpen={setModalOpen}>
        <div className="font-semibold text-center text-lg mb-6">Create new order</div>
        <AsyncCreatableSelect
          cacheOptions
          className="text-sm"
          placeholder={'Assign a customer'}
          isDisabled={creatingOrder}
          value={selectedCustomer}
          onChange={(value) => setSelectedCustomer(value)}
          formatCreateLabel={() => <div className="text-blue-500 w-full text-center">Add a new customer</div>}
          onCreateOption={() => router.push('/customers/new')}
          isValidNewOption={(input) => input.length >= 3}
          styles={{
            control: (base, state) => ({
              ...base,
              border: state.isFocused ? '2px solid #6b7280' : '1px solid #d1d5db',
              boxShadow: 'none',
              '*': {
                boxShadow: 'none !important',
              },
              '&:hover': {
                border: '2px solid #6b7280',
              },
              borderRadius: '6px',
              padding: '2px 0',
            }),
            menuPortal: (base) => ({ ...base, zIndex: 50 }),
          }}
          menuPortalTarget={document.body}
          isSearchable={true}
          isOptionDisabled={(option) => option.disabled}
          loadOptions={(value, callback) => {
            if (value.length < 3) {
              searchOptions.cancel();
              callback([{ label: 'Write at least 3 characters', value: '', disabled: true }]);
            } else {
              searchOptions(value, callback);
            }
          }}
          allowCreateWhileLoading={false}
          isLoading={queryLoading}
        />
        <div className="flex justify-between mt-6">
          <Button variant="neutral" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <div className="flex gap-2">
            <Button variant="neutral" onClick={() => router.push('/customers/new')}>
              Add a new customer
            </Button>
            <Button onClick={handleCreateOrder} isDisabled={loading || !!error || !selectedCustomer}>
              Create Order
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

CreateNewOrderButton.query = gql`
  query GetCreateOrderCustomers($search: String) {
    customers(search: $search) {
      items {
        id
        customId
        firstName
        lastName
      }
    }
  }
`;

export default CreateNewOrderButton;
