import { GetTryOnMeasurementQuery } from '@graphql';
import { ArrayElement } from 'types/common';

type Props = {
  info: ArrayElement<ArrayElement<GetTryOnMeasurementQuery['settings']['measurement']['tryOn']['tweaks']>['items']>['info'];
  title: string;
  subtitle: string;
};

export const TryOnTweakInfo = ({ title, subtitle, info }: Props) => (
  <div>
    <h3 className="text-2xl font-semibold mb-8">{title}</h3>
    <h3 className="text-xl font-semibold mb-8">{subtitle}</h3>
    <p className="mb-2 whitespace-pre-line">{info.text}</p>
    <img className="max-w-md mx-auto" src={info.image} />
  </div>
);
