import { format } from 'date-fns';
import { gql } from '@apollo/client';

import { Box } from 'components';
import { useCustomerOrderStatsQuery } from '@graphql';
import { formatAsCurrency } from 'helpers/reporting-helpers';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { useMeasurementUnit } from 'modules/common/MeasurementUnit';

interface Props {
  email: string;
}

export const CustomerInfo = ({ email }: Props) => {
  const { data, loading } = useCustomerOrderStatsQuery({ variables: { email }, fetchPolicy: 'cache-first' });
  const { tailor } = useActiveSession();
  const {
    convertFromServerUnit,
    measurementUnitInfo: { label: unitLabel },
  } = useMeasurementUnit();

  return (
    <Box isLoading={loading}>
      {data && (
        <ul className="normal-case text-sm space-y-2">
          <li className="flex justify-between align-center">
            <span className="text-gray-500">Name</span>
            <div>
              <a href={`/customers/${data.customerByEmail.id}`} className="text-blue-500 underline">
                {data.customerByEmail.firstName} {data.customerByEmail.lastName}
              </a>
            </div>
          </li>

          <li className="flex justify-between align-center">
            <span className="text-gray-500">Email</span>
            <div>
              <a href={`mailto:${data.customerByEmail.email}`}>{data.customerByEmail.email}</a>
            </div>
          </li>

          <li className="flex justify-between align-center">
            <span className="text-gray-500">Phone</span>
            <div>{data.customerByEmail.phone || '-'}</div>
          </li>

          <li className="flex justify-between align-center">
            <span className="text-gray-500">Total spent</span>
            <div>{formatAsCurrency(data.customerOrderStats?.totalSpent, tailor.locale, tailor.currency)}</div>
          </li>

          <li className="flex justify-between align-center">
            <span className="text-gray-500">Total orders</span>
            <div>{data.customerOrderStats?.totalOrders}</div>
          </li>

          <li className="flex justify-between align-center">
            <span className="text-gray-500">Member since</span>
            <div>{format(new Date(data.customerByEmail.createdAt.origin), 'MMM dd, yyyy')}</div>
          </li>

          <li className="flex justify-between align-center">
            <span className="text-gray-500">Last order</span>
            <div>{format(new Date(data.customerOrderStats.lastOrder), 'MMM dd, yyyy')}</div>
          </li>

          <li className="flex justify-between align-center">
            <span className="text-gray-500">Height</span>
            <div>{data.customerByEmail.height ? `${convertFromServerUnit(data.customerByEmail.height)}${unitLabel}` : '-'}</div>
          </li>

          <li className="flex justify-between align-center">
            <span className="text-gray-500">Weight</span>
            <div>{data.customerByEmail.weight || '-'}</div>
          </li>
        </ul>
      )}
    </Box>
  );
};

CustomerInfo.queries = {
  root: gql`
    query CustomerOrderStats($email: String!) {
      customerOrderStats(email: $email) {
        totalSpent
        totalOrders
        lastOrder
      }
      customerByEmail(email: $email) {
        id
        firstName
        lastName
        email
        phone
        height
        weight
        createdAt {
          origin
        }
      }
    }
  `,
};
