import React from 'react';
import cn from 'classnames';
import { InputError, InputLabel, InputProps, InputSymbol, defaultErrorStyles } from './Input';

interface UnitInputProps extends InputProps {
  unit?: string;
}

export const UnitInput = React.forwardRef<HTMLInputElement, UnitInputProps>(
  ({ className, register, htmlProps, unit = 'cm', errorMessage, label, testId, showError = true, hideSpinBox = true }, ref): React.ReactElement => {
    return (
      <div className={className}>
        <InputLabel htmlProps={htmlProps} label={label} />
        <div className="relative rounded-md shadow-sm">
          <input
            data-testid={testId}
            type="number"
            ref={ref}
            className={cn('block w-full pr-10 sm:text-sm rounded-md disabled:cursor-not-allowed', defaultErrorStyles({ errorMessage }), {
              'no-spin-box': hideSpinBox,
            })}
            {...register}
            {...htmlProps}
            data-1p-ignore
          />
          <InputSymbol symbol={unit} />
        </div>
        <InputError showError={showError} errorMessage={errorMessage} />
      </div>
    );
  }
);
