import { PrintOrderDesignItemFragment } from '@graphql';
import { setClassName } from 'helpers/pdf/table-helpers';
import { OrderDetailsPrintSectionHeader } from 'modules/orders/components/OrderDetailsPrintSectionHeader';
import { OrderDetailsPrintTableCell } from 'modules/orders/components/OrderDetailsPrintTableCell';
import { DesignItem } from 'modules/orders/orders.types';

interface Props {
  designItems: DesignItem[];
}

export const OrderDetailsPrintDetails = ({ designItems }: Props) => {
  const getMade = (options: PrintOrderDesignItemFragment['options']) => {
    return options.find((option) => option.designTypeOffering.name.toLowerCase().includes('make')).name;
  };

  return (
    <div className="mt-4">
      <OrderDetailsPrintSectionHeader label="Order Details" />
      <div className="uppercase text-6 ml-px">
        <div className="flex bg-neutral-200">
          <OrderDetailsPrintTableCell content="#" className="w-4" />
          <OrderDetailsPrintTableCell content="Ref ID" className="w-13" />
          <OrderDetailsPrintTableCell content="QTY" className="w-6" />
          <OrderDetailsPrintTableCell content="Fabric" className="flex-1" />
          <OrderDetailsPrintTableCell content="Lining" className="flex-1" />
          <OrderDetailsPrintTableCell content="Class" className="flex-1" />
          <OrderDetailsPrintTableCell content="Made" className="flex-1" />
          <OrderDetailsPrintTableCell content="Try On" className="flex-1" />
          <OrderDetailsPrintTableCell content="Fit" className="flex-1" />
          <OrderDetailsPrintTableCell content="Vendor" className="flex-1" />
        </div>
        {designItems.map(({ item, design }, i) => (
          <div key={`detailRow_${design.id}`} className="flex text-6">
            <OrderDetailsPrintTableCell content={i + 1} bold className="w-4" />
            <OrderDetailsPrintTableCell content={design.id} truncate className="w-13" />
            <OrderDetailsPrintTableCell content={item.quantity} className="w-6" />
            <OrderDetailsPrintTableCell content={item.customFabric || item.fabric?.code} className="flex-1" />
            <OrderDetailsPrintTableCell content={item.lining?.code} className="flex-1" />
            <OrderDetailsPrintTableCell content={setClassName(item, design)} className="flex-1" />
            <OrderDetailsPrintTableCell content={getMade(design.options)} className="flex-1" />
            <OrderDetailsPrintTableCell content={design.measurement?.size} className="flex-1" />
            <OrderDetailsPrintTableCell content={design.measurement?.fit?.name} className="flex-1" />
            <OrderDetailsPrintTableCell content={item.fabric?.vendor} className="flex-1" />
          </div>
        ))}
      </div>
    </div>
  );
};
