import {
  CheckDesignConflictsQueryResult,
  DesignOptionsFormProviderGroupsFragment,
  DesignOptionsFormProviderOrderItemQueryResult,
  LiningOptions,
} from '@graphql';
import { DesignOptionFieldConflict, HiddenOptionConfigType, DesignOptionKeyValue } from 'modules/designOptions/designOptions.types';
import { JEROME_CMT_LINING_VENDOR_KEY, isCustomLining } from 'modules/fabric/fabric.helpers';

export const LINING_OPTIONS = ['Lining Fabric', 'CMT Lining', 'CMT Lining Vendor', 'CMT Lining Code', 'CMT Lining Quantity', 'Lining Composition'];

const DESIGN_OPTIONS_TO_EXCLUDE = [];

// will remove lining options
export const parseDesignOptions = (
  designOptionGroups: DesignOptionsFormProviderGroupsFragment[],
  ignoreFields: string[] = [],
  liningOptions: LiningOptions
): DesignOptionsFormProviderGroupsFragment[] => {
  const parsedDesignOptions = designOptionGroups.map((group) => ({
    ...group,
    subGroups: group.subGroups.map((subGroup) => ({
      ...subGroup,
      types: subGroup.types
        .map((type) => {
          const { name, code, designTypeOffering } = type;
          const keepDesignType =
            !['Try on Restriction', ...ignoreFields].includes(name) && !DESIGN_OPTIONS_TO_EXCLUDE.includes(code) && !designTypeOffering.obsolete;
          if (keepDesignType) {
            // specific code for Jerome
            if (code === JEROME_CMT_LINING_VENDOR_KEY) {
              if (!liningOptions.enableCustomLining && !liningOptions.enableCmtLining) {
                return {
                  ...type,
                  designTypeOffering: {
                    ...type.designTypeOffering,
                    options: type.designTypeOffering.options.filter((opt) => opt.isDefault),
                  },
                };
              } else if (!liningOptions.enableCustomLining) {
                return {
                  ...type,
                  designTypeOffering: {
                    ...type.designTypeOffering,
                    options: type.designTypeOffering.options.filter((opt) => !isCustomLining(opt.code)),
                  },
                };
              } else if (!liningOptions.enableCmtLining) {
                return {
                  ...type,
                  designTypeOffering: {
                    ...type.designTypeOffering,
                    options: type.designTypeOffering.options.filter((opt) => isCustomLining(opt.code) || opt.isDefault),
                  },
                };
              }
            }
            return type;
          }
          return null;
        })
        .filter((t) => !!t),
    })),
  }));

  return parsedDesignOptions;
};

/*
 * @param designOptions A list of grouped design options (grouped e.g. by 'Make and Style', 'Monogram', 'Buttons and Lining')
 * @param fieldId Also referred to as the field name or type id, the parent code of the field i.e. T01234
 * @returns Returns a single field from a grouped list of design options
 */
export const getDesignOptionByTypeCode = (designOptionGroups: DesignOptionsFormProviderGroupsFragment[], typeCode: string) =>
  designOptionGroups
    .flatMap((group) => group.subGroups.flatMap((subGroup) => subGroup.types.find((subGroupType) => subGroupType.code === typeCode)))
    .find((group) => !!group);

export const getDesignConflictsMessage = (conflicts?: CheckDesignConflictsQueryResult['data']['checkDesignConflicts']): DesignOptionFieldConflict[] =>
  conflicts?.map(({ sourceOption, conflictOption, message }) => ({
    groupTitles: [sourceOption?.type.groupName, conflictOption?.type.groupName],
    designOptionCodes: [sourceOption?.type?.code, conflictOption?.type?.code],
    message,
  })) || [];

export const getHiddenFields = (designOptionGroups: DesignOptionsFormProviderGroupsFragment[]) => {
  const hiddenFields: HiddenOptionConfigType[] = [];

  designOptionGroups.forEach((group) =>
    group.subGroups.forEach((subGroup) =>
      subGroup.types.forEach((subGroupType) =>
        subGroupType.designTypeOffering?.hiddenOption?.forEach((option) => {
          if (option && subGroupType?.code) {
            hiddenFields.push({
              hiddenOption: option,
              showOnSelecting: {
                name: subGroupType?.code,
                value: subGroupType.designTypeOffering?.showOnSelection,
              },
            });
          }
        })
      )
    )
  );

  return hiddenFields;
};

export const serializeDesignOptions = (formData: Record<string, string>) =>
  Object.entries(formData).map(([typeCode, value]) => ({ typeCode, value }));

export const deserializeDesignOptions = (designOptionGroups: DesignOptionsFormProviderGroupsFragment[], options: DesignOptionKeyValue[]) => {
  const formData: Record<string, string> = {};

  designOptionGroups.forEach((group) =>
    group.subGroups.forEach((subGroup) =>
      subGroup.types.forEach((subGroupType) => {
        formData[subGroupType?.code] = options.find((option) => option.typeCode === subGroupType?.code)?.value || '';
      })
    )
  );

  return formData;
};

export const getIsFieldHidden = (fieldName: string, formData: Record<string, string>, config: HiddenOptionConfigType[]) => {
  if (!config.length) {
    return false;
  }

  const hiddenField = config.find((option) => option.hiddenOption === fieldName);

  if (!hiddenField) {
    return false;
  }

  return formData[hiddenField.showOnSelecting.name] !== hiddenField.showOnSelecting.value;
};

export const getHiddenOptionsToReset = (
  designOptionGroups: DesignOptionsFormProviderGroupsFragment[],
  formData: Record<string, string>,
  hiddenFields: HiddenOptionConfigType[],
  typeCode: string
) => {
  let options = [];

  const field = getDesignOptionByTypeCode(designOptionGroups, typeCode);

  if (field?.designTypeOffering?.hiddenOption?.length && formData[field.name] !== field?.designTypeOffering?.showOnSelection) {
    options = hiddenFields
      // Any hidden field with a `showOnSelecting` value of `ImpossibleValue` is completely hidden (no trigger can ever show it) and should be ignored.
      .filter((f) => f.showOnSelecting.name === typeCode && f.showOnSelecting.value !== 'ImpossibleValue')
      .map((o) => getDesignOptionByTypeCode(designOptionGroups, o.hiddenOption))
      .map((o) => ({ typeCode: o?.code || '', value: o?.designTypeOffering?.options.find((option) => option.isDefault)?.code || '' }));
  }

  return options;
};

type Options = DesignOptionsFormProviderOrderItemQueryResult['data']['orderItem']['designs'][number]['options'];

export const overrideDesignOptions = (oldOptions: Options, newOptions: DesignOptionKeyValue[]) => {
  const newDesignOptionKeysPerTypeCode = [...oldOptions, ...newOptions].reduce<Record<string, Options[number]>>(
    (overridenOptions, option) => ({
      ...overridenOptions,
      [option.typeCode]: { ...overridenOptions[option.typeCode], ...option } as Options[number],
    }),
    {}
  );
  return Object.values(newDesignOptionKeysPerTypeCode);
};
