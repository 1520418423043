import { Button, PageFooter } from 'modules/common';

interface Props {
  isDisabled?: boolean;
  isNew?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  children?: React.ReactNode;
  isGarmentDisabled?: boolean;
}

export const MeasurementFormFooter = ({ children, isDisabled, isNew, onSubmit, onCancel, isGarmentDisabled }: Props) => {
  return (
    <>
      <div className="w-full h-36" />
      <PageFooter>
        <div className="flex justify-between items-center max-w-7xl mx-auto sm:px-6 md:px-8">
          <Button isDisabled={isDisabled} variant="neutral" onClick={onCancel}>
            Cancel
          </Button>
          <div>
            {children}
            <Button isDisabled={isDisabled || isGarmentDisabled} onClick={onSubmit}>
              {isNew ? 'Save' : 'Update'}
            </Button>
          </div>
        </div>
      </PageFooter>
    </>
  );
};
