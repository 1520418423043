import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { gql } from '@apollo/client';
import {
  AlterationOption,
  useCreateAlterationOptionMutation,
  useGetSettingsGarmentsCategoriesQuery,
  useUpdateAlterationOptionMutation,
  CreateAlterationOptionInput,
} from '@graphql';
import { Input, Modal, Select } from 'components';
import { Button } from 'modules/common';
import { useAlterationOptionValue } from './useAlterationOptionValue';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  alterationOption?: AlterationOption;
}

interface FormInput extends Omit<CreateAlterationOptionInput, 'isActive'> {
  isActive: string;
}

export const AlterationsOptionModal = (props: Props) => {
  const { isOpen, setIsOpen, alterationOption } = props;

  const { data: garmentCategoriesData } = useGetSettingsGarmentsCategoriesQuery();
  const { convertFromAlterationOptionServerUnit, convertToAlterationOptionValueServerUnit, unitShortName } = useAlterationOptionValue();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInput>();

  useEffect(() => {
    if (alterationOption) {
      reset({
        name: alterationOption.name,
        isActive: alterationOption.isActive ? '1' : '0',
        garmentCategory: alterationOption.garmentCategory.key,
        values: alterationOption.values.map(convertFromAlterationOptionServerUnit),
      });
    }
  }, [alterationOption, reset]);

  const [addAlterationOption, { loading: addLoading }] = useCreateAlterationOptionMutation({
    onCompleted: () => {
      toast.success('Alteration option added.');

      reset();

      setIsOpen(false);
    },

    onError: () => {
      toast.error('Something went wrong, please try again.');
    },

    refetchQueries: ['GetAlterationOptions'],

    awaitRefetchQueries: true,
  });

  const [updateAlterationOption, { loading: updateLoading }] = useUpdateAlterationOptionMutation({
    onCompleted: () => {
      toast.success('Alteration option updated.');

      setIsOpen(false);
    },

    onError: () => {
      toast.error('Something went wrong, please try again.');
    },

    refetchQueries: ['GetAlterationOptions'],

    awaitRefetchQueries: true,
  });

  const onSubmit = handleSubmit(async (formData) => {
    const rawValues = formData?.values?.toString().split(',');
    const values = rawValues.map((v) => convertToAlterationOptionValueServerUnit(v)).filter((v) => !!v);
    if (alterationOption?.id) {
      updateAlterationOption({
        variables: {
          updateAlterationOptionInput: {
            alterationOptionId: alterationOption.id,
            name: formData.name,
            isActive: Boolean(Number(formData.isActive)),
            values: values || [],
            garmentCategory: formData.garmentCategory,
          },
        },
      });
    } else {
      addAlterationOption({
        variables: {
          createAlterationOptionInput: {
            name: formData.name,
            isActive: Boolean(Number(formData.isActive)),
            garmentCategory: formData.garmentCategory,
            values: values || [],
          },
        },
      });
    }
  });

  const exampleValues = useMemo(() => {
    return ['-2.5cm', '-1.5cm', '0cm', '1cm', '2.5cm'].map((v) => convertFromAlterationOptionServerUnit(v)).join(', ');
  }, []);

  const valueRegex = useMemo(() => new RegExp(`^-?\\d+(\\.\\d{0,2})?(${unitShortName})?$`), [unitShortName]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={alterationOption ? 'Update alteration option' : 'Add a new alteration option'}>
      <form onSubmit={onSubmit} className="sm:grid sm:grid-cols-2 gap-4">
        <div>
          <Input
            label="Option name"
            register={register('name', { required: 'Please enter a name.' })}
            htmlProps={{ type: 'text' }}
            errorMessage={errors?.name?.message && errors.name.message}
          />
        </div>

        <div>
          <Select label="Status" register={register('isActive')}>
            <option value="0">Inactive</option>
            <option value="1">Active</option>
          </Select>
        </div>

        <div>
          {/* TODO: ideally use GarmentCategoriesSelect here if we can fix issue with register() */}
          <Select label="Garment category" register={register('garmentCategory')}>
            {garmentCategoriesData?.settings.garmentCategories.map(
              (category) =>
                !category.name.includes('legacy') && (
                  <option key={category.key} value={category.key}>
                    {category.name}
                  </option>
                )
            )}
          </Select>
        </div>

        <div>
          <Input
            label="Values (Use comma separate values)"
            register={register('values', {
              validate: (v) => {
                const rawValues = String(v).split(',');
                if (!rawValues.every((raw) => valueRegex.test(raw.trim()))) {
                  return 'error';
                }
                return true;
              },
            })}
            showError={false}
            errorMessage={errors.values?.message}
            htmlProps={{ type: 'text' }}
          />
          <span className="text-xs text-gray-400">E.g. &quot;{exampleValues}&quot;</span>
        </div>

        <div className="pt-4">
          <Button type="submit" isDisabled={addLoading || updateLoading}>
            {alterationOption ? 'Update alteration option' : 'Add alteration option'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

AlterationsOptionModal.mutations = {
  createAlterationOption: gql`
    mutation CreateAlterationOption($createAlterationOptionInput: CreateAlterationOptionInput!) {
      createAlterationOption(createAlterationOptionInput: $createAlterationOptionInput) {
        id
        name
        isActive
      }
    }
  `,
  updateAlterationOption: gql`
    mutation UpdateAlterationOption($updateAlterationOptionInput: UpdateAlterationOptionInput!) {
      updateAlterationOption(updateAlterationOptionInput: $updateAlterationOptionInput) {
        id
        name
        isActive
      }
    }
  `,
};
