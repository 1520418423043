import { useMemo } from 'react';
import cn from 'classnames';

import { PricingPill } from 'modules/pricing';
import { useDesignOptionsForm } from '../designOptions.context';
import { DesignOptionField } from '../designOptions.types';
import SimpleCombobox, { Option } from 'components/SimpleCombobox';
import SimpleListbox from 'components/SimpleListbox';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { useFeature } from 'hooks';

type Props = {
  className?: string;
  field: DesignOptionField;
  errorMessage?: string;
  isDisabled?: boolean;
};

const OPTIONS_TO_DISABLE = ['--', 'discontinued', 'temp oos', 'temporarily out of stock'];
const EMPTY_OPTION = { id: '', value: '', disabled: true };
export const SEARCHABLE_TYPES = ['Button Code'];

// TODO: even if this component has a value selected as a default, an onChange isn't triggered when the default/initial value is set
// Be careful as this will mean the value displayed in this Select won't always be reflected in formData
export const DesignOptionsSelect = ({ className, field, errorMessage, isDisabled = false }: Props) => {
  const { formData, setAndSaveField, getPrice } = useDesignOptionsForm();
  const price = useMemo(() => getPrice({ typeCode: field.name }), [formData, field]);
  const { tailorSlug } = useActiveSession();
  const { isValueInAllowList } = useFeature();

  const options = useMemo(
    () => [
      EMPTY_OPTION,
      ...field.texts.map((option, count) => {
        const price = field?.prices?.[count];

        return {
          id: `${field.values[count]}`,
          value: option + (price ? ` - ${price}` : ''),
          disabled: Boolean(OPTIONS_TO_DISABLE.find((op) => option?.toString().toLowerCase().includes(op))),
          image: field?.illustrations?.[count],
        };
      }),
    ],
    [field]
  );

  const onChange = (option: Option) => {
    setAndSaveField({
      id: field.name,
      value: option.id,
      label: field.label,
      displayValue: option.value,
    });
  };

  const SelectComponent = useMemo(() => (SEARCHABLE_TYPES.includes(field.label) ? SimpleCombobox : SimpleListbox), [field]);

  return (
    <div className={cn('flex items-start flex-col', className)}>
      <div className="flex justify-between w-full mb-1">
        <div className="text-gray-500 text-xs font-medium">{field.label}</div>
        {isValueInAllowList({ feature: 'ff_pricing_allow_list', valueToCheck: tailorSlug }) && Boolean(price) && <PricingPill amount={price} />}
      </div>
      <div className="w-full">
        <SelectComponent
          onChange={onChange}
          options={options}
          selectedOption={options.find((o) => o.id === formData[field.name])}
          error={errorMessage}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};
