import { PopupButton } from '@typeform/embed-react';
import cn from 'classnames';
import { Icon } from 'components';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import mixpanelService from 'services/mixpanel.service';

interface Props {
  type?: 'DESKTOP' | 'MOBILE';
}

const Typeform = ({ type = 'DESKTOP' }: Props) => {
  const classNames = cn({ 'ml-auto': type === 'DESKTOP', 'px-2 py-1.5': type === 'MOBILE' });

  const { user, tailor } = useActiveSession();

  if (!process.env.NEXT_PUBLIC_TYPEFORM_ID) return null;

  const hidden = {
    first_name: user?.firstName,
    last_name: user?.lastName,
    email: user?.email,
    company: tailor?.name,
    page: window.location.pathname,
    env: process.env.NEXT_PUBLIC_ENVIRONMENT,
  };

  const handleOnReady = () => {
    mixpanelService.track('TYPEFORM_OPENED');
  };

  const handleOnSubmit = () => {
    mixpanelService.track('TYPEFORM_SUBMITTED');
  };

  return (
    <div className={classNames}>
      <PopupButton
        id={process.env.NEXT_PUBLIC_TYPEFORM_ID}
        className="flex items-center"
        size={80}
        hidden={hidden}
        onReady={handleOnReady}
        onSubmit={handleOnSubmit}
      >
        <span className="text-sm">Submit feedback</span>
        <Icon icon="chat" className="ml-2 text-yellow-crayola-500" />
      </PopupButton>
    </div>
  );
};

export default Typeform;
