import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDesignOptionsForm } from '../designOptions.context';
import { DesignOptionsFormField } from './DesignOptionsFormField';

export const DesignOptionsFormFields = () => {
  const { selectedGroup } = useDesignOptionsForm();
  const [showExtras, setShowExtras] = useState(false);
  const DesignOptionsFormFieldsRef = useRef(null);

  const selectedGroupSorted = useMemo(
    () => ({
      ...selectedGroup,
      subGroups:
        selectedGroup?.subGroups.map((group) => ({
          ...group,
          types: group.types
            .map((type) => type)
            // make sure parent option is first
            .sort(
              (a, b) =>
                Number(!!b.designTypeOffering?.showOnSelection?.includes(b.code)) - Number(!!a.designTypeOffering?.showOnSelection?.includes(a.code))
            ),
        })) || [],
    }),
    [selectedGroup?.subGroups]
  );

  const unassignedGroups = useMemo(
    () => selectedGroupSorted?.subGroups.filter((group) => group.name.toLowerCase() === 'unassigned') || [],
    [selectedGroupSorted?.subGroups]
  );
  const extraGroups = useMemo(() => selectedGroupSorted?.subGroups.filter((group) => group.extra) || [], [selectedGroupSorted?.subGroups]);
  const normalGroups = useMemo(
    () => selectedGroupSorted?.subGroups.filter((group) => group.name.toLowerCase() !== 'unassigned' && !group.extra && !group.hidden) || [],
    [selectedGroupSorted?.subGroups]
  );

  useLayoutEffect(() => {
    DesignOptionsFormFieldsRef.current.scrollTop = 0;
  }, [selectedGroup]);

  return (
    <div className="flex flex-wrap flex-1 overflow-y-auto pt-3 pb-16 px-4" ref={DesignOptionsFormFieldsRef}>
      <div className="flex flex-col w-full">
        {unassignedGroups.map((group) => (
          <div className="mb-10" key={group.id}>
            <div className="flex flex-wrap gap-6">
              {group.types.map((type) => (
                <DesignOptionsFormField key={type.name} typeCode={type.code} />
              ))}
            </div>
          </div>
        ))}
        {normalGroups.map((group) => (
          <div className="mb-10" key={group.id}>
            <div className="font-semibold text-neutral-900 mb-4">{group.name}</div>
            <div className="flex flex-wrap gap-6">
              {group.types.map((type) => (
                <DesignOptionsFormField key={type.name} typeCode={type.code} />
              ))}
            </div>
          </div>
        ))}
        {Boolean(extraGroups.length) && (
          <div className="mt-6">
            {!showExtras && (
              <div onClick={() => setShowExtras(true)} className="cursor-pointer underline">
                View all options
              </div>
            )}
            {showExtras && (
              <>
                <div onClick={() => setShowExtras(false)} className="cursor-pointer mb-6 underline">
                  Hide all options
                </div>
                <div>
                  {extraGroups.map((group) => (
                    <div className="mb-10" key={group.id}>
                      <div className="flex flex-wrap gap-6">
                        {group.types.map((type) => (
                          <DesignOptionsFormField key={type.name} typeCode={type.code} />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
