import TaperLogo from 'assets/svg/taper-logo.svg';
import format from 'date-fns/format';
import { PrintOrderSalesRepFragment, PrintOrderShippingShowroomFragment } from '@graphql';

interface Props {
  orderId: string;
  showroom: PrintOrderShippingShowroomFragment | null;
  salesRep: PrintOrderSalesRepFragment | null;
  orderDate: string;
  submissionDate: string;
}

export const OrderDetailsPrintHeader = ({ orderId, showroom, salesRep, orderDate, submissionDate }: Props) => {
  return (
    <div className="top-0 bg-neutral-900 p-4 w-full h-full">
      <div className="grid grid-cols-2 bg-neutral-900 w-full">
        <div className="col-span-1">
          <TaperLogo />
        </div>
        <div className="col-span-1 text-right">
          <h1 className="text-xl text-yellow-crayola-500 font-semibold">Order {orderId}</h1>
        </div>
      </div>
      <div className="grid grid-cols-2 mt-4 text-neutral-50 text-10">
        <div className="col-span-1">
          {showroom && (
            <>
              <p>
                {showroom.name} {showroom.address.company ? `,${showroom.address.company}` : ''}
              </p>
              <p className="mt-1 truncate">
                {showroom.address.line1}, {showroom.address.line2}, {showroom.address.city}, {showroom.address.province} {showroom.address.postalCode}
              </p>
              <p className="mt-1">{showroom.address.phone}&nbsp;</p>
            </>
          )}
          {salesRep && (
            <p className="mt-1">
              {salesRep.firstName} {salesRep.lastName}
            </p>
          )}
        </div>
        <div className="col-span-1 text-right flex justify-end gap-4">
          <div>
            <p>Order date</p>
            <p>Submission date</p>
          </div>
          <div>
            <p>{format(new Date(orderDate), 'dd/MM/yyyy')}</p>
            <p>{format(new Date(submissionDate), 'dd/MM/yyyy')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
