import { UserRole } from '@graphql';
import { Option } from '../components/SimpleCombobox';

export const filterUserRoles = (options: Option[], isSuperAdmin: boolean): Option[] => {
  if (isSuperAdmin) {
    options = options.filter(({ id }) => id !== UserRole.SuperAdmin);
  } else {
    options = options.filter(({ id }) => id !== UserRole.Admin && id !== UserRole.SuperAdmin);
  }

  return options;
};

export const filterApplicableUserRoles = (allRoles: UserRole[], isSuperAdmin: boolean): UserRole[] => {
  const rolesToExclude = isSuperAdmin ? [UserRole.SuperAdmin] : [UserRole.Admin, UserRole.SuperAdmin];
  return allRoles.filter((r) => !rolesToExclude.includes(r));
};
