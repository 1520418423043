import { ActiveSessionQuery, UserRole } from '@graphql';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

export const useIntercomInitializer = (session?: ActiveSessionQuery['activeSession']) => {
  const { user, tailor } = session ?? {};
  const intercomWorkspaceId = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
  const router = useRouter();
  const previousLocation = useRef('');

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const basePath = url.split('?')[0];
      if (intercomWorkspaceId && user && previousLocation.current !== basePath) {
        previousLocation.current = basePath;
        window.Intercom?.('update', {
          email: user.email,
          last_request_at: Math.floor(new Date().getTime() / 1000),
        });
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [user, router.events]);

  useEffect(() => {
    if (user && tailor && intercomWorkspaceId) {
      const intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: intercomWorkspaceId,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        created_at: Date.parse(user.createdAt.origin) / 1000,
        user_hash: user.userHash,
        company: user.role !== UserRole.SuperAdmin ? { company_id: tailor.slug, name: tailor.name } : undefined,
      };
      window.Intercom?.('boot', intercomSettings);
    }
  }, [user, tailor]);
};
