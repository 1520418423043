import { generateFabricPriceCsv } from './helpers';
import cn from 'classnames';
import { ArrowCircleDownIcon } from '@heroicons/react/outline';
import { HeaderProductCategory, TierPricingLookupMap } from '../types';
import { TierType } from '@graphql';

type Props = {
  headerProductCategories: HeaderProductCategory[];
  tiers: string[];
  lookup: TierPricingLookupMap;
  isDisabled: boolean;
  type: TierType;
};

export const ExportCsvButton = ({ type, headerProductCategories, tiers, lookup, isDisabled }: Props) => {
  return (
    <a
      href={generateFabricPriceCsv(headerProductCategories, tiers, lookup)}
      target="_blank"
      aria-disabled={isDisabled}
      download={`${type.toLowerCase()}_prices.csv`}
      rel="noreferrer"
    >
      <div
        className={cn('flex items-center text-xs', {
          'text-gray-500': !isDisabled,
          'text-gray-300': isDisabled,
        })}
      >
        <ArrowCircleDownIcon className="h-6 w-6 mr-2" />
        Export
      </div>
    </a>
  );
};
