interface Props {
  orderId: string;
}

export const OrderDetailsPrintFooter = ({ orderId }: Props) => {
  return (
    <>
      <div className="grid grid-cols-2 text-8 w-full">
        <div>Taper.ai</div>
        <div className="text-right">
          Order <span className="pl-2 font-semibold">{orderId}</span>
        </div>
      </div>
    </>
  );
};
