import { useRef, useState } from 'react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Combobox, Listbox } from '@headlessui/react';
import cn from 'classnames';
import MissingImage from 'assets/svg/missing.svg';
import { replace } from 'lodash';

export type Option = {
  id: string;
  value: string;
  disabled?: boolean;
  image?: string;
};

export interface Props {
  label?: string;
  options: Option[];
}

export interface SimpleComboboxProps extends Props {
  selectedOption?: Option;
  onChange?: (option: Option) => void;
  onChangeText?: (text: string) => void;
  autoFilter?: boolean;
  isDisabled?: boolean;
  error?: string;
  clearOnFocus?: boolean;
}

export const styles = {
  label: 'block text-sm font-medium text-gray-700',
  input:
    'text-left inline-block w-full border border-gray-300 bg-white py-2.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm min-h-10.5',
  button: 'absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none',
  icon: 'h-5 w-5 text-gray-400',
  options: 'absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm',
  option: 'relative flex items-center cursor-default select-none py-2 pl-3 pr-9 min-h-9',
  optionActive: 'bg-indigo-600 text-white',
  optionInactive: 'text-gray-900',
  optionDisabled: 'text-gray-400',
  value: 'block truncate',
  valueSelected: 'font-semibold',
  checkIcon: 'absolute inset-y-0 right-0 flex items-center pr-4',
  checkIconActive: 'text-white',
  checkIconInactive: 'text-indigo-600',
};

type ComboOrList = typeof Combobox | typeof Listbox;

export const Button = ({ type: Type = Combobox }: { type?: ComboOrList }) => {
  return (
    <Type.Button className={styles.button}>
      <SelectorIcon className={styles.icon} aria-hidden="true" />
    </Type.Button>
  );
};

export const ErrorMessage = ({ children }: { children: React.ReactNode }) => <p className="mt-1 text-red-500 text-xs">{children}</p>;

export const Options = ({ options, type: Type = Combobox }: Pick<Props, 'options'> & { type?: ComboOrList }) => {
  const hasImages = options.some((o) => o?.image);

  return options.length > 0 ? (
    <Type.Options className={styles.options}>
      {options.map((option) => (
        <Type.Option
          key={option.id}
          value={option}
          disabled={option.disabled}
          className={({ active }) =>
            cn(styles.option, {
              [styles.optionActive]: active,
              [styles.optionInactive]: !active && !option?.disabled,
              [styles.optionDisabled]: option?.disabled,
            })
          }
        >
          {({ active, selected }) => (
            <>
              {hasImages ? (
                option?.image ? (
                  <img className="w-5 h-5 mr-2" src={option.image} alt={option.value} />
                ) : (
                  <MissingImage className="w-5 h-5 fill-current mr-2" />
                )
              ) : null}
              <span className={cn(styles.value, { [styles.valueSelected]: selected })}>{option.value}</span>
              {selected && (
                <span className={cn(styles.checkIcon, { [styles.checkIconActive]: active, [styles.checkIconInactive]: !active })}>
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              )}
            </>
          )}
        </Type.Option>
      ))}
    </Type.Options>
  ) : null;
};

const getFilteredOptions = (query: string, options: Option[]) =>
  query === ''
    ? options
    : options.filter((option) => {
        return option.value.toLowerCase().includes(query.toLowerCase());
      });

const SimpleCombobox = ({
  label,
  options,
  selectedOption,
  onChange,
  onChangeText,
  autoFilter = true,
  isDisabled,
  error,
  clearOnFocus = false,
}: SimpleComboboxProps) => {
  const [query, setQuery] = useState('');
  const filteredOptions = autoFilter ? getFilteredOptions(query, options) : options;

  const inputRef = useRef<HTMLInputElement>();
  const isReset = useRef(false);

  return (
    <>
      <Combobox as="div" value={selectedOption} onChange={onChange} disabled={isDisabled} by={(a, b) => a?.id === b?.id}>
        {label && <Combobox.Label className={styles.label}>{label}</Combobox.Label>}
        <div className="relative">
          <Combobox.Input
            ref={inputRef}
            className={error ? styles.input.replace('border-gray-300', 'border-red-500') : styles.input}
            onChange={(event) => {
              if (clearOnFocus && !isReset.current && event.target.value.includes(selectedOption.value)) {
                event.target.value = replace(event.target.value, selectedOption.value, '');
                isReset.current = true;
              }
              const text = event.target.value;
              onChangeText?.(text);
              autoFilter && setQuery(text);
            }}
            displayValue={(option: any) => option?.value}
            onBlur={() => {
              if (clearOnFocus) {
                isReset.current = false;
                if (!inputRef.current.value && selectedOption) {
                  inputRef.current.value = selectedOption.value;
                }
              }
            }}
          />
          <Button />
          <Options options={filteredOptions} />
        </div>
      </Combobox>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default SimpleCombobox;
