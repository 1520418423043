import { gql } from '@apollo/client';
import { Address } from '@graphql';

interface Props {
  address: Partial<Address>;
}

export const FormattedAddress = ({ address }: Props) => {
  const postCodeAndProvince = [address.postalCode, address.province].join(' ');

  return (
    <div className="text-sm text-gray-600">
      {(address.firstName || address.lastName) && <div>{[address.firstName, address.lastName].join(' ')}</div>}
      {address.company && <div>{address.company}</div>}
      {address.line1 && <div>{address.line1}</div>}
      {address.line2 && <div>{address.line2}</div>}
      {address.city && <div>{[address.city, postCodeAndProvince].join(', ')}</div>}
      {address.country && <div>{address.country}</div>}
      {address.phone && <div>{address.phone}</div>}
    </div>
  );
};

FormattedAddress.fragments = {
  root: gql`
    fragment FormattedAddress on Address {
      id
      firstName
      lastName
      company
      line1
      line2
      city
      country
      phone
      postalCode
      province
    }
  `,
};
