import { uniq } from 'lodash';

import { OrderRequirements } from '@graphql';
import { Option } from 'components/SimpleCombobox';

export const orderRequirementDisplayName = (requirements: OrderRequirements[]): string[] => {
  const displayMessages = requirements.map((requirement) => {
    switch (requirement) {
      case 'NOT_FOUND':
        return 'Order not found';
      case 'SHOWROOM':
        return 'Assign showroom';
      case 'SALES_REP':
        return 'Assign sales rep';
      case 'CUSTOMER':
        return 'Assign customer';
      case 'ORDER_ID':
        return 'Update PO#';
      case 'ITEMS':
        return 'At least 1 item';
      case 'ITEM_DESIGN_MEASUREMENT':
        return 'Set item measurement';
      case 'ITEM_DESIGN_LINING':
        return 'Item missing lining';
      case 'ITEM_DESIGN_FABRIC':
        return 'Item missing fabric';
      case 'ITEM_DESIGN_CONFLICTS':
      case 'ITEM_DESIGN_INCOMPLETE':
        return 'Fix item conflicts';
    }
  });
  return uniq(displayMessages);
};

export const getStatusOptions = (map: { [key: string]: string }) => Object.keys(map).map((status) => ({ id: status, value: map[status] }));

export const getSelectedOption = (options: Option[], id = '') => options?.find((o) => o.id === id);

export const getSelectedOptions = (options: Option[], ids: string | string[]) =>
  options?.filter((o) => (Array.isArray(ids) ? ids.includes(o.id) : ids === o.id));
