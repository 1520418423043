import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import {
  AccessorFn,
  ColumnDef,
  DeepKeys,
  DisplayColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Table as TanStackTable,
  useReactTable,
} from '@tanstack/react-table';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { Button, TaperLoading } from 'modules/common/components';
import { TextSkeleton } from 'components/TextSkeleton';

interface ITableProps<T> {
  className?: string;
  data: Array<T>;
  loading?: boolean;
  children: ReactNode;
}
type TableContextParams<T> = {
  table: TanStackTable<T>;
  columns: ColumnDef<T, any>[];
  loading: boolean;
  setColumn: (props: ColumnProps<T>) => void;
  setPagination: Dispatch<SetStateAction<object>>;
};
const TableContext = createContext<TableContextParams<any>>({
  table: null,
  columns: [],
  loading: false,
  setColumn: () => null,
  setPagination: () => null,
});

const Table = <T extends object>({ className, data, loading = false, children }: ITableProps<T>): React.ReactElement => {
  const columnHelper = createColumnHelper<T>();
  const [columns, setColumns] = useState([]);
  const [pagination, setPagination] = useState({});
  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    ...pagination,
  });

  const setColumn = (columnProps: ColumnProps<T>) => {
    const { accessor, id, ...displayProps } = columnProps;
    const columnConfig = columnHelper.accessor(accessor, { id, ...displayProps });
    setColumns((previous) => {
      const previousAccessors = previous.map((c) => c.accessorKey);
      const previousIds = previous.map((c) => c.id);
      let foundIndex = previousAccessors.indexOf(accessor);
      if (id && foundIndex === -1) foundIndex = previousIds.indexOf(id);
      const next = [...previous];
      if (foundIndex === -1) {
        next.push(columnConfig);
      } else {
        next.splice(foundIndex, 1, columnConfig);
      }
      return next;
    });
  };

  const rows = table.getRowModel().rows;

  return (
    <TableContext.Provider value={{ table, columns, loading, setColumn, setPagination }}>
      <div className="bg-white border border-gray-300">
        {loading ? (
          <TaperLoading />
        ) : (
          <table className={cn('min-w-full divide-y divide-gray-200 table-fixed', className)}>
            <thead className="text-11 font-semibold uppercase tracking-wider text-gray-400">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} className="p-4">
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="text-sm">
              {rows?.length ? (
                rows.map((row, i) => (
                  <tr
                    key={row.id}
                    className={cn('text-gray-500 hover:bg-gray-100 hover:text-gray-900', {
                      'bg-white': i % 2 === 0,
                      'bg-gray-50': i % 2 !== 0,
                    })}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="p-4 whitespace-nowrap">
                        {loading ? <TextSkeleton className="w-full" /> : flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length} className="p-4 text-center">
                    No results were found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      {children}
    </TableContext.Provider>
  );
};

type ColumnProps<T> = { accessor: AccessorFn<T> | DeepKeys<T> } & Partial<DisplayColumnDef<T, string>>;

const Column = <T extends object>(props: ColumnProps<T>) => {
  const context = useContext(TableContext);

  if (!context) {
    console.error(`<Column accessor="${props.accessor}" /> must be used within a <Table />`);
  }

  useEffect(() => {
    context.setColumn(props);
  }, [props]);

  return null;
};

type PaginationProps = {
  count?: number;
  total?: number;
};

const Pagination = ({ count, total }: PaginationProps) => {
  const { query, push } = useRouter();

  const onPaginate = (page: number) => {
    push({ query: { ...query, page } }, undefined, { shallow: true });
  };

  const page = Number(query?.page) || 1;
  const pageSize = Number(query?.pageSize) || 10;
  const pointer = (page - 1) * pageSize;

  return (
    <div className="h-16 px-4 flex items-center justify-between">
      <div className="text-sm text-neutral-900">{total ? `Showing ${pointer + 1} to ${pointer + count} of ${total} results` : ''}</div>
      <div>
        <Button variant="neutral" isDisabled={page <= 1} onClick={() => onPaginate(page - 1)}>
          Previous
        </Button>
        <Button className="ml-4" variant="neutral" isDisabled={page * pageSize >= total} onClick={() => onPaginate(page + 1)}>
          Next
        </Button>
      </div>
    </div>
  );
};

export { Table, Column, Pagination };
