import { ReactNode } from 'react';
import cn from 'classnames';

const BASE_CLASSES_INDICATOR_BAR = ['w-1', 'rounded', 'mr-6'];

interface Props {
  status: 'neutral' | 'success' | 'error';
  onClick?: () => void;
  children: ReactNode;
}

export const OrderItemProgress = ({ status, children, onClick }: Props) => {
  const indicatorBarClasses = cn(BASE_CLASSES_INDICATOR_BAR, {
    'bg-neutral-200': status === 'neutral',
    'bg-emerald-500': status === 'success',
    'bg-red-400': status === 'error',
  });

  const classes = cn('flex w-full', {
    'cursor-pointer': Boolean(onClick),
  });

  return (
    <div onClick={onClick && onClick} className={classes}>
      <div className={indicatorBarClasses}></div>
      <div className="flex items-center text-neutral-200 w-4/5">{children}</div>
    </div>
  );
};
