import { RemakeOrderInput, RemakeReason } from '@graphql';
import { RemakeItem } from './RemakeState';

export const getRemakeOrderParams = (orderId: string, items: RemakeItem[]): RemakeOrderInput => ({
  orderId,
  items: items
    .filter((i) => i.isChecked)
    .map((i) => ({
      orderItemId: i.orderItemId,
      reason: i.remakeReason as RemakeReason,
      description: i.remakeDescription,
      designIds: i.designs.filter((d) => d.isChecked).map((d) => d.orderItemDesignId),
    })),
});
