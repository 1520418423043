import router from 'next/router';
import cn from 'classnames';
import { gql } from '@apollo/client';

import { Icon, IconType } from 'components';
import { useOrderItemNavQuery } from '@graphql';

type NavItem = {
  icon: IconType;
  text: string;
  getRoute: (isTemplate: boolean, orderId: string | string[], itemId?: string | string[]) => string;
};

// Steps will be rendered in the order seen here
const navConfig: NavItem[] = [
  {
    icon: 'fabric',
    text: 'Fabric',
    getRoute: (isTemplate, orderId, itemId) => (isTemplate ? `/design-templates/${itemId}/fabric` : `/orders/${orderId}/order-item/${itemId}/fabric`),
  },
  {
    icon: 'pocket',
    text: 'Design Options',
    getRoute: (isTemplate, orderId, itemId) =>
      isTemplate ? `/design-templates/${itemId}/design-options` : `/orders/${orderId}/order-item/${itemId}/design-options`,
  },
  {
    icon: 'clipboard',
    text: 'Summary',
    getRoute: (isTemplate, orderId, itemId) =>
      isTemplate ? `/design-templates/${itemId}/summary` : `/orders/${orderId}/order-item/${itemId}/summary`,
  },
];

const BASE_CLASSES = ['pb-2', 'flex', 'justify-between', 'flex-row', 'rounded', 'whitespace-nowrap', 'overflow-auto'];

const getCurrentStep = (router) => {
  const { tab } = router.query;
  switch (String(tab)) {
    case 'fabric':
      return 'Select Fabric';
    case 'lining':
      return 'Select Lining';
    default: {
      const path = router.pathname;
      if (path.includes('design-options')) return 'Design Options';
      if (path.includes('summary')) return 'Summary';
      return '';
    }
  }
};

interface Props {
  className?: string;
  isTemplate: boolean;
}

// TODO: work out how to mark a step as finished
export const OrderItemNav = ({ className, isTemplate }: Props) => {
  const { orderId, itemId } = router.query;
  const { data } = useOrderItemNavQuery({
    skip: !itemId,
    variables: { orderItemId: itemId as string, isTemplate },
    fetchPolicy: 'cache-first',
  });
  const orderItemName = data?.orderItem.name || '';
  const classes = cn(BASE_CLASSES, className);

  return (
    <div className={classes}>
      <div className="flex items-center">
        <div className="text-neutral-900 text-3xl font-bold">{orderItemName}</div>
        <Icon icon="vertical-line" className="mx-4" />
        <div className="text-neutral-900 text-3xl">{getCurrentStep(router)}</div>
      </div>
      {itemId && (
        <div className="flex grow justify-end items-center mt-0 ml-4">
          {navConfig.map((navItem) => {
            return (
              <div
                key={`order-item-nav-${navItem.getRoute(isTemplate, orderId, itemId)}`}
                className={cn('flex cursor-pointer py-2 px-4 rounded border justify-center items-center ml-2', {
                  'text-neutral-900 bg-yellow-crayola-50 border-yellow-crayola-500': router.route.includes(
                    navItem.getRoute(isTemplate, '[orderId]', '[itemId]')
                  ),
                  'text-neutral-500': !router.route.includes(navItem.getRoute(isTemplate, '[orderId]', '[itemId]')),
                })}
                onClick={() => router.push(navItem.getRoute(isTemplate, orderId, itemId))}
              >
                <Icon className="mr-1" icon={navItem.icon} width={20} height={20} />
                <span className="hidden lg:block">{navItem.text}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

OrderItemNav.fragments = {
  orderItem: gql`
    fragment OrderItemNavFragment on OrderItem {
      id
      name
      isTemplate
    }
  `,
};

OrderItemNav.query = gql`
  ${OrderItemNav.fragments.orderItem}

  query OrderItemNav($orderItemId: ID!, $isTemplate: Boolean!) {
    orderItem(orderItemId: $orderItemId, isTemplate: $isTemplate) {
      ...OrderItemNavFragment
    }
  }
`;
