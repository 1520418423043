import React from 'react';
import { ClipboardListIcon } from '@heroicons/react/outline';
import { HeaderProductCategory } from './types';
import { TierType } from '@graphql';

type Props = {
  headerProductCategories: HeaderProductCategory[];
  type: TierType;
};

export const ExampleCsvButton = ({ headerProductCategories, type }: Props) => {
  return (
    <a href={generateExamplePriceCsv(headerProductCategories)} target="_blank" download={`${type.toLowerCase()}_import_example.csv`} rel="noreferrer">
      <div className={'flex items-center text-xs text-gray-500'}>
        <ClipboardListIcon className="h-6 w-6 mr-2" />
        CSV Template
      </div>
    </a>
  );
};

const generateExamplePriceCsv = (headerProductCategories: HeaderProductCategory[]) => {
  const th = ['tier', ...headerProductCategories.map((h) => h.truncatedName)];

  const td = [['EXAMPLE_TIER', ...headerProductCategories.map((_, index) => String(index === headerProductCategories.length / 2 ? '' : 23.43))]];

  return `data:text/csv;charset=utf-8,${[th, ...td].map((cell) => cell.map((c) => `"${c}"`).join(',')).join('\n')}`;
};
