import { Listbox } from '@headlessui/react';

import { SimpleComboboxProps, Options, ErrorMessage, styles } from './SimpleCombobox';
import { SelectorIcon } from '@heroicons/react/solid';
import cn from 'classnames';

const SimpleListbox = ({ options, selectedOption, onChange, isDisabled, error }: SimpleComboboxProps) => (
  <>
    <Listbox as="div" value={selectedOption} onChange={onChange} disabled={isDisabled}>
      <div className="relative">
        <Listbox.Button className={cn(error ? styles.input.replace('border-gray-300', 'border-red-500') : styles.input, 'flex justify-between pr-2')}>
          <span>{selectedOption?.value ?? ' '}</span>
          <SelectorIcon className={cn(styles.icon, '')} aria-hidden="true" />
        </Listbox.Button>
        <Options options={options} type={Listbox} />
      </div>
    </Listbox>
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </>
);

export default SimpleListbox;
