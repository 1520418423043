import { useEffect, useState } from 'react';
import { ITab, Tabs, TabsProps } from './Tabs';
import { useRouter } from 'next/router';

interface ITabWithRoute extends ITab {
  route: string;
}

interface TabsWithRouteProps extends TabsProps {
  tabs: ITabWithRoute[];
}

const TabsWithRoute = ({ tabs, ...props }: TabsWithRouteProps) => {
  const router = useRouter();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    const selectedTab = String(router.query.tab);
    const selectedTabIndex = tabs.indexOf(tabs.find((tab) => tab.route === selectedTab));
    setSelectedIndex(selectedTabIndex >= 0 ? selectedTabIndex : 0);
  }, [router.query?.tab]);

  if (!router.isReady) {
    return null;
  }

  const tabsWithOnChange: ITab[] = tabs.map((tab) => ({
    ...tab,
    onChangeTab: () => {
      tab.onChangeTab && tab.onChangeTab();

      setSelectedIndex(tabs.indexOf(tabs.find((t) => t.name.toLowerCase() === tab.name.toLowerCase())) ?? 0);
      const pathParts = router.asPath.split('/');
      pathParts[pathParts.length - 1] = tab.route;
      const newPath = pathParts.join('/');
      router.push(newPath, undefined, { shallow: false });
    },
  }));

  return <Tabs tabs={tabsWithOnChange} {...props} selectedIndex={selectedIndex} />;
};

export default TabsWithRoute;
