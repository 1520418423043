import { Popover } from '@headlessui/react';
import { CalendarIcon } from '@heroicons/react/outline';
import { endOfDay } from 'date-fns';
import cn from 'classnames';

import { Calendar } from 'components/Calendar';
import { DateRange } from 'helpers/reporting-helpers';
import { getAbbreviatedDate } from 'helpers/date-helpers';
import { styles } from 'components/SimpleCombobox';

interface Props {
  range?: DateRange;
  onSelect: (range: DateRange) => void;
}

export const DateRangePicker = ({ range, onSelect }: Props) => {
  const onCalendarSelect = (dateRange: DateRange) => {
    let from, to;

    if (!dateRange) {
      // triggers when selecting `from` when already selected
      from = range.from;
      to = range.from;
    } else if (!dateRange.to) {
      // triggers when selecting `to` when already selected
      from = dateRange.from;
      to = dateRange.from;
    } else {
      from = dateRange.from;
      to = dateRange.to;
    }

    // date range picker does not set `to` to end of the day
    to = endOfDay(to);

    onSelect({ from, to });
  };

  return (
    <Popover className="relative">
      <Popover.Button as="div">
        <button className={cn(styles.input.replace('pr-10', 'pr-3'), 'flex text-left')}>
          <span className="w-full truncate">
            {range ? `${getAbbreviatedDate(range.from)} - ${getAbbreviatedDate(range.to)}` : 'No Date Selected'}
          </span>
          <CalendarIcon className="ml-2 w-4 h-4" />
        </button>
      </Popover.Button>
      <Popover.Panel className="absolute z-10 mt-1">
        <Calendar
          className="bg-white border"
          mode="range"
          numberOfMonths={2}
          defaultMonth={range?.to || new Date()}
          selected={range}
          onSelect={onCalendarSelect}
        />
      </Popover.Panel>
    </Popover>
  );
};
