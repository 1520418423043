/**
 * Mixpanel docs: https://www.notion.so/palomagroup/Mixpanel-3e666a39470346359d484180297e4067
 */
import { ActiveSessionQuery } from '@graphql';
import { EventProperties, EventName, events } from 'helpers/analytic-events';
import mixpanel, { Dict } from 'mixpanel-browser';

export enum MixpanelType {
  PAGE = 'PAGE',
  EVENT = 'EVENT',
}

const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

const mixpanelService = {
  /**
   * Initializes a new instance of the Mixpanel tracking object
   */
  init: (): void => {
    if (mixpanelToken) {
      mixpanel.init(mixpanelToken);
    }
  },
  /**
   * Clears super properties and generates a new random distinct_id for this instance. Useful for clearing data when a user logs out.
   */
  reset: (): void => {
    if (mixpanelToken) {
      mixpanel.reset();
    }
  },
  /**
   * Send tracking event to Mixpanel
   */
  trackPage: (properties?: Dict, ...args): void => {
    if (mixpanelToken) mixpanel.track(events.PAGE_VIEW, properties, ...args);
  },
  /**
   * Send tracking event to Mixpanel
   */
  track: <T extends EventName>(eventName: T, properties?: EventProperties[T], ...args): void => {
    if (mixpanelToken) mixpanel.track(events[eventName], properties, ...args);
  },
  /**
   * Identify a user with a unique ID to track user activity across devices, tie a user to their events, and create a user profile.
   * If you never call this method, unique visitors are tracked using a UUID generated the first time they visit the site.
   */
  identify: async ({ user, tailor }: ActiveSessionQuery['activeSession'], sendSignInEvent = false): Promise<void> => {
    if (mixpanelToken) {
      mixpanel.identify(user.id);
      // mixpanel user profile properties
      mixpanel.people.set({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        tailorSlug: tailor.slug,
        fulfilment: tailor.fulfilment,
      });

      if (sendSignInEvent) {
        mixpanel.track(events.SIGN_IN);
      }
    }
  },
};

export default mixpanelService;
