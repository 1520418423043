import { gql } from '@apollo/client';
import cn from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import { useFilters, useTable } from 'react-table';

import { GetDesignTypeOfferingsQuery } from '@graphql';
import { Input } from 'components';

interface Props {
  types: GetDesignTypeOfferingsQuery['designTypeOfferings'];
  isLoading: boolean;
}

const columnsData = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }) => <div className="capitalize">{String(value)}</div>,
    id: 'name',
  },
  { Header: 'Code', accessor: 'code' },
  {
    Header: () => <div className="text-center">Total Options</div>,
    disableFilters: true,
    Cell: ({ value }) => <div className="text-center">{String(value)}</div>,
    accessor: (row) => row?.options?.length,
    id: 'totalOptions',
  },
];

export const DesignOptionsTypesListTable = (props: Props): React.ReactElement => {
  const { types, isLoading } = props;

  const router = useRouter();

  const data = React.useMemo(() => types, [types]);

  const columns = React.useMemo(
    () =>
      isLoading
        ? columnsData.map((column) => ({
            ...column,
            Cell: <div className="animate-pulse h-6 rounded bg-gray-200" />,
          }))
        : columnsData,
    [isLoading]
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: ({ column }) => {
        return (
          <Input
            htmlProps={{
              type: 'text',
              value: column.filterValue || '',
              onChange: (e) => {
                column.setFilter(e.currentTarget.value || undefined);
              },
              placeholder: `Filter by ${column.Header}`,
            }}
          />
        );
      },
    }),
    []
  );

  const tableInstance = useTable({ columns, data, defaultColumn }, useFilters);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mt-6">
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200 table-fixed">
        <thead className="bg-gray-50">
          {
            // Loop over the header rows
            headerGroups.map((headerGroup, index) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, i) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps()}
                      className={`p-4 text-xs font-semibold uppercase tracking-wider text-left ${i === 0 ? 'w-2/6' : 'w-1/6'}`}
                      key={i}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }

          {
            // Loop over the header rows
            headerGroups.map((headerGroup, index) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()} key={index} className="bg-gray-100 border-t">
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, i) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()} className="p-2 text-xs font-semibold uppercase tracking-wider text-left" key={i}>
                      {column.canFilter ? column.render('Filter') : null}
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row, index) => {
              // Prepare the row for display
              prepareRow(row);

              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  onClick={() =>
                    !isLoading && router.push(`/design-options?typeCode=${row.original.code}&garmentCategory=${row.original.garmentCategory.key}`)
                  }
                  className={cn('text-gray-500 group', {
                    'cursor-pointer hover:bg-gray-100 hover:text-gray-900': !isLoading,
                    'bg-white': index % 2 === 0,
                    'bg-gray-50': index % 2 !== 0,
                  })}
                  key={row.id}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, index) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()} className="p-4 text-sm text-left" key={index}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};

DesignOptionsTypesListTable.queries = {
  coreDesignTypes: gql`
    query GetDesignTypeOfferings($garmentCategory: GarmentCategory!) {
      designTypeOfferings(garmentCategory: $garmentCategory) {
        id
        name
        code
        groupName
        garmentCategory {
          key
        }
        options {
          id
          name
          code
          isDefault
        }
      }
      garments {
        category
        label
      }
    }
  `,
};
