import cn from 'classnames';

interface Props {
  content?: string | number;
  className?: string;
  bold?: boolean;
  truncate?: boolean;
  width?: string;
}

export const OrderDetailsPrintTableCell = ({ content, className, bold, truncate }: Props) => {
  const BASE_CLASSES = ['p-1', 'border', 'border-neutral-200', 'flex', 'items-center'];

  const classes = cn(className, BASE_CLASSES, {
    'font-bold': bold,
    truncate: truncate,
  });

  return (
    <div className={classes} style={{ marginTop: '-1px', marginLeft: '-1px' }}>
      {content ?? '-'}
    </div>
  );
};
