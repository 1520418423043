import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import cn from 'classnames';
import { CSSProperties, ReactNode, useMemo } from 'react';

type MeasurementFieldProps = { children: ReactNode; isThereDependableFields?: boolean };

type Props = {
  isExpandable: boolean;
} & MeasurementFieldProps;

const useStyles = (isThereDependableFields?: boolean) => useMemo(() => getGridStyle(isThereDependableFields), [isThereDependableFields]);

const getGridStyle = (isThereDependableFields?: boolean): CSSProperties => ({
  gridTemplateColumns: isThereDependableFields ? '30px repeat(7, 1fr)' : 'repeat(7, 1fr)',
});

const Header = ({ children, isThereDependableFields }: MeasurementFieldProps) => {
  const styles = useStyles(isThereDependableFields);
  return (
    <div className="grid gap-4" style={styles}>
      {isThereDependableFields && <div className="col-span-1">&nbsp;</div>}
      {children}
    </div>
  );
};

const Container = ({ isExpandable, children, isThereDependableFields }: Props) => {
  const styles = useStyles(isThereDependableFields);
  if (isThereDependableFields && isExpandable) {
    return (
      <Disclosure as="div" className="grid gap-4" style={styles}>
        {({ open }) => (
          <>
            <div className="col-span-1 flex items-center">
              <Disclosure.Button>
                <ChevronUpIcon
                  className={cn('h-5 w-5 text-gray-500', {
                    'rotate-180 transform': !open,
                  })}
                />
              </Disclosure.Button>
            </div>
            {children}
          </>
        )}
      </Disclosure>
    );
  } else {
    return (
      <div className="grid gap-4" style={styles}>
        {isThereDependableFields && <div className="col-span-1">&nbsp;</div>}
        {children}
      </div>
    );
  }
};

const Panel = ({ children, isThereDependableFields }: MeasurementFieldProps) => {
  const styles = useStyles(isThereDependableFields);
  return (
    <Disclosure.Panel className="col-span-8 grid gap-4" style={styles}>
      <div className="col-span-1">&nbsp;</div>
      {children}
    </Disclosure.Panel>
  );
};

export const MeasurementSettingField = {
  Header,
  Container,
  Panel,
};
