import React, { ReactNode, memo } from 'react';
import cn from 'classnames';
import { Icon } from 'components';

type Props = {
  variant?: 'light' | 'dark';
  children: ReactNode;
  className?: string;
  content: string | ReactNode;
  contentWidth?: string;
};

const BASE_CLASSES = ['relative', 'inline-block', 'group'];

export const Tooltip = memo(({ children, className, content, variant = 'light', contentWidth = '' }: Props) => {
  const baseClasses = cn(className, BASE_CLASSES);

  return (
    <div className={baseClasses}>
      {children}
      <div className="absolute text-center left-1/2 transform -translate-x-1/2 hidden group-hover:block z-50">
        <Icon icon="tooltip-caret" className={cn('-mb-2 w-full', { 'text-neutral-900': variant === 'dark', 'text-white': variant === 'light' })} />
        <div
          className={cn('p-2 rounded whitespace-normal text-center shadow-lg', {
            'bg-white border border-gray-300': variant === 'light',
            'bg-neutral-900': variant === 'dark',
          })}
          style={{ ...(contentWidth ? { width: contentWidth } : {}) }}
        >
          <div className="text-xs">{content}</div>
        </div>
      </div>
    </div>
  );
});
