import { datadogRum } from '@datadog/browser-rum';
import { fromCommaDelimitedString } from 'helpers';

export const ddRumService = () => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production') {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DD_RUM_APP_ID,
      clientToken: process.env.NEXT_PUBLIC_DD_RUM_TOKEN,
      site: 'datadoghq.com',
      service: process.env.NEXT_PUBLIC_DD_SERVICE,
      env: process.env.NEXT_PUBLIC_ENVIRONMENT,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.NEXT_PUBLIC_COMMIT_HASH,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: fromCommaDelimitedString(process.env.NEXT_PUBLIC_DD_RUM_ALLOWED_TRACING_URLS),
    });

    datadogRum.startSessionReplayRecording();
  }
};
