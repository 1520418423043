import { gql, useReactiveVar } from '@apollo/client';
import cn from 'classnames';
import { LiningOptions as LiningOptionType, useOrderItemSummaryPageQuery, useUpdateOrderItemSummaryMutation } from '@graphql';
import { Input, Tabs } from 'components';
import { parseRouteId, TaperLoading, Button } from 'modules/common';
import { DesignOptionsFormProvider, DesignOptionsSummaryListContainer } from 'modules/designOptions';
import {
  FabricList,
  FabricOrLiningCard,
  LiningList,
  BASE_FABRIC_CARD_CLASSES,
  isCmtLiningOptionSelected,
  getCmtLiningComponents,
  isCustomLining,
} from 'modules/fabric';
import { OrderItemFooter } from 'modules/orderItem';
import router from 'next/router';
import { useState } from 'react';
import { uiVar } from 'graphql/common';
import { toast } from 'react-toastify';
import mixpanelService from 'services/mixpanel.service';
import { FabricLiningImage } from 'modules/fabric/components/FabricLiningImage';

const LiningOptions = ({ options }: { options: LiningOptionType }) => {
  if (!isCmtLiningOptionSelected(options)) {
    return null;
  }

  const cmtLiningComponents = getCmtLiningComponents(options);

  return (
    <div className={cn(BASE_FABRIC_CARD_CLASSES, 'border-neutral-200', 'h-full')}>
      <FabricLiningImage className="h-32 min-w-32 my-auto" type="lining" />
      <div className="grow mx-2">
        <div className="flex justify-between pb-1">
          <span className="font-bold">Custom Lining</span>
        </div>
        {!isCustomLining(cmtLiningComponents.vendor) && (
          <div className="flex justify-between">
            <span className="mr-6">Vendor:</span>
            <span className="font-medium">{cmtLiningComponents.vendor}</span>
          </div>
        )}
        <div className="flex justify-between">
          <span className="mr-6">Code:</span>
          <span className="font-medium">{cmtLiningComponents.code}</span>
        </div>
        <div className="flex justify-between">
          <span className="mr-6">Quantity:</span>
          <span className="font-medium">{cmtLiningComponents.quantity}</span>
        </div>
        {isCustomLining(cmtLiningComponents.vendor) && (
          <div className="flex justify-between">
            <span className="mr-6">Composition:</span>
            <span className="font-medium">{cmtLiningComponents.composition}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export const OrderItemSummary = ({ isTemplate = false }: { isTemplate?: boolean }) => {
  const ui = useReactiveVar(uiVar);
  const { orderId, itemId } = router.query;
  const [name, setName] = useState('');
  const [updateItem] = useUpdateOrderItemSummaryMutation();

  const { data: orderItemData, loading: isLoading } = useOrderItemSummaryPageQuery({
    variables: { orderItemId: parseRouteId(itemId), isTemplate },
    onCompleted(data) {
      setName(data.orderItem.name);
    },
  });

  const fabric = orderItemData?.orderItem.fabric;
  const customFabric = orderItemData?.orderItem.customFabric;
  const lining = orderItemData?.orderItem.lining;

  const handleSave = async () => {
    if (name && name !== orderItemData.orderItem.name) {
      try {
        uiVar('saving');
        await updateItem({ variables: { orderItemId: parseRouteId(itemId), orderItemInput: { name }, isTemplate } });
      } catch (e) {
        toast.error(`${e?.message ?? `Couldn't save update.`}`, { autoClose: false });
        console.error(e);
      } finally {
        uiVar('ready');
      }
    }
    mixpanelService.track('ORDER_ITEM_DESIGN_OPTION_SAVE', { orderId: String(orderId), orderItemId: String(itemId) });
    router.push(isTemplate ? { pathname: `/design-templates`, query: { category: orderItemData.orderItem.productCategory } } : `/orders/${orderId}`);
  };

  const handleBack = () => {
    router.push(isTemplate ? `/design-templates/${itemId}/design-options` : `/orders/${orderId}/order-item/${itemId}/design-options`);
  };

  if (isLoading) {
    return <TaperLoading isCentred />;
  }

  return (
    <>
      <div className="mb-40 mr-4">
        <div className="flex justify-center">
          <div className="w-full pt-4">
            <div className="w-80">
              <Input label="Name" htmlProps={{ type: 'text', value: name, onChange: ({ currentTarget }) => setName(currentTarget.value) }} />
            </div>
            <div className="flex mt-8 flex-col xl:flex-row">
              <div className="relative flex-1 pt-8 mb-8 xl:mb-0 xl:mr-4">
                <div className="absolute top-0 text-lg font-semibold mb-2">Fabric</div>
                {!(fabric?.id || customFabric) && <div className="italic text-neutral-600">No fabric selected</div>}
                {fabric?.id && <FabricOrLiningCard className="lg:h-full" fabricOrLining={{ ...fabric, typename: 'fabric' }} />}
                {customFabric && (
                  <FabricOrLiningCard className="lg:h-full" fabricOrLining={{ name: customFabric, vendor: 'Custom', typename: 'fabric' }} />
                )}
              </div>
              {orderItemData.orderItem.productCategorySetting.requiresLining && (
                <div className="relative flex-1 pt-8">
                  <div className="absolute top-0 text-lg font-semibold mb-2">Lining</div>
                  {!lining?.code && !isCmtLiningOptionSelected(orderItemData?.orderItem?.liningOptions) && (
                    <div className="italic text-neutral-600">No lining selected</div>
                  )}
                  {lining?.code && <FabricOrLiningCard className="lg:h-full" fabricOrLining={{ ...lining, typename: 'lining' }} />}
                  {isCmtLiningOptionSelected(orderItemData?.orderItem?.liningOptions) && (
                    <LiningOptions options={orderItemData?.orderItem?.liningOptions} />
                  )}
                </div>
              )}
            </div>
            <div className="text-lg font-semibold my-10">Design Options</div>
            {orderItemData?.orderItem.designs.length > 1 && (
              <Tabs
                tabs={orderItemData.orderItem.designs.map((design) => {
                  return {
                    name: design.garmentCategory.name,
                    content: (
                      <DesignOptionsFormProvider isTemplate={isTemplate} selectedDesignId={design.id}>
                        <DesignOptionsSummaryListContainer designId={design.id} isTemplate={isTemplate} />
                      </DesignOptionsFormProvider>
                    ),
                  };
                })}
              ></Tabs>
            )}
            {orderItemData?.orderItem.designs.length === 1 && (
              <>
                <DesignOptionsFormProvider isTemplate={isTemplate} selectedDesignId={orderItemData?.orderItem.designs[0].id}>
                  <DesignOptionsSummaryListContainer designId={orderItemData?.orderItem.designs[0].id} isTemplate={isTemplate} />
                </DesignOptionsFormProvider>
              </>
            )}
          </div>
        </div>
      </div>
      <OrderItemFooter
        isTemplate={isTemplate}
        backButton={
          <Button variant="neutral" onClick={handleBack} isDisabled={ui === 'saving'}>
            Previous
          </Button>
        }
        nextButton={
          <Button onClick={handleSave} isDisabled={ui === 'saving'}>
            Finish
          </Button>
        }
      />
    </>
  );
};

OrderItemSummary.fragments = {
  orderItem: gql`
    ${FabricList.fragments.list}
    ${LiningList.fragments.list}

    fragment OrderItemSummaryPageFragment on OrderItem {
      id
      name
      isTemplate
      productCategory
      productCategorySetting {
        key
        requiresLining
      }
      designs {
        id
        garmentCategory {
          key
          name
        }
      }
      fabric {
        ...FabricList
      }
      customFabric
      lining {
        ...LiningList
      }
      liningOptions {
        enableCmtLining
        enableCustomLining
        cmtDefaultOptions {
          key
          value
        }
        cmtOptions {
          key
          value
        }
      }
    }
  `,
};

OrderItemSummary.query = gql`
  ${OrderItemSummary.fragments.orderItem}

  query OrderItemSummaryPage($orderItemId: ID!, $isTemplate: Boolean!) {
    orderItem(orderItemId: $orderItemId, isTemplate: $isTemplate) {
      ...OrderItemSummaryPageFragment
    }
  }
`;

OrderItemSummary.mutation = gql`
  mutation UpdateOrderItemSummary($orderItemId: ID!, $orderItemInput: UpdateOrderItemInput!, $isTemplate: Boolean!) {
    updateOrderItem(orderItemId: $orderItemId, orderItemInput: $orderItemInput, isTemplate: $isTemplate) {
      id
    }
  }
`;
