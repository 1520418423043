import cn from 'classnames';
import { gql } from '@apollo/client';
import { useDuplicateOrderMutation, OrderPageQuery, useGetDuplicateOrderCustomersLazyQuery } from '@graphql';
import { Icon, Modal } from 'components';
import { debounce } from 'lodash';
import { Button } from 'modules/common';
import router from 'next/router';
import { useMemo, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import mixpanelService from 'services/mixpanel.service';

interface Option {
  readonly label: string;
  readonly value: string;
  readonly disabled?: boolean;
}

interface Props {
  order: OrderPageQuery['order'];
  isDisabled?: boolean;
}

export const DuplicateOrderButton = ({ order, isDisabled = false }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [duplicateOrder] = useDuplicateOrderMutation();

  const [searchCustomers, { loading: queryLoading }] = useGetDuplicateOrderCustomersLazyQuery();

  const searchOptions = useMemo(
    () =>
      debounce((value: string, callback: (options: Option[]) => void) => {
        searchCustomers({ variables: { search: value } }).then(({ data }) => {
          const newOptions = data.customers.items
            .map((customer) => ({
              value: customer.id,
              label: `${customer.firstName} ${customer.lastName} ${customer.customId ? `- ${customer.customId}` : ''}`,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
          callback(newOptions);
        });
      }, 500),
    [searchCustomers]
  );

  const handleClickDuplicate = async () => {
    try {
      setIsSaving(true);
      mixpanelService.track('ORDER_DUPLICATE', { orderToDuplicateId: order.id });
      const { data } = await duplicateOrder({
        variables: {
          orderId: order.id,
          duplicateOrderInput: {
            customId: `${order.customId} (duplicate)`,
            customerId: selectedOption.value,
          },
        },
      });
      setIsSaving(false);
      toast.success('Order duplicated');
      router.push(`/orders/${data.duplicateOrder.id}`);
    } catch (e) {
      setIsSaving(false);
      console.error(e.message);
      toast.error('Encountered an error duplicating the order.');
    }
  };

  const loading = queryLoading || isSaving;

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)} variant="alternative" isText isDisabled={isDisabled} className="mr-6">
        <div
          className={cn('flex items-center text-xs', {
            'text-gray-500': !isDisabled,
            'text-gray-300': isDisabled,
          })}
        >
          <Icon icon="duplicate" className="mr-2" />
          Duplicate order
        </div>
      </Button>
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <div className="font-semibold text-center text-lg mb-6">Duplicate order</div>
        {/* TODO: create a wrapper component for react-select Select */}
        <AsyncSelect
          cacheOptions
          className="text-sm"
          isDisabled={isSaving}
          placeholder={'Assign a customer'}
          isSearchable={true}
          styles={{
            control: (base, state) => ({
              ...base,
              border: state.isFocused ? '2px solid #6b7280' : '1px solid #d1d5db',
              boxShadow: 'none',
              '*': {
                boxShadow: 'none !important',
              },
              '&:hover': {
                border: '2px solid #6b7280',
              },
              borderRadius: '6px',
              padding: '2px 0',
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          menuPortalTarget={document.body}
          value={selectedOption}
          onChange={(value) => setSelectedOption(value)}
          isOptionDisabled={(option) => option.disabled}
          loadOptions={(value, callback) => {
            if (value.length < 3) {
              searchOptions.cancel();
              callback([{ label: 'Write at least 3 characters', value: '', disabled: true }]);
            } else {
              searchOptions(value, callback);
            }
          }}
          isLoading={queryLoading}
        />
        <div className="flex justify-end mt-6">
          <Button variant="neutral" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button className="ml-2" onClick={handleClickDuplicate} isDisabled={loading || !selectedOption}>
            Duplicate
          </Button>
        </div>
      </Modal>
    </>
  );
};

DuplicateOrderButton.query = gql`
  query GetDuplicateOrderCustomers($search: String) {
    customers(search: $search) {
      items {
        id
        customId
        firstName
        lastName
      }
    }
  }
`;

DuplicateOrderButton.mutation = gql`
  mutation DuplicateOrder($orderId: ID!, $duplicateOrderInput: DuplicateOrderInput!) {
    duplicateOrder(orderId: $orderId, duplicateOrderInput: $duplicateOrderInput) {
      id
      __typename
    }
  }
`;
