import React, { ReactNode, useMemo } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';

type Props = {
  children?: ReactNode;
  className?: string;
  total: number;
  completed: number;
  variant?: 'success' | 'danger' | 'warning';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const BASE_CLASSES = ['inline-block'];
const BASE_CONTENT_CLASSES = ['absolute', 'top-1/2', 'left-1/2', 'transform', '-translate-y-1/2', '-translate-x-1/2'];

export const ProgressWheel = ({ children, className, total, completed, size = 'md', variant = 'success' }: Props) => {
  const classes = cn(className, BASE_CLASSES);
  const contentClasses = cn(BASE_CONTENT_CLASSES, {
    'mt-2': size === 'md' || size === 'lg' || size === 'xl',
  });

  const percentage = useMemo(() => {
    if (!total) {
      return 0;
    }

    return completed <= total ? (completed / total) * 100 : 100;
  }, [total, completed]);

  const color = useMemo(() => {
    switch (variant) {
      case 'success':
        return '#6366F1';
      case 'warning':
        return 'orange';
      case 'danger':
        return 'red';
    }
  }, [variant]);

  const { length, strokeWidth, strokeOffset, innerStrokeWidth } = useMemo(() => {
    switch (size) {
      case 'xs':
        return { length: 100, strokeWidth: 10, strokeOffset: 0, innerStrokeWidth: 5 };
      case 'sm':
        return { length: 150, strokeWidth: 16, strokeOffset: 0, innerStrokeWidth: 8 };
      case 'md':
      default:
        return { length: 248, strokeWidth: 32, strokeOffset: 0, innerStrokeWidth: 16 };
      case 'lg':
        return { length: 300, strokeWidth: 38, strokeOffset: 0, innerStrokeWidth: 20 };
      case 'xl':
        return { length: 400, strokeWidth: 54, strokeOffset: 0, innerStrokeWidth: 28 };
    }
  }, [size]);

  const viewBox = useMemo(() => `0 0 ${length} ${length}`, [length]);
  const radius = useMemo(() => (length - strokeWidth) / 2, [length, strokeWidth]);
  const strokeDasharray = useMemo(() => radius * Math.PI * 2, [size, percentage]);
  const strokeDashoffset = useMemo(() => strokeDasharray - (strokeDasharray * percentage) / 100, [strokeDasharray, percentage]);

  return (
    <div className={classes}>
      <div className="relative">
        <svg className="overflow-visible" width={length} height={length} viewBox={viewBox}>
          <circle fill="none" stroke="#E0E7FF" cx={length / 2} cy={length / 2} r={radius + strokeOffset} strokeWidth={`${innerStrokeWidth}px`} />
          <motion.circle
            fill="none"
            stroke={color}
            cx={length / 2}
            cy={length / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            strokeDashoffset={strokeDasharray}
            transform={`rotate(-90 ${length / 2} ${length / 2})`}
            animate={{ strokeDasharray, strokeDashoffset }}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          />
        </svg>
        {children && <div className={contentClasses}>{children}</div>}
      </div>
    </div>
  );
};
