import { useState } from 'react';
import { toast } from 'react-toastify';
import { gql } from '@apollo/client';
import { GetAlterationOptionsQuery, useUpsertAlterationProviderOptionMutation } from '@graphql';
import { MoneyInput, Spinner } from 'components';

interface Props {
  cost: number;
  alterationOption: GetAlterationOptionsQuery['alterationOptions'][0];
  alterationProviderId: string;
  isActive: boolean;
  lastUpdated: string;
}

export const AlterationsOption = (props: Props) => {
  const { cost, alterationOption, alterationProviderId, isActive, lastUpdated } = props;

  const [isUpdated, setIsUpdated] = useState(false);

  const [upsertOption, { loading: mutationLoading }] = useUpsertAlterationProviderOptionMutation({
    onCompleted: () => {
      setIsUpdated(true);
    },

    onError: () => {
      toast.error('Please try again.');
    },
  });

  const onStatusChange = async (e) => {
    await upsertOption({
      variables: {
        alterationProviderOptionInput: {
          alterationProviderId: alterationProviderId,
          alterationOptionId: alterationOption.id,
          isActive: e.currentTarget.checked,
        },
      },
    });
  };

  const onCostChange = async (e, type) => {
    const _newCost = Number(e.currentTarget.value);

    if (!!_newCost && _newCost !== cost) {
      if (type === 'blur' || (type === 'key' && e.key === 'Enter'))
        await upsertOption({
          variables: {
            alterationProviderOptionInput: {
              alterationProviderId: alterationProviderId,
              alterationOptionId: alterationOption.id,
              cost: Number(e.currentTarget.value),
            },
          },
        });
    }
  };

  return (
    <div className="grid grid-cols-12 gap-8 border-t text-sm">
      <div className="col-span-1 p-2 flex items-center justify-center">
        <input
          type="checkbox"
          className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded cursor-pointer"
          defaultChecked={isActive}
          onChange={onStatusChange}
        />
      </div>

      <div className="col-span-3 p-2 flex items-center">{alterationOption.name}</div>
      <div className="col-span-3 p-2 flex items-center">
        <span className="bg-emerald-50 py-1 px-2 text-xs rounded text-emerald-600 font-semibold">{alterationOption.garmentCategory.name}</span>
      </div>

      <div className="col-span-3 p-2 flex items-center justify-end">
        <MoneyInput
          htmlProps={{
            defaultValue: cost,
            onBlur: (e) => onCostChange(e, 'blur'),
            onKeyPress: (e) => onCostChange(e, 'key'),
          }}
        />
      </div>

      <div className="col-span-2 p-2 flex items-center justify-center text-center">
        {mutationLoading ? (
          <Spinner size="sm" />
        ) : (
          <>
            {isUpdated ? (
              <span className="bg-emerald-50 py-1 px-2 text-xs rounded text-emerald-600 font-semibold">Updated</span>
            ) : (
              <span className="italic text-gray-400">{lastUpdated}</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

AlterationsOption.mutations = {
  upsertAlterationProviderOption: gql`
    mutation UpsertAlterationProviderOption($alterationProviderOptionInput: AlterationProviderOptionInput!) {
      upsertAlterationProviderOption(alterationProviderOptionInput: $alterationProviderOptionInput) {
        id
        alterationOption {
          isActive
        }
        cost {
          amount
        }
        isActive
      }
    }
  `,
};
