import { useState } from 'react';
import { gql } from '@apollo/client';
import { OrderItemFabricTabsLayoutFragment, useLiningListQuery, ProductCategory } from '@graphql';
import { Search, TaperLoading } from 'modules/common';
import { FabricOrLiningCard } from 'modules/fabric/components/FabricOrLiningCard';
import mixpanelService from 'services/mixpanel.service';

interface Props {
  onSelectLining?: (lining: OrderItemFabricTabsLayoutFragment['lining']) => void;
  selectedLining?: OrderItemFabricTabsLayoutFragment['lining'];
  itemId?: string;
  productCategory?: ProductCategory;
}

export const LiningList = ({ onSelectLining, selectedLining, itemId, productCategory }: Props) => {
  const [searchText, setSearchText] = useState(selectedLining?.name || '');
  const { data, loading: isLoading } = useLiningListQuery({ variables: { text: searchText, productCategory }, fetchPolicy: 'cache-first' });

  const searchCoreLinings = data?.searchCoreLinings || [];
  const orderedLinings = [...searchCoreLinings].sort((a, b) => a.status.localeCompare(b.status)) || [];

  const handleSelectLining = (lining: OrderItemFabricTabsLayoutFragment['lining']) => {
    if (onSelectLining != null) {
      mixpanelService.track('ORDER_ITEM_LINING_SELECTED', { ...lining, orderItemId: itemId, searchText });
      onSelectLining(lining);
    }
  };

  return (
    <>
      <Search value={searchText} onChange={setSearchText} />
      {isLoading && (
        <div className="w-full h-96 flex items-center justify-center">
          <TaperLoading />
        </div>
      )}
      {!isLoading && (
        <>
          {!orderedLinings.length || !searchText ? (
            <div className="w-full h-96 flex items-center justify-center">{searchText ? 'No lining results' : 'Use search to find lining'}</div>
          ) : (
            <div className="mt-6 grid grid-cols-1 xl:grid-cols-2 gap-5">
              {orderedLinings.map((lining) => (
                <FabricOrLiningCard
                  key={lining.id}
                  fabricOrLining={{ ...lining, typename: 'lining' }}
                  handleOnClick={() => handleSelectLining(lining)}
                  isSelected={selectedLining?.id === lining.id}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

LiningList.fragments = {
  list: gql`
    fragment LiningList on CoreLining {
      id
      name
      code
      status
      liningLevel
      replenishmentDate
      stock
      imageUrl
    }
  `,
};

LiningList.query = gql`
  ${LiningList.fragments.list}

  query LiningList($text: String!, $productCategory: ProductCategory) {
    searchCoreLinings(text: $text, productCategory: $productCategory) {
      ...LiningList
    }
  }
`;
