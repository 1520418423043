import { OrderPageQuery } from '@graphql';
import { areSelectedCmtLiningValid } from 'modules/fabric';
import { ArrayElement } from 'types/common';

// If any of these design options are selected, monogram text is required
export const MONOGRAM_DESIGN_OPTIONS = [
  'T01024401',
  'T01052201',
  'T01172701',
  'T01063301',
  'T01042301',
  'T01162701',
  'T01162702',
  'T02724101',
  'T02724102',
  'T02742701',
  'T02731101',
  'T01493901',
  'T01693901',
  'T01134301',
  'T01074401',
];

class OrderService {
  // TODO: split this logic into checking item and checking design
  getItemDesignDetails = (item: ArrayElement<OrderPageQuery['order']['items']>) => {
    const designs = item.designs;
    const requiresLining = item.productCategorySetting.requiresLining;
    const errors: string[] = [];

    if (requiresLining && !item.lining && !areSelectedCmtLiningValid(item.liningOptions)) {
      errors.push('Please select lining.');
    }

    // TODO: this should no longer be possible
    if (designs?.find((design) => !design.options.length)) {
      errors.push('Design options incomplete.');
    }

    if (!item?.fabric && !item.customFabric) {
      errors.push('No fabric selected.');
    }

    if (designs?.find((design) => design.conflicts.length)) {
      errors.push('Please resolve design conflicts.');
    }

    if (designs?.length && item?.fabric && !['', 'AVAILABLE', 'LOW STOCK', 'CHECK SUPPLIER STOCK'].includes(item?.fabric?.status?.toUpperCase())) {
      errors.push('Fabric out of stock.');
    }

    if (
      designs?.length &&
      requiresLining &&
      item?.lining &&
      !['', 'AVAILABLE', 'LOW STOCK', 'CHECK SUPPLIER STOCK'].includes(item?.lining?.status?.toUpperCase())
    ) {
      errors.push('Lining out of stock.');
    }

    return {
      isFinished: designs?.length && !errors.length,
      errorMessage: errors.join(' '),
    };
  };

  // TODO: monitor hiding errors from users, and keep an eye on needing to add more things to DayangErrorMapping
  parseDayangOrJeromeError = (message: string[] | string | null) => {
    const errors = [];
    const DayangErrorMapping: Record<string, string> = {
      'fabric is not Exist': 'The fabric does not exist.',
      'lining is not Exist': 'The lining does not exist.',
      'the fabric is not Available': 'Fabric out of stock.',
      'the lining is not Available': 'Lining out of stock.',
      'The fabric is out of stock': 'Fabric out of stock.',
      'The lining is out of stock': 'Lining out of stock.',
    };

    Object.keys(DayangErrorMapping).forEach((error) => {
      if (message?.includes(error)) {
        errors.push(DayangErrorMapping[error]);
      }
    });

    return errors.join(' ');
  };
}

export const orderService = new OrderService();
