import { OrderPageQuery } from '@graphql';
import { formatAsCurrency } from 'helpers';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { ArrayElement } from 'types/common';

type Props = {
  item: ArrayElement<OrderPageQuery['order']['items']>;
};

export const OrderItemPriceBreakdown = ({ item }: Props) => {
  const { tailor } = useActiveSession();
  const formatPrice = (amount: number) => formatAsCurrency(amount, tailor.locale, tailor.currency, false);

  const { productCategorySetting, retail } = item;

  return (
    <div className="text-white w-72 text-left p-6">
      <div className="flex flex-wrap border-b border-neutral-800 pb-4">
        <div className="text-yellow-crayola-500 font-semibold mr-4">{productCategorySetting.name}</div>
        <div>Price Breakdown</div>
      </div>
      <div className="flex flex-col">
        <div className="mt-5 mb-2 flex items-center justify-between">
          <div className="flex-initial">Fabric</div>
          <div className="shrink-0">{formatPrice(retail.base)}</div>
        </div>
        {item.productCategorySetting.requiresLining && (
          <div className="my-2 flex items-center justify-between">
            <div className="flex-initial">Lining</div>
            <div className="shrink-0">{formatPrice(retail.lining)}</div>
          </div>
        )}
        {Boolean(retail.designOptions) && (
          <div className="my-2 flex items-center justify-between">
            <div className="flex-initial">Design options</div>
            <div className="shrink-0">{formatPrice(retail.designOptions)}</div>
          </div>
        )}
        {Boolean(retail.discount) && (
          <div className="my-2 flex items-center justify-between">
            <div className="flex-initial">Discount Code</div>
            <div className="shrink-0">{retail.discount.code}</div>
          </div>
        )}
        {Boolean(retail.discountedAmount > 0) && (
          <div className="my-2 flex items-center justify-between">
            <div className="flex-initial">Discount Value</div>
            <div className="shrink-0">{formatPrice(retail.discountedAmount)}</div>
          </div>
        )}
        <div className="my-2 flex items-center justify-between pt-2 border-t border-neutral-700">
          <div className="flex-initial">Total</div>
          <div className="shrink-0">{retail.discountedPrice}</div>
        </div>
      </div>
    </div>
  );
};
