import { RemakeReason } from '@graphql';
import { Reducer, useReducer } from 'react';
import { OrderItem } from '../../types';
import { createRemakeItemsState } from './helpers';
import { RemakeItem } from './types';

type RemakeState = RemakeItem[];

const INITIAL_STATE: RemakeState = [];

export const actions = {
  initialize: (items: OrderItem[]) => ({ type: 'INITIALIZE_STATE' as const, payload: { items } }),
  switchOrderItem: (orderItemId: string) => ({ type: 'SWITCH_ORDER_ITEM' as const, payload: { orderItemId } }),
  switchOrderItemDesign: (orderItemId: string, orderItemDesignId: string) => ({
    type: 'SWITCH_ORDER_ITEM_DESIGN' as const,
    payload: { orderItemId, orderItemDesignId },
  }),
  updateRemakeReason: (orderItemId: string, reason: RemakeReason) => ({
    type: 'UPDATE_ORDER_ITEM_DESIGN_REMAKE_REASON' as const,
    payload: { orderItemId, reason },
  }),
  updateRemakeDescription: (orderItemId: string, description: string) => ({
    type: 'UPDATE_ORDER_ITEM_DESIGN_REMAKE_DESC' as const,
    payload: { orderItemId, description },
  }),
};

type Actions = ReturnType<(typeof actions)[keyof typeof actions]>;

const reducer: Reducer<RemakeState, Actions> = (prevRemakeItems, { type, payload }) => {
  switch (type) {
    case 'INITIALIZE_STATE':
      return createRemakeItemsState(payload.items);
    case 'SWITCH_ORDER_ITEM': {
      const indexToChange = prevRemakeItems.findIndex((item) => item.orderItemId === payload.orderItemId);
      const orderItemRemake = prevRemakeItems[indexToChange];
      return [
        ...prevRemakeItems.slice(0, indexToChange),
        {
          ...orderItemRemake,
          isChecked: !orderItemRemake.isChecked,
          designs: orderItemRemake.designs.map((d) => ({ ...d, isChecked: !orderItemRemake.isChecked })),
        },
        ...prevRemakeItems.slice(indexToChange + 1),
      ];
    }
    case 'SWITCH_ORDER_ITEM_DESIGN': {
      const orderItemIndexToChange = prevRemakeItems.findIndex((item) => item.orderItemId === payload.orderItemId);
      const orderItemRemake = prevRemakeItems[orderItemIndexToChange];
      const orderItemDesignIndexToChange = orderItemRemake.designs.findIndex((item) => item.orderItemDesignId === payload.orderItemDesignId);
      const orderItemDesignRemake = orderItemRemake.designs[orderItemDesignIndexToChange];

      const newDesigns = [
        ...orderItemRemake.designs.slice(0, orderItemDesignIndexToChange),
        { ...orderItemDesignRemake, isChecked: !orderItemDesignRemake.isChecked },
        ...orderItemRemake.designs.slice(orderItemDesignIndexToChange + 1),
      ];

      return [
        ...prevRemakeItems.slice(0, orderItemIndexToChange),
        {
          ...orderItemRemake,
          isChecked: newDesigns.some((d) => d.isChecked),
          designs: newDesigns,
        },
        ...prevRemakeItems.slice(orderItemIndexToChange + 1),
      ];
    }
    case 'UPDATE_ORDER_ITEM_DESIGN_REMAKE_REASON': {
      const indexToChange = prevRemakeItems.findIndex((item) => item.orderItemId === payload.orderItemId);
      const orderItemRemake = prevRemakeItems[indexToChange];
      return [
        ...prevRemakeItems.slice(0, indexToChange),
        {
          ...orderItemRemake,
          remakeReason: payload.reason,
        },
        ...prevRemakeItems.slice(indexToChange + 1),
      ];
    }
    case 'UPDATE_ORDER_ITEM_DESIGN_REMAKE_DESC': {
      const indexToChange = prevRemakeItems.findIndex((item) => item.orderItemId === payload.orderItemId);
      const orderItemRemake = prevRemakeItems[indexToChange];
      return [
        ...prevRemakeItems.slice(0, indexToChange),
        {
          ...orderItemRemake,
          remakeDescription: payload.description,
        },
        ...prevRemakeItems.slice(indexToChange + 1),
      ];
    }
    default:
      return prevRemakeItems;
  }
};

export const useRemakeReducer = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return {
    state,
    dispatch,
  };
};
