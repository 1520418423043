import { Icon } from 'components';
import { Button } from 'modules/common';

export const PrintButton = () => {
  const onClick = async (e) => {
    e.preventDefault();
    window.print();
  };

  return (
    <Button onClick={onClick} variant="alternative" isText>
      <div className="text-gray-500 flex items-center text-xs">
        <Icon icon="printer" className="mr-2" />
        Print Order
      </div>
    </Button>
  );
};
