import { setClassName } from 'helpers/pdf/table-helpers';
import { useMeasurementUnit } from 'modules/common/MeasurementUnit';
import { OrderDetailsPrintSectionHeader } from 'modules/orders/components/OrderDetailsPrintSectionHeader';
import { OrderDetailsPrintTableCell } from 'modules/orders/components/OrderDetailsPrintTableCell';
import { DesignItem } from 'modules/orders/orders.types';

interface Props {
  designItems: DesignItem[];
}

export const OrderDetailsPrintMeasurements = ({ designItems }: Props) => {
  const {
    convertFromServerUnit,
    measurementUnitInfo: { label },
  } = useMeasurementUnit();
  return (
    <div style={{ breakBefore: 'page' }}>
      <OrderDetailsPrintSectionHeader label="Measurements" />
      <div className="uppercase text-6 ml-px">
        {designItems.map(({ item, design }, i) => (
          <div key={`measurementRow_${i}`}>
            <div className="flex bg-neutral-200">
              <OrderDetailsPrintTableCell content="#" className="w-4" />
              <OrderDetailsPrintTableCell content="Ref ID" className="w-13" />
              <OrderDetailsPrintTableCell content="Class" className="w-14" />
              {design.measurement?.finishedMeasurementFields?.map((measurement, mIdx) => (
                <OrderDetailsPrintTableCell
                  key={`measurementHeader_${design.id}_${measurement.key}_${mIdx}`}
                  content={measurement.label}
                  className="flex-1"
                />
              ))}
            </div>
            <div className="flex text-6">
              <OrderDetailsPrintTableCell content={i + 1} bold className="w-4" />
              <OrderDetailsPrintTableCell content={design.id} truncate className="w-13" />
              <OrderDetailsPrintTableCell content={setClassName(item, design)} className="w-14" />
              {design.measurement?.finishedMeasurementFields?.map((measurement, mIdx) => (
                <OrderDetailsPrintTableCell
                  key={`measurementRow_${design.id}_${measurement.key}_${mIdx}`}
                  content={measurement.value ? `${convertFromServerUnit(measurement.value)}${label}` : undefined}
                  className="flex-1"
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
