import { useState } from 'react';

import { Icon } from 'components';
import { VerticalMenuSelection } from 'modules/common';
import { ConfigGroup } from 'types/common';
import { useDesignOptionsForm } from '../designOptions.context';

export const DesignOptionsGroupsMenu = () => {
  const { conflicts, config, selectedGroup, setSelectedGroup } = useDesignOptionsForm();
  const [showExtras, setShowExtras] = useState(false);

  const errors = conflicts?.flatMap((c) =>
    [...config.extras, ...config.foundations]
      .filter((o) => o.subGroups.flatMap((sg) => sg.types).some((f) => [c.conflictOption.typeCode, c.sourceOption.typeCode].includes(f.code)))
      .map((c) => c.name)
  );

  if (config?.extras?.length && config?.foundations?.length) {
    return (
      <div className="flex-1 overflow-y-auto max-w-design-options-group-menu">
        <div className="border-r border-neutral-300 min-h-full py-3">
          <div className="mr-6">
            <div className="font-semibold mb-3 pb-4 border-b border-neutral-300">Foundations</div>
            <VerticalMenuSelection<ConfigGroup>
              options={config.foundations.map((group) => {
                return { content: group.name, value: group };
              })}
              onClick={setSelectedGroup}
              selectedItemValue={selectedGroup}
              errors={errors}
            />
            <div
              className="flex justify-between items-center mb-3 pb-4 border-b border-neutral-300 mt-8 cursor-pointer"
              onClick={() => setShowExtras(!showExtras)}
            >
              <div className="font-semibold">Extras</div>
              <Icon icon={showExtras ? 'chevron-up' : 'chevron-down'} />
            </div>

            {showExtras && (
              <VerticalMenuSelection<ConfigGroup>
                options={config.extras.map((group) => {
                  return { content: group.name, value: group };
                })}
                onClick={setSelectedGroup}
                selectedItemValue={selectedGroup}
                errors={errors}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <VerticalMenuSelection<ConfigGroup>
      options={config.foundations.map((group) => {
        return { content: group.name, value: group };
      })}
      onClick={setSelectedGroup}
      selectedItemValue={selectedGroup}
      errors={errors}
    />
  );
};
