import { endOfDay, startOfDay, subMonths, subDays } from 'date-fns';

export const getMonthlyRangeFromDate = (date = new Date(), numMonths = 1) => {
  return {
    from: subMonths(date, numMonths),
    to: date,
  };
};

export const getWeeklyRangeFromDate = (date = new Date()) => {
  return {
    from: startOfDay(subDays(date, 6)),
    to: endOfDay(date),
  };
};

export const getAbbreviatedDate = (date = new Date()) => date.toLocaleDateString();
