import { gql } from '@apollo/client';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

import { Button } from 'modules/common';
import { useDeleteMeasurementMutation } from '@graphql';
import { DeleteMeasurementConfirmationModal } from 'modules/measurements/components/DeleteMeasurementConfirmationModal';

interface Props {
  measurementId: string;
  measurementName: string;
  customerId: string;
}

export const DeleteMeasurementButton = ({ measurementId, measurementName, customerId }: Props) => {
  const router = useRouter();
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);

  const [deleteMeasurement, { loading: isDeleting }] = useDeleteMeasurementMutation({
    variables: {
      measurementId: measurementId,
    },

    onCompleted: () => {
      toast.success('Measurement deleted.');

      router.push(`/customers/${customerId}`);
    },

    onError: (error) => {
      toast.error('Something went wrong, try again please.');

      console.error(error);
    },
  });

  return (
    <>
      <Button variant="destructive" onClick={() => setIsConfirmationModalVisible(true)}>
        Delete
      </Button>
      <DeleteMeasurementConfirmationModal
        measurementName={measurementName}
        isVisible={isConfirmationModalVisible}
        setIsVisible={setIsConfirmationModalVisible}
        deleteMeasurement={deleteMeasurement}
        isDeleting={isDeleting}
      />
    </>
  );
};

DeleteMeasurementButton.mutations = {
  DeleteMeasurement: gql`
    mutation DeleteMeasurement($measurementId: ID!) {
      deleteMeasurement(measurementId: $measurementId)
    }
  `,
};
