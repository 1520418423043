import ReactTooltip from 'react-tooltip';

import { OrderItemStatus, OrderPageQuery } from '@graphql';
import { Tooltip } from 'components';
import { orderService } from 'services/order.service';
import { ArrayElement } from 'types/common';

interface Props {
  className?: string;
  status: ArrayElement<OrderPageQuery['order']['items']>['itemStatus'];
  dateLeftFactory: string;
}

const getOrderItemStatusColour = (itemStatus: OrderItemStatus) => {
  switch (itemStatus) {
    case 'ON_HOLD':
      return 'grey';
    case 'LEFT_FACTORY':
    case 'SHIPPED':
    case 'DELIVERED':
      return 'emerald';
    case 'ERROR':
    case 'CANCELLED':
      return 'red';
    case 'AWAITING_FABRIC':
    case 'IN_PRODUCTION':
    case 'PATTERN_MAKING':
    case 'PROCESSED':
    case 'CONFIRMED':
    case 'PRODUCTION_COMPLETE':
    default:
      return 'yellow';
  }
};

export const OrderItemStatusCell = ({ className = '', status, dateLeftFactory }: Props) => {
  const renderStatusCell = () => {
    return (
      <div className={className}>
        {status?.key && (
          <>
            <div
              data-tip={orderService.parseDayangOrJeromeError(status.message)}
              className={`bg-${getOrderItemStatusColour(status.key)}-50 text-${getOrderItemStatusColour(
                status.key
              )}-500 py-1 px-2 rounded-sm text-xs whitespace-nowrap mr-2 flex items-baseline justify-center`}
            >
              <div className={`rounded-full w-2 h-2  mr-2 bg-${getOrderItemStatusColour(status.key)}-400`}></div>
              {status?.name}
            </div>
            {orderService.parseDayangOrJeromeError(status.message) && <ReactTooltip place="top" type="dark" effect="solid" />}
          </>
        )}
      </div>
    );
  };

  if (dateLeftFactory) {
    return (
      <Tooltip
        variant="dark"
        content={
          <div className="w-64 text-white p-2 justify-center">
            <div className="flex">
              <div className="mr-2">Date Left Factory:</div>
              <div>{dateLeftFactory || 'Unavailable'}</div>
            </div>
          </div>
        }
      >
        {renderStatusCell()}
      </Tooltip>
    );
  }

  return renderStatusCell();
};
