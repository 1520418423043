import React, { useMemo } from 'react';
import { DesignOptionsInput } from './DesignOptionsInput';
import { DesignOptionsRadioGroup } from './DesignOptionsRadioGroup';
import { DesignOptionsSelect, SEARCHABLE_TYPES } from './DesignOptionsSelect';
import { DesignOptionsSelectNestedGraphic } from './DesignOptionsSelectNestedGraphic';
import { useDesignOptionsForm } from '../designOptions.context';
import { DesignOptionField } from '../designOptions.types';
import { useReactiveVar } from '@apollo/client';
import { uiVar } from 'graphql/common';

type Props = {
  typeCode: string; // The code for the field as whole, i.e. T01234
};

export const DesignOptionsFormField = ({ typeCode }: Props) => {
  const ui = useReactiveVar(uiVar);
  const { designOptionGroups: designOptions, getIsFieldHidden, conflicts } = useDesignOptionsForm();
  const field: DesignOptionField = useMemo(() => {
    return designOptions
      .flatMap((group) =>
        group.subGroups.flatMap((subGroup) =>
          subGroup.types.flatMap((subGroupType) => {
            if (subGroupType.code === typeCode) {
              const parsedNames = [];
              const parsedCodes = [];
              const parsedIllustrations = [];
              const parsedImages = [];
              const parsedPrices = [];
              const parsedSortOrders = [];
              subGroupType.designTypeOffering.options.forEach((option) => {
                if (!(subGroupType.designTypeOffering.hiddenValues?.includes(option.code) || option.obsolete)) {
                  parsedNames.push(option.name);
                  parsedCodes.push(option.code);
                  parsedIllustrations.push(option.cacheIllustration?.url);
                  parsedImages.push(option.image?.url);
                  parsedPrices.push(option?.priceFormatted?.amount > 0 ? option?.priceFormatted.formatted : null);
                  parsedSortOrders.push(option?.sortOrder);
                  //parsedPrices.push(option?.priceFormatted.formatted);
                }
              });
              return {
                name: typeCode,
                label: subGroupType.name,
                type: subGroupType.designTypeOffering.inputType as DesignOptionField['type'],
                placeholder: subGroupType.designTypeOffering?.placeholder,
                defaultValue: subGroupType.designTypeOffering?.defaultValue,
                maxlength: subGroupType.designTypeOffering?.maxlength,
                texts: parsedNames,
                values: parsedCodes,
                illustrations: parsedIllustrations,
                images: parsedImages,
                prices: parsedPrices,
                sortOrders: parsedSortOrders,
              };
            }
          })
        )
      )
      .find((group) => !!group);
  }, [typeCode, designOptions]);

  const isFieldHidden = getIsFieldHidden(field?.name);

  if (!field || isFieldHidden) return null;

  const conflict = conflicts?.find((c) => c.conflictOption.typeCode === typeCode || c.sourceOption.typeCode === typeCode)?.message || '';
  const len = conflicts?.find((c) => c.conflictOption.typeCode === typeCode || c.sourceOption.typeCode === typeCode)?.maxLength || '';
  const pattern = conflicts?.find((c) => c.conflictOption.typeCode === typeCode || c.sourceOption.typeCode === typeCode)?.pattern || '';

  // T010203 = Jacket Lapel
  if (field.name === 'T010203') {
    return (
      <DesignOptionsSelectNestedGraphic
        groupNames={['Curved wide peak', 'Notch lapel', 'Notch with low gorge', 'Open peak lapel', 'Peak lapel', 'Peak w Low Gorge', 'Shawl collar']}
        subGroupLabel="Lapel Size"
        isDisabled={ui === 'saving'}
        field={field}
        errorMessage={conflict}
      />
    );
  }
  // if (field.name === 'T010509') {
  //   return (
  //     <DesignOptionsSelectNestedGraphic
  //       groupNames={['Wide Long Extension', 'Wide Extension', 'Wide']}
  //       subGroupLabel="Waistband Size"
  //       isDisabled={ui === 'saving'}
  //       field={field}
  //       errorMessage={conflict}
  //     />
  //   );
  // }
  // render illustrations if there are any but not if they're searchable e.g. Button Code (which contains images)
  if (field.illustrations?.filter(Boolean)?.length && !SEARCHABLE_TYPES.includes(field.label)) {
    return <DesignOptionsSelectNestedGraphic subGroupLabel="Lapel Size" isDisabled={ui === 'saving'} field={field} errorMessage={conflict} />;
  }
  if (field.type === 'SELECT' && field.texts.length !== 2)
    return <DesignOptionsSelect className="grow w-48" isDisabled={ui === 'saving'} field={field} errorMessage={conflict} />;
  if (field.type === 'SELECT' && field.texts.length === 2)
    return <DesignOptionsRadioGroup className="grow w-48" isDisabled={ui === 'saving'} field={field} errorMessage={conflict} />;
  if (field.type === 'TEXT' || field.type === 'NUMBER')
    return <DesignOptionsInput isDisabled={ui === 'saving'} field={field} errorMessage={conflict} maxLength={len} regexPattern={pattern} />;
};
