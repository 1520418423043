import { SlideoverPanel } from 'components';
import { Button } from 'modules/common';
import JSONPretty from 'react-json-pretty';

interface Props {
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  orderPayload: string;
}
export const OrderPayloadDrawer = ({ isOpen, onClose, orderPayload }: Props) => {
  return (
    <SlideoverPanel
      isOpen={isOpen}
      setIsOpen={onClose}
      maxWidthCss="max-w-6xl"
      showCancel={false}
      showExit={false}
      submitButton={<Button onClick={() => onClose(false)}>Close</Button>}
    >
      <JSONPretty id="json-pretty" data={orderPayload.replace('Order Payload: ', '')}></JSONPretty>
    </SlideoverPanel>
  );
};
