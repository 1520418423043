import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import cn from 'classnames';

interface Item {
  name: string;
  path: string;
  isDisabled?: boolean;
}

interface DropdownProps {
  name: string;
  items: Item[];
}

export const Dropdown = (props: DropdownProps): React.ReactElement => {
  const { name, items } = props;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex items-center justify-center w-full px-3 h-8 text-xs border border-gray-300 rounded-md font-medium text-gray-700 hover:bg-gray-50">
        {name}
        <ChevronDownIcon className="w-5 h-5 ml-1 -mr-1 text-gray-500" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-64 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black/5 focus:outline-none">
          {items.map((item, index) => (
            <div className="px-1 py-1" key={index}>
              <Menu.Item>
                {({ active }) => (
                  <a
                    className={cn(`group flex rounded-md items-center w-full px-2 py-2 text-xs text-left`, {
                      'bg-gray-100': active,
                      'text-gray-400 pointer-events-none': item?.isDisabled,
                    })}
                    href={item.path}
                  >
                    {item.name}
                  </a>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
