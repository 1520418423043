import { RemakeReason } from '@graphql';
import { REMAKE_OPTIONS } from '../constants';

type Props = {
  reason: RemakeReason;
  description: string;
};

export const RemakeReasonSection = ({ reason, description }: Props) => {
  return (
    <div className="flex flex-col border-b py-4 mx-4">
      <div>
        <span className="text-sm">Remake reason: </span>
        <span className="text-sm font-semibold">{REMAKE_OPTIONS.find((o) => o.key === reason).label}</span>
      </div>
      {description && (
        <div>
          <span className="text-sm">Remake additional info: </span>
          <span className="inline-block text-sm font-semibold">{description}</span>
        </div>
      )}
    </div>
  );
};
