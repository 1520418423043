import { AddItemButton } from '../../orders/components/AddItemButton';
import { Box, Tooltip, Icon } from 'components';

interface OrderItemListNoItemsProps {
  showroomId: string;
  allowAddItem: boolean;
}

export const OrderItemListNoItems = (props: OrderItemListNoItemsProps) => {
  const { showroomId, allowAddItem } = props;

  return (
    <Box>
      <div className="flex items-start justify-between border-b border-neutral-200 pb-6">
        <div className="text-lg">Items</div>
        {allowAddItem && (
          <>
            {Boolean(!showroomId) && (
              <Tooltip content={<div className="p-2 w-52">To add items to this order, please set the showroom.</div>}>
                <AddItemButton isDisabled />
              </Tooltip>
            )}
            {Boolean(showroomId) && <AddItemButton />}
          </>
        )}
      </div>
      <div className="flex flex-col items-center justify-center h-96">
        {allowAddItem && showroomId && (
          <AddItemButton isText>
            <Icon icon="plus" className="text-yellow-crayola-600 bg-yellow-crayola-50 m-2 rounded" />
          </AddItemButton>
        )}
        <div className="">No items added</div>
        <div className="text-neutral-600">
          {showroomId ? 'Get started by creating a new project' : 'To add items to this order, please set the showroom.'}
        </div>
      </div>
    </Box>
  );
};
