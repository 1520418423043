import 'react-sortable-tree/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'tailwindcss/tailwind.css';

import { ApolloProvider } from '@apollo/client';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import { ReactNode, useEffect } from 'react';

import { useFeatureAsync } from 'hooks';
import { useFeature } from 'hooks/useFeature';
import { AppLayout } from 'layouts';
import { ApolloProviderWithAuth0 } from 'providers/apolloAuth0.provider';
import { AuthProvider } from 'providers/auth.provider';
import { ddRumService } from 'services/dd-rum.service';
import mixpanelService from 'services/mixpanel.service';
import apolloClient from '../libs/apollo';
import { IntercomScript } from 'libs/IntercomScript';
import amplitudeService from 'services/amplitude.service';

type NextPageWithLayout = NextPage & {
  getLayout?: (children: ReactNode) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export const baseSiteTitle = 'Taper';

const App = ({ Component, pageProps }): React.ReactElement<AppPropsWithLayout> => {
  const getLayout = Component.getLayout ?? ((page) => <AppLayout>{page}</AppLayout>);
  const { initFeatureService } = useFeature();

  const { enabled: auth0AuthFF, loading: auth0AuthFFLoading } = useFeatureAsync('AUTH_AUTH0');

  useEffect(() => {
    document.body.classList.add('font-inter');
    document.body.classList.add('text-neutral-900');

    (async () => {
      Router.events.on('routeChangeStart', () => {
        NProgress.start();
      });

      Router.events.on('routeChangeComplete', () => {
        NProgress.done();
      });

      Router.events.on('routeChangeError', () => {
        NProgress.done();
      });

      await initFeatureService();
    })();

    // init DataDog RUM
    ddRumService();

    // init Mixpanel
    mixpanelService.init();
    amplitudeService.init();
  }, []);

  if (auth0AuthFFLoading) return <IntercomScript />; // preload

  return (
    <>
      <Head>
        <title>{baseSiteTitle}</title>
        <link rel="stylesheet" type="text/css" href="/nprogress.css" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#222831" />
        <meta name="msapplication-TileColor" content="#222831" />
        <meta name="theme-color" content="#ffffff"></meta>

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap" rel="stylesheet"></link>
      </Head>
      <IntercomScript />
      <ApolloProviderWithAuth0>
        {auth0AuthFF ? (
          <AuthProvider>{getLayout(<Component {...pageProps} />)}</AuthProvider>
        ) : (
          <ApolloProvider client={apolloClient}>
            <AuthProvider>{getLayout(<Component {...pageProps} />)}</AuthProvider>
          </ApolloProvider>
        )}
      </ApolloProviderWithAuth0>
    </>
  );
};

export default App;
