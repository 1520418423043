import React, { ReactElement, ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  testId?: string;
  className?: string;
  options: {
    value: string;
    label: string;
    image?: ReactElement;
  }[];
  value?: string;
  isDisabled?: boolean;
  onChange: (value: string) => void;
  children?: ReactNode;
}

export const ToggleSelect = ({ testId, className = 'flex', options, value, isDisabled, onChange, children }: Props) => {
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const v = (e.currentTarget as HTMLButtonElement).dataset.value;
    const isActive = v === value;

    // unselect toggle if it's clicked when active
    onChange(isActive ? '' : v);
  };

  return (
    <div className={cn('flex', className)} data-testid={testId} data-value={value}>
      {options.map(({ value: v, label, image }, i) => {
        const isActive = v === value;
        const className = cn(
          `flex items-center justify-center flex-wrap lg:flex-nowrap px-3 text-sm text-gray-700 border border-gray-300 rounded-md`,
          {
            'ml-4': i,
            'py-2': !image,
            'bg-yellow-crayola-500': isActive,
            'cursor-not-allowed': isDisabled,
            'opacity-50': isDisabled && !isActive,
            'w-1/2': options.length === 2,
            'w-full': options.length === 1,
          }
        );

        return (
          <button key={v} className={className} data-value={v} onClick={onClick} disabled={isDisabled}>
            {image && image}
            <span className="w-full">{label}</span>
          </button>
        );
      })}
      {children}
    </div>
  );
};
